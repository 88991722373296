import { CityModel } from '../trip/city.model';
import { TransportTypeModel } from '../trip/transportRes.model';
import {TransferTypeModel} from '../trip/transferType.model';

export class TransportResModel {
	_id?: string;
	notes: string;
	fromCity: string | CityModel = new CityModel();
	pickUpStation: string;
	startTime: string;
	toCity: string | CityModel = new CityModel();
	dropOffStation: string;
	dropOffTime: string;
	transfer_description: string | TransportTypeModel = new TransportTypeModel();
	isPublic: boolean;
	transfer_type: string | TransferTypeModel = new TransferTypeModel();
}

