import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { GroupExpensesPopupComponent } from '../popups/group-expenses-popup/group-expenses-popup.component';
import { GroupExpensesModel } from 'src/app/models/pricing/groupExpenses.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { PricingService } from 'src/app/services/pricing.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
  selector: 'app-pricing-group-expenses',
  templateUrl: './pricing-group-expenses.component.html',
  styleUrls: ['./pricing-group-expenses.component.scss']
})
export class PricingGroupExpensesComponent implements OnInit {

  constructor(public dialog: MatDialog, public pricingService:PricingService) {
  }

  public cols: TableColModel[] = [];
  groupExpensesData:GroupExpensesModel[] = []
  priceRanks:any
  pricesCatagories:number[] = []
  tableHeight:number = 400;

  @Output() emitStep = new EventEmitter()
  @Output() getPricingData = new EventEmitter()
  @Input() tripData:TripModel
  currentCurrency:string = '$'
  ngOnInit() {
	this.priceRanks = this.tripData.pricing.generalDetails.priceRanks
		this.pricesCatagories = this.tripData.pricing.generalDetails.pricesCatagory
	this.createColsTableItem();
	this.groupExpensesData = this.tripData.pricing.groupExpenses.tableData
}

  private createColsTableItem() {
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.GROUND_EXPENSES.GROUP_EX, 'title', ColSize.MEDIUM, 'priceNotes', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.CASH_EXPENSES.SUM,'price', ColSize.MEDIUM, 'price', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.GROUND_EXPENSES.NOTES, 'notes', ColSize.LONG, 'priceNotes', undefined, undefined));
}

removeItem(itemToRemove){
	let index = this.groupExpensesData.findIndex( item => {
		return item === itemToRemove
	})
	this.groupExpensesData.splice(index,1)
	let data = {
		pricingId:this.tripData.pricing._id,
		dataToUpdate: this.groupExpensesData,
		fieldToUpdate:'groupExpenses',
		tripId:this.tripData._id,
	}
	  this.pricingService.updatePricingFlights(data).subscribe( res => {
		  this.getPricingData.emit(this.tripData._id)
	  })
  }

addEditGroupExpenses(groupData?): void {
	if(this.tripData.completed && this.tripData.tripClass === 1) return;
	const dialogRef = this.dialog.open(GroupExpensesPopupComponent, {
	  position: { left: '0', top: '0' },
	  width: '360px',
	  height: '100vh',
	  data: {formData:groupData && groupData.data,pricesCatagory:this.pricesCatagories},
	  disableClose:true
	});

	dialogRef.afterClosed().subscribe(result => {
		if(result){
			if(groupData && groupData.data){
				this.groupExpensesData[groupData.index] = result
			} else {
				this.groupExpensesData.push(result)
			}
		let data = {
		  pricingId:this.tripData.pricing._id,
		  dataToUpdate: this.groupExpensesData,
		  fieldToUpdate:'groupExpenses',
		  tripId:this.tripData._id,
	  }
		this.pricingService.updatePricingFlights(data).subscribe( res => {
			this.getPricingData.emit(this.tripData._id)
		})
	  }
	});
}

  goStep(stepNumber){
	if(!this.tripData.pricing.generalDetails.includeFreeTravelers  && stepNumber === 5) stepNumber = 4
	this.emitStep.emit(stepNumber)
}

public deleteGroupService(itemToRemove) {
	const openedDialog = this.dialog.open(ConfirmPopupComponent, {
		width: '500px',
		height: '226px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: true,
		data: ConfirmationPopupTypes.RemoveGroup,
	});

	openedDialog.afterClosed().subscribe((confirm: boolean) => {
		if(confirm){
			this.removeItem(itemToRemove)
		}
	});
}

}
