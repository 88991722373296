import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadModel } from '../../../../models/client/lead.model';
import * as moment from 'moment';

@Component({
	selector: 'app-lead-move-to-future-success',
	templateUrl: './lead-move-to-future-success.component.html',
	styleUrls: [ './lead-move-to-future-success.component.scss' ]
})
export class LeadMoveToFutureSuccessComponent implements OnInit {
	@Input() lead: LeadModel;
	@Output() close = new EventEmitter();

	public month: string;
	public year: string;

	constructor() {
	}

	ngOnInit() {
		moment.locale('he');
		const departureDateMoment = moment(this.lead.departureDate);
		this.month = departureDateMoment.format('MMM');
		this.year = departureDateMoment.format('YYYY');

	}

	public closeClicked() {
		this.close.emit(true);
	}
}
