import { ClientInfoModel } from './../../models/client/client.model';
import { Component, OnInit, Input } from '@angular/core';
import { tripNotebookTabsEnum, NOTEBOOK_STRINGS } from 'src/app/models/trip/tripNotebookTabs.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { TripsService } from 'src/app/services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-trip-notebook',
	templateUrl: './trip-notebook.component.html',
	styleUrls: ['./trip-notebook.component.scss']
})
export class TripNotebookComponent implements OnInit {

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public tripsService: TripsService,
	) { }

	pdfData: any

	public showingPage: tripNotebookTabsEnum = tripNotebookTabsEnum.Schedule;
	public NotebookString = NOTEBOOK_STRINGS
	public tripNotebookTabsEnum = tripNotebookTabsEnum;
	tripId:string;
	tripData:TripModel
	completed:boolean;
	currentCurrency:string;
	isLoading:boolean = false;

	ngOnInit() {
		this.tripId = this.activatedRoute.snapshot.paramMap.get('id');
		this.getTripPdfDataByID()
		this.getTripData()
	}

	public getTripPdfDataByID() {
		this.tripsService.getPDFData(this.tripId).subscribe(pdfData => {
			this.pdfData =  pdfData
		}, err => {
			console.log('error', err);
			this.router.navigateByUrl('/app/trips');
		});
	}

	public getTripData(){
		this.tripsService.getTripByID(this.tripId).subscribe(res => {
			this.tripData =  res
			this.completed = this.tripData.completed
			this.checkCurrency()
			console.log(this.tripData)
		}, err => {
			console.log('error', err);
		});
	}

	checkCurrency() {
		if(this.pdfData && this.pdfData.pricing && this.pdfData.pricing.currency){
			let currency = this.pdfData.pricing.currency
			if(currency === 1) this.currentCurrency = '$'
			if(currency === 2) this.currentCurrency = '€'
			if(currency === 3) this.currentCurrency = '₪'
		    if(currency === 4) this.currentCurrency = '£'
		}
		console.log(this.currentCurrency,'current')
	}

	public exportPDF(){
		this.isLoading = true;
		this.tripsService.getPDFForm(this.tripId).subscribe( res=> {
			let url = window.URL.createObjectURL(res);
			let a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = `${this.pdfData.tripName}.pdf`;
			document.body.style.height = '100%'
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			this.isLoading = false;
		}, err => {
			this.isLoading = false;
			console.log(err);
		})
	}

	public goTrip(){
		if(this.completed){
			this.router.navigateByUrl(`/app/trips/details/${this.pdfData.tripId}`)
		} else {
			this.router.navigateByUrl(`/app/trips/build/${this.pdfData.tripId}`)
		}
	}

	public changeShowingPage(toTab: tripNotebookTabsEnum) {
		this.showingPage = toTab;
		this.getTripPdfDataByID();
	}

}
