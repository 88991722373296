import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IFreeTravelers } from 'src/app/models/pricing/freeTravelers.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { PricingService } from 'src/app/services/pricing.service';

@Component({
  selector: 'app-pricing-travel-free',
  templateUrl: './pricing-travel-free.component.html',
  styleUrls: ['./pricing-travel-free.component.scss']
})
export class PricingTravelFreeComponent implements OnInit {

  constructor(public pricingService:PricingService) { }

  @Output() emitStep = new EventEmitter()
  @Output() getPricingData = new EventEmitter()
  @Input() tripData:TripModel
  @Input() currentCurrency:string;
  freeTravelersData:IFreeTravelers[] = []
  totalArray:number[]
  pricesCatagories:number[] = []

	ngOnInit() {
		this.freeTravelersData = this.tripData.pricing.freeTravelersDetails.freeTravelersData
		this.pricesCatagories = this.tripData.pricing.generalDetails.pricesCatagory
		this.totalArray = new Array(this.pricesCatagories.length)
		this.totalArray.fill(0)
	}

	goStep(stepNumber){
		this.emitStep.emit(stepNumber)
	}

	changeItem(value,i){
		if(this.freeTravelersData[i].numberOfFreeTravelers === 0 && value === -1 || (this.tripData.completed && this.tripData.tripClass === 1)) return;
		this.freeTravelersData[i].numberOfFreeTravelers += value
		this.calculateSum()
		let data = {
			pricingId:this.tripData.pricing._id,
			tripId:this.tripData._id,
			dataToUpdate: this.freeTravelersData,
			fieldToUpdate:'freeTravelersDetails'
		}
		  this.pricingService.updatePricingFlights(data).subscribe( res => {
			this.getPricingData.emit(this.tripData._id)
		  })
	}

	calculateSum(){
		this.totalArray.fill(0)
		this.freeTravelersData.forEach( (subject,i) => {
			this.totalArray[i] += (subject.numberOfFreeTravelers * subject.price)
		})
	}
}
