import {ColSize} from '../types/colSize.enum';
import { SortableColModel} from './sortableCol';

export class TableColModel {
	public name: string;
	public propName: string;
	public size: ColSize;
	public type: string;
	public action: () => void;
	public colSortable: SortableColModel;
	public index:any


	constructor(name: string, propName: string, size: ColSize | undefined, type: string = 'text', action: () => void | undefined, colSortable: SortableColModel,value?:number) {
		this.name = name;
		this.propName = propName;
		this.type = type;
		this.action = action;
		this.colSortable = colSortable;
		this.size = size ? size : ColSize.MEDIUM;
		this.index = value
	}
}
