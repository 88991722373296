export enum LeadCancelEnum {
	Other = 1,
	Competitors,
	Price,
	NotInteresting,
	Aborted,
	NoProductExist,
	Full,
	Date,
	Health
}

export const LEAD_ENUM_STRINGS = [
	'אחר ..',
	'פונה למתחרה',
	'מחיר יקר',
	'לא מעוניין',
	'אין מענה',
	'מוצר לא קיים',
	'הטיול מלא',
	'מועד לא מתאים',
	'בעיות בריאות',
];
