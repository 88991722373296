import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { DepartmentService } from 'src/app/services/department.service';
import { DestinationService } from 'src/app/services/destination.service';
import { FilterService } from 'src/app/services/filter.service';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'app-leads-table-filters',
  templateUrl: './leads-table-filters.component.html',
  styleUrls: ['./leads-table-filters.component.scss']
})
export class LeadsTableFiltersComponent implements OnInit {

  @Input() tableRef: TableComponent;
	@Output() rowClickedAction = new EventEmitter();

	public form: FormGroup;
  public departments: DepartmentModel[];
  public pagination: PaginationModel = new PaginationModel(15);
  

  constructor(public filterService: FilterService, private departmentService: DepartmentService, private destinationService: DestinationService){}

  ngOnInit() {
    this.getTableFilters();
  }

  private getTableFilters() {
		this.departmentService.notAdministrative().subscribe((departments: DepartmentModel[]) => this.departments = departments);
	}

  public filterChanged() {
		// this.tableRef.
	}
}
