import { Pipe, PipeTransform } from '@angular/core';
import { HotelMealsEnum } from '../models/types/hotelMeals.enum';

@Pipe({
	name: 'meal'
})
export class MealPipe implements PipeTransform {

	transform(meal: HotelMealsEnum): any {
		switch (meal) {
			case HotelMealsEnum.BB: return 'BB';
			case HotelMealsEnum.HB: return 'HB';
			case HotelMealsEnum.FB: return 'FB';
			default: return 'N/A';
		}
	}

}
