export const CLIENT_OPTIONS = {
	NOTES: 'הערות',
	REGISTRATION_INFO: 'פרטי הרשמה',
	SALE: 'תהליך מכירה',
	OLD_TRIP: 'טיולי עבר',
	FUTURE_TRIP: 'טיולים עתידיים',
	DOCUMENTS: 'מסמכים',
	NOT_ANSWER: 'התקשרויות שנכשלו',
	ACTIVE_TRAVELERS: 'מטיילים פעילים',
	SOLD_LEADS: 'לידים שנמכרו',
};
