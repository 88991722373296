export enum FlightSitEnum {
	Window = 1,
	Middle,
	Pass,
}

export const FlightSits = {
	1:'חלון',
	2:'אמצע',
	3:'מעבר'
}



