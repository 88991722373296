import { Subscription } from 'rxjs';
import { RoomTypeEnum } from 'src/app/models/types/roomType.enum';
import { ClientInfoModel } from 'src/app/models/client/client.model';
import { RoomModel } from './../../../models/client/client.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { RoomsService } from 'src/app/services/rooms.service';

@Component({
  selector: 'app-trip-room-dialog',
  templateUrl: './trip-room-dialog.component.html',
  styleUrls: ['./trip-room-dialog.component.scss']
})


export class TripRoomDialogComponent implements OnInit {
  sglRoomsList: RoomModel[] = [];
  dblRoomsList: RoomModel[] = [];
  twnRoomsList: RoomModel[] = [];
  tplRoomsList: RoomModel[] = [];
  quadRoomsList: RoomModel[] = [];
  sglPrefsList: ClientInfoModel[] = [];
  dblPrefsList: ClientInfoModel[] = [];
  twnPrefsList: ClientInfoModel[] = [];
  tplPrefsList: ClientInfoModel[] = [];
  quadPrefsList: ClientInfoModel[] = [];

  clients: RoomModel[] = []
  freeClients: ClientInfoModel[] = []

  constructor(private roomService: RoomsService, private dialogRef: MatDialogRef<TripRoomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit() {
    this.initializeRooms()
  }
  closeDialog() {
    this.dialogRef.close();

  }

  initializeRooms() {
    this.roomService.getAllTripRooms(this.data).subscribe(res => {
      if (res.length > 0) {
        this.clients = res
        this.getAvailableTravelers()
      } else {
        this.firstCreate()
      }
    });
  }

  getAvailableTravelers() {
    this.roomService.getAvailable(this.data).subscribe(res => {
	  this.freeClients = res
	  console.log(res)
      this.populateLists()
    });
  }

  firstCreate() {
    this.roomService.firstTime(this.data).subscribe(res => {
		this.getRooms()
	})
  }

  getRooms(){
	this.roomService.getAllTripRooms(this.data).subscribe(res => {
        this.clients = res;
		this.getAvailableTravelers();
        this.populateLists();
      });
  }

  createNewRoomByType(type:RoomTypeEnum){
	let room = new RoomModel()
	room.roomType = type
	room.tripId = this.data
	this.roomService.postNewRoom(room).subscribe(res => {
		this.getRooms()
	  });

  }

  populateLists() {
	this.clearDataFromList()
    this.getListByType();
    this.getClientsByPref();

  }

  clearDataFromList(){
	this.sglRoomsList = [];
	this.dblRoomsList = [];
	this.twnRoomsList = [];
	this.tplRoomsList = [];
	this.quadRoomsList = [];
	this.sglPrefsList = [];
	this.dblPrefsList = [];
	this.twnPrefsList = [];
	this.tplPrefsList = [];
	this.quadPrefsList = [];
	}

  getClientsByPref() {
    this.freeClients.forEach(item => {
      switch (item['lead_preferences'] && item['lead_preferences'].room_split) {
        case RoomTypeEnum.SGNL: {
          this.sglPrefsList.push(item);
          break;
        }
        case RoomTypeEnum.DBL: {
          this.dblPrefsList.push(item);
          break;
        }
        case RoomTypeEnum.TRPL: {
          this.tplPrefsList.push(item);
          break;
        }
        case RoomTypeEnum.TWN: {
          this.twnPrefsList.push(item);
          break;
        }
        case RoomTypeEnum.QUAD: {
          this.quadPrefsList.push(item);
          break;
        }
      }
	});
}

  getListByType() {
    this.clients.forEach(item => {
      switch (item['roomType']) {
        case RoomTypeEnum.SGNL: {
          this.sglRoomsList.push(item);
          break;
        }
        case RoomTypeEnum.DBL: {
          this.dblRoomsList.push(item);
          break;
        }
        case RoomTypeEnum.TRPL: {
          this.tplRoomsList.push(item);
          break;
        }
        case RoomTypeEnum.TWN: {
          this.twnRoomsList.push(item);
          break;
        }
        case RoomTypeEnum.QUAD: {
          this.quadRoomsList.push(item);
          break;
        }
      }
    });
  }

  updateRoom(room: RoomModel) {
    this.roomService.updateRoom(room).subscribe( res => {
		console.log(res)
		this.roomService.getAvailable(this.data).subscribe(availables => {
		  this.freeClients = availables
		  this.roomService.getAllTripRooms(this.data).subscribe(res => {
		  this.clients = res
		  this.populateLists()
		})
		})
	});
  }

  removeRoom(roomId:string){
	  this.roomService.removeRoom(roomId).subscribe( res => {
		console.log(res)
		this.roomService.getAvailable(this.data).subscribe(availables => {
		  this.freeClients = availables
		  this.roomService.getAllTripRooms(this.data).subscribe(res => {
		  this.clients = res
		  this.populateLists()
		})
		})
	});
  }
}
