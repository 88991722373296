import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DestinationModel} from '../../../models/client/destination.model';
import {FORM_MESSAGES, FORM_PLACEHOLDERS} from '../../../shared/texts';

@Component({
	selector: 'app-destination-autocomplete',
	templateUrl: './destination-autocomplete.component.html',
	encapsulation: ViewEncapsulation.None
})
export class DestinationAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() control: FormControl;
	@Input() options: DestinationModel[] = [];

	@ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;
	public filteredOptions: DestinationModel[] = [];
	public form: FormGroup;
	private subscription: Subscription;

	constructor(private fb: FormBuilder) {
	}

	get controls() {
		return this.form.controls;
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.form = this.fb.group({
			name: ['', Validators.required]
		});
		this.filteredOptions = this.options;

		this.form.controls.name.valueChanges.subscribe(inputValue => {
			this.setFilteredUsers(inputValue);
		});
	}

	handler(event: MatAutocompleteSelectedEvent): void {
		this.control.setValue(event.option.value._id);
		this.form.controls.name.setValue(event.option.value.name);
	}

	public setFilteredUsers(inputRegex) {
		let filteredOptions = this.options;
		if (inputRegex) {
			filteredOptions = this.options.filter(option => {
				return option.name.startsWith(inputRegex);
			});
		}
		this.filteredOptions = filteredOptions;
	}

	private _subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger.panelClosingActions
			.subscribe(e => {
					if (!e || !e.source) {
						this.control.setValue(null);
						this.form.controls.name.setValue(null);
					}
				},
				err => this._subscribeToClosingActions(),
				() => this._subscribeToClosingActions());
	}
}
