export const CONFIRM_POPUP = {
	// ConfirmationPopupTypes.RemoveUser
	1: {
		title: 'מחיקת משתמש',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את המשתמש ?',
		button: 'מחק'
	},
	2: {
		title: 'מחיקת קובץ',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את הקובץ?',
		button: 'מחק'
	},
	3: {
		title: 'מחיקת משימה',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את המשימה?',
		button: 'מחק'
	},
	4: {
		title: 'מחיקת מלון',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את המלון?',
		button: 'מחק'
	},
	5: {
		title: 'מחיקת עיר',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את העיר?',
		button: 'מחק'
	},
	6: {
		title: 'מחיקת מדינה',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את המדינה?',
		button: 'מחק'
	},
	7: {
		title: 'מחיקת סיור',
		subTitle: 'האם את/ה בטוח/ה שברצונך ללמחוק את הסיור?',
		button: 'מחק'
	},
	8: {
		title: 'הפיכת טיול לפעיל',
		subTitle: 'האם את/ה בטוח/ה שברצונך להפוך את הטיול לפעיל?',
		button: 'אישור'
	},
	9: {
		title: 'מחיקת מדריך',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את המדריך?',
		button: 'אישור'
	},
	10: {
		title: 'מחיקת יום',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את היום?',
		button: 'אישור'
	},
	11: {
		title: 'מחיקת סיכום שיחה',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את סיכום השיחה?',
		button: 'אישור'
	},
	12: {
		title: 'מחיקת טיול בבניה',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הטיול?',
		button: 'אישור'
	},
	13: {
		title: 'מחיקת שירות קרקע',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את השירות?',
		button: 'אישור'
	},
	14: {
		title: 'מחיקת הוצאה במזומן',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הההוצאה?',
		button: 'אישור'
	},
	15: {
		title: 'מחיקת הוצאה קבוצתית',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הההוצאה?',
		button: 'אישור'
	},
	16: {
		title: 'מחיקת הוצאת הדרכה',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הההוצאה?',
		button: 'אישור'
	},
	17:{
		title: 'העברה לנמכר',
		subTitle: 'האם את/ה בטוח/ה שברצונך להעביר את הליד לנמכר?',
		button: 'אישור'
	},
	18: {
		title: 'סגירת טיול',
		subTitle: 'האם את/ה בטוח/ה שברצונך לסגור את הטיול?',
		button: 'אישור'
	},
	19: {
		title: 'מחיקת תנאי',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הפריט?',
		button: 'אישור'
	},
	20: {
		title: 'מחיקת שובר',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את השובר?',
		button: 'אישור'
	},
	21: {
		title: 'מחיקת ספק',
		subTitle: 'האם את/ה בטוח/ה שברצונך למחוק את הספק?',
		button: 'אישור'
	},
	22: {
		title: 'עדכון מידע',
		subTitle: 'האם את/ה בטוח/ה שברצונך לעדכן את המידע?',
		button: 'אישור'
	}
};
