import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { ClientModel } from '../../../models/client/client.model';
import { Subscription } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import formatPhoneNumberUtil from 'src/app/utils/formatPhoneNumber';

@Component({
	selector: 'app-client-autocomplete',
	templateUrl: './client-autocomplete.component.html',
	styleUrls: [ './client-autocomplete.component.scss' ]
})
export class ClientAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() client: ClientModel = undefined;
	@Input() control: FormControl;
	@Output() clientSelectedAction = new EventEmitter();
	@Output() clientDeSelectedAction = new EventEmitter();
	@ViewChild(MatAutocompleteTrigger, {static:true}) trigger: MatAutocompleteTrigger;

	public filteredOptions: ClientModel[] = [];
	public form: FormGroup;
	public clientName: string;
	private subscription: Subscription;

	constructor(private fb: FormBuilder, private clientService: ClientService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if ( this.subscription && !this.subscription.closed ) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.form = this.fb.group({
			name: [ '', Validators.required ]
		});
		this.filteredOptions = [];

		this.form.controls.name.valueChanges.subscribe(inputValue => {
			this.setFilteredUsers(inputValue);
		});

		if ( this.client ) {
			this.control.setValue(formatPhoneNumberUtil(this.client.personalDetails.phoneNumber));
			this.form.controls.name.setValue(formatPhoneNumberUtil(this.client.personalDetails.phoneNumber));
			this.clientName = `${ this.client.personalDetails.firstName } ${ this.client.personalDetails.lastName }`;
			this.clientSelectedAction.emit(this.client);
		}
	}

	handler(event: MatAutocompleteSelectedEvent): void {
		this.control.setValue(formatPhoneNumberUtil(event.option.value.personalDetails.phoneNumber));
		this.form.controls.name.setValue(formatPhoneNumberUtil(event.option.value.personalDetails.phoneNumber));
		this.clientName = `${ event.option.value.personalDetails.firstName } ${ event.option.value.personalDetails.lastName }`;
		this.clientSelectedAction.emit(event.option.value);
	}

	public setFilteredUsers(inputRegex) {
		this.clientService.getClientByPhone(inputRegex).subscribe((clients: ClientModel[]) => {
			this.filteredOptions = clients;
		});
	}

	private _subscribeToClosingActions(): void {
		if ( this.subscription && !this.subscription.closed ) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger.panelClosingActions
			.subscribe(e => {
					if ( !e || !e.source ) {
						this.control.setValue(this.form.controls.name.value);
						this.clientName = undefined;
						this.clientDeSelectedAction.emit();
					}
				},
				err => this._subscribeToClosingActions(),
				() => this._subscribeToClosingActions());
	}
}
