import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { PricingService } from 'src/app/services/pricing.service';

@Component({
  selector: 'app-pricing-summary',
  templateUrl: './pricing-summary.component.html',
  styleUrls: ['./pricing-summary.component.scss']
})
export class PricingSummaryComponent implements OnInit {

	constructor(public dialog: MatDialog, public pricingService:PricingService) {
	  }
	  public cols: TableColModel[] = [];
	  tableHeight:number = 300;
	  currentEditIndex:number;
	  pricesCatagories:number[] = []
	  summaryData:any
	  objectKeys = Object.keys;
	  totalPricesKeysArray:string []
	  dataKeysArray:string []
	  summaryTitles = TABLE_COLS_NAMES.SUMMARY

	  @Output() emitStep = new EventEmitter()
	  @Output() getPricingData = new EventEmitter()
	  @Input() tripData:TripModel
	  @Input() currentCurrency:string;

	  ngOnInit() {
		this.pricesCatagories = this.tripData.pricing.generalDetails.pricesCatagory
		this.getSummaryData()
		console.log(this.tripData)
	}

	  goStep(stepNumber){
		this.emitStep.emit(stepNumber)
	}

	getObjectKeys(item){
		return Object.keys(item);
	}

	updateSummary(updatedPrices){
		let data = {
			pricingId: this.tripData.pricing._id,
			dataToUpdate: updatedPrices,
			fieldToUpdate: 'PriceWithoutPortTaxes',
			tripId:this.tripData._id,
		}
		this.pricingService.updatePricingSummary(data).subscribe( res => {
			this.getSummaryData()
		})
	}

	getSummaryData(){
		let priceId = this.tripData.pricing._id
		let tripId = this.tripData._id
		this.pricingService.getSummaryData(priceId,tripId).subscribe( res => {
			console.log(res,'summary data')
			this.summaryData = res
			this.totalPricesKeysArray = this.getObjectKeys(res.totalPrices)
		})
	}
}
