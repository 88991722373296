import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { UserModel } from '../models/client/user.model';
import { PaginationModel } from '../models/client/pagination.model';
import { SortableColModel } from '../models/client/sortableCol';
import { SortTypeEnum } from '../models/types/sortType.enum';

@Injectable({
	providedIn: 'root'
})
export class UserService extends HttpHandlerService {
	private url: string = 'users/';


	public addUser(user: UserModel) {
		return this.post(this.url + 'register', user);
	}

	public updateUser(user: UserModel) {
		return this.put(this.url, user);
	}

	public checkIfEmailNotExist(email: string) {
		return this.post(this.url + 'email', { email });
	}

	public getUsersMinimize() {
		return this.get(this.url + 'minimize', {});
	}

	public getCurrentUserProfile() {
		return this.get(this.url + 'profile', {});
	}

	public removeUser(id) {
		return this.delete(this.url, { id });
	}

	public getAllUsers(pagination: PaginationModel, sortableCol: SortableColModel, searchText?: string, role?: string, department?: string) {
		const query: any = pagination.toObject();

		query.sort = sortableCol.value;
		query.descending = sortableCol.SortType === SortTypeEnum.Descending;

		if ( searchText ) {
			query.searchText = searchText;
		}

		if ( role ) {
			query.role = role;
		}

		if ( department ) {
			query.department = department;
		}

		return this.get(this.url, query);
	}

	public getAllUsersWithoutPagination() {
		return this.get(this.url+ 'all',{});
	}

	public changePassword(password: string, newPassword: string) {
		const path = 'changePassword';

		return this.post(this.url + path, { password, newPassword });
	}

}
