import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TripScheduleDayItem } from '../../../models/trip/tripScheduleDayItem.enum';
import { MatDialog } from '@angular/material';
import { TripNewFlightComponent } from '../../../components/trip/trip-new-flight/trip-new-flight.component';
import { TripNewHotelComponent } from '../../../components/trip/trip-new-hotel/trip-new-hotel.component';
import { TripNewTransportComponent } from '../../../components/trip/trip-new-transport/trip-new-transport.component';
import { TripNewActivityComponent } from '../../../components/trip/trip-new-activity/trip-new-activity.component';
import { CityService } from '../../../services/city.service'
import { HotelService } from '../../../services/hotel.service';
import { ActivityService } from '../../../services/activity.service';
import { TransportService } from '../../../services/transport.service';
import { FlightService } from 'src/app/services/flight.service';
import { MealService } from 'src/app/services/meal.service';
import { ReservationsService } from 'src/app/services/reservations.service';


@Component({
	selector: 'app-trip-schedule-day-item',
	templateUrl: './trip-schedule-day-item.component.html',
	styleUrls: ['./trip-schedule-day-item.component.scss']
})
export class TripScheduleDayItemComponent implements OnInit {

	@Input() isOpen: boolean = false;
	@Input() editMode: boolean;
	@Input() viewOption: TripScheduleDayItem;
	public scheduleItemEnum = TripScheduleDayItem;
	@Output() edited = new EventEmitter();
	@Output() getData = new EventEmitter();
	@Input() data: any;

	constructor(private dialog: MatDialog,private cityService:CityService, private reservationsService:ReservationsService) { }

	ngOnInit() {
		console.log(this.data,'data')
		if(this.viewOption === 1 && this.data){
		}
	}

	public toggle() {
		this.isOpen = !this.isOpen;
	}

	public editFlight() {
		this.edited.emit({data: this.data, type: TripScheduleDayItem.Flight});
	}

	public editTransport() {
		this.edited.emit({data: this.data, type: TripScheduleDayItem.Transport});
	}

	public editHotel() {
		this.edited.emit({data: this.data, type: TripScheduleDayItem.Hotel});
	}

	public editActivity() {
		this.edited.emit({data: this.data, type: TripScheduleDayItem.Activity});
	}

	public editMeal() {
		this.edited.emit({data: this.data, type: TripScheduleDayItem.Meal});
	}



	public deleteActivity(){
		this.reservationsService.deleteActivityRes(this.data._id).subscribe(res => {
			this.getData.emit()
		})
	}

	public deleteTransfer(){
		this.reservationsService.deleteTransportRes(this.data._id).subscribe(res => {
			this.getData.emit()
		})
	}

	public deleteMeal(){
		this.reservationsService.deleteMealRes(this.data._id).subscribe(res => {
			this.getData.emit()
		})
	}

	public deleteFlight(){
		this.reservationsService.deleteFlightRes(this.data._id).subscribe(res => {
			this.getData.emit()
		})
	}

	public deleteHotel(){
		this.reservationsService.deleteHotelRes(this.data._id).subscribe(res => {
			this.getData.emit()
		})
	}

}
