import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PricingService extends HttpHandlerService{

	private url: string = 'trips/pricing';

	getPricingData(tripId){
		return this.get(this.url, { tripId });
	}

	updatePricingGeneralDetails(data){
		return this.put(this.url,  data);
	}

	updatePricingFlights(data){
		return this.put(this.url,  data);
	}

	updatePricingSummary(data){
		let path  = '/summery'
		return this.put(this.url + path,  data);
	}

	getSummaryData(pricingId,tripId){
		let path  = '/summery'
		return this.get(this.url + path,  {pricingId,tripId});
	}

	getCurrency(currency){
		let path = '/currency'
		return this.get(this.url + path,  {currency});
	}

	getAllPricings(){
		let path = '/import'
		return this.get(this.url + path,  {});
	}



}
