import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableColModel } from '../../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { NewGuideComponent } from '../new-guide/new-guide.component';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { GuideService } from 'src/app/services/guide.service';
import { DestinationService } from 'src/app/services/destination.service';

@Component({
	selector: 'app-guides-table',
	templateUrl: './guides-table.component.html',
	styleUrls: ['./guides-table.component.scss']
})
export class GuidesTableComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 430;
	public cols: TableColModel[] = [];
	@Input() guides: GuideModel[];
	@Input() destinationId: string;
	@Output() edited = new EventEmitter();

	constructor(private dialog: MatDialog,
				private destinationService: DestinationService,
				private guideService: GuideService) { }

	ngOnInit() {
		this.createColsTableItem();
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.NAME, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.PHONE, 'phone', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.EMAIL, 'email', ColSize.LONG, undefined, undefined, undefined));
	}

	public editClicked(guide: GuideModel) {
		const openedDialog = this.dialog.open(NewGuideComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { guide }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.edited.emit();
			}
		});
	}

	public removeClicked(guide: GuideModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGuide,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.removeGuide(guide._id);
			}
		});
	}

	private removeGuide(guideId: string) {
		this.destinationService.removeGuide(this.destinationId , guideId).subscribe(success => {
			this.edited.emit();
		});
	}

}
