import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDocument } from '../../models/client/appDocument';
import * as moment from 'moment';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from '../../models/types/confirmationPopup.enum';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'app-file-row',
	templateUrl: './file-row.component.html',
	styleUrls: [ './file-row.component.scss' ]
})

export class FileRowComponent implements OnInit {
	@Input() document: AppDocument;
	@Input() uploadMode: boolean = false;
	@Input() canDelete: boolean = true;
	@Input() canDownload: boolean = true;
	@Output() delete: EventEmitter<void> = new EventEmitter();
	public date: string;

	constructor(private dialog: MatDialog) {
	}

	ngOnInit() {
		this.date = moment(this.document.createdAt).format('DD.MM.YYYY');
	}

	public downloadDocument() {
		if (this.canDownload) {
			const link = document.createElement('a');
			link.download = this.document.name + '.' + this.document.type;
			link.href = this.document.url;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	public deleteFile() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveFile,
		});

		openedDialog.afterClosed().subscribe((remove: boolean) => {
			if ( remove ) {
				this.delete.emit();
			}
		});
	}
}
