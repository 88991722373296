export enum ClientOptionEnum {
	Notes = 1,
	RegistrationInfo,
	Old_Trip,
	Sale,
	Future_Trip,
	Documents,
	NotAnswer,
	Active_Travelers,
	Sold_Leads,
}
