import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
	providedIn: 'root'
})
export class TransportService extends HttpHandlerService {

	private url: string = 'transfers/';

	public createTransport(transport: any) {
		const path = '';
		return this.post(this.url + path, transport);
	}

	public getTransportTypes(){
		const path = '';
		let url = 'transfersType/'
		return this.get(url + path, {});
	}
	public getCustomTransportTypes() {
		const url = 'transportType/'
		return this.get(url,{});
	}
	public deleteTransfer(id: string) {
		const path = '';
		return this.delete(this.url + path, {id});
	}
}
