import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TableColModel } from '../../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { DestinationModel } from '../../../models/client/destination.model';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { DestinationService } from 'src/app/services/destination.service';
import { NewCountryComponent } from '../new-country/new-country.component';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import * as _ from 'lodash';


@Component({
	selector: 'app-destinations-table',
	templateUrl: './destinations-table.component.html',
	styleUrls: ['./destinations-table.component.scss']
})
export class DestinationsTableComponent implements OnInit {

	@Component({
		selector: 'app-storage-department-table-page',
		templateUrl: './storage-department-table-page.component.html',
		styleUrls: ['./storage-department-table-page.component.scss']
	})

	@Input() destinations: DestinationModel[];
	@Input() departmentId: string;
	@Input() department: DepartmentModel;
	@Output() edited = new EventEmitter();
	public cols: TableColModel[] = [];
	public tableHeight: number = window.innerHeight - 430;
	public dataLoaded: boolean = false;

	constructor(private router: Router,
				private dialog: MatDialog,
				private destinationService: DestinationService) {}

	ngOnInit() {
		this.validateData();
		this.createColsTableItem();
		// setTimeout(() => {
			// console.log(this.department)

		// }, 1000);
	}

	public validateData(){
		this.dataLoaded = false;

		const destinationsLoaded: boolean =  !(!this.destinations || this.destinations.length == 0) ;
		const departmentLoaded: boolean = !(!this.department);

		this.dataLoaded = destinationsLoaded && departmentLoaded;
	}

	public rowClicked(destination: any) {
		this.router.navigateByUrl(`/app/storage/destination/${destination._id}`);
	}

	private createColsTableItem() {
		this.validateData();
		
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.STATE, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.HOTELS_NUM, 'hotels', ColSize.MEDIUM, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.TOURS_NUM, 'activities', ColSize.MEDIUM, undefined, undefined, undefined));
	}


	public editClicked(destination: DestinationModel) {
		const openedDialog = this.dialog.open(NewCountryComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { destination, department: this.departmentId }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.edited.emit();
			}
		});
	}

	public removeClicked(destination: DestinationModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveCountry,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if ( success ) {
				this.removeCountry(destination._id);
			}
		});
	}

	private removeCountry(destinationId: string) {
		this.destinationService.remove(destinationId).subscribe(success => {
			this.edited.emit();
		});
	}

}
