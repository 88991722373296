import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AddVoucherPopComponent } from '../../finance/add-voucher-pop/add-voucher-pop.component';
import { CurrencyOptions,VoucherTypesHedlines,CurrencyOptionsHedlines } from '../../../models/trip/finance.enum'
import { VoucherModel } from 'src/app/models/trip/voucher.model';
import { VouchersService } from 'src/app/services/vouchers.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import * as _ from 'lodash';
import * as moment from 'moment'
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { CurrencyEnum } from 'src/app/models/pricing/currency.Enum'
import { FinanceService } from 'src/app/services/finance.service';

@Component({
  selector: 'app-trip-manage',
  templateUrl: './trip-manage.component.html',
  styleUrls: ['./trip-manage.component.scss']
})
export class TripManageComponent implements OnInit {

  constructor(public dialog:MatDialog,public vouchersService:VouchersService) { }

  @Input() trip:TripModel
  @Output() totalExpensesAction = new EventEmitter()
  public currentCurrency:string;
  public CurrencyOptions = CurrencyOptions
  public CurrencyOptionsHedlines = CurrencyOptionsHedlines
  public vouchers:VoucherModel[]
  public moment = moment
  public CurrencyEnum = CurrencyEnum
  public VoucherTypesHedlines = VoucherTypesHedlines

  ngOnInit() {
	  this.getTripVouchers()
  }



  public openVoucherPop(voucher?): void {
    const dialogRef = this.dialog.open(AddVoucherPopComponent, {
      width: '513px',
      panelClass: 'custom-right-dialog-container',
      backdropClass: 'custom-backdropClass',
      disableClose: false,
      data: {voucher}
    });

    dialogRef.afterClosed().subscribe(data => {
		if(data){
			data.trip = this.trip._id
				this.saveVoucher(data)
		}
    });
  }

  checkCurrency(currency) {
	if(currency === 1) this.currentCurrency = '$'
	if(currency === 2) this.currentCurrency = '€'
	if(currency === 3) this.currentCurrency = '₪'
	if(currency === 4) this.currentCurrency = '£'
}

	saveVoucher(data){
		if(data._id){
			this.vouchersService.updateVoucher(data).subscribe( savedVoucher => {
				this.getTripVouchers()
			})
		} else {
			this.vouchersService.addVoucher(data).subscribe( savedVoucher => {
				this.getTripVouchers()
			})
		}
	}

	getTripVouchers(){
		this.vouchersService.getTripVouchers(this.trip._id).subscribe( vouchers => {
			this.vouchers = vouchers
			this.groupTripVouchers(vouchers)
		})
	}

	groupTripVouchers(vouchers){
		let totalExpenses:any = 0
		vouchers.forEach(item => {
			totalExpenses += item.total
		});
		this.totalExpensesAction.emit(totalExpenses)
		this.vouchers = vouchers
	}

	removeVoucher(voucher){
		const dialogRef = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '250px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveVoucher,
		});

		  dialogRef.afterClosed().subscribe(result => {
			if(result){
				voucher.provider = voucher.provider[0]._id
				voucher.status = 0;
				this.vouchersService.updateVoucher(voucher).subscribe( savedVoucher => {
					this.getTripVouchers()
				})
				}
		  });
	}


}


