export class GuideModel {
	_id?: string;
	name: string;
	phone: string;
	email: string;
	wageFix: number;
	wageClosed: number;
	commission: number;
	nameEng: string;
	lastEng: string;
	DOB: Date;
	passportNumber:string;
	passportDOI: Date;
	passportDOE: Date;
	nationality:string;
	status: number;
}
