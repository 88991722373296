import { Component, OnInit, Input } from '@angular/core';
import { BankTitles } from 'src/app/models/types/currencies.strings.enum';
import { FORM_PLACEHOLDERS, FORM_MESSAGES } from 'src/app/shared/texts';

@Component({
  selector: 'app-type-credit-card',
  templateUrl: './type-credit-card.component.html',
  styleUrls: ['./type-credit-card.component.scss']
})
export class TypeCreditCardComponent implements OnInit {

	@Input() accountControl
	BankTitles = BankTitles
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

  constructor() { }

  ngOnInit() {
  }

}
