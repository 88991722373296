import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class CanActivateNotLoggedUserService implements  CanActivate {
	constructor(public router: Router, private authService: AuthService) {
	}

	canActivate() {
		const userToken = this.authService.getToken();
		if (userToken && userToken !== 'undefined') {
			this.router.navigateByUrl('/');
			return false;
		} else {
			return true;
		}
	}
}
