import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES, GUIDE_FORM_PLACEHOLDERS } from '../../../shared/texts';
import { GuideModel } from '../../../models/trip/guide.model';
import { GuideService } from '../../../services/guide.service';
import { CustomValidatorsService } from '../../../services/custom-validators.service';


@Component({
	selector: 'app-new-guide',
	templateUrl: './new-guide.component.html',
	styleUrls: ['./new-guide.component.scss']
})
export class NewGuideComponent implements OnInit, OnDestroy {



	// public guide: GuideModel = new GuideModel();
	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = GUIDE_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;
	public editMode: boolean = true;

	constructor(private fb: FormBuilder,
		private guideService: GuideService,
		private customValidatorsService: CustomValidatorsService,
		@Inject(MAT_DIALOG_DATA) private guide: GuideModel,
		private dialogRef: MatDialogRef<NewGuideComponent>) {
		if (!guide) {
			guide = new GuideModel();
		}
	}

	ngOnInit() {
		if (!this.guide) {
			this.guide = new GuideModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		this.createForm();
	}

	private createForm() {
		const { name, email, phone, wageFix, wageClosed, commission, nameEng, lastEng, DOB, passportNumber, passportDOI, passportDOE, nationality} = this.guide;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			email: new FormControl(email, [Validators.email,Validators.required]),
			phone: new FormControl(phone, Validators.compose([Validators.required,this.customValidatorsService.validateCellPhoneNumber])),
			wageFix: new FormControl(wageFix),
			wageClosed: new FormControl(wageClosed),
			commission: new FormControl(commission),
			nameEng: new FormControl(nameEng),
			lastEng: new FormControl(lastEng),
			DOB: new FormControl(DOB),
			passportNumber:new FormControl(passportNumber),
			passportDOI:new FormControl(passportDOI),
			passportDOE:new FormControl(passportDOE),
			nationality:new FormControl(nationality),
	})
}


	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const form = this.form.controls;
		let guide = this.form.value
		if (this.guide._id) {
			guide._id = this.guide._id;
			this.updateGuide(guide);
		} else {
			this.createGuide(guide);
		}
	}


	private updateGuide(guide: GuideModel) {
		const subscription = this.guideService.update(guide).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createGuide(guide: GuideModel) {
		const subscription = this.guideService.create(guide).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

}
