import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from '../../services/department.service';
import { GuideService } from '../../services/guide.service';
import { TableColModel } from '../../models/client/tableCols.model';
import { MatDialog } from '@angular/material';
import { NewCountryComponent } from 'src/app/components/storage/new-country/new-country.component';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { DestinationService } from 'src/app/services/destination.service';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { DestinationModel } from 'src/app/models/client/destination.model';

enum TableEnum {
	Destinations = 1,
	Guides,
}

@Component({
	selector: 'app-storage-department-table-page',
	templateUrl: './storage-department-table-page.component.html',
	styleUrls: ['./storage-department-table-page.component.scss']
})
export class StorageDepartmentTablePageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 330;
	public cols: TableColModel[] = [];
	public department: DepartmentModel;
	public destinations: DestinationModel[];
	public guidesList: GuideModel[] = [];
	public tableEnum = TableEnum;
	public showingPage: TableEnum = TableEnum.Destinations;
	public departmentId: string;

	departmentLoaded: boolean = false;

	constructor(private activatedRoute: ActivatedRoute,
				private router: Router,
				private guideService: GuideService,
				private dialog: MatDialog,
				private departmentService: DepartmentService,
				public destinationService:DestinationService) {
	}

	ngOnInit() {
		this.departmentId = this.activatedRoute.snapshot.paramMap.get('id');
		this.getDeparmentById(this.departmentId)
	}

	public rowClicked(destination: any) {
		this.router.navigateByUrl(`/app/storage/destination/${destination._id}`);
	}

	public getDeparmentById(id){
		this.departmentService.getDepartmentDestinations(id).subscribe(departmentWithData => {
			this.department = Object.assign({}, departmentWithData) as DepartmentModel;
			this.destinations = Object.assign([], this.department.destinations);
			this.departmentLoaded = true;
			// this.getDestinations()
		})
	}
	
	public getDestinations() {
		this.destinationService.getAllCitiesByDestinationId(this.departmentId).subscribe(departmentWithData => {
			this.department = Object.assign({}, departmentWithData) as DepartmentModel;
			this.destinations = Object.assign([], this.department.destinations);
			this.departmentLoaded = true;
		}, err => {
			console.log('error', err);
			this.router.navigateByUrl('/app/storage');
		});
	}


	public addDestination() {
		const openedDialog = this.dialog.open(NewCountryComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { undefined, department: this.departmentId }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.getDestinations();
			}
		});
	}


}
