import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HotelMealsEnum } from 'src/app/models/types/hotelMeals.enum';
import { PricesCatagoryModel } from 'src/app/models/pricing/pricesCatagoryModel';
import { ProvidersService } from 'src/app/services/providers.service';

@Component({
  selector: 'app-ground-service-popup',
  templateUrl: './ground-service-popup.component.html',
  styleUrls: ['./ground-service-popup.component.scss']
})
export class GroundServicePopupComponent implements OnInit {

	constructor(
		public providerService:ProvidersService,
		public fb:FormBuilder,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA)
		public data: any)
		 {}

		 pricesCatagory:PricesCatagoryModel[] = []
		 public hotelMealsEnum = HotelMealsEnum;
		 public readonly INVALID_FORM = FORM_MESSAGES;
		 form:FormGroup
		 serviceList = ['שירותי קרקע','ויזה','מלון','ארוחות','מופע','כניסות','טיפים','ערכות שמע','אחר']
		 title:string = ''
		 providers:any[]


	ngOnInit() {
		this.pricesCatagory = this.data.pricesCatagory.map( price =>{
			return {numberOfPeople:price,price:null}
		})
		this.getAllProviders()
		this.createForm()
		if(this.data.formData){
			this.form.patchValue({
				type:this.data.formData.type,
				provider: this.data.formData.provider._id,
				ExtraPerOne: this.data.formData.ExtraPerOne,
				note: this.data.formData.note,
				other: this.data.formData.other,
			})
			this.pricesCatagory.forEach( (price,i) => {
				price.price = this.data.formData.prices[i].price
			})
		}
		this.checkValueForOther()
		this.title = this.data.title
	}

	getAllProviders(){
		this.providerService.getAllProviders().subscribe( res => {
			this.providers = res
		})
	}

	public get controls() {
	  return this.form.controls;
  }

  checkValueForOther(){
	  if(this.form.value.type === 9){
		this.form.controls['other'].setValidators(Validators.required);
		} else {
			this.form.controls['other'].setValue('');
			this.form.controls['other'].clearValidators();
		}
		this.form.updateValueAndValidity();
  }

  private createForm() {
	this.form = this.fb.group({
		type: new FormControl(null, Validators.required),
		provider: new FormControl('', Validators.required),
		other: new FormControl(''),
		ExtraPerOne: new FormControl(null),
		note: new FormControl(''),
	});
	}

	addGroundService(){
		let data = this.form.value
		data['prices'] = this.pricesCatagory
		this.dialogRef.close(data);
	}

	close(){
		this.dialogRef.close();
	}

}
