import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BundlePopComponent } from '../../notebook/bundle-pop/bundle-pop.component';
import { MatDialog } from '@angular/material';
import { TripsService } from 'src/app/services/trips.service';
import { NotebookService } from 'src/app/services/notebook.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
  selector: 'app-trip-notebook-bundle',
  templateUrl: './trip-notebook-bundle.component.html',
  styleUrls: ['./trip-notebook-bundle.component.scss']
})
export class TripNotebookBundleComponent implements OnInit {

  isReversed:boolean = false;

  public form: FormGroup;
  @Input() currentCurrency

  @Input() trip;

  includeArray:any[] = []
  excludeArray:any[] = []
  pdfData:any;
  pricing:any;
  tripId:string;


  constructor(private fb: FormBuilder,public route:ActivatedRoute, public router:Router,private dialog: MatDialog,public tripsService:TripsService,public notebookService:NotebookService) { }


  ngOnInit() {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.tripId = this.route.snapshot.paramMap.get('id');
		this.getPDFData()
	});
 }

    getPDFData(){
	this.tripsService.getPDFData(this.tripId).subscribe( res => {
		this.pdfData = res
		this.pricing = res.pricing
		this.includeArray = res.pricing.include
		this.excludeArray = res.pricing.notInclude
	})
  }



  openTermsDialog(){
		const dialogRef = this.dialog.open(BundlePopComponent, {
	      width: '858px',
		  height: '775px',
		  panelClass: 'custom-right-dialog-container',
		  backdropClass: 'custom-backdropClass',
		  disableClose: false,
		  data:{component:'included',componentHeadline:'הוסף תנאי'}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				let note = {
					headLine:result.headLine,
					description:result.description? result.description:[]
				}
				if(result.isInclude){
					this.includeArray.push(note)
				} else {
					this.excludeArray.push(note)
				}
			}
			this.pricing.include = this.includeArray
			this.pricing.notInclude = this.excludeArray
			this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
				this.getPDFData()
			})
		});
  }

  openEditDialog(note,index,include){
	note['isInclude'] = include
	const dialogRef = this.dialog.open(BundlePopComponent, {
		width: '858px',
		height: '775px',
	  panelClass: 'custom-right-dialog-container',
	  backdropClass: 'custom-backdropClass',
	  disableClose: true,
	  data:{component:'included',note,componentHeadline:'ערוך תנאי'}
	});

	dialogRef.afterClosed().subscribe(result => {
		
		if(result){
			let note = {
				headLine:result.headLine,
				description:result.description? result.description:[]
			}
			if(result.isInclude){
				this.excludeArray.splice(index,1)
				this.includeArray.push(note)
			} else {
				this.includeArray.splice(index,1)
				this.excludeArray.push(note)
			}
		}
		this.pricing.include = this.includeArray
		this.pricing.notInclude = this.excludeArray
		this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
			this.getPDFData()
		})
		});
}

  removeNote(include,index){
	 const dialogRef = this.dialog.open(ConfirmPopupComponent, {
		width: '500px',
		height: '226px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: false,
		data:ConfirmationPopupTypes.RemoveItem
	  });

	  dialogRef.afterClosed().subscribe(result => {
		  if(result){
			if(include){
				this.includeArray.splice(index,1)
			} else {
				this.excludeArray.splice(index,1)
			}
			this.pricing.include = this.includeArray
			this.pricing.notInclude = this.excludeArray
			this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
				this.getPDFData()
			})
		}
	  });
  }

}
