import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TaskModel } from 'src/app/models/client/task.model';
import { TasksService } from 'src/app/services/tasks.service';
import { PaginationModel } from '../../models/client/pagination.model';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debug } from 'util';
import { TaskQueryModel } from 'src/app/models/request/taskQuery.model';
import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/models/client/user.model';

interface Schedule {
	time: string;
	tasks: TaskModel[];
}

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
	readonly halfHour = 32;
	readonly startHour = 7;
	readonly lastHour = 19;
	public timesArray: Schedule[] = [];
	public dateSelected: moment.Moment;
	public math = Math;
	public pagination: PaginationModel = new PaginationModel(1000);
	private subscriptions: Subscription[] = [];
	public tasksList: TaskModel[] = [];
	currentSupervisor: string;
	filteredTasks: TaskModel[] = [];
	supervisors: UserModel[]

	@Output() close: EventEmitter<void> = new EventEmitter();

	constructor(public userService: UserService, private tasksService: TasksService) {
		this.dateSelected = moment();
	}

	ngOnInit() {
		this.updateDate(0);
		this.getUsersMinimize()
	}

	private getUsersMinimize() {
		this.userService.getUsersMinimize().subscribe(users => {
			let usersOriginal = users
			this.supervisors = usersOriginal.filter((user, i) => {
				return user['status'] === 1
			})
			console.log(this.supervisors)
		});
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public closeClicked() {
		this.close.emit();
	}



	updateDate(add: number) {
		this.dateSelected.add(add, 'day');
		const dueDate = moment.utc(this.dateSelected).startOf('day').format('YYYY-MM-DD');
		this.userService.getCurrentUserProfile().subscribe(res => {
			let level = res.user.role.level
			const subscription = this.tasksService.getTasksByDueDate(dueDate, res.user._id, level).subscribe(tasks => {
				this.tasksList = tasks;
				this.filteredTasks = tasks
				this.setTimeArray();
			});
			console.log('this.tasksList', this.tasksList)
			this.subscriptions.push(subscription);
		})
	}

	private setTimeArray() {
		const startHour = 7;
		const lastHour = 19;
		const length = ((lastHour - startHour + 1) * 2);
		this.timesArray = [];

		const quarterHours = ['00', '15', '30', '45'];
		for (let i = startHour; i < lastHour; i++) {
			for (let j = 0; j < 4; j++) {
				let time = i + ':' + quarterHours[j];
				if (i < 10) {
					time = '0' + time;
				}
				this.timesArray.push({ time, tasks: [] });
			}
		}

		for (const task of this.filteredTasks) {
			const time = moment.utc(task.dueDate).format('HH:mm');
			const timeSlot = this.timesArray.find(slot => slot.time === time);
			if (timeSlot) {
				timeSlot.tasks.push(task);
			}
		}
	}

	handler($event) {
		this.filteredTasks = this.tasksList.filter(task => {
			return task.user === this.currentSupervisor
		})
		this.setTimeArray()
	}

}

