import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { AppDocument } from '../models/client/appDocument';

@Injectable({
	providedIn: 'root'
})
export class DayService extends HttpHandlerService {
	private url: string = 'days/';

	public CreateDay(tripId: string, date: string) {
		const path = '';
		return this.post(this.url + path, {tripId, date});
	}

	public CreateDayInMiddle(tripId: string, index: number) {
		const path = `dayInMiddle?index=${index}`;
		return this.post(this.url + path, {tripId});
	}

	public getDayDetails(dayId: string) {
		const path = 'byId';
		return this.get(this.url + path, {dayId});
	}

	public addDocument(dayId: string, document: AppDocument) {
		const path = 'document';
		return this.post(this.url + path, {dayId, document});
	}

	public deleteDocument(fileId: string) {
		const path = 'document';
		return this.delete(this.url + path, {fileId});
	}

	public deleteDay(dayId: string) {
		const path = '';
		return this.delete(this.url + path, {dayId});
	}
}
