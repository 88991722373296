import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES, COUNTRY_FORM_PLACEHOLDERS } from '../../../shared/texts';
import { DestinationModel } from '../../../models/client/destination.model';
import { DestinationService } from '../../../services/destination.service';

@Component({
	selector: 'app-new-country',
	templateUrl: './new-country.component.html',
	styleUrls: ['./new-country.component.scss']
})
export class NewCountryComponent implements OnInit, OnDestroy {

	@Input() destination: DestinationModel = new DestinationModel();
	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = COUNTRY_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;

	constructor(private fb: FormBuilder,
		private destinationService: DestinationService,
		@Inject(MAT_DIALOG_DATA) private data: { destination: DestinationModel, department: string },
		private dialogRef: MatDialogRef<NewCountryComponent>) {
		this.destination = this.data.destination;
	}

	ngOnInit() {
		if (!this.destination) {
			this.destination = new DestinationModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		console.log(this.destination)
		this.createForm();
	}

	private createForm() {
		const { name,english_name } = this.destination;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			english_name: new FormControl(english_name, Validators.required),
		});
	}

	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const form = this.form.controls;
		const destination = new DestinationModel();
		destination.name = form.name.value;
		destination.english_name = form.english_name.value;
		destination.department = this.data.department;


		if (this.destination._id) {
			destination._id = this.destination._id;
			this.updateCountry(destination);
		} else {
			this.createCountry(destination);
		}
	}


	private updateCountry(destination: DestinationModel) {
		const subscription = this.destinationService.update(destination).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createCountry(destination: DestinationModel) {
		const subscription = this.destinationService.create(destination).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

}
