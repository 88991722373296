import {SortableOptionsEnum} from '../types/sortableOptions.enum';
import {SortTypeEnum} from '../types/sortType.enum';

export class SortableColModel {
	public readonly value: SortableOptionsEnum;
	private sortType: SortTypeEnum = SortTypeEnum.None;
	private active: boolean = false;


	constructor(value: SortableOptionsEnum, active: boolean) {
		this.value = value;
		if (active) {
			this.active = true;
			this.sortType = SortTypeEnum.Ascending;
		}
	}

	get SortType() {
		return this.sortType;
	}

	get Active() {
		return this.active;
	}

	public setAscending() {
		this.active = true;
		this.sortType = SortTypeEnum.Ascending;
	}

	public setInactive() {
		this.active = false;
		this.sortType = SortTypeEnum.None;
	}

	public 	setDescending() {
		this.active = true;
		this.sortType = SortTypeEnum.Descending;
	}
}
