import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pricing-stepper',
  templateUrl: './pricing-stepper.component.html',
  styleUrls: ['./pricing-stepper.component.scss']
})
export class PricingStepperComponent implements OnInit {

  constructor(public router:Router) { }
	@Output() emitStep = new EventEmitter()
	@Input() currentStep:number;
	@Input() tripData

  ngOnInit() {
  }

  goStep(stepNumber){
	if(stepNumber === 5 && !this.tripData.pricing.generalDetails.includeFreeTravelers) return;
	if(this.tripData && this.tripData.tripClass === 3 && stepNumber > 3 && stepNumber < 8) return;
	this.emitStep.emit(stepNumber)
}

checkTrue(tripData){

}

}
