import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';


@Injectable()
export class CanActivateLoggedUserService implements CanActivate {
	constructor(private router: Router, private authService: AuthService) {
	}

	canActivate() {
		const userToken = this.authService.getToken();
		if (userToken && userToken !== 'undefined') {
			return true;
		} else {
			this.router.navigateByUrl('/login');
			return false;
		}
	}
}
