import { ClientInfoModel } from './../../../../models/client/client.model';
import { ClientService } from 'src/app/services/client.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LEAD_ENUM_STRINGS, LeadCancelEnum } from '../../../../models/types/leadCancel.enum';
import { LeadsService } from '../../../../services/leads.service';
import { LeadModel } from '../../../../models/client/lead.model';
import { DataService } from 'src/app/services/data.service';

@Component({
	selector: 'app-lead-cancel',
	templateUrl: './lead-cancel.component.html',
	styleUrls: ['./lead-cancel.component.scss']
})
export class LeadCancelComponent implements OnInit {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public cancelEnum = LeadCancelEnum;
	public cancelEnumStrings = LEAD_ENUM_STRINGS;
	public enumKeys = [];
	public waitingResponse: boolean = false;
	@Input() lead: LeadModel;
	@Output() saveCompleted = new EventEmitter();
	@Output() close = new EventEmitter();
	public form: FormGroup;
	@Input() public isClient: string;
	@Input() personalDetails: ClientInfoModel;
	constructor(public dataService: DataService, private fb: FormBuilder, private leadService: LeadsService, private clientService: ClientService) {
		this.createForm();
		this.getEnumKeys();
	}

	get controls() {
		return this.form.controls;
	}

	public getEnumKeys() {
		const keys = Object.keys(this.cancelEnum);
		this.enumKeys = keys.slice(keys.length / 2);
	}

	ngOnInit() {
		console.log(this.isClient)
	}


	public setReasonValue(reason: LeadCancelEnum) {
		this.form.controls.reason.setValue(reason);
		if (reason === LeadCancelEnum.Other) {
			this.form.controls.other.setValidators(Validators.required);
			this.form.controls.other.enable();
		} else {
			this.form.controls.other.clearValidators();
			this.form.controls.other.setValue('');
			this.form.controls.other.disable();
		}
	}

	public closeClicked() {
		this.close.emit();
	}

	public save() {
		const { reason, other } = this.form.controls;
		this.waitingResponse = true;
		if (typeof this.isClient == 'undefined' || this.isClient == 'false' || (typeof this.isClient === 'object' && this.isClient !== null)) {
			this.leadService.close(this.lead._id, reason.value, other.value, this.lead.tripId).subscribe(() => {
				this.dataService.getUserTasks()
				this.waitingResponse = false;
				this.saveCompleted.emit();
			});
		}
		if (this.isClient === 'true') {
			console.log('true');
			this.clientService.closeFamilyMember(this.personalDetails._id, this.lead.tripId, reason.value, other.value).subscribe(() =>{
				this.dataService.getUserTasks()
				this.waitingResponse = false;
				this.saveCompleted.emit();
			});

		}
	}

	private createForm() {
		this.form = this.fb.group({
			other: new FormControl({ value: '', disabled: true }),
			reason: new FormControl('', Validators.required)
		});
	}
}
