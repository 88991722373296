import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { CityService } from 'src/app/services/city.service';
import { TripDialogDataModel } from 'src/app/models/trip/tripDialogData.model';
import * as moment from 'moment';
import { FlightResModel } from 'src/app/models/reservations/flightRes.model';
import { ReservationsService } from 'src/app/services/reservations.service';

enum DepartureTime {
	Morning = 1,
	Afternoon,
	Evening,
	Night
}

@Component({
	selector: 'app-trip-new-flight',
	templateUrl: './trip-new-flight.component.html',
	styleUrls: ['./trip-new-flight.component.scss']
})
export class TripNewFlightComponent implements OnInit {

	public form: FormGroup;
	public INVALID_FORM = FORM_MESSAGES;
	public dateTitle: string = '';
	public departureTime = DepartureTime;

	constructor(private dialogRef: MatDialogRef<TripNewFlightComponent>,
				private reservationsService: ReservationsService,
				@Inject(MAT_DIALOG_DATA) private data: TripDialogDataModel,
				private fb: FormBuilder) { }

	ngOnInit() {
		this.createForm();
		this.setDateTitle();
	}

	private setDateTitle() {
		this.dateTitle = `יום ${this.data.dayNum}, ${moment(this.data.day.date).format('DD.MM.YY')}`;
	}

	public get controls() {
		return this.form.controls;
	}

	private createForm() {
		const flight: FlightResModel = Object.assign(new FlightResModel(), this.data.data);
		this.form = this.fb.group({
			fromLocation: new FormControl(flight.fromLocation, Validators.required),
			fromLocationAirportCode:new FormControl(flight.fromLocationAirportCode, Validators.required),
			fromLocationCountry:new FormControl(flight.fromLocationCountry, Validators.required),
			fromLocationCountryInHeb:new FormControl(flight.fromLocationCountryInHeb, Validators.required),
			fromLocationCityInHeb:new FormControl(flight.fromLocationCityInHeb),
			toLocation: new FormControl(flight.toLocation, Validators.required),
			toLocationAirportCode:new FormControl(flight.toLocationAirportCode, Validators.required),
			toLocationCountry:new FormControl(flight.toLocationCountry, Validators.required),
			toLocationCountryInHeb:new FormControl(flight.toLocationCountryInHeb, Validators.required),
			toLocationCityInHeb:new FormControl(flight.toLocationCityInHeb),
			startTime: new FormControl(flight.startTime),
			endTime: new FormControl(flight.endTime),
			notes: new FormControl(flight.notes)
		});
	}

	public close() {
		this.dialogRef.close();
	}

	public onSubmit() {
		if (this.form.invalid) {
			return;
		}
		const form = this.form.controls;
		const flightRes: FlightResModel = {
			toLocation: form.toLocation.value,
			toLocationAirportCode: form.toLocationAirportCode.value,
			toLocationCountry: form.toLocationCountry.value,
			toLocationCountryInHeb: form.toLocationCountryInHeb.value,
			toLocationCityInHeb: form.toLocationCityInHeb.value,
			fromLocation: form.fromLocation.value,
			fromLocationAirportCode: form.fromLocationAirportCode.value,
			fromLocationCountry: form.fromLocationCountry.value,
			fromLocationCountryInHeb: form.fromLocationCountryInHeb.value,
			fromLocationCityInHeb: form.fromLocationCityInHeb.value,
			notes: form.notes.value,
			startTime: form.startTime.value,
			endTime: form.endTime.value
		};
		if (this.data.data && this.data.data._id) {
			flightRes._id = this.data.data._id;
			this.updateFlightRes(flightRes);
		} else {
			this.createNewFlightRes(flightRes);
		}


	}

	private createNewFlightRes(newFlightRes: FlightResModel) {
		this.reservationsService.createFlightRes(newFlightRes, this.data.day._id).subscribe(flightRes => {
			this.dialogRef.close(flightRes);
		});
	}

	private updateFlightRes(flightRes: FlightResModel) {
		this.reservationsService.updateFlightRes(flightRes).subscribe(updatedFlightRes => {
			this.dialogRef.close(updatedFlightRes);
		});
	}

}
