// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// baseUrl: 'http://localhost:3000/api/v1/',
	baseUrl: 'https://geotours-staging.moveodevelop.com/api/v1/',
	// baseUrl: 'http://ec2-34-243-70-81.eu-west-1.compute.amazonaws.com/api/v1/'
	// baseUrl: 'http://geotours-dev.moveodevelop.com/api/v1/'
	// baseUrl: 'https://www.geotours-crm.com/api/v1/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
