import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationPopupTypes } from '../../models/types/confirmationPopup.enum';
import { CONFIRM_POPUP } from '../../shared/texts/confirmPopup.messages';

@Component({
	selector: 'app-confirm-popup',
	templateUrl: './confirm-popup.component.html',
	styleUrls: [ './confirm-popup.component.scss' ]
})
export class ConfirmPopupComponent implements OnInit {

	public title: string;
	public subTitle: string;
	public button: string;

	constructor(private dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) private type: ConfirmationPopupTypes) {
		this.title = CONFIRM_POPUP[type].title;
		this.subTitle = CONFIRM_POPUP[type].subTitle;
		this.button = CONFIRM_POPUP[type].button;
	}

	ngOnInit() {
	}

	closeClicked(success: boolean) {
		this.dialogRef.close(success);
	}

}
