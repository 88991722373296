import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { HotelMealsEnum } from 'src/app/models/types/hotelMeals.enum';
import * as moment from 'moment';

@Component({
	selector: 'app-trip-pricing',
	templateUrl: './trip-pricing.component.html',
	styleUrls: ['./trip-pricing.component.scss']
})
export class TripPricingComponent implements OnInit {
	@Input() trip:TripModel
	flights:any = [];
	hotels:any = [];
	public colsFlight: TableColModel[] = [];
	public colsHotel: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public pagination: PaginationModel = new PaginationModel(15);
	constructor(
		public router:Router
	) { }


	ngOnInit() {
		let counter = 1
		this.trip.days.forEach( item =>{
			if(item.hotels.length > 0){
				item.hotels.forEach(hotel =>{
					hotel['date'] = moment(item.date).format('DD.MM.YY')
					hotel['dayNumber'] = counter
					this.hotels.push(hotel)
				})
			}
			counter++
		})
		counter = 1
		this.trip.days.forEach( item =>{
			if(item.flights.length > 0){
				item.flights.forEach(flight =>{
					flight['date'] = moment(item.date).format('DD.MM.YY')
					flight['dayNumber'] = counter
					this.flights.push(flight)
				})
			}
			counter++
		})
		this.createColsTableFlights()
		this.createColsTableHotels()
	}

	private createColsTableFlights() {
		this.colsFlight.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.DAY, 'dayNumber', ColSize.SMALL, 'text', undefined, undefined));
		this.colsFlight.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.FLIGHT_NUMBER, '', ColSize.SMALL, 'text', undefined, undefined));
		this.colsFlight.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.AIR_COMPANY, '', ColSize.SMALL, 'text', undefined, undefined));
		this.colsFlight.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.DEPARTURE, '', ColSize.SMALL, 'departure', undefined, undefined));
		this.colsFlight.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.LANDING, '', ColSize.SMALL, 'landing', undefined, undefined));
	}

	private createColsTableHotels() {
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.DAY, 'date', ColSize.SMALL, 'date', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.COUNTRY, 'hotel.destination.name', ColSize.SMALL, 'text', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.CITY, 'city.name', ColSize.SMALL, 'text', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.NAME, 'hotel.name', ColSize.SMALL, 'text', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.CHECKIN, '', ColSize.SMALL, 'text', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.CHECKOUT, '', ColSize.SMALL, 'text', undefined, undefined));
		this.colsHotel.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.ROOM_TYPE, 'meals', ColSize.SMALL, 'text', undefined, undefined));
	}

}
