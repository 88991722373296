import { RoomModel } from './../models/client/client.model';
import { HttpHandlerService } from './http-handler.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoomsService extends HttpHandlerService {
  private url: string = 'rooms/';
  public firstTime(id: string) {
    return this.get(this.url + 'firstTime', { tripId: id });
  }
  public getAllTripRooms(id: string) {
    return this.get(this.url, { tripId: id });
  }
  public updateRoom(room: RoomModel) {
    return this.put(this.url, room);
  }
  public postNewRoom(room: RoomModel) {
    return this.post(this.url,  room);
  }
  public removeRoom(roomId: string) {
	let path = `remove?roomId=${roomId}`
    return this.put(this.url + path,  undefined);
  }
  public getAvailable(id: string) {
    return this.get(this.url + 'available', { tripId: id });
  }
}
