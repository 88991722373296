import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { DestinationModel } from '../models/client/destination.model';

@Injectable({
	providedIn: 'root'
})
export class DestinationService extends HttpHandlerService {
	private url: string = 'destinations/';


	public all() {
		return this.get(this.url, {});
	}

	public getAllCities(destinationId: string) {
		const path = 'cities';
		return this.get(this.url + path, { destinationId });
	}

	public getAllCitiesByDestinationId(departmentId: string) {
		console.log('departmentId', departmentId)
		const path = 'byDepartemntId';
		return this.get(this.url + path, { departmentId });
	}

	public create(country: DestinationModel) {
		const path = '';
		return this.post(this.url + path, country);
	}

	public update(country: DestinationModel) {
		const path = '';
		return this.put(this.url + path, country);
	}

	public remove(id: string) {
		const path = '';
		return this.delete(this.url + path, { id });
	}

	public addGuide(destinationId: string, guideId: string) {
		const path = 'guides';
		return this.post(this.url + path, { destinationId, guideId });
	}

	public removeGuide(destinationId: string, guideId: string) {
		const path = 'guides';
		return this.delete(this.url + path, { destinationId, guideId });
	}

	public getGuides(destinationId: string) {
		const path = 'guides';
		return this.get(this.url + path, { destinationId });
	}

	public addSupplier(destinationId: string, supplierId: string) {
		const path = 'suppliers';
		return this.post(this.url + path, { destinationId, supplierId });
	}

	public removeSupplier(destinationId: string, supplierId: string) {
		const path = 'suppliers';
		return this.delete(this.url + path, { destinationId, supplierId });
	}

	public getSuppliers(destinationId: string) {
		const path = 'suppliers';
		return this.get(this.url + path, { destinationId });
	}
}
