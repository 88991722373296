import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentOptionsEnum } from 'src/app/models/types/paymentOptions.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CurrenciesTitles,BankTitles,paymentTypeTitles } from 'src/app/models/types/currencies.strings.enum';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from 'src/app/shared/texts';
import * as moment from 'moment';
import { CurrencyOptionsHedlines } from 'src/app/models/trip/finance.enum';

@Component({
  selector: 'app-trip-payment',
  templateUrl: './trip-payment.component.html',
  styleUrls: ['./trip-payment.component.scss']
})
export class TripPaymentComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<TripPaymentComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
  public readonly INVALID_FORM = FORM_MESSAGES;
  BankTitles = BankTitles
  paymentTypeTitles = paymentTypeTitles
  paymentOptions = PaymentOptionsEnum
  currencies = CurrenciesTitles;
  public CurrencyOptionsHedlines = CurrencyOptionsHedlines
  selectedPaymentOption:PaymentOptionsEnum = this.paymentOptions.Credit
  travelerFinanceData:any;
  prices = [
	{title:'סה״כ לתשלום',price:0},
	{title:'סה״כ שולם',price:0},
	{title:'יתרה לתשלום',price:0,priceIls:0},
]
	paymentMethods = [
		{img:'../../../../assets/images/creditCard.svg', option:this.paymentOptions.Credit, title:'אשראי', style:'credit'},
		{img:'../../../../assets/images/banktransfer.svg', option:this.paymentOptions.BankTransfer, title:'העברה בנקאית', style:''},
		{img:'../../../../assets/images/checkk.svg', option:this.paymentOptions.Cheque, title:'צ׳ק', style:'cheque'},
		{img:'../../../../assets/images/cash.svg', option:this.paymentOptions.Cash, title:'מזומן', style:'cash'},
		]
  currentTypeTitle = 'אשראי'

  public form: FormGroup;


  ngOnInit() {
	  this.createForm()
	  this.form.patchValue({
		  currency:this.data.currency
	  })
	  this.travelerFinanceData = this.data.travelerFinanceData
  }

  calculateTotals(){
	this.prices[0].price = this.travelerFinanceData.totalToPay
	this.prices[1].price = this.travelerFinanceData.totalPayed
	this.prices[2].price = this.travelerFinanceData.totalToPay - this.travelerFinanceData.totalPayed
	this.prices[2].priceIls = (this.prices[2].price) * this.travelerFinanceData.currentToIls
 }

  setTitle(type){
	  switch(type){
		case 1:
		this.currentTypeTitle =  paymentTypeTitles.TRANSFER
		break;

		case 2:
		this.currentTypeTitle =  paymentTypeTitles.CREDIT
		break;

		case 3:
		this.currentTypeTitle =  paymentTypeTitles.CHEQUE
		break;

		case 4:
		this.currentTypeTitle =  paymentTypeTitles.CASH
		break;
	  }
  }

  get controls(){
	  return this.form.controls
  }

  handler(value){
	  console.log(value,'value')
  }

  createForm(){
	this.form = this.fb.group({
		price: new FormControl('', Validators.required),
		currency: new FormControl(1, Validators.required),
		paymentType: new FormControl(null, Validators.required),
		bankToTransfer: new FormControl('', Validators.required),
		date: new FormControl(moment(), Validators.required),
		accountNumber: new FormControl(null),
		chequeNumber: new FormControl(null),
		branchNumber: new FormControl(null),
	});
	}

	changePaymentMethod(method){
		this.selectedPaymentOption = method
		this.form.patchValue({paymentType:method})
		this.setTitle(method)
	}

	closePaymentDialog(data?) {
		this.dialogRef.close(data)
	  }

}
