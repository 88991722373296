import { DayModel } from '../trip/day.model';
import { CityModel } from '../trip/city.model';
import { TransportationTypeEnum } from '../types/transportationType.enum';
import { ActivityModel } from '../trip/activity.model';

export class ActivityResModel {
	_id?: string;
	notes: string;
	activity: string | ActivityModel = new ActivityModel();
	fullDay: string;
	isPublic: boolean;
	startTime: string;
	city: string | CityModel = new CityModel();
}

