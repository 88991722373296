import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TripsService } from '../../../../services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES } from '../../../../shared/texts/form.messages';
import { LeadsService } from '../../../../services/leads.service'
import { LeadMoveToActiveComponent } from 'src/app/components/lead-move-to-active/lead-move-to-active.component';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { RegisterStepEnum } from '../../../../models/types/registerStep.enum';
import { ClientService } from 'src/app/services/client.service';
import { ClientModel, ClientInfoModel } from 'src/app/models/client/client.model';
import { TripQueryModel } from 'src/app/models/request/tripQuery.model';
import { TripFullPopComponent } from '../trip-full-pop/trip-full-pop.component';

@Component({
  selector: 'app-lead-move-to-register',
  templateUrl: './lead-move-to-register.component.html',
  styleUrls: ['./lead-move-to-register.component.scss']
})
export class LeadMoveToRegisterComponent implements OnInit, OnDestroy {

  public trips: TripModel[] = [];
  public form: FormGroup;
  public INVALID_FORM = FORM_MESSAGES;
  public waitingResponse: boolean = false;
  public selectedStep: RegisterStepEnum = RegisterStepEnum.SelectTrip
  public RegisterStepEnum = RegisterStepEnum;
  private subscriptions: Subscription[] = [];
  public tripDetails: any;
  public client: ClientModel
  selectedRelatives: ClientInfoModel[]

  constructor(
    public clientService: ClientService,
    public leadService: LeadsService,
    private tripsService: TripsService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LeadMoveToRegisterComponent>,
    private dialog: MatDialog,
    private router: Router,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public lead: any) { }
  @ViewChild('firstStep', { static: true }) firstStep: ElementRef;

  ngOnInit() {
    this.getTrips();
    this.getClientProfile()
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public emitTripStep(tripDetails) {
    this.tripDetails = tripDetails
    this.selectedStep = this.RegisterStepEnum.AddRelatives
  }

  public getClientProfile() {
    this.clientService.getProfile(this.lead.client._id).subscribe(client => {
      this.client = client
    })
  }

  public goLastStep(relatives) {
    let data = {
      tripId: this.tripDetails.trip._id,
      leadEmail: this.tripDetails.email,
      leadToChange: this.lead._id,
      membersToUpdate: relatives.existRelatives,
      membersToCreate: relatives.newRelatives,
      clientId: this.lead.client._id
	}
	this.getActiveTripTravelersData(relatives,data)
  }

  getActiveTripTravelersData(relatives,data) {
	this.leadService.getTripActiveClients(this.tripDetails.trip._id).subscribe(leads => {
		leads = leads.filter( lead =>{
			return lead.status != 7 && lead.status != 0
		})
		
		if(leads.length + relatives.existRelatives.length + relatives.newRelatives.length + 1 <= this.tripDetails.trip.maxTravelers ){
			this.leadService.registerLeadWithMembers(data).subscribe(res => {
			this.waitingResponse = false;
			this.selectedStep = this.RegisterStepEnum.Success
			})
		} else {
			this.openFaildRegisterDialog()
		}
	})
}

  private getTrips() {
	const tripQuery = new TripQueryModel(true,undefined,undefined);
    const subscription = this.tripsService.getAllTrips(tripQuery).subscribe(trips => {
      this.trips = trips;
    });

    this.subscriptions.push(subscription);
  }

  public close(success?) {
    if (success) this.router.navigateByUrl(`/app/trips/details/${this.tripDetails.trip._id}`)
    this.dialogRef.close(success);
  }

  public register() {
    this.waitingResponse = true;
    this.leadService.registerTraveler(this.form.value.trip, this.lead).subscribe(res => {
      this.waitingResponse = false;
      this.close(true)
      this.dataService.leadSubscription.forEach(res => {
        res.unsubscribe()
      })
      this.openDialog()
    })
  }

  openFaildRegisterDialog(): void {
    const dialogRef = this.dialog.open(TripFullPopComponent, {
      width: '500px',
	  height: '250px',
	  position: { top: '10%' },
      panelClass: 'custom-right-dialog-container',
      backdropClass: 'custom-backdropClass',
      disableClose: true,
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LeadMoveToActiveComponent, {
      width: '500px',
	  height: '250px',
	  position: { top: '10%' },
      panelClass: 'custom-right-dialog-container',
      backdropClass: 'custom-backdropClass',
      disableClose: true,
      data: { client: this.lead.client }
    });

    dialogRef.afterClosed().subscribe(result => {

      this.router.navigateByUrl(`/app/trips/lead/${this.lead._id}`)
    });
  }


  public get controls() {
    return this.form.controls;
  }

}
