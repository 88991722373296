import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IFileUpload } from 'src/app/utils/fileUpload/IFileUpload';
import S3FileUpload from 'src/app/utils/fileUpload/S3FileUpload';
import { MatDialog } from '@angular/material';
import { FileNewComponent } from '../../file-new/file-new.component';
import { AppDocument } from '../../../models/client/appDocument';
import * as moment from 'moment';
import { TripsService } from '../../../services/trips.service';
import { DayService } from '../../../services/day.service';
import { Subscription } from 'rxjs';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-trip-documents',
	templateUrl: './trip-documents.component.html',
	styleUrls: ['./trip-documents.component.scss']
})

export class TripDocumentsComponent implements OnInit, OnDestroy {
	amazonFileUpload: IFileUpload;
	@Input() trip: TripModel;
	private subscriptions: Subscription[] = [];
	public daysArray: {date: Date, documents: AppDocument[]}[] = [];
	public isLoading:boolean = false;

	constructor(private dialog: MatDialog, private tripsService: TripsService, private dayService: DayService) {
		this.amazonFileUpload = new S3FileUpload('eu-west-1', '7eab6e63-7f95-4b67-9546-f0e39c6931af', 'geo-dev');

	}

	ngOnInit() {
		this.getTripDocuments();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public fileInputOnChange(event) {
		this.isLoading = true
		if ( event.target.files.length > 0 ) {
			this.uploadFile(event.target.files[0]);
		}
	}

	private getTripDocuments() {
		const subscription = this.tripsService.getDocuments(this.trip._id).subscribe((days: any[]) => {
			this.daysArray = days;
		});
		this.subscriptions.push(subscription);
	}

	private async uploadFile(file: File) {
		await this.amazonFileUpload.UploadFileAsync(file, (res) => {
			if ( !res.success ) {
				this.isLoading = false;
				return console.error('%c an error has occurred trying to upload the image', 'background-color: salmon;');
			}
			let fileName = file.name;
			const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
			fileName = fileName.replace('.' + fileType, '');
			const document = new AppDocument();
			document.url = res.url;
			document.type = fileType;
			document.name = fileName;
			document.createdAt = moment().toISOString();
			this.isLoading = false;
			const dialogRef = this.dialog.open(FileNewComponent, {
				width: '500px',
				height: '530px',
				panelClass: 'custom-dialog-container',
				backdropClass: 'custom-backdropClass',
				position: { top: '170px' },
				disableClose: true,
				data: { document, trip: this.trip }
			});

			const subscription = dialogRef.afterClosed().subscribe((addedDoc: boolean) => {
				if ( addedDoc ) {
					this.getTripDocuments();
				}
			});
			this.subscriptions.push(subscription);
		});

	}

	public deleteFile(file: AppDocument) {
		const subscription = this.dayService.deleteDocument(file._id).subscribe(success => {
			this.getTripDocuments();
		});
		this.subscriptions.push(subscription);
	}
}
