import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-flight-popup',
  templateUrl: './flight-popup.component.html',
  styleUrls: ['./flight-popup.component.scss']
})
export class FlightPopupComponent implements OnInit {



	constructor(
		public fb:FormBuilder,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA)
		public data: any)
		 {}

	public readonly INVALID_FORM = FORM_MESSAGES;
	form:FormGroup

	ngOnInit() {
		this.createForm()
		if(this.data.flightData){
			this.form.setValue(this.data.flightData.data)
		}
	}

	public get controls() {
	  return this.form.controls;
  }

  private createForm() {
	this.form = this.fb.group({
		id:new FormControl('', Validators.required),
		flightRoute: new FormControl('', Validators.required),
		airline: new FormControl('', Validators.required),
		cost: new FormControl(null, Validators.required),
		portTaxes: new FormControl(null, Validators.required),
		notes: new FormControl('', Validators.required),
	});
	}

	addFlight(){
		this.dialogRef.close(this.form.value);
	}

	close(){
		this.dialogRef.close();
	}

}
