import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LeadModel } from '../../../../models/client/lead.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-lead-no-answer-popup',
	templateUrl: './lead-no-answer-popup.component.html',
	styleUrls: [ './lead-no-answer-popup.component.scss' ]
})
export class LeadNoAnswerPopupComponent implements OnInit {
	public isSuccess: boolean = false;
	public lead: LeadModel;
	public dueDate: string;

	constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<LeadNoAnswerPopupComponent>, @Inject(MAT_DIALOG_DATA) private data: LeadModel, private router: Router) {
		this.lead = data;
	}

	ngOnInit(): void {
	}

	close(success?: boolean) {
		this.dialogRef.close();

		if ( success ) {
			this.router.navigateByUrl('app/leads');
		}
	}

	afterSave(dueDate: string) {
		this.dueDate = dueDate;
		this.dialogRef.updateSize('600px', '335px');
		this.isSuccess = true;
	}
}
