import { Component, OnInit, Input } from '@angular/core';
import { TripModel } from 'src/app/models/trip/trip.model';
import { FinanceService } from 'src/app/services/finance.service';
import * as moment from 'moment'
@Component({
  selector: 'app-trip-balance',
  templateUrl: './trip-balance.component.html',
  styleUrls: ['./trip-balance.component.scss']
})
export class TripBalanceComponent implements OnInit {

  constructor(public financeService:FinanceService) { }

  @Input() trip:TripModel
  totalExpenses:number = 0;
  totalIncomes:number = 0
  balance:number = 0;
  incomesData:any;
  public moment = moment


  ngOnInit() {
	  this.getTravelersIncomes()
  }

  getTotalExpenses(total){
	this.totalExpenses = total
	this.balance = (this.totalIncomes - this.totalExpenses)
  }

  getBalance(total){
	this.totalIncomes = total
	this.balance = (this.totalIncomes - this.totalExpenses)
  }

  getTravelersIncomes(){
	this.financeService.getTripIncomes(this.trip._id).subscribe( res =>{
		this.incomesData = res
		console.log(res,'incomess')
	})
  }




}
