import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-suppliers-table',
	templateUrl: './suppliers-table.component.html',
	styleUrls: ['./suppliers-table.component.scss']
})
export class SuppliersTableComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
