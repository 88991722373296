import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { AccessService } from '../../services/access.service';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES } from '../../shared/texts/form.messages';
import { MatDialog } from '@angular/material';
import { ForgetPasswordComponent } from '../../components/forget-password/forget-password.component';
import { LoginModel } from '../../models/response/login.model';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { UserProfileModel } from '../../models/response/userProfile.model';

@Component({
	selector: 'app-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: [ './login-page.component.scss' ]
})
export class LoginPageComponent implements OnInit, OnDestroy {
	public INVALID_FORM = FORM_MESSAGES;
	public loginError: boolean = false;
	public waitingResponse: boolean = false;
	public formChangeSubscribe: Subscription;
	public form: FormGroup;

	constructor(private fb: FormBuilder, private accessService: AccessService, private dialog: MatDialog,
				private dataService: DataService, private router: Router) {
		this.createForm();
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
	}

	public forgotPasswordClicked() {
		this.dialog.open(ForgetPasswordComponent, {
			width: '500px',
			height: '275px',
			panelClass: 'custom-dialog-container',
			backdropClass: 'custom-backdropClass',
			position: { top: '170px' }
		});
	}

	public goLink(){

	}

	public loginClicked() {
		if ( !(this.waitingResponse || this.form.invalid) ) {
			const { email, password } = this.form.controls;
			this.waitingResponse = true;

			this.accessService.login(email.value, password.value).subscribe((resp: UserProfileModel) => {
				this.waitingResponse = false;
				this.loginError = false;
				this.dataService.login(resp);
				this.router.navigateByUrl('/app/clients');
			}, err => {
				this.waitingResponse = false;
				this.loginError = true;
			});
		}
	}

	ngOnDestroy() {
		this.formChangeSubscribe.unsubscribe();
	}

	private createForm() {
		this.form = this.fb.group({
			email: new FormControl('', Validators.compose([ Validators.required, CustomValidators.email ])),
			password: new FormControl('', Validators.required)
		});

		this.formChangeSubscribe = this.form.valueChanges.subscribe(() => {
			this.loginError = false;
		});
	}

}
