import { CityModel } from './city.model';
import { DestinationModel } from '../client/destination.model';

export class HotelModel {
	_id?: string;
	name: string = '';
	destination: DestinationModel | string = '';
	city: string | CityModel = '';
	phone: string = '';
	hotelWeb: string = '';
}
