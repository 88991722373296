import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { LeadModel } from 'src/app/models/client/lead.model';
import { ClientModel } from 'src/app/models/client/client.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-lead-move-to-active',
  templateUrl: './lead-move-to-active.component.html',
  styleUrls: ['./lead-move-to-active.component.scss']
})
export class LeadMoveToActiveComponent implements OnInit {
	public client: ClientModel;

  constructor(@Inject(MAT_DIALOG_DATA) public lead: any,
  public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
  }

  public closeClicked() {
    this.dialogRef.close();
}

}
