import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LeadModel } from '../../../../models/client/lead.model';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-lead-move-to-future-popup',
	templateUrl: './lead-move-to-future-popup.component.html',
	styleUrls: [ './lead-move-to-future-popup.component.scss' ]
})
export class LeadMoveToFuturePopupComponent implements OnInit {
	public isSuccess: boolean = false;
	public lead: LeadModel;


	constructor(private dialogRef: MatDialogRef<LeadMoveToFuturePopupComponent>, @Inject(MAT_DIALOG_DATA) private data: LeadModel, private router: Router) {
		this.lead = data;
	}

	ngOnInit(): void {
	}

	close(success?: boolean) {
		this.dialogRef.close();

		if ( success ) {
			this.router.navigateByUrl('app/leads');
		}
	}

	afterSave() {
		this.dialogRef.updateSize('600px', '275px');
		this.isSuccess = true;
	}
}
