import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';


@Injectable()
export class HttpHandlerService {

	private baseUrl = environment.baseUrl;
	public headers = new HttpHeaders();

	constructor(private http: HttpClient, private router: Router) {
		this.headers = new HttpHeaders().set('Content-Type', 'application/json');
	}

	protected get(url: string, params: {} | undefined): Observable<any> {
		this.cleanParams(params);
		return this.http.get(this.baseUrl + url, { params, headers: this.headers});
	}

	protected getBlob(url: string, params: {} | undefined): Observable<any> {
		this.cleanParams(params);
		return this.http.get(this.baseUrl + url, { params, headers: this.headers,responseType: 'blob'});
	}

	protected delete(url: string, params: {} | undefined): Observable<any> {
		return this.http.delete(this.baseUrl + url, { params, headers: this.headers});
	}

	protected put(url: string, body: {}): Observable<any> {
		return this.http.put(this.baseUrl + url, body, {headers: this.headers});
	}

	protected post(url: string, body: {}): Observable<any> {
		return this.http.post(this.baseUrl + url, body, {headers: this.headers});
	}

	protected getPdf(url: string, params: {} | undefined): Observable<any> {
		return this.http.get(this.baseUrl + url, { params, headers: this.headers,responseType: 'blob'});
	}

	// goes over the keys and remove undefineds
	private cleanParams(params: any) {
		const keys = Object.keys(params);
		keys.forEach((key: string) => {
			if (params[key] === 'undefined' || params[key] === undefined || params[key] === null || params[key] === 'null') {
				delete params[key];
			}
		});
	}
}
