import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-success-message',
	templateUrl: './success-message.component.html',
	styleUrls: [ './success-message.component.scss' ]
})
export class SuccessMessageComponent implements OnInit {
	@Input() title: string;
	@Output() confirm = new EventEmitter();

	public titleToShow: string;
	public isPopup: boolean = false;

	constructor(private dialogRef: MatDialogRef<SuccessMessageComponent>, @Inject(MAT_DIALOG_DATA) private data: string) {
		this.isPopup = this.data !== undefined;
	}

	ngOnInit() {
		this.titleToShow = this.isPopup ? this.data : this.title;
	}

	confirmClicked() {
		if ( this.isPopup ) {
			this.dialogRef.close();
		} else {
			this.confirm.emit();
		}
	}

}
