import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from '../services/data.service';



@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
	constructor(private injector: Injector) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if ( err.status === 401 || err.status === 403 ) {
				const dataService = this.injector.get(DataService);
				dataService.logOut();
			}
			return throwError(err);
		}));
	}
}
