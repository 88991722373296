import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../shared/texts';
import { CustomValidatorsService } from '../../services/custom-validators.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: [ './change-password.component.scss' ]
})
export class ChangePasswordComponent implements OnInit {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public waitingResponse: boolean = false;
	public form: FormGroup;

	constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ChangePasswordComponent>, private customValidatorsService: CustomValidatorsService
		, private userService: UserService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createForm();
	}

	closeClick() {
		this.dialogRef.close();
	}

	updatePassword() {
		this.waitingResponse = true;
		this.userService.changePassword(this.form.controls.oldPassword.value, this.form.controls.password.value).subscribe(() => {
			this.waitingResponse = false;
			this.dialogRef.close();
		}, err => {
			this.waitingResponse = false;
		});
	}

	private createForm() {
		this.form = this.fb.group({
				oldPassword: new FormControl('', Validators.required),
				password: new FormControl('', Validators.required),
				confirmPassword: new FormControl('', Validators.required)
			},
			{
				validator: [
					this.customValidatorsService.confirmPasswordValidator('password', 'confirmPassword'),
				],
			});
	}
}
