import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { PricesCatagoryModel } from 'src/app/models/pricing/pricesCatagoryModel';

@Component({
	selector: 'app-group-expenses-popup',
	templateUrl: './group-expenses-popup.component.html',
	styleUrls: ['./group-expenses-popup.component.scss']
})
export class GroupExpensesPopupComponent implements OnInit {

	constructor(
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA)
		public data: any) { }


	public readonly INVALID_FORM = FORM_MESSAGES;
	form: FormGroup
	pricesCatagory:PricesCatagoryModel[] = []

	public get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createForm()
		if(this.data.formData){
			this.form.patchValue({
				title:this.data.formData.title,
				notes: this.data.formData.notes,
				price: this.data.formData.price,
			})
		}	}

	private createForm() {
		this.form = this.fb.group({
			title: new FormControl('', Validators.required),
			price: new FormControl(null, Validators.required),
			notes: new FormControl('', Validators.required),
		});
	}

	addGroundExpenses() {
		let data = this.form.value
		this.dialogRef.close(data);
	}

	close() {
		this.dialogRef.close();
	}

}
