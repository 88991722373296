import { TRIP_ACTIVE_ENUM_STRINGS_AND_COLORS } from './../../../models/trip/tripStatus.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColModel } from '../../../models/client/tableCols.model';
import { Utils } from '../../../shared/utils';
import { ColSize } from '../../../models/types/colSize.enum';
import { SortTypeEnum } from '../../../models/types/sortType.enum';
import * as moment from 'moment';
import { LEAD_ENUM_STRINGS_AND_COLORS, LeadStatusEnum } from '../../../models/types/leadStatus.enum';
import { LEAD_ENUM_STRINGS } from '../../../models/types/leadCancel.enum';
import { HotelMealsEnum } from 'src/app/models/types/hotelMeals.enum';
import { GROUND_SERVICE_TYPES } from '../../../models/types/groundServiceTypes'
import { RoomTypeEnum } from 'src/app/models/types/roomType.enum';
import { TripPaymentComponent } from '../../trip/trip-payment/trip-payment.component';
import { FilterService } from 'src/app/services/filter.service';
/**
 * Generic Table
 */

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

	leadEnumAndColors = LEAD_ENUM_STRINGS_AND_COLORS;
	tripEnumAndColors = TRIP_ACTIVE_ENUM_STRINGS_AND_COLORS;
	public RoomTypeEnum = RoomTypeEnum
	canceleadLeadEnums = LEAD_ENUM_STRINGS;
	public groundServiceType = GROUND_SERVICE_TYPES;
	moment = moment;
	public smallWidth: number;
	public colSize = ColSize;
	public sortTypeEnum = SortTypeEnum;

	public currentNotes = {
		flight: undefined,
		room: undefined
	}
	@Input() tableHeight: number = window.innerHeight - 400;
	@Input() cols: TableColModel[];
	@Input() data: any[];
	@Input() page: number;
	@Input() itemsPerPage: number;
	@Input() totalPages: number;
	@Input() tableHeightVH: number = 57;
	@Input() specialStyle: boolean = false;
	@Input() isLoading: boolean = false;

	//#region Output Actions

	@Output() nextPage = new EventEmitter();
	@Output() prevPage = new EventEmitter();
	@Output() newAction = new EventEmitter();
	@Output() editAction = new EventEmitter();
	@Output() removeAction = new EventEmitter();
	@Output() sortAction = new EventEmitter();
	@Output() rowCLickAction = new EventEmitter();
	@Output() rowPriceClick = new EventEmitter();
	@Output() editActionPricing = new EventEmitter();
	@Output() openPaymentPopAction = new EventEmitter();

	public utils = new Utils();
	//#endregion

	//#region Input Action Exist

	@Input() withActions: boolean = false;
	@Input() withEdit: boolean = false;
	@Input() withRemove: boolean = false;
	@Input() withRowClick: boolean = false;
	@Input() withPagination: boolean = true;
	@Input() currentCurrency: string
	@Input() withEditPricing: boolean = false;
	//#endregion
	counter: number = 0;

	constructor(public filterService: FilterService) {
		this.moment.locale('he');
	}

	ngOnInit() {
		console.log('sortTypeEnum', this.sortTypeEnum)
		this.calculateColWidht();
		this.counter = 0;
	}

	//#region Actions Clicked
	public nextPageOnClick(selectedPage: number) {
		this.filterService.currentPage = selectedPage
		this.nextPage.emit(selectedPage);
	}

	openSite(site) {
		if (site.includes('http')) {
			window.open(site, "_blank");
		} else {
			window.open('https://' + site, "_blank")
		}
	}


	public editActionOnClick(rowData) {
		this.editAction.emit(rowData);
	}

	public removeActionOnClick(rowData) {
		this.removeAction.emit(rowData);
	}

	public rowOnClick(rowData) {
		if (this.withRowClick) {
			this.rowCLickAction.emit(rowData);
		}
	}

	public priceOnClick(rowData, index) {
		if (this.withRowClick) {
			let data = {
				data: rowData,
				index: index
			}
			this.rowPriceClick.emit(data);
		}
	}

	public sortColClicked(col: TableColModel, sortType: SortTypeEnum) {
		if (SortTypeEnum.None === col.colSortable.SortType) {
			col.colSortable.setAscending();
		} else if (SortTypeEnum.Ascending === col.colSortable.SortType) {
			col.colSortable.setDescending();
		} else if (SortTypeEnum.Descending === col.colSortable.SortType) {
			col.colSortable.setAscending();
		}
		this.sortAction.emit(col);
	}

	private calculateColWidht() {
		let amount = this.cols.reduce((s, a) => s + a.size, 0);
		if (this.withActions) {
			amount += ColSize.MEDIUM;
		}
		this.smallWidth = Math.round(100 / (amount));
	}

	public noteHover(rowData) {
		this.currentNotes.flight = rowData.lead_preferences.flight_notes
		this.currentNotes.room = rowData.lead_preferences.room_notes
	}

	public nullCurrentNotes() {
		this.currentNotes.flight = undefined,
			this.currentNotes.room = undefined
	}

	public openPaymentDialog(data) {
		this.openPaymentPopAction.emit(data)
	}

	//#endregion

}
