import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NoteModel } from '../../../models/client/note.model';
import * as moment from 'moment';
import { ClientModel } from '../../../models/client/client.model';
import { NotesService } from '../../../services/notes.service';

@Component({
	selector: 'app-client-notes',
	templateUrl: './client-notes.component.html',
	styleUrls: [ './client-notes.component.scss' ]
})
export class ClientNotesComponent implements OnInit {
	@Input() client: ClientModel;
	public notes: NoteModel[] = [];
	public textInput: FormControl = new FormControl('', Validators.required);

	constructor(private noteService: NotesService) {
	}

	ngOnInit() {
		this.getNotes();
	}

	public addNote() {
		const nowMoment = moment();
		const dueDate = moment.utc().year(nowMoment.year()).month(nowMoment.month())
			.date(nowMoment.date()).startOf('day').hour(Number(nowMoment.hour())).minutes(nowMoment.minutes()).toISOString(true);
		const newNote: NoteModel = {
			description: this.textInput.value,
			client: this.client._id,
			date: dueDate
		} as NoteModel;

		this.noteService.saveNot(newNote).subscribe(() => {
			this.textInput.reset('');
			this.getNotes();
		});
	}

	private getNotes() {
		this.noteService.getClientNotes(this.client._id).subscribe(notes => {
			this.notes = notes;
		});
	}

	public updateNotes() {
		this.getNotes();
	}
}
