import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { TaskViewEnum } from '../../models/types/taskView.enum';
import { DataService } from '../../services/data.service';
import { LeadModel } from 'src/app/models/client/lead.model';
import { TaskModel } from 'src/app/models/client/task.model';
import { TaskStatusEnum } from '../../models/types/taskStatus.enum';
//import { toDate } from '@angular/common/src/i18n/format_date';

@Component({
	selector: 'app-task-view',
	templateUrl: './task-view.component.html',
	styleUrls: [ './task-view.component.scss' ]
})
export class TaskViewComponent implements OnInit {

	@Input() viewOption: TaskViewEnum;
	@Input() task: TaskModel;
	@Output() viClicked: EventEmitter<TaskModel> = new EventEmitter();
	@Output() delete: EventEmitter<TaskModel> = new EventEmitter();
	@Output() restore: EventEmitter<TaskModel> = new EventEmitter();
	public taskViewEnum = TaskViewEnum;
	public taskEnum = TaskStatusEnum;
	public isSelected: boolean = false;
	public date: string;
	public time: string;
	public timeHasPassed: boolean = false;

	constructor(private dataService: DataService) {
	}

	ngOnInit() {
		const now = moment.utc();
		const taskDate = moment.utc(this.task.dueDate);
		this.timeHasPassed = now.isAfter(taskDate) && this.task.status === this.taskEnum.Open;
	}

	public setAsDone() {
		if ( this.task.status === this.taskEnum.Open ) {
			this.viClicked.emit(this.task);
		}
	}

	public deleteClicked() {
		this.delete.emit(this.task);
	}

	public restoreClicked() {
		this.restore.emit(this.task);
	}

	public openNewTaskForm() {
		setTimeout(() => {
			this.dataService.updateNewTask((this.task.lead as LeadModel));
		}, 1);
	}
}
