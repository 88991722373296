import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { FlightPriceModel } from 'src/app/models/pricing/FlightPriceModel';
import { MatDialog } from '@angular/material';
import { FlightPopupComponent } from '../popups/flight-popup/flight-popup.component';
import { TripModel } from 'src/app/models/trip/trip.model';
import { PricingService } from 'src/app/services/pricing.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-pricing-flights',
  templateUrl: './pricing-flights.component.html',
  styleUrls: ['./pricing-flights.component.scss']
})
export class PricingFlightsComponent implements OnInit {

  constructor(public dialog: MatDialog, public pricingService:PricingService) {
	this.createColsTableItem();
  }
  public cols: TableColModel[] = [];
  tableHeight:number = 300;
  flights:FlightPriceModel[] = []
  currentEditIndex:number;
  @Output() emitStep = new EventEmitter()
  @Output() getPricingData = new EventEmitter()
  @Input() tripData:TripModel
  currentCurrency:string = '$'

  ngOnInit() {
	  this.flights = this.tripData.pricing.flightPricing.flightPricingData
	  console.log(this.tripData,'tripdata')
  }

  private createColsTableItem() {
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.PRICE_FLIGHTS.ROUTE, 'flightRoute', ColSize.LONG, 'priceNotes', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.PRICE_FLIGHTS.AIRLINE, 'airline', ColSize.LONG, 'priceNotes', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.PRICE_FLIGHTS.PRICE, 'cost', ColSize.MEDIUM, 'price', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.PRICE_FLIGHTS.TAXES, 'portTaxes', ColSize.MEDIUM, 'price', undefined, undefined));
	this.cols.push(new TableColModel(TABLE_COLS_NAMES.PRICE_FLIGHTS.NOTES, 'notes', ColSize.MEDIUM, 'priceNotes', undefined, undefined));
}

removeItem(itemToRemove){
	let index = this.flights.findIndex( item => {
		return item === itemToRemove
	})
	this.flights.splice(index,1)
	let data = {
		pricingId:this.tripData.pricing._id,
		dataToUpdate: this.flights,
		fieldToUpdate:'flightPricing'
	}
	  this.pricingService.updatePricingFlights(data).subscribe( res => {
		  this.getPricingData.emit(this.tripData._id)
	  })
  }

addEditFlight(flightData?): void {
	if(this.tripData.completed && this.tripData.tripClass === 1) return;
    const dialogRef = this.dialog.open(FlightPopupComponent, {
	  position: { left: '0', top: '0' },
	  width: '360px',
	  height: '100vh',
	  data: {flightData},
	  disableClose:true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
		  if(flightData && flightData.data){
			  this.flights[flightData.index] = result
		  } else {
			  this.flights.push(result)
		  }
		  let data = {
			pricingId:this.tripData.pricing._id,
			tripId:this.tripData._id,
			dataToUpdate: this.flights,
			fieldToUpdate:'flightPricing'
		}
		  this.pricingService.updatePricingFlights(data).subscribe( res => {
			  this.getPricingData.emit(this.tripData._id)
		  })
	  }
    });
  }

  goStep(stepNumber){
	this.emitStep.emit(stepNumber)
}

	getCurrency(){
		let currency = this.tripData.pricing.generalDetails.currency
		let currencyType
		switch(currency) {
			case 1:
			return 1;

			case 2:
			currencyType = 'USDEUR'
			break;

			case 3:
			currencyType = 'USDILS'
			break;

			case 4:
			currencyType = 'USDGBP'
			break;

		  }
		  this.pricingService.getCurrency(currencyType).subscribe(currency =>{
			return currency;
		})
	}

}
