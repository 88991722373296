import { ThreeSuccessMessageComponent } from './components/lead/popups/lead-move-to-register/components/three-success-message/three-success-message.component';
import { TripRoomDialogComponent } from './components/trip/trip-room-dialog/trip-room-dialog.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {CanActivateNotLoggedUserService} from './shared/can-activate-not-logged-user.service';
import {CanActivateLoggedUserService} from './shared/can-activate-logged-user.service';
import {AppLayoutComponent} from './components/app-layout/app-layout.component';
import {LeadsPageComponent} from './pages/leads-page/leads-page.component';
import {UsersPageComponent} from './pages/users-page/users-page.component';
import {ClientsPageComponent} from './pages/clients-page/clients-page.component';
import { LeadPageComponent } from './pages/lead-page/lead-page.component';
import { ClientPageComponent } from './pages/client-page/client-page.component';
import { TripsPageComponent } from './pages/trips-page/trips-page.component';
import { TripPageComponent } from './pages/trip-page/trip-page.component';
import { TripNewPageComponent } from './pages/trip-new-page/trip-new-page.component';
import { StorageDepartmentPageComponent } from './pages/storage-department-page/storage-department-page.component';
import { StorageDepartmentTablePageComponent } from './pages/storage-department-table-page/storage-department-table-page.component';
import { StorageDestinationTablePageComponent } from './pages/storage-destination-table-page/storage-destination-table-page.component';
import { StorageCityTablePageComponent } from './pages/storage-city-table-page/storage-city-table-page.component';
import { TripBuildPageComponent } from './pages/trip-build-page/trip-build-page.component';
import { PastTripsPageComponent } from './pages/past-trips-page/past-trips-page.component';
import { StoragePageComponent } from './pages/storage-page/storage-page.component';
import { StorageGuidesPageComponent } from './pages/storage-guides-page/storage-guides-page.component';
import { StorageAirlinesPageComponent } from './pages/storage-airlines-page/storage-airlines-page.component';
import { TripLeadPage } from './pages/trip-lead-page/trip-lead-page.component';
import { TripNotebookComponent } from './pages/trip-notebook/trip-notebook.component';
import { TripPricingPageComponent } from './pages/trip-pricing-page/trip-pricing-page.component';
import { ProviderPaymentsPageComponent } from './pages/provider-payments-page/provider-payments-page.component';
import { StorageProvidersPageComponent } from './pages/storage-providers-page/storage-providers-page.component';

const routes: Routes = [
	{path: '', redirectTo: 'app/clients', pathMatch: 'full', canActivate: [CanActivateNotLoggedUserService]},
	{path: 'login', component: LoginPageComponent, canActivate: [CanActivateNotLoggedUserService]},
	{
		path: 'app', component: AppLayoutComponent, canActivate: [CanActivateLoggedUserService],

		children: [
			{path: 'testing', component:TripRoomDialogComponent},
			{

				path: 'users',
				children: [
					{path: '', component: UsersPageComponent},
				]
			},
			{
				path: 'clients',
				children: [
					{path: '', component: ClientsPageComponent},
					{path: ':id', component: ClientPageComponent},
				]
			},
			{
				path: 'leads',
				children: [
					{path: '', component: LeadsPageComponent},
					{path: ':id', component: LeadPageComponent},

				]
			},
			{
				path: 'trips',
				children: [
					{path: 'completed', component: TripsPageComponent},
					{path: 'inprogress', component: TripsPageComponent},
					{path: 'details/:id', component: TripPageComponent},
					{path: 'build/:id', component: TripBuildPageComponent},
					{path: 'new', component: TripNewPageComponent},
					{path: 'edit/:id', component: TripNewPageComponent},
					{path: 'past', component: PastTripsPageComponent},
					{path: 'lead/:id', component: TripLeadPage},
					{path: 'notebook/:id', component: TripNotebookComponent},
					{path: 'pricing/:id', component: TripPricingPageComponent},

					// {path: '**', redirectTo: 'completed', pathMatch: 'full'}
				]
			},
			{
				path: 'storage',
				children: [
					{path: '', component: StoragePageComponent},
					{path: 'departments', component: StorageDepartmentPageComponent},
					{path: 'guides', component: StorageGuidesPageComponent},
					{path: 'airlines', component: StorageAirlinesPageComponent},
					{path: 'providers', component: StorageProvidersPageComponent},
					{path: 'department/:id', component: StorageDepartmentTablePageComponent},
					{path: 'destination/:id', component: StorageDestinationTablePageComponent},
					{path: 'city/:id', component: StorageCityTablePageComponent},
				]
			},
			{
				path: 'finance', component: ProviderPaymentsPageComponent
			},
		]
	},
	{path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
