import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TripModel } from 'src/app/models/trip/trip.model';
import { LeadModel } from 'src/app/models/client/lead.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES } from 'src/app/shared/texts';

@Component({
	selector: 'app-one-select-trip',
	templateUrl: './one-select-trip.component.html',
	styleUrls: ['./one-select-trip.component.scss']
})
export class OneSelectTripComponent implements OnInit {

	@Input() trips: TripModel[];
	@Input() lead: any;
	@Output() close = new EventEmitter();
	@Output() emitTripStep = new EventEmitter();
	public INVALID_FORM = FORM_MESSAGES;

	public form: FormGroup;

	constructor(private fb: FormBuilder) {
	}

	ngOnInit() {
		console.log(this.lead, ' lead')
		this.buildForm()
	}

	private buildForm() {
		this.form = this.fb.group({
			email: new FormControl(this.lead.client.personalDetails.email, Validators.compose([Validators.required, CustomValidators.email])),
			trip: new FormControl('', Validators.required),
		});
	}

	public nextStep() {
		if (this.form.valid)
			this.emitTripStep.emit(this.form.value)
	}

	public get controls() {
		return this.form.controls;
	}

	cancel() {
		this.close.emit(false)
	}





}
