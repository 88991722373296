import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

    @Output() ClickedOutside = new EventEmitter();
	constructor(private el: ElementRef) {}
    
    @HostListener('document:click', ['$event'])
    clickout(event) {
      if(!this.el.nativeElement.contains(event.target)) {
        this.ClickedOutside.emit(this.el.nativeElement.id);
      }
    }

}
