export enum tripNotebookTabsEnum {
	Schedule = 1,
	Bundle,
	General,
	Pricing,
	Terms
}

export const NOTEBOOK_STRINGS = {
	SCHEDULE: 'לו״ז טיול',
	BUNDLE: 'כלול/לא כלול',
	PRICING:'מחירים',
	GENERAL:'פרטים כללים',
	TERMS:'תנאי תשלום'
};
