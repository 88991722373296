import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { ReservationsService } from '../../../services/reservations.service';
import { CityModel } from 'src/app/models/trip/city.model';
import { TripDialogDataModel } from 'src/app/models/trip/tripDialogData.model';
import * as moment from 'moment';
import { TransportationTypeEnum, TRANSPORTATION_TYPE } from 'src/app/models/types/transportationType.enum';
import { MealResModels } from 'src/app/models/reservations/mealRes.model';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
  selector: 'app-trip-new-meal',
  templateUrl: './trip-new-meal.component.html',
  styleUrls: ['./trip-new-meal.component.scss']
})
export class TripNewMealComponent implements OnInit {

	public form: FormGroup;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public dateTitle: string = '';
	public readonly TRANSPORTATION_TYPE = TRANSPORTATION_TYPE;
	public actionTitle: string = '';

	constructor(private dialogRef: MatDialogRef<TripNewMealComponent>,
				@Inject(MAT_DIALOG_DATA) private data: TripDialogDataModel,
				private fb: FormBuilder,
				private reservationsService: ReservationsService) { }

	ngOnInit() {
		this.createForm();
		this.setActionTitle()
		this.setDateTitle()
	}



	private createForm() {
		const meal: MealResModels = Object.assign(new MealResModels(), this.data.data);
		this.form = this.fb.group({
			meal_type: new FormControl(meal.meal_type, Validators.required),
			restaurant: new FormControl(meal.restaurant, Validators.required),
			startTime: new FormControl(meal.startTime, Validators.required),
			only_seats: new FormControl(meal.only_seats, Validators.required),
			notes: new FormControl(meal.notes),
		});
	}

	public get controls() {
		return this.form.controls;
	}


	private setDateTitle() {
		this.dateTitle = `יום ${this.data.dayNum}, ${moment(this.data.day.date).format('DD.MM.YY')}`;
	}

	private setActionTitle() {
		this.actionTitle = this.data.data ? 'עריכת' : 'הוספת';
	}

	public close() {
		this.dialogRef.close();
	}

	public onSubmit() {
		if(!this.form.value.pay_direct){
			this.form.patchValue({
				pay_direct:false
			})
		}
		if(!this.form.value.only_seats){
			this.form.patchValue({
				only_seats:false
			})
		}
		if (this.form.invalid) {
			return;
		}
		const form = this.form.controls;
		const meal: MealResModels = {
			meal_type: form.meal_type.value,
			restaurant: form.restaurant.value,
			startTime: form.startTime.value,
			only_seats: form.only_seats.value,
			notes:form.notes.value
		};
		if (this.data.data && this.data.data._id) {
			meal._id = this.data.data._id;
			this.UpdateMealRes(meal);
		} else {
			this.createMealRes(meal);
		}


	}

	public createMealRes(newMealRes: MealResModels) {
		this.reservationsService.createMealRes(newMealRes,this.data.day._id).subscribe(mealCreated => {
			this.dialogRef.close(mealCreated);
		});
	}


	public UpdateMealRes(mealRes: MealResModels) {
		this.reservationsService.updateMealRes(mealRes).subscribe(mealCreated => {
			this.dialogRef.close(mealCreated);
		});
	}

}
