import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
	selector: 'app-lead-cancel-success',
	templateUrl: './lead-cancel-success.component.html',
	styleUrls: [ './lead-cancel-success.component.scss' ]
})
export class LeadCancelSuccessComponent implements OnInit {

	@Output() close = new EventEmitter();
	@Input() client

	constructor() {
	}

	ngOnInit() {
	}

	public closeClicked() {
		this.close.emit(true);
	}
}
