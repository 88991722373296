import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TaskViewEnum } from '../../../../models/types/taskView.enum';
import { DoneTasksComponent } from '../../../../components/done-tasks/done-tasks.component';
import { DataService } from '../../../../services/data.service';
import { MatDialog } from '@angular/material';
import { LeadModel } from '../../../../models/client/lead.model';
import { TaskModel } from 'src/app/models/client/task.model';
import * as moment from 'moment';

@Component({
selector: 'app-lead-tasks',
	templateUrl: './lead-tasks.component.html',
	styleUrls: [ './lead-tasks.component.scss' ]
})
export class LeadTasksComponent implements OnInit {
	public taskViewEnum = TaskViewEnum;
	public moment = moment;

	@Input() tasks = [];
	@Input() lead: LeadModel;

	@Output() taskComplete: EventEmitter<TaskModel> = new EventEmitter();


	constructor(private dataService: DataService, private dialog: MatDialog) {
	}

	ngOnInit() {
	}

	public addNewTask() {
		this.dataService.updateNewTask(this.lead);
	}

	public openDoneTasks() {
		const openedDialog = this.dialog.open(DoneTasksComponent, {
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			data: this.lead._id
		});
	}

	public setTaskAsDone(task: TaskModel) {
		this.taskComplete.emit(task);
	}

}
