import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ClientModel } from '../../../models/client/client.model';
import formatPhoneNumberUtil from '../../../utils/formatPhoneNumber';

@Component({
	selector: 'app-client-base-info',
	templateUrl: './client-base-info.component.html',
	styleUrls: [ './client-base-info.component.scss' ]
})
export class ClientBaseInfoComponent implements OnInit {
	@Input() client: ClientModel = new ClientModel();
	@Output() AddRelative = new EventEmitter()
	@Output() removeRelative = new EventEmitter()

	phoneNumber: string;

	constructor() {
	}

	ngOnInit() {
		this.client.relatives.sort((a,b)=>{return a.firstName.localeCompare(b.firstName)})
		// console.log(this.client.relatives)
		this.phoneNumber = formatPhoneNumberUtil(this.client.personalDetails.phoneNumber);
	}




}
