import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CityService } from '../../../services/city.service';
import { CityModel } from 'src/app/models/trip/city.model';

@Component({
	selector: 'app-city-autocomplete',
	templateUrl: './city-autocomplete.component.html',
	styleUrls: ['./city-autocomplete.component.scss']
})
export class CityAutocompleteComponent implements OnInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() country: string; // country (Destination) id to filter by
	@Input() tripCountries: string[] = [];
	@Input() control: FormControl;
	@Input() placeHolder: string = 'City';
	@Input() class: string; // options are: 'location' or nothing
	@Input() notRequired: boolean = false; // options are: 'location' or nothing
	@Output() emittedCity = new EventEmitter()

	public form: FormGroup;
	public citiesList: CityModel[] = [];
	public filteredOptions: CityModel[] = [];
	private subscription: Subscription;

	constructor(private fb: FormBuilder, private cityService: CityService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.getCities();
		this.buildForm();
	}

	public getCities() {
		if (this.tripCountries.length > 0) {
			this.tripCountries.push(this.country);
			this.tripCountries.forEach((num, index) => {
				if(!num)
					this.tripCountries.splice(index,1)
				})
		}

		this.subscription = this.cityService.getAll(this.tripCountries.length ? this.tripCountries : this.country).subscribe(citiesList => {
			this.citiesList = citiesList;
			// sets city name value when in edit mode
			const city = citiesList.find(c => c._id === this.control.value);
			if (city) {
				this.form.controls.name.setValue(city._id);
			}
		});
	}

	public buildForm() {
		if (this.notRequired) {
			this.form = this.fb.group({
				name: ['']
			});
		} else {
			this.form = this.fb.group({
				name: ['', Validators.required]
			});
		}
	}

	public handler(city): void {
		this.control.setValue(city);
		// this.form.patchValue({
		// 	name: city._id
		// })
		this.emittedCity.emit(city)
	}


}
