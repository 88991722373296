import { Component, OnInit, Input } from '@angular/core';
import { TripsService } from 'src/app/services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import { ActivatedRoute } from '@angular/router';
import { NotebookService } from 'src/app/services/notebook.service';
import { Subscription } from 'rxjs';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-trip-notebook-schedule',
  templateUrl: './trip-notebook-schedule.component.html',
  styleUrls: ['./trip-notebook-schedule.component.scss']
})
export class TripNotebookScheduleComponent implements OnInit {

  constructor(private dialog: MatDialog,public tripsService:TripsService,public route:ActivatedRoute, public notebookService:NotebookService) { }
  @Input() currentCurrency

  @Input() trip:TripModel
  daysArray = []
  notesArray:boolean[] = []
  pdfData
  currentNote:string
  editArray:any[] = [];
  id:string;
  subscription:Subscription[] = []
  daysNgModelArray:any[]

  ngOnInit() {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.id = this.route.snapshot.paramMap.get('id');
		this.getPDFData()
	});
	this.subscription.push(leadSubscription)
  }

  ngOnDestroy(){
	  this.subscription.forEach( sub =>{
		  sub.unsubscribe()
	  })
  }

  getPDFData(){
	this.tripsService.getPDFData(this.id).subscribe( res => {
		this.daysArray = res.daysDescription
		this.pdfData = res
		this.daysNgModelArray = res.daysDescription.map(day => {
			return {description:day.description,show:false}
		})
	})
  }

  showEditDescription(index){
	this.daysNgModelArray[index].show = true;
  }

  saveDayDescription(index){
	this.daysArray[index].description = this.daysNgModelArray[index].description
	this.daysNgModelArray[index].show = false;
	this.updateDaysDescription()
  }

  cancelDayDescription(index){
	this.daysNgModelArray[index].description = this.daysArray[index].description
	this.daysNgModelArray[index].show = false;
  }

  openNoteField(index){
	this.currentNote = ''
	this.notesArray.forEach( noteCell =>{
		noteCell = false;
	})
	this.notesArray[index] = true;

  }

  addNote(index){
	this.daysArray[index].notes.push(this.currentNote)
	this.notesArray[index] = false;
	this.notebookService.updateNotebookData(this.pdfData._id,'daysDescription',this.daysArray).subscribe(res => {
		this.getPDFData()
	})
  }

  removeNote(dayIndex,noteIndex){
	  this.daysArray[dayIndex].notes.splice(noteIndex,1)
	  this.notebookService.updateNotebookData(this.pdfData._id,'daysDescription',this.daysArray).subscribe(res => {
		this.getPDFData()
	})
  }

  editNote(note,dayIndex,noteIndex){
	this.daysArray[dayIndex].notes[noteIndex] = note
	this.notebookService.updateNotebookData(this.pdfData._id,'daysDescription',this.daysArray).subscribe(res => {
		this.getPDFData()
	})
  }

  updateDaysDescription(){
	this.notebookService.updateNotebookData(this.pdfData._id,'daysDescription',this.daysArray).subscribe(res => {
		this.getPDFData()
	})
  }

  public openUpdateDialog() {
	const openedDialog = this.dialog.open(ConfirmPopupComponent, {
		width: '500px',
		height: '226px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: true,
		data: ConfirmationPopupTypes.UpdateDayDescription,
	});

	openedDialog.afterClosed().subscribe((confirm: boolean) => {
		if(confirm){
			this.tripsService.getUpdatedDayDescription(this.pdfData._id).subscribe( res =>{
				this.daysArray = res.daysDescription
				this.pdfData = res
				this.daysNgModelArray = res.daysDescription.map(day => {
					return {description:day.description,show:false}
				})
			})
		}
	});
}

}
