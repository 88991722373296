import { IFileUpload } from './IFileUpload';

declare var AWS: any;

class S3FileUpload implements IFileUpload {
	private s3: any;

	constructor(region: string, identityID: string, bucketName: string, apiVersion = '2006-03-01') {
		AWS.config.region = region;
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: region + ':' + identityID,
		});

		this.s3 = new AWS.S3({
			apiVersion,
			params: { Bucket: bucketName }
		});
	}

	async UploadFileAsync(fileToUpload: File, cb: (ret: any) => void) {
		let fileName = fileToUpload.name;
		const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
		fileName = fileName.replace(fileType, '');
		const photoKey = fileName + Date.now() + '.' + fileType;
		return await this.s3.upload({
				Key: photoKey,
				Body: fileToUpload,
				ACL: 'public-read'
			},
			(err: any, data: any) => {
				let ret;
				if ( err ) {
					ret = { success: false, error: err };
				} else {
					ret = { success: true, url: data.Location };
				}
				cb(ret);
			});
	}
}

export default S3FileUpload;
