import { Component, OnInit, Input } from '@angular/core';
import { BundlePopComponent } from '../../notebook/bundle-pop/bundle-pop.component';
import { MatDialog } from '@angular/material';
import { TripsService } from 'src/app/services/trips.service';
import { NotebookService } from 'src/app/services/notebook.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trip-notebook-general-details',
  templateUrl: './trip-notebook-general-details.component.html',
  styleUrls: ['./trip-notebook-general-details.component.scss']
})
export class TripNotebookGeneralDetailsComponent implements OnInit {

  constructor(public route:ActivatedRoute,private dialog: MatDialog,public tripsService:TripsService,public notebookService:NotebookService) { }

  @Input() trip;
  @Input() currentCurrency

	pdfData:any
	generalInformation:any
	tripId:string;

  ngOnInit() {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.tripId = this.route.snapshot.paramMap.get('id');
		this.getPDFData()
	});
  }

  getPDFData(){
	this.tripsService.getPDFData(this.tripId).subscribe( res => {
		this.pdfData = res
		this.generalInformation = res.generalInformation
	})
  }

  openTermsDialog(){
	const dialogRef = this.dialog.open(BundlePopComponent, {
		width: '858px',
		height: '775px',
	  panelClass: 'custom-right-dialog-container',
	  backdropClass: 'custom-backdropClass',
	  disableClose: false,
	  data:{component:'general',componentHeadline:'הוסף תנאי'}
	});

	dialogRef.afterClosed().subscribe(result => {
		if(result){
			let note = {
				headLine:result.headLine,
				description:result.description? result.description:[]
			}
			this.generalInformation.push(note)
		}
		this.notebookService.updateNotebookData(this.pdfData._id,'generalInformation',this.generalInformation).subscribe(res => {
			this.getPDFData()
		})
	});
}

editNote(note,index){
		const dialogRef = this.dialog.open(BundlePopComponent, {
			width: '858px',
			height: '775px',
		  panelClass: 'custom-right-dialog-container',
		  backdropClass: 'custom-backdropClass',
		  disableClose: false,
		  data:{component:'general',note,componentHeadline:'ערוך תנאי'}
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.generalInformation[index] = result
			}
			this.notebookService.updateNotebookData(this.pdfData._id,'generalInformation',this.generalInformation).subscribe(res => {
				this.getPDFData()
			})
		});
}

removeNote(index){
	this.generalInformation.splice(index,1)
	this.notebookService.updateNotebookData(this.pdfData._id,'generalInformation',this.generalInformation).subscribe(res => {
		this.getPDFData()
	})
}


}
