import { RoomTypeEnum } from './../../../models/types/roomType.enum';
import { ClientInfoModel } from 'src/app/models/client/client.model';
import { RoomModel } from './../../../models/client/client.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-trip-room-container',
  templateUrl: './trip-room-container.component.html',
  styleUrls: ['./trip-room-container.component.scss']
})
export class TripRoomContainerComponent implements OnInit {
  @Input() roomsList: RoomModel[] = []; // TODO: update model
  @Input() availableClientsList: ClientInfoModel[] = [];
  @Input() type: string = '';
  @Output() roomUpdated: EventEmitter<RoomModel> = new EventEmitter();
  @Output() createRoom = new EventEmitter();
  @Output() removeRoom = new EventEmitter();
  values: any[] = [];
  break: boolean = false;
  selectionForm: FormGroup;
  constructor(private fb: FormBuilder ) { }

  ngOnInit() {
	this.generateRoomsArray();
	console.log(this.availableClientsList,'clientListInComp')
  }

  generateRoomsArray() {
    let i = 0;
    this.roomsList.forEach((room) => {
      this.values.push(room['sleepers']);
    });
  }

  getClassByType() {
    return this.type.toLowerCase();
  }
  getNumByType() {
    switch (this.type) {
      case 'Single': {
        return [...Array(1).keys()];
      }
      case 'Double': {
        return [...Array(2).keys()];
      }
      case 'Twin': {
        return [...Array(2).keys()];
      }
      case 'Triple': {
        return [...Array(3).keys()];
      }
      case 'Quad': {
        return [...Array(4).keys()];
      }
    }
  }

  optionClicked(guest, i, j) {
    if (guest !== '') {
      this.roomsList[i]['sleepers'][j] = guest;
    }
    else {
      this.roomsList[i]['sleepers'].splice(j,1);
	}
    this.roomUpdated.emit(this.roomsList[i]);
  }
  checkIfOptionSHouldBeHidden(i, j){
    return this.roomsList && this.roomsList[i] && this.roomsList[i].sleepers && this.roomsList[i].sleepers[j];
  }

  addNewRoom() {
    this.createRoom.emit(this.getType())
  }
  getType() {
    switch (this.type){
      case 'Single': {
        return RoomTypeEnum.SGNL;
      }
      case 'Double': {
        return RoomTypeEnum.DBL;
      }
      case 'Twin': {
        return RoomTypeEnum.TWN;
      }
      case 'Triple': {
        return RoomTypeEnum.TRPL;
      }
      case 'Quad': {
        return RoomTypeEnum.QUAD;
      }
    }
  }




}
