import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trip-summary-item',
  templateUrl: './trip-summary-item.component.html',
  styleUrls: ['./trip-summary-item.component.scss']
})
export class TripSummaryItemComponent implements OnInit {

  constructor() { }

  @Input() title:string;
  @Input() categoryArray:number[]
  @Input() bold:boolean
  @Input() grey:boolean
  @Input() special:boolean
  @Input() recommended:boolean
  @Input() item:any
  @Input() percent:any
  @Input() withEdit:boolean = false;
  @Input() currentCurrency:string;
  @Input() tripData
  @Input() isConverted:boolean
  @Output() emitSummaryUpdate = new EventEmitter()

  isEdit:boolean = false;
  profitArray:number[] = []

  ngOnInit() {
	  this.categoryArray.forEach( (price,i) => {
		  this.profitArray[i] = this.item[price]
	  })
  }

  openEdit(){
	if(this.tripData.completed && this.tripData.tripClass === 1) return;
	  if(this.withEdit){
		  this.isEdit = true;
	  }
  }

  saveProfit(){
		let data:any = {}
	    this.categoryArray.forEach( (price,i) => {
		if(this.profitArray[i] === null) this.profitArray[i] = 0
		data[price] = this.profitArray[i]
	  })
	  this.emitSummaryUpdate.emit(data)
	  this.isEdit = false;
  }

  cancelProfit(){
	this.categoryArray.forEach( (price,i) => {
		this.profitArray[i] = this.item[price]
	})
	this.isEdit = false;
  }

}
