import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORM_MESSAGES } from '../../shared/texts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppDocument } from '../../models/client/appDocument';
import { ClientService } from '../../services/client.service';
import { DayService } from '../../services/day.service';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-file-new',
	templateUrl: './file-new.component.html',
	styleUrls: [ './file-new.component.scss' ]
})
export class FileNewComponent implements OnInit {
	public INVALID_FORM = FORM_MESSAGES;
	public form: FormGroup;
	public document: AppDocument;
	public clientId: string;
	public dayId: string;
	public waitingResponse: boolean = false;
	public trip:TripModel

	constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<FileNewComponent>, private dayService: DayService,
				@Inject(MAT_DIALOG_DATA) private data: { document: AppDocument, clientId: string, dayId: string, trip:TripModel }, private clientSerive: ClientService) {
		this.document = data.document;
		this.clientId = data.clientId;
		this.dayId = data.dayId;
		this.trip = data.trip
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createForm();
	}

	public closeClicked() {
		this.dialogRef.close();
	}

	public saveDoc() {
		if (this.data.clientId) {
			this.saveClientDoc();
		} else {
			this.saveTripDayDoc();
		}
	}

	public saveClientDoc() {
		this.waitingResponse = true;
		this.document.name = this.form.controls.name.value;
		this.clientSerive.addDocument(this.clientId, this.document).subscribe(resp => {
			this.waitingResponse = false;
			this.dialogRef.close(true);
		}, err => {
			this.waitingResponse = false;

		});
	}

	public saveTripDayDoc() {
		this.waitingResponse = true;
		this.document.name = this.form.controls.name.value;

		this.dayService.addDocument(this.form.value.dayId, this.document).subscribe(resp => {
			this.waitingResponse = false;
			this.dialogRef.close(true);
		}, err => {
			this.waitingResponse = false;
		});
	}

	private createForm() {
		if(this.trip){
			this.form = this.fb.group({
				name: new FormControl(this.document.name, Validators.required),
				dayId: new FormControl('', Validators.required),
			});
		} else {
			this.form = this.fb.group({
				name: new FormControl(this.document.name, Validators.required),
			});
		}
	}

}
