import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'utcDateTask'
})
export class UtcDateTaskPipe implements PipeTransform {

	transform(dueDate: string, args?: any): any {
		return moment(dueDate).utc().format('DD.MM.YY');
	}

}
