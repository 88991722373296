import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NoteModel } from '../../../../models/client/note.model';
import * as moment from 'moment';
@Component({
	selector: 'app-client-last-calls',
	templateUrl: './client-last-calls.component.html',
	styleUrls: [ './client-last-calls.component.scss' ]
})
export class ClientLastCallsComponent implements OnInit {
	public moment = moment;
	@Input() notes: NoteModel[];
	@Output() update = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	public notesUpdated() {
		this.update.emit();
	}

}
