import { LeadStatusEnum } from '../types/leadStatus.enum';
import { PaginationModel } from '../client/pagination.model';
import { SortTypeEnum } from '../types/sortType.enum';
import { SortableColModel } from '../client/sortableCol';
import { TaskStatusEnum } from '../types/taskStatus.enum';

export class TaskQueryModel {
	private lead: string;
	private dueDate: string;
	private pagination: PaginationModel;
	private status: TaskStatusEnum;
	private userId:string;
	private level:number;

	constructor(lead: string | undefined, dueDate: string | undefined, pagination: PaginationModel | undefined, status: TaskStatusEnum | undefined,userId:string,level:number) {
		this.dueDate = dueDate;
		this.pagination = pagination;
		this.lead = lead;
		this.status = status;
		this.userId = userId
		this.level = level
	}

	public toQueryObject() {
		const query: any = this.pagination ? this.pagination.toObject() : {};

		if ( this.dueDate ) {
			query.dueDate = this.dueDate;
		}

		if ( this.lead ) {
			query.lead = this.lead;
		}

		if ( this.status ) {
			query.status = this.status;
		}

		if ( this.userId ) {
			query.id = this.userId;
		}
		if (this.level){
			query.level = this.level;
		}

		return query;
	}
}
