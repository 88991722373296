import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LEAD_SALE_TYPES } from '../../../../shared/texts';
import { LeadSaleOptionEnum } from '../../../../models/types/leadSaleOption.enum';
import { NoteModel } from '../../../../models/client/note.model';
import { LeadModel } from '../../../../models/client/lead.model';
import * as moment from 'moment';
import { TaskViewEnum } from '../../../../models/types/taskView.enum';
import { NotesService } from '../../../../services/notes.service';
import { TasksService } from '../../../../services/tasks.service';
import { DataService } from '../../../../services/data.service';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TaskModel } from 'src/app/models/client/task.model';
import { TaskStatusEnum } from 'src/app/models/types/taskStatus.enum';
import { Router, NavigationStart, Event } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MessagePopupComponent } from 'src/app/components/message-popup/message-popup.component';

@Component({
	selector: 'app-lead-sale-process',
	templateUrl: './lead-sale-process.component.html',
	styleUrls: ['./lead-sale-process.component.scss']
})
export class LeadSaleProcessComponent implements OnInit, OnDestroy, OnChanges {

	@Input() leadId: string;
	@Input() lead: LeadModel;
	public taskViewEnum = TaskViewEnum;
	public leadSaleTypes = LEAD_SALE_TYPES;
	public saleOptionEnum = LeadSaleOptionEnum;
	public showingComponent = LeadSaleOptionEnum.Call;
	public notes: NoteModel[] = [];
	public textInput: FormControl = new FormControl('', Validators.required);
	public tasksList: TaskModel[] = [];
	public nextTask: TaskModel;
	public allTasks: TaskModel[] = [];
	private subscriptions: Subscription[] = [];

	constructor(private noteService: NotesService,
		private router: Router,
		private dialog: MatDialog,
		private tasksService: TasksService,
		private dataService: DataService) {
	}

	ngOnInit() {
		this.getTasks();
		this.getNotes();
		const tasksSubscription = this.dataService.globalTasks.subscribe((tasks: TaskModel[]) => {
			this.allTasks = tasks;
			this.filterTasks();
		});
		this.subscriptions.push(tasksSubscription);

		// don't navigate unless you have next task
		// const routerSubscription = this.router.events.subscribe((event: Event) => {
		// 	if (event instanceof NavigationStart) {
		// 		if (!this.nextTask) {
		// 			this.router.navigateByUrl(this.router.url, { replaceUrl: true });
		// 			this.openMessagePopup();
		// 		}
		// 	}
		// });
		// this.dataService.leadSubscription.push(routerSubscription);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.filterTasks();
		this.getNotes()
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public changeComponent(showComponent: LeadSaleOptionEnum) {
		this.showingComponent = showComponent;
	}

	public addNote() {
		const nowMoment = moment();
		const dueDate = moment.utc().year(nowMoment.year()).month(nowMoment.month())
			.date(nowMoment.date()).startOf('day').hour(Number(nowMoment.hour())).minutes(nowMoment.minutes()).toISOString(true);
		const newNote: NoteModel = {
			description: this.textInput.value,
			lead: this.leadId,
			date: dueDate
		} as NoteModel;

		const subscription = this.noteService.saveNot(newNote).subscribe(() => {
			this.textInput.reset('');
			this.getNotes();
		});
		this.subscriptions.push(subscription);
	}

	public setTaskAsDone(task: TaskModel) {
		task.status = TaskStatusEnum.Completed;
		const subscription = this.tasksService.updateTask(task).subscribe(resp => {
			this.getTasks();
		});

		this.subscriptions.push(subscription);
	}

	public addTask() {

	}

	private filterTasks() {
		// tslint:disable-next-line:max-line-length
		this.tasksList = this.allTasks.filter((task: TaskModel) => ((task.status === TaskStatusEnum.Open || task.status === TaskStatusEnum.Completed) && task.lead as LeadModel && (task.lead as LeadModel)._id === this.leadId));
		const futureTasks = this.tasksList.filter(task => {
			const now = moment();
			const nowUtc = moment.utc().hours(now.hour()).minute(now.minute());
			const taskDue = moment.utc(task.dueDate);
			return taskDue.isSameOrAfter(nowUtc);
		});

		if (futureTasks.length === 0) {
			this.nextTask = undefined;
		} else {
			this.nextTask = futureTasks[0];
		}
		console.log(this.tasksList, 'tasklist')
		this.tasksList.reverse();
	}


	private getNotes() {
		this.notes = []
		const subscription = this.noteService.getLeadNotes(this.lead._id).subscribe(notes => {
			this.notes = notes;
		});

		this.subscriptions.push(subscription);
	}

	public updateNotes() {
		this.getNotes();
	}

	private getTasks() {
		this.dataService.getUserTasks();
	}

	private openMessagePopup() {
		const dialogRef = this.dialog.open(MessagePopupComponent, {
			panelClass: 'custom-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: 'אנא מלא את השלב הבא'
		});
	}

}
