export enum TransportationTypeEnum {
	Hotel = 1,
	Airport,
	Train,
	Pier,
	Restaurant,
	Border,
	Show
}

export const TRANSPORTATION_TYPE = {
	HOTEL: 'Trf. to hotel',
	AIRPORT: 'Trf. to airport',
	TRAIN: 'Trf. to train',
	PIER: 'Trf. to pier',
	RESTAURANT: 'Trf. to restaurant',
	BORDER: 'Trf. to border',
	SHOW: 'Trf. to show'
};
