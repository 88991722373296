import { Component, OnInit, Input } from '@angular/core';
import { FORM_PLACEHOLDERS, FORM_MESSAGES } from 'src/app/shared/texts';
import { BankTitles } from 'src/app/models/types/currencies.strings.enum';

@Component({
  selector: 'app-type-bank-trnasfer',
  templateUrl: './type-bank-trnasfer.component.html',
  styleUrls: ['./type-bank-trnasfer.component.scss']
})
export class TypeBankTrnasferComponent implements OnInit {

  @Input() accountControl
  BankTitles = BankTitles
  public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
  public readonly INVALID_FORM = FORM_MESSAGES;

  constructor() { }

  ngOnInit() {
  }

  handler(value){
	console.log(value,'value')
}


}
