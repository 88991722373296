import { CityModel } from '../trip/city.model';

export class FlightResModel {
	_id?: string;
	notes: string = '';
	startTime: string = '';
	endTime: string = '';
	toLocation: string;
	toLocationAirportCode: string;
	toLocationCountry:string;
	toLocationCountryInHeb:string;
	toLocationCityInHeb:string;
	fromLocation: string;
	fromLocationAirportCode: string;
	fromLocationCountry:string;
	fromLocationCountryInHeb:string;
	fromLocationCityInHeb:string;
}

