import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ngx-custom-validators';
import {FORM_MESSAGES} from '../../shared/texts/form.messages';
import {MatDialogRef} from '@angular/material';
import {AccessService} from '../../services/access.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
	public INVALID_FORM = FORM_MESSAGES;
	public form: FormGroup;
	public emailNotExist: boolean = false;
	public waitingResponse: boolean = false;
	public forgotResponseSuccess: boolean = false;
	private formChange: Subscription;

	constructor(private fb: FormBuilder, private accessService: AccessService, private dialogRef: MatDialogRef<ForgetPasswordComponent>) {
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createForm();
	}

	public forgotClicked() {

		const {email} = this.form.controls;
		this.accessService.forgot(email.value).subscribe(() => {
			this.forgotResponseSuccess = true;
		}, err => {
			this.emailNotExist = true;
		});
	}

	public close() {
		this.dialogRef.close();
	}

	public ngOnDestroy(): void {
		this.formChange.unsubscribe();
	}

	private createForm() {
		this.form = this.fb.group({
			email: new FormControl('', Validators.compose([Validators.required, CustomValidators.email])),
		});

		this.form.valueChanges.subscribe(() => {
			this.emailNotExist = false;
		});
	}

}
