import { Component, OnInit, Input } from '@angular/core';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { PaginationResponseModel } from 'src/app/models/response/paginationResponse.model';
import { LeadQueryModel } from 'src/app/models/request/leadQuery.model';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { SortTypeEnum } from 'src/app/models/types/sortType.enum';
import { SortableOptionsEnum } from 'src/app/models/types/sortableOptions.enum';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { LeadModel } from 'src/app/models/client/lead.model';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LeadsService } from 'src/app/services/leads.service';
import { DepartmentService } from 'src/app/services/department.service';
import { LeadStatusEnum } from 'src/app/models/types/leadStatus.enum';
import { MatDialog } from '@angular/material';
import { DestinationService } from 'src/app/services/destination.service';
import { FilterService } from 'src/app/services/filter.service';
import { UserModel } from 'src/app/models/client/user.model';
import { UserProfileModel } from 'src/app/models/response/userProfile.model';
import { UserService } from 'src/app/services/user.service';
import { isArray } from 'util';
@Component({
	selector: 'app-lead-sold',
	templateUrl: './lead-sold.component.html',
	styleUrls: ['./lead-sold.component.scss']
})
export class LeadSoldComponent implements OnInit {

	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel(15);
	public leads: LeadModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	@Input() tableHeight: number;
	@Input() destinations: DestinationModel[];
	@Input() departments: DepartmentModel[];
	@Input() supervisors: [];
	@Input() tripTypes: [];
	private readonly leadStatus = LeadStatusEnum.Sold;
	private subscriptions: Subscription[] = [];
	isLoading: boolean = false;
	user: UserModel

	constructor(public userService: UserService, public filterService: FilterService, private router: Router,
		private dialog: MatDialog, private fb: FormBuilder, private leadService: LeadsService, private departmentService: DepartmentService, public destinationService: DestinationService) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.DepartureDate, true);
		this.sortableCol.setDescending()
		this.createColsTableItem();
		this.createForm();
		this.manageUser();
	}


	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		if (this.filterService.currentPage) {
			this.pagination.nextPage = this.filterService.currentPage
		}
	}

	private createForm() {
		this.form = this.fb.group({
			destination: new FormControl(''),
			department: new FormControl(''),
			supervisor: new FormControl(''),
			searchText: new FormControl(''),
			supervisorQuery: new FormControl(''),
			startDate: new FormControl(''),
			endDate: new FormControl(''),
			startDateEntry: new FormControl(''),
			endDateEntry: new FormControl(''),
			tripType: new FormControl('')
		});
	}


	private manageUser() {
		this.userService.getCurrentUserProfile().subscribe((data: UserProfileModel) => {
			this.user = Object.assign(new UserModel(), data.user);
			this.getNewLeads();
		});
	}


	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public clearFilters() {
		this.form.reset();
		this.filterService.clearFilters()
		this.afterFilterChange();
	}


	public goToPage(nextPage: number) {
		this.pagination.nextPage = nextPage;
		this.getNewLeads();
	}

	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if (col.colSortable.SortType === SortTypeEnum.Descending) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.pagination.resetPagination();
		this.getNewLeads();
	}

	public rowClicked(event) {
		this.router.navigateByUrl(`/app/leads/${event._id}`)
	}


	setQuery() {
		let filter = this.filterService
		let query: any = {}
		query.tripType = filter.tripType ? filter.tripType._id : undefined
		query.currentSupervisor = filter.currentSupervisor ? filter.currentSupervisor._id : undefined
		query.currentDepartment = filter.currentDepartment ? filter.currentDepartment._id : undefined
		query.currentDestination = filter.currentDestination ? filter.currentDestination._id : undefined
		return query;
	}

	private updateDestinations() {
		const subscription = this.departmentService.getDepartmentDestinations(this.filterService.currentDepartment && this.filterService.currentDepartment._id).subscribe(resp => {
			this.destinations = resp.destinations;
			let checkDestination
			if (this.filterService.currentDestination) {
				if (isArray(this.filterService.currentDestination._id)) {
					checkDestination = this.destinations.find(d => d._id === this.filterService.currentDestination._id[0])
				} else {
					checkDestination = this.destinations.find(d => d._id === this.filterService.currentDestination._id)
				}
			} else {
				checkDestination = false;
			}
			if (!isArray(this.destinations) || !checkDestination) {
				this.form.controls.destination.setValue(undefined);
				this.filterService.currentDestination = undefined;
			}
			this.afterFilterChange();
		});
		this.subscriptions.push(subscription);
	}

	public afterDepartmentChange() {
		this.updateDestinations();
	}


	public clearClicked() {
		this.controls['searchText'].setValue('');
		this.afterFilterChange();
	}
	public afterFilterChange() {
		this.pagination.resetPagination();
		this.getNewLeads();
	}

	public getNewLeads(report: boolean = false) {
		this.destinationService.getAllCitiesByDestinationId(this.filterService.currentDepartment && this.filterService.currentDepartment._id).subscribe(resp => {
			this.destinations = resp;
		})
		const { searchText, destination, department } = this.form.controls;
		let query = this.setQuery()
		const leadQuery = new LeadQueryModel(query.tripType, this.filterService.currentDates, query.currentSupervisor, query.currentDepartment, query.currentDestination, undefined, this.user._id, searchText.value, this.leadStatus, this.pagination, this.sortableCol, report);
		if (!report) {
			this.isLoading = true
		}
		if (report) {
			this.leadService.getLeadsReport(leadQuery).subscribe(res => {
				let url = window.URL.createObjectURL(res);
				let a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = `report-leads.xlsx`;
				document.body.style.height = '100%'
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			}, err => {
				console.log(err);
			})
		} else {
			this.leads = []
			this.leadService.getAll(leadQuery).subscribe((resp: PaginationResponseModel<LeadModel>) => {
				this.isLoading = false
				this.pagination.amountPages = resp.totalPages;
				this.filterService.totalPages = resp.totalPages
				this.leads = resp.items;
				console.log('this.leads', this.leads)
				this.leads.forEach(lead => {
					if (lead.supervisor['status'] === 0)
						lead.supervisor = ''
				})
			});
		}
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.FIRST_NAME, 'client.personalDetails.firstName', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.LAST_NAME, 'client.personalDetails.lastName', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.LastName, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.PHONE, 'client.personalDetails.phoneNumber', ColSize.MEDIUM, 'phone', undefined, new SortableColModel(SortableOptionsEnum.Phone, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.EMAIL, 'client.personalDetails.email', ColSize.LONG, undefined, undefined, new SortableColModel(SortableOptionsEnum.Email, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.SUPERVISOR, 'supervisor', ColSize.MEDIUM, 'supervisor', undefined, new SortableColModel(SortableOptionsEnum.Supervisor, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.INTERESTED, 'tripType.name', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.InterestedIn, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.DATE_ENTERED, 'createdAt', ColSize.MEDIUM, 'date', undefined, new SortableColModel(SortableOptionsEnum.DueDate, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.STATUS, 'status', ColSize.MEDIUM, 'leadStatus', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.LOCATION, 'destination.name', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.Destination, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.DUE_DATE, 'departureDate', ColSize.MEDIUM, 'monthDate', undefined, new SortableColModel(SortableOptionsEnum.DepartureDate, true)));
	}



}
