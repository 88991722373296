import { LeadStatusEnum } from '../types/leadStatus.enum';
import { PaginationModel } from '../client/pagination.model';
import { SortTypeEnum } from '../types/sortType.enum';
import { SortableColModel } from '../client/sortableCol';

export class LeadQueryModel {
	private department: string;
	private destination: string;
	private client: string;
	private supervisor: string;
	private searchText: string;
	private sortableCol: SortableColModel;
	private status: LeadStatusEnum;
	private pagination: PaginationModel;
	private supervisorQuery: string;
	private dates: any;
	private tripType: string;
	private report: boolean;
	constructor(tripType: string, dates: any, supervisorQuery: string, department: string | undefined, destination: string | undefined, client: string | undefined,
		supervisor: string | undefined, searchText: string | undefined, status: LeadStatusEnum | undefined,
		pagination: PaginationModel | undefined, sortableCol: SortableColModel, report: boolean) {
		this.department = department;
		this.destination = destination;
		this.supervisorQuery = supervisorQuery;
		this.client = client;
		this.supervisor = supervisor;
		this.searchText = searchText;
		this.status = status;
		this.pagination = pagination;
		this.sortableCol = sortableCol;
		this.dates = dates
		this.tripType = tripType
		this.report = report
	}

	public toQueryObject() {
		const query: any = this.pagination ? this.pagination.toObject() : {};

		if (this.sortableCol) {
			query.sort = this.sortableCol.value;
			query.descending = this.sortableCol.SortType === SortTypeEnum.Descending;
		}

		if (this.searchText) {
			query.searchText = this.searchText;
		}

		if (this.department) {
			query.department = this.department;
		}

		if (this.destination) {
			query.destination = this.destination;
		}
		if (this.report) {
			query.report = this.report;
		}

		if (this.supervisor) {
			query.supervisor = this.supervisor;
		}
		if (this.supervisorQuery) {
			query.supervisorQuery = this.supervisorQuery;
		}
		if (this.status) {
			query.status = this.status;
		}
		if (this.client) {
			query.client = this.client;
		}
		if (this.dates.start) {
			query.startDate = this.dates.start;
		}
		if (this.dates.end) {
			query.endDate = this.dates.end
		}
		if (this.dates.leadStart) {
			query.leadStart = this.dates.leadStart;
		}
		if (this.dates.leadEnd) {
			query.leadEnd = this.dates.leadEnd
		}
		if (this.tripType) {
			query.tripType = this.tripType
		}
		console.log(query)
		return query;
	}
}
