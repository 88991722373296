import {Injectable} from '@angular/core';
import {HttpHandlerService} from './http-handler.service';

@Injectable({
	providedIn: 'root'
})
export class TripTypeService extends HttpHandlerService {
	private url: string = 'tripType/';


	public all() {
		return this.get(this.url, {});
	}
}
