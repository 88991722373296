export const FORM_PLACEHOLDERS = {
	FIRST_NAME: 'שם פרטי',
	LAST_NAME: 'שם משפחה',
	EMAIL: 'דוא״ל',
	POSITION: 'תפקיד',
	STATUS: 'סטטוס',
	PHONE: 'טלפון',
	DEPARTMENT: 'מחלקה',
	DESTINATION: 'יעד מבוקש',
	SUPERVISOR: 'גורם מטפל',
	LEAD_SOURCE: 'מקור הפניה',
	LEAD_REASON: 'מהות הפניה',
	DATE: 'תאריך',
	TIME: 'Hour',
	YEAR: 'שנה',
	MONTH: 'חודש',
	DAY: 'יום',
	ACTIONS: 'פעולות',
	TRAVELERS: 'מטיילים',
	BELONG_TRIP: 'שייך לטיול',
	BELONG_CUSTOMER: 'שייך ללקוח',
	THE_MISSION: 'המשימה',
	TIME_TO_EXE: 'זמן לביצוע המשימה',
	OLD_PASSWORD: 'סיסמה ישנה',
	NEW_PASSWORD: 'סיסמה חדשה',
	CONFIRM_PASSWORD: 'אשר סיסמה',
	LEAD_TASK: 'יעד מבוקש',
	CREATOR_TASK: 'מבצע המשימה',
	NEW_DUEDATE: 'זמן חדש לביצוע המשימה'
};


export const NEW_TRIP_FORM_PLACEHOLDERS = {
	TRIP_NAME: 'שם הטיול',
	DEPARTMENT: 'מחלקה',
	DESTINATION: 'יעד',
	CITY: 'יעד משנה',
	TRIP_TYPE: 'סוג הטיול',
	MAX_TRAVELERS: 'גודל הקבוצה',
	SUPERVISOR: 'גורם מטפל',
	START_DATE: 'תאריך יציאה',
	INSTRUCTOR: 'מדריך',
	MEALS: 'ארוחות',
	IMPORT_SCHEDULE: 'ייבא לו״ז טיול',
	YEAR: 'שנה',
};

export const HOTEL_FORM_PLACEHOLDERS = {
	NAME: 'שם המלון',
	CITY: 'עיר',
	COUNTRY: 'מדינה',
	PHONE: 'טלפון',
	WEBSITE: 'אתר המלון',
};

export const CITY_FORM_PLACEHOLDERS = {
	NAME: 'שם העיר באנגלית',
	NAMEHEB:'שם העיר בעברית'
};

export const COUNTRY_FORM_PLACEHOLDERS = {
	NAME: 'שם המדינה באנגלית',
	NAMEHEB: 'שם המדינה בעברית',
};

export const ACTIVITY_FORM_PLACEHOLDERS = {
	NAME: 'שם הסיור',
};

export const GUIDE_FORM_PLACEHOLDERS = {
	NAME: 'שם המדריך',
	PHONE: 'טלפון',
	EMAIL: 'דוא״ל',
	WAGEFIX:'שכר חוברת',
	WAGECLOSED:'שכר סגורות',
	COMMISION: 'עמלה',
	NAMEENG: 'שם פרטי באנגלית',
	LASTENG: 'שם משפחה באנגלית',
	DOB: 'תאריך לידה',
	PASSNUMB: 'מס׳ דרכון',
	PASSDOI: 'תאריך הוצאה',
	PASSDOE: 'תאריך פקיעת תוקף',
	NATIONALITY: 'אזרחות'
};

export const PROVIDER_FORM_PLACEHOLDERS = {
	NAME: 'שם הספק',
	ACCOUNTNUM: 'מס׳ חשבון ספק',
	COUNTRY: 'מדינה',
	DEPARTMENT: 'מחלקה',
	PHONE: 'טלפון',
	EMAIL: 'דוא״ל',
	PHONEE: 'טלפון חירום',
	ADDRESS: 'כתובת',
	BENEFICIARY: 'שם המוטב (Beneficiary)',
	IBAN: 'IBAN',
	SWIFT: 'Swift/Routing code',
	COIN: 'מטבע החשבון',
	BANK_ADDRESS: 'כתובת סניף בנק',
	NOTES: 'הערות'
};

export const AIRLINE_FORM_PLACEHOLDERS = {
	NAME: 'שם חברת התעופה',
};
