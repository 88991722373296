import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-lead-icon-text',
	templateUrl: './lead-icon-text.component.html',
	styleUrls: [ './lead-icon-text.component.scss' ]
})
export class LeadIconTextComponent implements OnInit {

	@Input() text: string;
	@Input() img: string;
	@Input() pointer:boolean
	@Input() clientPage:boolean
	@Input() destination:boolean = false;
	@Input() emailString
	@Input() isPhoneNumber: boolean = false;

	constructor() {
	}

	ngOnInit() {
	}

}
