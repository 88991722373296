import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TableColModel } from '../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../shared/texts';
import { ColSize } from '../../models/types/colSize.enum';
import { DestinationModel } from '../../models/client/destination.model';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '../../components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { GuideService } from 'src/app/services/guide.service';
import { NewGuideComponent } from 'src/app/components/storage/new-guide/new-guide.component';
import { GuideModel } from 'src/app/models/trip/guide.model';

@Component({
	selector: 'app-storage-guides-page',
	templateUrl: './storage-guides-page.component.html',
	styleUrls: ['./storage-guides-page.component.scss']
})
export class StorageGuidesPageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 430;
	public cols: TableColModel[] = [];
	public guidesList: GuideModel[] = [];

	constructor(private router: Router,
				private dialog: MatDialog,
				private guideService: GuideService) {}

	ngOnInit() {
		this.createColsTableItem();
		this.getGuides();
	}

	private getGuides() {
		this.guideService.getAll().subscribe(resp => {
			this.guidesList = resp;
		});
	}

	public rowClicked(guide: any) {
		// this.router.navigateByUrl(`/app/storage/destination/${destination._id}`);
		this.editClicked(guide)
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.NAME, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.EMAIL, 'email', ColSize.MEDIUM, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GUIDE.PHONE, 'phone', ColSize.MEDIUM, undefined, undefined, undefined));
	}


	public editClicked(guide: GuideModel) {
		const openedDialog = this.dialog.open(NewGuideComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: guide
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getGuides();
			}
		});
	}

	public addClicked() {
		const openedDialog = this.dialog.open(NewGuideComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getGuides();
			}
		});
	}

	public removeClicked(guide: GuideModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGuide,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if ( success ) {
				this.removeGuide(guide._id);
			}
		});
	}

	private removeGuide(guideId: string) {
		this.guideService.remove(guideId).subscribe(success => {
			this.getGuides();
		});
	}

}
