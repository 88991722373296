import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { NoteModel } from '../models/client/note.model';

@Injectable({
	providedIn: 'root'
})
export class NotesService extends HttpHandlerService {
	private url: string = 'notes/';


	public getLeadNotes(leadId,leadStatus?) {
		return this.get(this.url, { leadId,leadStatus });
	}

	public getClientNotes(clientId) {
		return this.get(this.url, { clientId });
	}

	public saveNot(note: NoteModel) {
		return this.post(this.url, note);
	}

	public updateNote(noteId: string, description: string) {
		return this.put(this.url, {noteId, description});
	}

	public deleteNote(noteId: string) {
		return this.delete(this.url, {noteId});
	}
}
