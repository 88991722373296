import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cash-expenses-popup',
  templateUrl: './cash-expenses-popup.component.html',
  styleUrls: ['./cash-expenses-popup.component.scss']
})
export class CashExpensesPopupComponent implements OnInit {

	constructor(
		public fb:FormBuilder,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA)
		public data: any)
		 {}

	public readonly INVALID_FORM = FORM_MESSAGES;
	form:FormGroup

	ngOnInit() {
		this.createForm()
		if(this.data.cashExpense){
			this.form.setValue(this.data.cashExpense.data)
		}
	}

	public get controls() {
	  return this.form.controls;
  }

  private createForm() {
	this.form = this.fb.group({
		title: new FormControl('', Validators.required),
		price: new FormControl(null, [Validators.required, Validators.min(1)]),
	});
	}

	addCashExpense(){
		this.dialogRef.close(this.form.value);
	}

	close(){
		this.dialogRef.close();
	}

}

