import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORM_MESSAGES, NEW_TRIP_FORM_PLACEHOLDERS } from '../../../shared/texts';
import { TripsService } from '../../../services/trips.service';
import { DepartmentService } from '../../../services/department.service';
import { GuideService } from '../../../services/guide.service';
import { DestinationService } from '../../../services/destination.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import { Router } from '@angular/router';
import { DayService } from '../../../services/day.service';
import { CityService } from '../../../services/city.service';
import * as moment from 'moment';
import { TripClassEnum,tripTypes } from 'src/app/models/types/tripClass.enum';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { CustomValidatorsService } from '../../../services/custom-validators.service';
import { LeadsService } from 'src/app/services/leads.service';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { DayModel } from 'src/app/models/trip/day.model';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { UserModel } from 'src/app/models/client/user.model';
import { UserService } from 'src/app/services/user.service';
import { CityModel } from 'src/app/models/trip/city.model';
import { TripQueryModel } from 'src/app/models/request/tripQuery.model';

@Component({
	selector: 'app-trip-new',
	templateUrl: './trip-new.component.html',
	styleUrls: ['./trip-new.component.scss']
})
export class TripNewComponent implements OnInit, OnDestroy {
	@Input() trip: TripModel;
	public form: FormGroup;
	public filterForm: FormGroup;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public readonly FORM_PLACEHOLDERS = NEW_TRIP_FORM_PLACEHOLDERS;
	public readonly tripClassEnum = TripClassEnum;
	public submitted: boolean = false;
	public readonly years = [];
	public tripTypes = tripTypes

	destination = new FormControl('',Validators.required)
	public departmentsList: DepartmentModel[] = [];
	public destinationsList: DestinationModel[] = [];
	public tripsList: TripModel[] = [];
	public guidesList: GuideModel[] = [];
	public allowTripScheduleImport: boolean = true;
	public travelersRangeOptions: string[] = ['5-9', '10-14', '15-19', '20-24', '25-29'];
	private daysToImport: DayModel[];
	private subscriptions: Subscription[] = [];
	public userMinimize: UserModel[] = [];
	public citiesList:CityModel[] = []
	destinationForm:any[] = []

	constructor(private fb: FormBuilder, private customValidatorsService: CustomValidatorsService,
				private leadService: LeadsService,
				private guideService: GuideService,
				private userService: UserService,
				private dataService: DataService,
				private cityService: CityService,
				private tripsService: TripsService, private router: Router, private dayService: DayService,
				private departmentService: DepartmentService, private destinationService: DestinationService) {
	}

	ngOnInit() {
		console.log(this.trip)
		this.getDepartments();
		this.getLeadSources();
		this.getUsersMinimize();
		this.createForm();
		this.createYearsArray()
		this.getTrips();
		if (this.trip._id) { // means we're in edit mode
			this.getDestinations(this.trip.department as string);
			this.allowTripScheduleImport = false;
		}
		if(this.trip.city){
			this.form.patchValue({
				city : this.trip.city[0]
			})
		}
		if(this.trip.completed){
			this.disableActiveTripFields()
        }
		this.getGlobalUser();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private disableActiveTripFields(){
		this.form.disable();
		this.form.controls['tripName'].enable();
		this.form.controls['guide'].enable();
		this.form.controls['startDate'].enable();
	}

	private getDepartments() {
		const subscription = this.departmentService.notAdministrative().subscribe(departmentsList => {
			this.departmentsList = departmentsList;
		});
		this.subscriptions.push(subscription);
	}

	public addDestination() {

	}


	private getGlobalUser() {
		const subscription = this.dataService.globalUser.subscribe(user => {
			if (user && user._id && user.department) {
				this.form.controls.supervisor.setValue(user._id);
				if(!this.form.value.department && user.role['level'] > 1){
					this.form.controls.department.setValue((user.department as DepartmentModel)._id);
					this.getDestinations(this.form.value.department);
				}
			}
		});

		this.subscriptions.push(subscription);
	}

	private createYearsArray() {
		for (let i = 0; i < 7; i++) {
			const date = moment();
			date.add(i, 'years');
			this.years.push(date.year());
		}
	}

	public changedDepartmentFilter(department){
		this.onDepartmentSelect(department)
	}

	private getUsersMinimize() {
		this.userService.getUsersMinimize().subscribe(users => {
			let usersOriginal = users
			this.userMinimize = usersOriginal.filter( (user,i) =>{
				return user['status'] === 1
			})
		});
	}

	public get controls() {
		return this.form.controls;
	}

	public get controlsFilter() {
		return this.filterForm.controls;
	}


	private createForm() {
		const { name, department, destination, guide, tripClass, departureDate, minTravelers, maxTravelers, meal,city } = this.trip;
		const range = `${minTravelers}-${maxTravelers}`;
		this.form = this.fb.group({
			tripName: new FormControl(name, Validators.required),
			department: new FormControl(department, Validators.required),
			destination: new FormControl(destination,Validators.required),
			city: new FormControl(city),
			maxTravelers: new FormControl(maxTravelers),
			startDate: new FormControl(departureDate, Validators.required),
			tripClass: new FormControl(tripClass, Validators.required),
			guide: new FormControl(guide),
			tc_foc: new FormControl(''),
			importSchedule: new FormControl(false),
			importedSchedule: new FormControl(undefined),
			supervisor: new FormControl(undefined),
			mealType: new FormControl(meal, Validators.required),
		});
		this.filterForm = this.fb.group({
			year: new FormControl(''),
			department: new FormControl(''),
			destination: new FormControl(''),
			tripClass: new FormControl(null),
		});

		this.form.get('importSchedule').valueChanges.subscribe(importSchedule => {
			const importedSchedule = this.form.get('importedSchedule');
			if (importSchedule) {
				importedSchedule.setValidators([Validators.required]);
			} else {
				importedSchedule.clearValidators()
				importedSchedule.updateValueAndValidity()
			}

		});

		this.form.get('tripClass').valueChanges.subscribe(tClass => {

			const guideControl = this.form.get('guide');
			const maxTravelersControl = this.form.get('maxTravelers');

			// guides
			if (tClass === TripClassEnum.FIX || tClass === TripClassEnum.GROUP) {
				guideControl.setValidators([Validators.required]);
			} else {
				guideControl.setValidators(null);
			}

			//max travelers
			if (tClass === TripClassEnum.FIX) {
				maxTravelersControl.setValidators(Validators.required);
			} else {
				maxTravelersControl.setValidators(null);
			}

			maxTravelersControl.updateValueAndValidity();
			guideControl.updateValueAndValidity();
		});
		this.getGuides();
		this.createField()
	}


		public createField(){
			if(this.form.value.destination && !this.trip.secondaryDestinations && this.destinationForm.length === 0){
				if(this.trip.secondaryDestinations){
					this.destinationForm = this.trip.secondaryDestinations
				}
				let destination = new DestinationModel()
				this.destinationForm.push(destination)
			}
		}

		checkValues(value,i){
			if(this.destinationForm[i] && !this.destinationForm[i+1]){
				let destination = new DestinationModel()
				this.destinationForm.push(destination)
			}
		}

		removeFromArray(index){
			if(this.destinationForm.length === 1) return;
			if (this.destinationForm.length > 1) this.destinationForm.splice(index,1)
			if(this.destinationForm[this.destinationForm.length-1].name !== ''){
				let destination = new DestinationModel()
				this.destinationForm.push(destination)
			}
		}

	public onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}
		const form = this.form.controls;
		// const minTravelers = this.getMinTravelers(form.travelersRange.value);
		// const maxTravelers = this.getMaxTravelers(form.travelersRange.value);
		let days = [];

		if (form.importSchedule.value && form.importedSchedule.value) {
			days = this.daysToImport;
		}

		const realDateMoment = moment(form.startDate.value);
		const departureDate = moment.utc().year(realDateMoment.year()).month(realDateMoment.month())
		.date(realDateMoment.date()).startOf('day').toISOString();
		const tcFoc = form.tc_foc.value === true ? true : false;
		if(this.destinationForm[this.destinationForm.length-1] && !this.destinationForm[this.destinationForm.length-1].name){
			this.destinationForm.pop()
		}
		const trip: TripModel = {
			department: form.department.value,
			departureDate,
			destination: form.destination.value,
			secondaryDestinations: this.destinationForm,
			name: form.tripName.value,
			city: form.city.value,
			leads:[],
			maxTravelers: form.maxTravelers.value,
			guide: form.guide.value,
			tripClass: form.tripClass.value,
			meal: form.mealType.value,
			days,
			tcFoc,
			supervisor: form.supervisor.value,
		};
		if (trip.tripClass === TripClassEnum.FIT || trip.tripClass === TripClassEnum.INCENTIVE) { // then there's no guide to the trip
			trip.guide = this.trip._id ? null : undefined;
		}
		if (this.trip._id) { // update trip
			trip._id = this.trip._id;
			delete trip.days; // to avoid overriding the days;
			this.updateTrip(trip);
		} else {
			this.createTrip(trip);
		}


	}

	public onTripToImportSelect(tripId: string) {
		this.tripsService.getTripFormById(tripId).subscribe(importedTrip => {
			this.setTripDetailsAfterImport(importedTrip);
			this.daysToImport = importedTrip.days;
		});
	}

	private setTripDetailsAfterImport(importedTrip: TripModel) {
		const form = this.form.controls;
		form.tripName.setValue(importedTrip.name);
		form.department.setValue(importedTrip.department);
		form.destination.setValue(importedTrip.destination);
		form.maxTravelers.setValue(importedTrip.maxTravelers);
		form.startDate.setValue(importedTrip.departureDate);
		form.tripClass.setValue(importedTrip.tripClass);
		form.guide.setValue(importedTrip.guide);
		form.tc_foc.setValue(importedTrip.tcFoc);
		form.mealType.setValue(importedTrip.meal);
		form.supervisor.setValue(importedTrip.supervisor);

		this.onDepartmentSelect(importedTrip.department as string);
	}

	private updateTrip(trip: TripModel) {
		this.tripsService.updateTrip(trip).subscribe(tripUpdated => {
			if(this.trip.completed){
				this.router.navigateByUrl(`app/trips/details/${tripUpdated._id}`);
			} else {
				this.router.navigateByUrl(`app/trips/build/${tripUpdated._id}`);
			}
		});
	}


	private createTrip(trip: TripModel) {
		this.tripsService.createNewTrip(trip).subscribe(newTripCreated => {
			if (newTripCreated.days.length < 1) {
				this.addDay(newTripCreated);
			} else {
				this.router.navigateByUrl(`app/trips/build/${newTripCreated._id}`);
			}
		});
	}

	public getLeadSources() {
		this.leadService.getLeadSources().subscribe(resp => {
		});
	}

	public addDay(trip: TripModel) {
		let date;
		if (trip.days.length > 0) {
			// get the last day date
			date = moment(trip.days[trip.days.length - 1].date).add(1, 'day').toISOString();
		} else {
			date = moment(trip.departureDate).toISOString();
		}
		this.dayService.CreateDay(trip._id, date).subscribe(day => {
			this.router.navigateByUrl(`app/trips/build/${trip._id}`);
		});
	}

	private getMinTravelers(input: string) {
		const range = input.trim().split('-');
		if (range[0] < range[1]) {
			return Number(range[0]);
		}

		return Number(range[1]);
	}

	private getMaxTravelers(input: string) {
		const range = input.trim().split('-');
		if (range[0] > range[1]) {
			return Number(range[0]);
		}

		return Number(range[1]);
	}

	public onDepartmentSelect(departmentId: string) {
		this.getDestinations(departmentId);
		this.getTrips()
	}

	public getTrips() {
		let dates = {
			start:moment(`01/01/${this.filterForm.value.year}`)
		}
		const tripQuery = new TripQueryModel(true,this.filterForm.value.tripClass,this.filterForm.value.destination._id,this.filterForm.value.department._id,undefined,undefined,undefined,dates);
		this.tripsService.getAllTrips(tripQuery).subscribe(tripList => {
			this.tripsList = tripList;
		});
	}

	private getDestinations(departmentId: string) {
		this.departmentService.getDepartmentDestinations(departmentId).subscribe(department => {
			this.destinationsList = department.destinations;
		});
	}

	private getGuides() {
		this.guideService.getAll().subscribe(guides => {
			this.guidesList = guides;
		});
	}

}
