import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'propByString'
})
export class PropByStringPipe implements PipeTransform {

	transform(obj: any, propName: string): any {
		if ( !propName ) {
			return '';
		}
		const props = propName.split('.');
		let prop;
		let i: number;
		let iLen: number;
		for ( i = 0, iLen = props.length - 1; i < iLen; i++ ) {
			prop = props[i];
			const candidate = obj[prop];
			if ( candidate !== undefined ) {
				obj = candidate;
			} else {
				break;
			}
		}
		
		if(!obj || obj.length <= 0 || !props || props.length <= 0 || props.length -1 < i ) return '';
		return obj[props[i]];
	}

}
