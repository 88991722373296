export enum FinanceTabEnum {
	Specifications = 1,
	Payed
}

export enum SupplierPaymentsEnum {
	Vouchers = 1,
	Waiting,
	Resolved
}

export const CurrencyOptions = [
	{key:'$',value:1},
	{key:'€',value:2},
	{key:'₪',value:3},
	{key:'£',value:4}
]

export const VoucherTypes = [
	{key:'טיסות',value:1},
	{key:'הוצאות קרקע',value:2},
]

export const VoucherTypesHedlines = {
	1: 'טיסות',
	2: 'הוצאות קרקע'
};


export const CurrencyOptionsHedlines = {
	1: '$',
	2: '€',
	3: '₪',
	4: '£'
};

export const FinanceTabHeadlines = {
	SPEC: 'פירוט הגביה',
	PAYED: 'תשלומים שבוצעו',
	VOUCHERS:'שוברים',
	RESOLVED:'תשלום הוסדר',
	WAITING:'ממתין לאישור'
};
