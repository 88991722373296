import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORM_MESSAGES } from '../../../shared/texts';
import { ReservationsService } from '../../../services/reservations.service';
import { TripDialogDataModel } from 'src/app/models/trip/tripDialogData.model';
import * as moment from 'moment';
import { HotelMealsEnum } from 'src/app/models/types/hotelMeals.enum';
import { HotelResModel } from 'src/app/models/reservations/hotelRes.model';
import { CityModel } from 'src/app/models/trip/city.model';
import { HotelModel } from 'src/app/models/trip/hotel.model';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-trip-new-hotel',
	templateUrl: './trip-new-hotel.component.html',
	styleUrls: ['./trip-new-hotel.component.scss']
})
export class TripNewHotelComponent implements OnInit {

	public form: FormGroup;
	public INVALID_FORM = FORM_MESSAGES;
	public dateTitle: string = '';
	public submitted: boolean = false;
	public hotelMealsEnum = HotelMealsEnum;
	public actionTitle: string = '';
	public tripDestination: string;

	constructor(private dialogRef: MatDialogRef<TripNewHotelComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private fb: FormBuilder,
				private reservationsService: ReservationsService) { }

	ngOnInit() {
		this.createForm();
		this.setActionTitle();
		this.setDateTitle();
		this.tripDestination = (this.data.day.tripId as TripModel).destination as string;
	}

	private setDateTitle() {
		this.dateTitle = `יום ${this.data.dayNum}, ${moment(this.data.day.date).format('DD.MM.YY')}`;
	}

	private setActionTitle() {
		this.actionTitle = this.data.data ? 'עריכת' : 'הוספת';
	}

	public get controls() {
		return this.form.controls;
	}

	private createForm() {
		const hotelRes: HotelResModel = Object.assign(new HotelResModel(), this.data.data);
		this.form = this.fb.group({
			city:  new FormControl((hotelRes.city as CityModel)._id, Validators.required),
			hotel:  new FormControl((hotelRes.hotel as HotelModel)._id, Validators.required),
			notes: new FormControl(hotelRes.notes),
			roomDescription: new FormControl(hotelRes.roomDescription,Validators.required),
			roomOther: new FormControl(hotelRes.roomOther),
			meal:  new FormControl(hotelRes.meals, Validators.required)
		});
	}

	public close() {
		this.dialogRef.close();
	}

	public onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}
		const hotelRes: HotelResModel = {
			city: this.controls.city.value,
			hotel: this.controls.hotel.value,
			meals: this.controls.meal.value,
			notes: this.controls.notes.value,
			roomDescription: this.controls.roomDescription.value,
			roomOther: this.controls.roomOther.value
		};


		if (this.data.data && this.data.data._id) {
			hotelRes._id = this.data.data._id;
			this.updateHotelRes(hotelRes);
		} else {
			this.createNewHotelRes(hotelRes);
		}
	}

	private createNewHotelRes(hotelRes: HotelResModel) {
		this.reservationsService.createHotelRes(hotelRes, this.data.day._id).subscribe(newHotelRes => {
			this.dialogRef.close(newHotelRes);
		});
	}

	private updateHotelRes(hotelRes: HotelResModel) {
		this.reservationsService.updateHotelRes(hotelRes).subscribe(updatedHotelRes => {
			this.dialogRef.close(updatedHotelRes);
		});
	}

}
