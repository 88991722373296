import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { GuideModel } from '../models/trip/guide.model';

@Injectable({
	providedIn: 'root'
})
export class GuideService extends HttpHandlerService {
	private url: string = 'guides/';

	public getAll() {
		const path = '';
		return this.get(this.url + path, {});
	}

	public getByDestination(destinationId: string) {
		const path = 'byDestination';
		return this.get(this.url + path, {destinationId});
	}

	public create(guide: GuideModel) {
		const path = '';
		return this.post(this.url + path, guide);
	}

	public update(guide: GuideModel) {
		const path = '';
		return this.put(this.url + path, guide);
	}

	public remove(id: string) {
		const path = '';
		return this.delete(this.url + path, {id});
	}
}
