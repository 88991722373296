import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-lead-new-card',
	templateUrl: './lead-new-card.component.html',
	styleUrls: [ './lead-new-card.component.scss' ]
})
export class LeadNewCardComponent implements OnInit {
	@Input() img: string;
	@Input() text: string;
	@Output() action = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	public click() {
		this.action.emit();
	}

}
