import { Component, OnInit } from '@angular/core';
import { TripStatusEnum } from 'src/app/models/trip/tripStatus.enum';
import { TRIP_TYPES } from 'src/app/shared/texts/tripTypes.messages';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { GuideService } from 'src/app/services/guide.service';
import { GuideModel } from 'src/app/models/trip/guide.model';

@Component({
	selector: 'app-trips-page',
	templateUrl: './trips-page.component.html',
	styleUrls: ['./trips-page.component.scss']
})
export class TripsPageComponent implements OnInit {

	public tripStatusEnum = TripStatusEnum;
	public showingPage: TripStatusEnum;
	public tripTypes = TRIP_TYPES;

	public completedFilter: boolean;
	public pageTitle: string;
	guidesList:GuideModel[] = []

	constructor(public guideService:GuideService,public filterService:FilterService,private filterSerivce:FilterService,private dialog: MatDialog, private activatedRoute: ActivatedRoute,
		private router: Router) {
			const page = this.activatedRoute.snapshot.routeConfig.path;
			if (page === 'completed') {
				this.completedFilter = true;
				this.pageTitle = 'טיולים פעילים';
			} else {
				this.completedFilter = false;
				this.pageTitle = 'טיולים בבניה';
			}
		}

	ngOnInit() {
		this.showingPage = this.filterService.tripTab
		this.getGuides()
	}

	public changeShowingPage(toPage: TripStatusEnum) {
		this.filterSerivce.clearFilters()
		this.filterService.tripTab = toPage
		this.showingPage = toPage;
	}


	private getGuides() {
		this.guideService.getAll().subscribe(guides => {
			this.guidesList = guides;
		});
	}


	public rowClicked(tripId: string) {
		if (this.completedFilter) {
			this.router.navigateByUrl(`app/trips/details/${tripId}`);
		} else {
			this.router.navigateByUrl(`app/trips/build/${tripId}`);
		}
	}
}
