import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaginationModel } from '../../models/client/pagination.model';
import { TableColModel } from '../../models/client/tableCols.model';
import { SortableColModel } from '../../models/client/sortableCol';
import { RoleModel } from '../../models/client/role.model';
import { DepartmentModel } from '../../models/client/department.mode';
import { MatDialog } from '@angular/material';
import { SortableOptionsEnum } from '../../models/types/sortableOptions.enum';
import { SortTypeEnum } from '../../models/types/sortType.enum';
import { PaginationResponseModel } from '../../models/response/paginationResponse.model';
import { TABLE_COLS_NAMES } from '../../shared/texts';
import { ColSize } from '../../models/types/colSize.enum';
import { ClientModel } from '../../models/client/client.model';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
	selector: 'app-clients-page',
	templateUrl: './clients-page.component.html',
	styleUrls: ['./clients-page.component.scss']
})
export class ClientsPageComponent implements OnInit {

	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel(15);
	public clients: ClientModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public roles: RoleModel[] = [];
	public departments: DepartmentModel[] = [];
	private subscriptions: Subscription[] = [];
	isLoading: boolean = false;

	constructor(public filterService: FilterService, private dialog: MatDialog, private fb: FormBuilder, private clientService: ClientService, private router: Router) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.DepartureDate, true);
		this.sortableCol.setDescending()
		this.createColsTableItem();
		this.createForm();
		this.getClients();
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		if (this.filterService.currentPage) {
			this.pagination.nextPage = this.filterService.currentPage
		}
	}

	public clearFilters() {
		this.form.reset();
		this.afterFilterChange();
	}

	public removeClicked(user: ClientModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveUser,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				const subscription = this.clientService.deleteClient(user._id).subscribe(success => {
					this.getClients();
				});
				this.subscriptions.push(subscription);
			}
		});
	}

	public editClicked(user: ClientModel) {
		this.router.navigateByUrl(`app/clients/${user._id}#info`);
	}

	public goToPage(nextPage: number) {
		this.pagination.nextPage = nextPage;
		this.getClients();
	}

	public rowClicked(client: ClientModel) {
		this.router.navigateByUrl(`app/clients/${client._id}`);
	}
	public clearClicked() {
		this.controls['searchText'].setValue('');
		this.afterFilterChange();
	}
	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if (col.colSortable.SortType === SortTypeEnum.Descending) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.pagination.resetPagination();
		this.getClients();
	}

	public afterFilterChange() {
		this.pagination.resetPagination();
		this.getClients();
	}

	private getClients() {
		this.isLoading = true
		const { searchText } = this.form.controls
		this.pagination.nextPage = this.filterService.currentPage || 1;
		this.pagination.amountPages = this.filterService.totalPages || 1;
		this.clients = []
		const subscription = this.clientService.getAllClients(this.pagination, this.sortableCol, searchText.value).subscribe((resp: PaginationResponseModel<ClientModel>) => {
			this.isLoading = false
			this.pagination.amountPages = resp.totalPages;
			this.filterService.totalPages = resp.totalPages
			this.clients = resp.items;
		});
		this.subscriptions.push(subscription);
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.CLIENT_NUMBER, 'clientNumber', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.FIRST_NAME, 'personalDetails.firstName', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.LAST_NAME, 'personalDetails.lastName', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.LastName, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.EMAIL, 'personalDetails.email', ColSize.LONG, undefined, undefined, new SortableColModel(SortableOptionsEnum.Email, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.PHONE, 'personalDetails.phoneNumber', ColSize.MEDIUM, 'phone', undefined, new SortableColModel(SortableOptionsEnum.Phone, false)));
	}


	private createForm() {
		this.form = this.fb.group({
			searchText: new FormControl(''),
		});
	}

}
