import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TaskViewEnum } from 'src/app/models/types/taskView.enum';
import { TaskModel } from 'src/app/models/client/task.model';
import { TasksService } from 'src/app/services/tasks.service';
import { Subscription } from 'rxjs';
import { TaskStatusEnum } from 'src/app/models/types/taskStatus.enum';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {

	public taskViewEnum = TaskViewEnum;
	@Input() viewOption: TaskViewEnum;
	@Output() close: EventEmitter<void> = new EventEmitter();
	@Output() newTask: EventEmitter<void> = new EventEmitter();
	private subscriptions: Subscription[] = [];
	public openTasksList: TaskModel[] = [];
	public doneTasksList: TaskModel[] = [];

	constructor(private tasksService: TasksService, private dataService: DataService) { }

	ngOnInit() {
		const subscription = this.dataService.globalTasks.subscribe(tasks => {
			const nextWeek = moment().add(7, 'days');
			this.doneTasksList = tasks.filter((task: TaskModel) => task.status === TaskStatusEnum.Completed);
			this.openTasksList = tasks.filter((task: TaskModel) => (task.status === TaskStatusEnum.Open && moment(task.dueDate).isBefore(nextWeek)));
		});
		this.subscriptions.push(subscription);
		// this.getTasks();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private getTasks() {
		this.dataService.getUserTasks();
	}

	public closeClicked() {
		this.close.emit();
	}

	public newTaskClicked() {
		this.newTask.emit();
	}

	public setTaskAsDone(task: TaskModel) {
		task.status = TaskStatusEnum.Completed;
		const subscription = this.tasksService.updateTask(task).subscribe(resp => {
			this.getTasks();
		});

		this.subscriptions.push(subscription);
	}

	public deleteTask(task: TaskModel) {
		const subscription = this.tasksService.deleteTask(task._id).subscribe(resp => {
			this.getTasks();
		});

		this.subscriptions.push(subscription);
	}
}
