import { PaginationModel } from '../client/pagination.model';
import { SortTypeEnum } from '../types/sortType.enum';
import { SortableColModel } from '../client/sortableCol';
import { TaskStatusEnum } from '../types/taskStatus.enum';
import { SortableOptionsEnum } from '../types/sortableOptions.enum';

export class VoucherQueryModel {
	private status: number;
	private trip: string;
	private pagination: PaginationModel;
	private form:any
	private sort:SortableColModel
	private sortType:number;
	private direction:number = -1


	constructor(status: number, pagination: PaginationModel | undefined,form:any,sort:SortableColModel) {
		this.status = status;
		this.pagination = pagination;
		this.form = form
		this.sortType = sort.value
		this.direction = sort.SortType
	}

	public toQueryObject() {
		const query: any = this.pagination ? this.pagination.toObject() : {};
		query['skip'] = (this.pagination.currPage - 1) * this.pagination.itemsPerPage

		if ( this.status ) {
			query.status = this.status;
		}

		if ( this.form.month ) {
			query.month = this.form.month;
		}

		if ( this.form.department ) {
			query.department = this.form.department;
		}

		if ( this.form.searchText ) {
			query.searchText = this.form.searchText;
		}

		if ( this.form.providerType ) {
			query.providerType = this.form.providerType;
		}
		if ( this.sortType ) {
			query.sortType = this.sortType;
		}
		if ( this.direction ) {
			query.direction = this.direction;
		}
		return query;
	}
}
