import { PaginationModel } from '../client/pagination.model';
import { SortTypeEnum } from '../types/sortType.enum';
import { SortableColModel } from '../client/sortableCol';
import { TripStatusEnum } from '../trip/tripStatus.enum';
import { TripClassEnum } from '../types/tripClass.enum';

export class TripQueryModel {
    private completed:boolean;
	private tripClass: TripClassEnum;
	private destinationId: string;
	private departmentId: string;
	private searchText: string;
	private sortableCol: SortableColModel;
	private status: TripStatusEnum;
	private pagination: PaginationModel;
	private dates:any;
	private guideId:string;

	constructor(completed:boolean,tripClass:TripClassEnum,destinationId?:string,departmentId?:string,searchText?:string,guideId?:string,status?:TripStatusEnum,dates?:any,pagination?: PaginationModel | undefined, sortableCol?: SortableColModel) {
		this.completed = completed
		this.tripClass = tripClass
		this.guideId = guideId
		this.destinationId = destinationId
		this.departmentId = departmentId
		this.searchText = searchText
		this.sortableCol = sortableCol
		this.status = status
		this.pagination = pagination
		this.dates = dates
	}

	public toQueryObject() {
		const query: any = this.pagination ? this.pagination.toObject() : {};
		query.completed = this.completed;

		if ( this.sortableCol ) {
			query.sort = this.sortableCol.value;
			query.descending = this.sortableCol.SortType === SortTypeEnum.Descending;
		}
		if ( this.searchText ) {
			query.searchText = this.searchText;
		}

		if ( this.departmentId ) {
			query.departmentId = this.departmentId;
		}

		if ( this.destinationId ) {
			query.destinationId = this.destinationId;
		}
		if ( this.status ) {
			query.status = this.status;
		}
		if ( this.dates && this.dates.start) {
			query.startDate = this.dates.start;
		}
		if ( this.dates && this.dates.end) {
			query.endDate = this.dates.end
		}
		if ( this.dates && this.dates.leadStart) {
			query.leadStart = this.dates.leadStart;
		}
		if ( this.dates && this.dates.leadEnd) {
			query.leadEnd = this.dates.leadEnd
		}
		if ( this.tripClass) {
			query.tripClass = this.tripClass
		}
		if ( this.guideId) {
			query.guideId = this.guideId
		}
		return query;
	}
}
