import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationModel } from '../../../models/client/destination.model';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { Subscription } from 'rxjs';
import { times, fullDayTimes } from '../../../utils/times';

@Component({
	selector: 'app-time-autocomplete',
	templateUrl: './time-autocomplete.component.html',
	styleUrls: [ './time-autocomplete.component.scss' ]
})
export class TimeAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() title: string;
	@Input() style: string;
	@Input() control: FormControl;
	@Input() placeholder: boolean = true;
	@Input() fullDay: boolean = false;
	@Input() notRequired: boolean = false;
	options: string[] = [];

	@ViewChild(MatAutocompleteTrigger, { static: false }) trigger: MatAutocompleteTrigger;
	public filteredOptions: string[] = [];
	public form: FormGroup;
	private subscription: Subscription;

	constructor(private fb: FormBuilder) {
	}

	get controls() {
		return this.form.controls;
	}

	ngAfterViewInit() {
		// this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if ( this.subscription && !this.subscription.closed ) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.options = this.fullDay ? fullDayTimes : times;
		if(this.notRequired){
			this.form = this.fb.group({
				time: [ this.control.value ]
			});
		} else {
			this.form = this.fb.group({
				time: [ this.control.value, Validators.required ]
			});
		}
		this.filteredOptions = this.options;

		this.form.controls.time.valueChanges.subscribe(inputValue => {
			this.setFilteredUsers(inputValue);
		});
	}

	handler(value): void {
		this.control.setValue(value);
		// this.form.controls.time.setValue(event.option.value);
	}

	public setFilteredUsers(inputRegex) {
		let filteredOptions = this.options;
		if ( inputRegex ) {
			filteredOptions = this.options.filter(option => {
				return option.includes(inputRegex);
			});
		}
		this.filteredOptions = filteredOptions;
	}

	private _subscribeToClosingActions(): void {
		if ( this.subscription && !this.subscription.closed ) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger.panelClosingActions
			.subscribe(e => {
					if ( !e || !e.source ) {
						this.control.setValue(null);
						this.form.controls.time.setValue(null);
					}
				},
				err => this._subscribeToClosingActions(),
				() => this._subscribeToClosingActions());
	}
}
