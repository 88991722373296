import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { ActivatedRoute, Router, NavigationStart, Event } from '@angular/router';
import { LeadModel } from '../../models/client/lead.model';
import { ClientModel } from '../../models/client/client.model';
import { DestinationModel } from '../../models/client/destination.model';
import { NewLeadComponent } from '../../components/lead/components/new-lead/new-lead.component';
import { MatDialog } from '@angular/material';
import { LEAD_ENUM_STRINGS_AND_COLORS, LeadStatusEnum } from '../../models/types/leadStatus.enum';
import { Subscription } from 'rxjs';
import { LeadCancelPopupComponent } from 'src/app/components/lead/popups/lead-cancel-popup/lead-cancel-popup.component';
import { LeadMoveToRegisterComponent } from 'src/app/components/lead/popups/lead-move-to-register/lead-move-to-register.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { LeadMoveToFuturePopupComponent } from 'src/app/components/lead/popups/lead-move-to-future-popup/lead-move-to-future-popup.component';
import { DataService } from 'src/app/services/data.service';
import { LEAD_TYPES_Enum } from '../../shared/texts';
import { FilterService } from 'src/app/services/filter.service';
import { LeadMoveToSalePopupComponent } from 'src/app/components/lead/popups/lead-move-to-sale-popup/lead-move-to-sale-popup.component';

@Component({
	selector: 'app-lead-page',
	templateUrl: './lead-page.component.html',
	styleUrls: ['./lead-page.component.scss']
})
export class LeadPageComponent implements OnInit, OnDestroy {
	leadStatusEnum = LeadStatusEnum;
	leadType = LEAD_TYPES_Enum
	leadEnumAndColors = LEAD_ENUM_STRINGS_AND_COLORS;
	public waitingToLead: boolean = true;
	public lead: LeadModel = new LeadModel();
	public client: ClientModel = new ClientModel();
	public destination: DestinationModel = new DestinationModel();
	public withLeadChanges: boolean = false;
	public withLeadClose: boolean = false;
	public withLeadEdit: boolean = false;
	public leadName: string = '';
	private routerSubscription: Subscription;
	constructor(public filterService: FilterService, public dataService: DataService, private leadService: LeadsService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
	}



	ngOnInit() {
		this.leadUpdated();
		this.routerSubscription = this.route.paramMap.subscribe((params: any) => {
			this.leadUpdated();
		});
	}

	ngOnDestroy() {
		this.routerSubscription.unsubscribe();
	}

	leadUpdated() {
		const id = this.route.snapshot.paramMap.get('id');
		this.getLeadData(id);
	}

	goBack() {
		switch (this.lead.status) {
			case 1:
				this.filterService.currentLeadTab = this.leadStatusEnum.New
				break;

			case 2:
				this.filterService.currentLeadTab = this.leadStatusEnum.Future
				break;

			case 3:
				this.filterService.currentLeadTab = this.leadStatusEnum.Sale
				break;

			case 4:
				this.filterService.currentLeadTab = this.leadStatusEnum.Active
				break;

			case 5:
				this.filterService.currentLeadTab = this.leadStatusEnum.Sold
				break;
		}

		this.router.navigateByUrl('/app/leads')
	}

	private getLeadData(id) {
		this.leadService.getLeadProfile(id).subscribe((lead: LeadModel) => {
			this.waitingToLead = false;
			this.lead = lead;
			this.client = this.lead.client as ClientModel;
			this.destination = this.lead.destination as DestinationModel;
			this.lead.createdAt = new Date(this.lead.createdAt);
			this.leadName = `${(lead.client as ClientModel).personalDetails.firstName} ${(lead.client as ClientModel).personalDetails.lastName}`;
			this.manageLeadBaseActions();
		}, (err) => {
			console.log(err);
			this.router.navigateByUrl('/app/leads');
		});
	}


	private manageLeadBaseActions() {
		const { status } = this.lead;
		this.withLeadChanges = status === LeadStatusEnum.Sale || status === LeadStatusEnum.New;
		this.withLeadEdit = status === LeadStatusEnum.Sale || status === LeadStatusEnum.New;
		this.withLeadClose = status === LeadStatusEnum.Sale;
	}

	public editLeadClicked() {
		const openedDialog = this.dialog.open(NewLeadComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {
				lead: this.lead,
			}
		});

		openedDialog.afterClosed().subscribe((addedId: string) => {
			if (addedId) {
				this.leadUpdated();
			}
		});
	}

	public moveToFutureClicked() {
		this.dialog.open(LeadMoveToFuturePopupComponent, {
			width: '600px',
			height: '492px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.lead
		});
	}

	public closeLeadClicked() {
		this.dialog.open(LeadCancelPopupComponent, {
			width: '600px',
			height: '600px',
			position: { top: '5%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { lead: this.lead }
		});
	}

	public registerClicked() {
		const openedDialog = this.dialog.open(LeadMoveToRegisterComponent, {
			width: '545px',
			height: '422px',
			position: { top: '10%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: false,
			data: this.lead
		});

		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				// show success popup
			}
		});
	}

	public moveToSaleClicked() {
		const saleDialog = this.dialog.open(LeadMoveToSalePopupComponent, {
			width: '500px',
			height: '341px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.lead
		});

		saleDialog.afterClosed().subscribe(success => {
			if (success) {
				this.router.navigateByUrl('app/leads');
			}
		});
	}


	public soldClicked() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.MoveToSold,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.leadService.sold(this.lead._id).subscribe(res => {
					this.dataService.getUserTasks()
					this.router.navigateByUrl(`/app/leads/${this.lead._id}`)
				})
			}
		});
	}


}
