import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trip-notebook-note-item-schedule',
  templateUrl: './trip-notebook-note-item-schedule.component.html',
  styleUrls: ['./trip-notebook-note-item-schedule.component.scss']
})
export class TripNotebookNoteItemScheduleComponent implements OnInit {

  constructor() { }
  @Input() dayIndex
  @Input() note

  @Output() emitRemoveNote = new EventEmitter()
  @Output() emitSaveNote = new EventEmitter()


  isEdit:boolean = false;
  currentNote:string = ''

  ngOnInit() {
  }

  editNote(note,i,z){
	this.isEdit = true;
	this.currentNote = note
  }

  removeNote(){
	this.emitRemoveNote.emit('remove!')
  }

  saveNote(){
    this.emitSaveNote.emit(this.currentNote)
	  this.currentNote = ''
	  this.isEdit = false
  }

}
