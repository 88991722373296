import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PricesCatagoryModel } from 'src/app/models/pricing/pricesCatagoryModel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { GuideExpensesModel } from 'src/app/models/pricing/guideExpenses.model';
import { GuideService } from 'src/app/services/guide.service';

@Component({
  selector: 'app-guide-expenses-popup',
  templateUrl: './guide-expenses-popup.component.html',
  styleUrls: ['./guide-expenses-popup.component.scss']
})
export class GuideExpensesPopupComponent implements OnInit {

	constructor(public guideService:GuideService,
		public fb:FormBuilder,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA)
		public data: any)
		 {}

		 pricesCatagory:PricesCatagoryModel[] = []
		 public readonly INVALID_FORM = FORM_MESSAGES;
		 form:FormGroup
	     guidesList = []
		 title:string;
		 guidesArray:GuideExpensesModel[] = []

	ngOnInit() {
		this.title = this.data.formData.title
		this.guideService.getAll().subscribe( res => this.guidesList = res)
		this.initArray()
		this.checkDataCatagories()
	}

	initArray(){
		if(this.data.formData.rowDetails.length > 0){
			this.guidesArray = this.data.formData.rowDetails
		} else {
			let newGuide = new GuideExpensesModel(this.pricesCatagory)
			this.guidesArray.push(newGuide)
		}
	}

	checkDataCatagories(){
		this.guidesArray.forEach( guideData => {
			for(let i = 0; i<this.pricesCatagory.length;i++){
				if(!guideData.pricesCatagory[i]){
					guideData.pricesCatagory[i] = {numberOfPeople:this.pricesCatagory[i].numberOfPeople, price:null}
				}
			}
		})
	}


	addGuide(){
		let newGuide = new GuideExpensesModel(this.pricesCatagory)
		this.guidesArray.push(newGuide)
	}

	public get controls() {
	  return this.form.controls;
  }

	addGuideExpenses(){
		this.dialogRef.close(this.guidesArray);
	}

	close(){
		this.dialogRef.close();
	}
}
