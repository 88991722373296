import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AIRLINE_FORM_PLACEHOLDERS, FORM_MESSAGES } from 'src/app/shared/texts';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NewProviderComponent } from '../new-provider/new-provider.component';
import { AirlinesService } from 'src/app/services/airlines.service';
import { AirlineModel } from 'src/app/models/trip/airline.model';

@Component({
  selector: 'app-new-airline',
  templateUrl: './new-airline.component.html',
  styleUrls: ['./new-airline.component.scss']
})
export class NewAirlineComponent implements OnInit {

	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = AIRLINE_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;
	public editMode: boolean = true;

	constructor(private fb: FormBuilder,
		private airlinesService: AirlinesService,
		private customValidatorsService: CustomValidatorsService,
		@Inject(MAT_DIALOG_DATA) private airline: any,
		private dialogRef: MatDialogRef<NewProviderComponent>) {
		if (!airline) {
			airline = new AirlineModel();
		}
	}

	ngOnInit() {
		if (!this.airline) {
			this.airline = new AirlineModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		this.createForm();
	}

	private createForm() {
		const { name } = this.airline;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
	})
}


	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const form = this.form.controls;
		const airline = new AirlineModel();
		airline.name = form.name.value;

		if (this.airline._id) {
			airline._id = this.airline._id;
			this.updateAirline(airline);
		} else {
			this.createAirline(airline);
		}
	}


	private updateAirline(airline: any) {
		// const subscription = this.airlinesService.update(airline).subscribe(success => {
		// 	this.close(true);
		// });

		// this.subscriptions.push(subscription);
	}

	private createAirline(airline: any) {
		// const subscription = this.airlinesService.create(airline).subscribe(success => {
		// 	this.close(true);
		// });

		// this.subscriptions.push(subscription);
	}
}
