import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'hebDate'
})
export class HebDatePipe implements PipeTransform {

	transform(date: Date, args?: any): any {
		const formattedDate = moment(date).format('DD.MM.YY');
		const dayNum = moment(date).day();
		let day: string;
		switch (dayNum) {
			case 0: day = 'א׳'; break;
			case 1: day = 'ב׳'; break;
			case 2: day = 'ג׳'; break;
			case 3: day = 'ד׳'; break;
			case 4: day = 'ה׳'; break;
			case 5: day = 'ו׳'; break;
			case 6: day = 'ש׳'; break;
			default: day = 'N/A';
		}

		const strToReturn = `יום ${day}, ${formattedDate}`;
		return strToReturn;
	}

}
