import { ClientInfoModel } from './../../../models/client/client.model';
import { ClientService } from './../../../services/client.service';
import { CustomValidatorsService } from './../../../services/custom-validators.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CLIENT_TITLES } from './../../../models/client/clientTitles';
import { FIELDS_PLACEHOLDERS, FIELDS_OPTIONS } from './../../../shared/texts/client-registration-info.messages';
import { FORM_MESSAGES } from './../../../shared/texts/form.messages';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-relative-passport-details',
  templateUrl: './add-relative-passport-details.component.html',
  styleUrls: ['./add-relative-passport-details.component.scss']
})
export class AddRelativePassportDetailsComponent implements OnInit {
  @Input() relativePersonalDetails: ClientInfoModel;
  @Output() validDetailsInserted = new EventEmitter<ClientInfoModel>();
  @Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public today = new Date();
  public formPassport: FormGroup;
  public INVALID_FORM = FORM_MESSAGES;
  public FIELDS_PLACEHOLDERS = FIELDS_PLACEHOLDERS;
  public CLIENT_TITLES = CLIENT_TITLES;
  public FIELDS_OPTIONS = FIELDS_OPTIONS;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

    this.createForm();
  }

  private createForm() {
   this.formPassport = this.fb.group({
	  title: new FormControl(this.relativePersonalDetails.title, Validators.required),
	  passportFirstName: new FormControl(this.relativePersonalDetails.passportFirstName),
      passportLastName: new FormControl(this.relativePersonalDetails.passportLastName),
      passportNumber: new FormControl(this.relativePersonalDetails.passportNumber),
      passportReleaseDate: new FormControl(this.relativePersonalDetails.passportReleaseDate),
      passportExpiryDate: new FormControl(this.relativePersonalDetails.passportExpiryDate),
    });
    this.validated.emit(true);

    Object.keys(this.formPassport.controls).forEach(key => {

      this.formPassport.controls[key].valueChanges.subscribe(value => this.relativePersonalDetails[key] = value);
    });

    this.formPassport.statusChanges.subscribe(status => {
      if(status === 'VALID'){

        this.validDetailsInserted.emit(this.relativePersonalDetails);
        this.validated.emit(true);
      }
    });
  }

  get passportControls() {
    return this.formPassport.controls;
  }

}
