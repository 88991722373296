import { Component, OnInit } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AirlinesService } from 'src/app/services/airlines.service';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { NewGuideComponent } from 'src/app/components/storage/new-guide/new-guide.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { NewProviderComponent } from 'src/app/components/storage/new-provider/new-provider.component';
import { ProvidersService } from 'src/app/services/providers.service';

@Component({
  selector: 'app-storage-providers-page',
  templateUrl: './storage-providers-page.component.html',
  styleUrls: ['./storage-providers-page.component.scss']
})
export class StorageProvidersPageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 430;
	public cols: TableColModel[] = [];
	public providersList: any[] = [];

	constructor(private router: Router,
				private dialog: MatDialog,
				private providersService: ProvidersService) {}

	ngOnInit() {
		this.createColsTableItem();
		this.getProviders();
	}

	private getProviders() {
		this.providersService.getAllProviders().subscribe(resp => {
			this.providersList = resp;
		});
	}

	public rowClicked(guide: any) {
		this.editClicked(guide)
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.PROVIDERS.NAME ,'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.PROVIDERS.TYPE ,'type', ColSize.LONG, undefined, undefined, undefined));
	}


	public editClicked(airline:any) {
		const openedDialog = this.dialog.open(NewProviderComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: airline
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getProviders();
			}
		});
	}

	public addClicked() {
		const openedDialog = this.dialog.open(NewProviderComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getProviders();
			}
		});
	}

	public removeClicked(provider:any) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveProvider,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if ( success ) {
				this.removeProvider(provider._id);
			}
		});
	}

	private removeProvider(providerId: string) {
		this.providersService.remove(providerId).subscribe(success => {
			this.getProviders();
		});
	}

}
