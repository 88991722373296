import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { FIELDS_PLACEHOLDERS } from 'src/app/shared/texts/client-registration-info.messages';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import S3FileUpload from 'src/app/utils/fileUpload/S3FileUpload';
import { IFileUpload } from 'src/app/utils/fileUpload/IFileUpload';
import { AppDocument } from 'src/app/models/client/appDocument';
import { Subscription } from 'rxjs';
import * as moment from 'moment'
import { CurrencyOptions,VoucherTypes } from '../../../models/trip/finance.enum'
import { ProvidersService } from 'src/app/services/providers.service';

@Component({
	selector: 'app-add-voucher-pop',
	templateUrl: './add-voucher-pop.component.html',
	styleUrls: ['./add-voucher-pop.component.scss']
})
export class AddVoucherPopComponent implements OnInit {

	constructor(
		public providerService:ProvidersService,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) private data: any, public fb: FormBuilder
	) {
		this.amazonFileUpload = new S3FileUpload('eu-west-1', '7eab6e63-7f95-4b67-9546-f0e39c6931af', 'geo-dev');
		this.createForm()
		this.getAllProviders()
	}

	public INVALID_FORM = FORM_MESSAGES;
	form: FormGroup
	isLoading: boolean = false;
	amazonFileUpload: IFileUpload;
	subscriptions: Subscription[]
	dateOfFile: string;
	public CurrencyOptions = CurrencyOptions
	public VoucherTypes = VoucherTypes
	providers:any;
	voucher:any;

	ngOnInit() {
		this.voucher = this.data.voucher
		if(this.voucher){
			this.form.patchValue({
				type: this.voucher.type,
				provider: this.voucher.provider[0]._id,
				price: this.voucher.price,
				currency: this.voucher.currency,
				dateOfPayment: this.voucher.dateOfPayment,
				voucherLink: this.voucher.voucherLink,
				fileName: this.voucher.fileName
			})
			this.dateOfFile = moment(this.voucher['createdAt']).format('YYYY.MM.DD')
			console.log(this.form.value)
		}
	}

	public getAllProviders(){
		this.providerService.getAllProviders().subscribe( providers => {
			this.providers = providers
		})
	}

	get controls() {
		return this.form.controls;
	}

	createForm() {
		this.form = this.fb.group({
			type: ['', Validators.required],
			provider: ['', Validators.required],
			price: ['', Validators.required],
			currency: ['', Validators.required],
			dateOfPayment: ['', Validators.required],
			voucherLink: ['', Validators.required],
			fileName: ['', Validators.required]
		})
	}

	afterDateSelected(data) {
		console.log(data)
	}

	public fileInputOnChange(event) {
		this.isLoading = true
		if (event.target.files.length > 0) {
			this.uploadFile(event.target.files[0]);
		}
	}

	private async uploadFile(file: File) {
		await this.amazonFileUpload.UploadFileAsync(file, (res) => {
			if (!res.success) {
				this.isLoading = false;
				return console.error('%c an error has occurred trying to upload the image', 'background-color: salmon;');
			}
			let fileName = file.name;
			const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
			fileName = fileName.replace('.' + fileType, '');
			this.isLoading = false;
			this.form.patchValue({ voucherLink: res.url, fileName })
			this.dateOfFile = moment().format('YYYY.MM.DD')
		});
	}

	selectionChange(data){
		console.log('changed')
	}

	closeDialog(data?){
		if(this.voucher){
			data['_id'] = this.voucher._id
		}
		this.dialogRef.close(data)
	}


}
