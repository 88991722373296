export enum TripStatusEnum {
	Fix = 1,
	ClosedGroups,
	Fit,
	Incentive
}

export enum SingleTripStatusEnum {
	Travelers = 1,
	Schedule,
	Pricing,
	Manage,
	Documents,
	Balance
}

export const SINGLE_TRIP_ENUM_STRINGS = {
	TRAVELERS: 'מטיילים רשומים',
	SCHEDULE: 'לו״ז טיול',
	PRICING: 'מכירות',
	DOCUMENTS: 'מסמכים',
	MANAGE: 'ניהול הוצאות',
	BALANCE: 'באלאנס',
};

export enum TripActiveStatusEnum {
	Deleted = 0, // נמחק מהמערכת
    Active, // = 1 מטייל פעיל
    Closed, // = 2 ליד סגור
}


export const TRIP_ACTIVE_ENUM_STRINGS_AND_COLORS = {
	1: { name: 'פעיל', color: 'rgba(181, 231, 225, 0.8)' },
	2: { name: 'סגור', color: 'rgba(255, 116, 93, 0.8)' }
};

