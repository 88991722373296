const PAGE_AMOUNT = 13;

export class PaginationModel {
	public readonly itemsPerPage: number;
	private currentPage: number = 1;
	private _totalNumberOfPages: number = 1;

		constructor(itemsPerPage?: number) {
		this.itemsPerPage = itemsPerPage ? itemsPerPage : PAGE_AMOUNT;
	}

	get amountPages(): number {
		return this._totalNumberOfPages;
	}

	set amountPages(totalPages: number) {
		this._totalNumberOfPages = totalPages;
	}

	get currPage() {
		return this.currentPage;
	}

	set nextPage(nextPage: number) {
		this.currentPage = nextPage;
	}

	public goToPage(page: number) {
		this.currentPage = page;
	}

	public resetPagination() {
		this.currentPage = 1;
	}

	public checkAndUpdateCurrentPage() {
		if (this.currentPage < this._totalNumberOfPages) {
			++this.currentPage;
			return true;
		}
		return false;
	}

	public toObject() {
		const {currentPage, itemsPerPage} = this;
		return {page: currentPage - 1, itemsPerPage};
	}
}

