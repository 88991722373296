import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VouchersService } from 'src/app/services/vouchers.service';

@Component({
  selector: 'app-providers-vouchers-waiting-tab',
  templateUrl: './providers-vouchers-waiting-tab.component.html',
  styleUrls: ['./providers-vouchers-waiting-tab.component.scss']
})
export class ProvidersVouchersWaitingTabComponent implements OnInit {

	constructor(public vouchersService:VouchersService){

	}
	@Input() data
	@Output() getVouchers = new EventEmitter()
	vouchers:any;
	count:number;

	ngOnInit() {
		this.getVouchers.emit('now')
	}

	nextPageOnClick(pageNumber){
	  console.log(pageNumber)
	}

	updateVouchers(vouchers,status){
		vouchers.forEach(voucher => {
			voucher.status = status
			voucher.provider = voucher.provider[0]._id
		});
		this.vouchersService.updateMany(vouchers).subscribe( res => {
			this.getVouchers.emit('now')
		})
	  }

	  managerApproval(vouchers){
		this.vouchersService.managerApprove(vouchers).subscribe( res => {
			this.getVouchers.emit('now')
		})
	  }


}
