import { AddRelativeDialogComponent } from './../../components/client/add-relative-dialog/add-relative-dialog.component';
import { Component, OnInit } from '@angular/core';
import { ClientModel } from '../../models/client/client.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ClientService } from '../../services/client.service';
import { ClientOptionEnum } from '../../models/types/clientOption.enum';
import { CLIENT_OPTIONS } from '../../shared/texts/clientTypes.messages';
import { RemoveClientMemberDialogComponent } from 'src/app/components/client/remove-client-member-dialog/remove-client-member-dialog.component';

@Component({
	selector: 'app-client-page',
	templateUrl: './client-page.component.html',
	styleUrls: ['./client-page.component.scss']
})
export class ClientPageComponent implements OnInit {
	public clientOptionEnum = ClientOptionEnum;
	public showingComponent: ClientOptionEnum = ClientOptionEnum.Notes;
	public clientOptionsTexts = CLIENT_OPTIONS;
	public waitingToClient: boolean = true;
	public client: ClientModel = new ClientModel();
	public clientName: string = '';

	constructor(private clientService: ClientService,
		private router: Router,
		private route: ActivatedRoute,
		private dialog: MatDialog) {

		if (this.route.snapshot.fragment && this.route.snapshot.fragment === 'info') {
			this.showingComponent = ClientOptionEnum.RegistrationInfo;
		}
		if (this.route.snapshot.fragment && this.route.snapshot.fragment === 'cancelled') {
			this.showingComponent = ClientOptionEnum.NotAnswer;
		}
	}

	ngOnInit() {
		this.clientUpdate();
	}

	clientUpdate() {
		const id = this.route.snapshot.paramMap.get('id');
		this.getClient(id);
	}

	public changeComponent(showComponent: ClientOptionEnum) {
		this.showingComponent = showComponent;
	}


	private getClient(id) {
		this.clientService.getProfile(id).subscribe((client: ClientModel) => {
			this.waitingToClient = false;
			this.client = client;
			this.clientName = `${client.personalDetails.firstName} ${client.personalDetails.lastName}`;
		}, (err) => {
			console.log(err);
			this.router.navigateByUrl('/app/clients');
		});
	}

	openAddRelativesDialog(): void {
		console.log('relativees!!');
		const openedDialog = this.dialog.open(AddRelativeDialogComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { clientId: this.client }
		});
		openedDialog.afterClosed().subscribe(() => this.clientUpdate());

	}

	openRemoveRelativeDialog(relative: any): void {
		const dialog = this.dialog.open(RemoveClientMemberDialogComponent, {
			width: '600px',
			height: '320px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: relative
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				this.clientService.deleteClientMember(relative._id).subscribe(res => {
					console.log('removed')
					this.clientUpdate();
				})
			}
		})
	}
}
