import { Component, OnInit } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { NewGuideComponent } from 'src/app/components/storage/new-guide/new-guide.component';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { AirlinesService } from 'src/app/services/airlines.service';
import { NewAirlineComponent } from 'src/app/components/storage/new-airline/new-airline.component';

@Component({
	selector: 'app-storage-airlines-page',
	templateUrl: './storage-airlines-page.component.html',
	styleUrls: ['./storage-airlines-page.component.scss']
})
export class StorageAirlinesPageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 430;
	public cols: TableColModel[] = [];
	public airlinesList: any[] = [];

	constructor(private router: Router,
				private dialog: MatDialog,
				private airlineService: AirlinesService) {}

	ngOnInit() {
		this.createColsTableItem();
		this.getAirlines();
	}

	private getAirlines() {
		// this.airlineService.getAll().subscribe(resp => {
		// 	this.airlinesList = resp;
		// });
	}

	public rowClicked(guide: any) {
		this.editClicked(guide)
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.FLIGHTS.AIR_COMPANY, 'name', ColSize.LONG, undefined, undefined, undefined));
	}


	public editClicked(airline:any) {
		const openedDialog = this.dialog.open(NewAirlineComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: airline
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getAirlines();
			}
		});
	}

	public addClicked() {
		const openedDialog = this.dialog.open(NewAirlineComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.getAirlines();
			}
		});
	}

	public removeClicked(airline:any) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGuide,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if ( success ) {
				this.removeGuide(airline._id);
			}
		});
	}

	private removeGuide(airlineId: string) {
		// this.airlineService.remove(airlineId).subscribe(success => {
		// 	this.getGuides();
		// });
	}

}

