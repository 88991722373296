import { Component, OnInit, Input } from '@angular/core';
import { NoteModel } from 'src/app/models/client/note.model';
import * as moment from 'moment';
import { NotesService } from 'src/app/services/notes.service';
import { FormControl, Validators } from '@angular/forms';
import { LeadModel } from 'src/app/models/client/lead.model';

@Component({
  selector: 'app-trip-notes',
  templateUrl: './trip-notes.component.html',
  styleUrls: ['./trip-notes.component.scss']
})
export class TripNotesComponent implements OnInit {

	@Input() lead: LeadModel;
	public notes: NoteModel[] = [];
	public textInput: FormControl = new FormControl('', Validators.required);

	constructor(private noteService: NotesService) {
	}

	ngOnInit() {
		this.getNotes();
	}

	public addNote() {
		const nowMoment = moment();
		const dueDate = moment.utc().year(nowMoment.year()).month(nowMoment.month())
			.date(nowMoment.date()).startOf('day').hour(Number(nowMoment.hour())).minutes(nowMoment.minutes()).toISOString(true);
		const newNote: NoteModel = {
			description: this.textInput.value,
			lead: this.lead._id,
			date: dueDate
		} as NoteModel;

		this.noteService.saveNot(newNote).subscribe(() => {
			this.textInput.reset('');
			this.getNotes();
		});

		this.notes.push(newNote)
	}

	public getNotes() {
		this.noteService.getLeadNotes(this.lead._id,this.lead.status).subscribe(notes => {
			this.notes = notes;
		});
	}

	public updateNotes() {
		this.getNotes();
	}

}
