import { Injectable } from '@angular/core';
import { LeadStatusEnum } from './../models/types/leadStatus.enum';
import { TripStatusEnum } from '../models/trip/tripStatus.enum';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  currentDepartment:any;
  currentDestination:any;
  currentLeadTab:LeadStatusEnum = LeadStatusEnum.New;
  currentSupervisor:any;
  currentPage:number;
  currentGuide:any;
  searchText:string;
  currentFinanceDate:any;
  tripTab:TripStatusEnum = TripStatusEnum.Fix
  currentDates = {
	  start:undefined,
	  end:undefined,
	  leadStart:undefined,
	  leadEnd:undefined
  }
  tripType:any;
  totalPages:number

  clearFilters(){
	  this.currentDepartment = ''
	  this.currentDestination = ''
	  this.currentGuide = ''
	  this.currentSupervisor = ''
	  this.tripType = ''
	  this.searchText = ''
	  this.currentDates = {
		  start:undefined,
		  end:undefined,
		  leadStart:undefined,
		  leadEnd:undefined,
	  }
	  this.totalPages = null
	  this.currentPage = null
	  this.currentLeadTab = LeadStatusEnum.New
	  this.tripTab = TripStatusEnum.Fix
  }

}
