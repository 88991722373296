import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { ReservationsService } from '../../../services/reservations.service';
import { CityModel } from 'src/app/models/trip/city.model';
import { TripDialogDataModel } from 'src/app/models/trip/tripDialogData.model';
import * as moment from 'moment';
import { TransportationTypeEnum, TRANSPORTATION_TYPE } from 'src/app/models/types/transportationType.enum';
import { TransportResModel } from 'src/app/models/reservations/transportRes.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { CityAutocompleteComponent } from '../../autocompletes/city-autocomplete/city-autocomplete.component';
import { TransportService } from 'src/app/services/transport.service';
import { TransportModel } from 'src/app/models/trip/transport.model';
import { TransportTypeModel } from 'src/app/models/trip/transportRes.model';
import { TransferTypeModel } from 'src/app/models/trip/transferType.model';
import { from } from 'rxjs';

@Component({
	selector: 'app-trip-new-transport',
	templateUrl: './trip-new-transport.component.html',
	styleUrls: ['./trip-new-transport.component.scss']
})
export class TripNewTransportComponent implements OnInit {

	@ViewChild(CityAutocompleteComponent, { static: false }) citySelect: CityAutocompleteComponent;
	@ViewChild(FormGroupDirective, { static: false }) myForm;
	public form: FormGroup;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public dateTitle: string = '';
	public readonly transportationTypeEnum = TransportationTypeEnum;
	public readonly TRANSPORTATION_TYPE = TRANSPORTATION_TYPE;
	public actionTitle: string = '';
	public tripDestination: string;
	public showCustomFields: boolean;
	public transportTypes: []
	public transferTypes: any[];// = ['Car', 'Bus', 'Train', 'Bullet Train', 'Boat', 'Taxi' ];

	constructor(private dialogRef: MatDialogRef<TripNewTransportComponent>,
		@Inject(MAT_DIALOG_DATA) private data: TripDialogDataModel,
		private fb: FormBuilder,
		private reservationsService: ReservationsService,
		public transportService: TransportService) { }

	ngOnInit() {
		this.showCustomFields = this.data.data ? this.data.data.transfer_description === null : false;
		this.createForm();
		this.setActionTitle();
		this.setDateTitle();
		this.tripDestination = (this.data.day.tripId as TripModel).destination as string;
		this.getTransportTypesArray();
		this.getTransfers();
	}

	public get controls() {
		return this.form ? this.form.controls : {};
	}

	public getTransportTypesArray() {
		this.transportService.getTransportTypes().subscribe(res => {
			this.transportTypes = res
		})
	}

	private createForm() {
		const transport: TransportResModel = Object.assign(new TransportResModel(), this.data.data);
		this.form = this.fb.group({
			notes: new FormControl(transport.notes),
			fromCity: new FormControl((transport.fromCity as CityModel)._id || ''),
			toCity: new FormControl((transport.toCity as CityModel)._id || ''),
			pickUpStation: new FormControl(transport.pickUpStation),
			startTime: new FormControl(transport.startTime),
			dropOffStation: new FormControl(transport.dropOffStation),
			dropOffTime: new FormControl(transport.dropOffTime),
			isPublic: new FormControl(transport.isPublic),
		});
		if(transport.transfer_description) {
			this.form.addControl('transfer_description', new FormControl((transport.transfer_description as TransportTypeModel)._id, Validators.required))
		}
		if(transport.transfer_type) {
			this.form.addControl('transfer_type', new FormControl((transport.transfer_type as TransferTypeModel)._id, Validators.required))
		}
		// if (this.data && this.data.data && (this.form.value['dropOffTime'] || this.data.data['toCity'] || this.form.value['startTime'])) {
		// 	this.showCustomFields = true;
		// }
	}

	public getTransfers() {
		//TODO get from server
		//this.transferTypes = [{_id: 1, name: 'Car'}, {_id: 2, name: 'Bus'}, {_id: 3, name: 'Train'}, {_id: 4, name: 'Bullet Train'}, {_id: 5, name: 'Boat'}, {_id: 6, name: 'Taxi'}];
		this.transportService.getCustomTransportTypes().subscribe(res => this.transferTypes = res);
	}

	private setDateTitle() {
		this.dateTitle = `יום ${this.data.dayNum}, ${moment(this.data.day.date).format('DD.MM.YY')}`;
	}

	private setActionTitle() {
		this.actionTitle = this.data.data ? 'עריכת' : 'הוספת';
	}

	public close() {
		this.dialogRef.close();
	}

	public onSubmit() {
		if (this.form.invalid) {
			return;
		}
		const form = this.form.controls;
		let transportRes: any = {
			transfer_description: form.transfer_description ? form.transfer_description.value : null,
			pickUpStation: form.pickUpStation.value,
			dropOffStation: form.dropOffStation.value,
			startTime: form.startTime.value,
			dropOffTime: form.dropOffTime.value,
			notes: form.notes.value,
			isPublic: form.isPublic.value,
			transfer_type: form.transfer_type ? form.transfer_type.value : null
		};
		if (this.form.value.fromCity != '') {
			transportRes['fromCity'] = form.fromCity.value
		}
		if (this.form.value.toCity != '') {
			transportRes['toCity'] = form.toCity.value
		}
		if (this.data.data && this.data.data._id) {
			transportRes._id = this.data.data._id;
			this.updateTranportRes(transportRes);
		} else {
			this.createNewTranportRes(transportRes);
		}


	}

	changeCheckbox() {
		// this.citySelect.buildForm();
		if (this.showCustomFields) {
			this.form.get('transfer_description').setValidators([]);
			this.form.get('transfer_type').setValidators([Validators.required]);
			this.myForm.resetForm();
		} else {
			this.form.get('transfer_description').setValidators([Validators.required]);
			this.form.get('transfer_type').setValidators([]);
			this.myForm.resetForm();
		}
	}

	getTransferTypes() {
		// getTransferbyCity
	}

	cityChanged(value) {
	}

	public createNewTranportRes(newTransportRes: TransportResModel) {
		this.reservationsService.createTransportRes(newTransportRes, this.data.day._id).subscribe(transportCreated => {
			this.dialogRef.close(transportCreated);
		});
	}


	public updateTranportRes(transportRes: TransportResModel) {
		this.reservationsService.updateTransportRes(transportRes).subscribe(transportCreated => {
			this.dialogRef.close(transportCreated);
		});
	}

}
