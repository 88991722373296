import { Component, OnInit, Inject } from '@angular/core';
import { FinanceTabEnum,FinanceTabHeadlines, CurrencyOptions, CurrencyOptionsHedlines } from 'src/app/models/trip/finance.enum';
import { FinanceService } from 'src/app/services/finance.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TripModel } from 'src/app/models/trip/trip.model';
import { CurrencyEnum } from 'src/app/models/pricing/currency.Enum';

@Component({
  selector: 'app-finance-pop',
  templateUrl: './finance-pop.component.html',
  styleUrls: ['./finance-pop.component.scss']
})
export class FinancePopComponent implements OnInit {

	public FinanceTabEnum = FinanceTabEnum;
	public FINANCE_ENUM_STRINGS = FinanceTabHeadlines;
	public showingPage: FinanceTabEnum = FinanceTabEnum.Specifications;


  constructor(public financeService:FinanceService,
	@Inject(MAT_DIALOG_DATA) private data: any) { }
  travelerData:any;
  trip:TripModel;
  travelerFinanceData:any;
  tripCurrency:CurrencyEnum;
  public CurrencyOptionsHedlines = CurrencyOptionsHedlines


  prices = [
	  {title:'סה״כ לתשלום',price:0},
	  {title:'סה״כ שולם',price:0},
	  {title:'יתרה לתשלום',price:0,priceIls:0},
  ]

  ngOnInit() {
	  this.travelerData = this.data.traveler
	  this.trip = this.data.trip
	  this.getTravelerFile()
  }

  calculateTotals(){
	 this.prices[0].price = this.travelerFinanceData.totalToPay
	 this.prices[1].price = this.travelerFinanceData.totalPayed
	 this.prices[2].price = this.travelerFinanceData.totalToPay - this.travelerFinanceData.totalPayed
	 this.prices[2].priceIls = (this.prices[2].price) * this.travelerFinanceData.currentToIls
  }

  getTravelerFile(){
	  this.financeService.getTraverlerFinanceFile(this.travelerData._id,this.trip._id).subscribe( res =>{
		  this.travelerFinanceData = res
		  this.tripCurrency = res.trip.pricing.generalDetails.currency
		  this.calculateTotals()
	  })
  }

  updateFinanceData(data){
	  this.financeService.updateFinanceData(data).subscribe( res => {
		this.getTravelerFile()
	  })
  }

  updateCheckBox(data){
	this.financeService.updateFinanceNeedPaymentData(data,this.travelerFinanceData._id).subscribe( res => {
	  this.getTravelerFile()
	})
}

  updatePaymentData(data){
  	this.financeService.updateFinancePayment(data,this.tripCurrency,this.travelerFinanceData._id).subscribe( res => {
	this.getTravelerFile()
  })
}


  changeTab(tab){
	  this.showingPage = tab
  }

}
