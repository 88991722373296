import { ClientInfoModel } from "./../../../models/client/client.model";
import { DataService } from "./../../../services/data.service";
import { Subscription } from "rxjs";
import { UserModel } from "./../../../models/client/user.model";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { FORM_MESSAGES } from "./../../../shared/texts/form.messages";
import { FIELDS_PLACEHOLDERS } from "./../../../shared/texts/client-registration-info.messages";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CustomValidatorsService } from "src/app/services/custom-validators.service";

@Component({
	selector: "app-add-relative-personal-details",
	templateUrl: "./add-relative-personal-details.component.html",
	styleUrls: ["./add-relative-personal-details.component.scss"]
})
export class AddRelativePersonalDetailsComponent implements OnInit {
	@Input() relativePersonalDetails: ClientInfoModel;
	@Output() validDetailsInserted = new EventEmitter<ClientInfoModel>();
	@Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Input() set submitCLicked(val: boolean) {
		if (val && this.form && !this.form.valid) {
			Object.keys(this.form.controls).forEach(key => {
				const control = this.form.get(key);
				control.markAsTouched();
			});
		}
	}

	public today = new Date();
	public PLACEHOLDERS = FIELDS_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public form: FormGroup;
	public loggedInUser: UserModel;
	public userSubscription: Subscription;
	public relativeOptions: string[];
	public genderOptions: string[];
	constructor(
		private dataService: DataService,
		private fb: FormBuilder,
		private customValidatorsService: CustomValidatorsService
	) {}

	ngOnInit() {
		this.relativeOptions = ["אמא", "אבא", "אחות", "אח"];
		this.genderOptions = ["זכר", "נקבה"];
		this.createForm();
		this.getGlobalUser();
	}

	public firstAndLast(): string {
		return this.PLACEHOLDERS.FIRST_NAME.concat(
			" ו",
			this.PLACEHOLDERS.LAST_NAME
		);
	}

	private createForm() {
		// TODO: check the controls, update where necessary
		this.form = this.fb.group({
			firstName: [
				this.relativePersonalDetails.firstName,
				Validators.required
			],
			lastName: [
				this.relativePersonalDetails.lastName,
				Validators.required
			],
			gender: [
				this.relativePersonalDetails.isMale
					? this.genderOptions[0]
					: this.genderOptions[1],
				Validators.required
			],
			dateOfBirth: [
				this.relativePersonalDetails.dateOfBirth,
				Validators.required
			],
			idNumber: [
				this.relativePersonalDetails.idNumber,
				Validators.compose([
					Validators.required,
					this.customValidatorsService.validateIsraelId
				])
			],
			phoneNumber: [
				this.relativePersonalDetails.phoneNumber,
				Validators.compose([
					Validators.required,
					this.customValidatorsService.validateCellPhoneNumber,
					Validators.minLength(9)
				])
			],
			email: [
				this.relativePersonalDetails.email,
				[Validators.required, Validators.email]
			]
		});

		Object.keys(this.form.controls).forEach(key => {
			if (key == "gender") {
				let isMale: boolean;
				this.form.controls[key].valueChanges.subscribe(value => {
					value === "זכר" ? (isMale = true) : (isMale = false);
					this.relativePersonalDetails["isMale"] = isMale;
				});
			} else {
				this.form.controls[key].valueChanges.subscribe(value => {
					console.log(this.form);
					this.relativePersonalDetails[key] = value;
				});
			}
		});

		this.form.statusChanges.subscribe(status => {
			if (status === "VALID") {
				this.validDetailsInserted.emit(this.relativePersonalDetails);
				this.validated.emit(true);
			}
		});
	}

	private getGlobalUser() {
		this.userSubscription = this.dataService.globalUser.subscribe(user => {
			if (user) {
				this.loggedInUser = user;
			}
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
	}

	public get controls() {
		return this.form.controls;
	}
}
