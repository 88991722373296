import {Injectable} from '@angular/core';
import {HttpHandlerService} from './http-handler.service';

@Injectable({
	providedIn: 'root'
})
export class DepartmentService extends HttpHandlerService {
	private url: string = 'departments/';


	public all() {
		return this.get(this.url, {});
	}

	public notAdministrative() {
		const path = 'notAdministrative';
		return this.get(this.url + path, {});
	}

	public getDepartmentDestinations(departmentId: string) {
		const path = 'getCountries';
		return this.get(this.url + path, {departmentId});
	}
}
