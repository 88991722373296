import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { ActivityModel } from '../models/trip/activity.model';

@Injectable({
	providedIn: 'root'
})
export class ActivityService extends HttpHandlerService {
	private url: string = 'activities/';

	public getByCity(cityId: string,fullDay?:boolean) {
		const path = 'byCity';
		return this.get(this.url + path, { cityId,fullDay });
	}



	public updateActivity(activity: ActivityModel) {
		const path = '';
		return this.put(this.url + path, activity);
	}


	public createActivity(activity: ActivityModel) {
		const path = '';
		return this.post(this.url + path, activity);
	}

	public deleteActivity(id: string) {
		const path = '';
		return this.delete(this.url + path, { id });
	}

}
