import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class NotebookService extends HttpHandlerService {
	private url: string = 'trips/';

	public updateNotebookData(tripPdfId: string,fieldToUpdate:string,dataToUpdate:any) {
		const path = 'pdf';
		return this.put(this.url + path, { tripPdfId,fieldToUpdate,dataToUpdate });
	}
}
