import { Component, OnInit } from '@angular/core';
import { TableColModel } from '../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../shared/texts/tableCols.messages';
import { UserModel } from '../../models/client/user.model';
import { PaginationModel } from '../../models/client/pagination.model';
import { ColSize } from '../../models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { UserComponent } from '../../components/user/user.component';
import { RoleModel } from '../../models/client/role.model';
import { DepartmentModel } from '../../models/client/department.mode';
import { UserService } from '../../services/user.service';
import { RoleService } from '../../services/role.service';
import { DepartmentService } from '../../services/department.service';
import { PaginationResponseModel } from '../../models/response/paginationResponse.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SortableColModel } from '../../models/client/sortableCol';
import { SortableOptionsEnum } from '../../models/types/sortableOptions.enum';
import { SortTypeEnum } from '../../models/types/sortType.enum';
import { DataService } from '../../services/data.service';
import { PermissionTypesEnum } from '../../models/types/permissionTypesEnum.enum';
import { ConfirmationPopupTypes } from '../../models/types/confirmationPopup.enum';
import { ConfirmPopupComponent } from '../../components/confirm-popup/confirm-popup.component';
import { ForgetPasswordComponent } from '../../components/forget-password/forget-password.component';
import { SuccessMessageComponent } from '../../components/success-message/success-message.component';
import { SharedActionsService } from '../../services/shared-actions.service';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';

@Component({
	selector: 'app-users-page',
	templateUrl: './users-page.component.html',
	styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel();
	public users: UserModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public roles: RoleModel[] = [];
	public departments: DepartmentModel[] = [];
	public rolesToShow: RoleModel[] = [];
	public withActions: boolean = true;

	constructor(public filterService: FilterService, private dialog: MatDialog, private fb: FormBuilder, private userService: UserService, private roleService: RoleService, public router: Router,
		private departmentService: DepartmentService, private dataService: DataService, private sharedActionService: SharedActionsService) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.DepartureDate, true);
		this.sortableCol.setDescending()
		this.createColsTableItem();
		this.createForm();
		this.getUsers();
		this.getTableFilters();
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.filterService.clearFilters()
		this.dataService.globalUser.subscribe(user => {
			if (user._id) {
				const hasPermission = this.dataService.Permissions.some(per => per.enumValue === PermissionTypesEnum.USER_REMOVAL && per.level <= (user.role as RoleModel).level);
				if (user && user.role['level'] > 1) this.router.navigateByUrl('app/clients')
			}
		});
	}

	public clearClicked() {
		this.controls['searchText'].setValue('');
		this.afterFilterChange();
	}
	public clearFilters() {
		this.form.reset();
		this.afterFilterChange();
	}

	public removeClicked(user: UserModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveUser,
		});

		openedDialog.afterClosed().subscribe((addedUser: boolean) => {
			if (addedUser) {
				this.userService.removeUser(user._id).subscribe(resp => {
					this.getUsers();
					this.sharedActionService.showActionConfirm('המחיקה הסתיימה בהצלחה');
				});
			}
		});
	}

	public editClicked(user: UserModel) {
		const openedDialog = this.dialog.open(UserComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {
				roles: this.roles,
				departments: this.departments,
				user
			}
		});

		openedDialog.afterClosed().subscribe((addedUser: boolean) => {
			if (addedUser) {
				this.getUsers();
				this.sharedActionService.showActionConfirm('העדכון הסתיים בהצלחה');
			}
		});
	}


	public goToPage(nextPage: number) {
		this.pagination.nextPage = nextPage;
		this.getUsers();
	}

	public newUserClicked() {
		const openedDialog = this.dialog.open(UserComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {
				roles: this.roles,
				departments: this.departments
			}
		});

		openedDialog.afterClosed().subscribe((addedUser: boolean) => {
			if (addedUser) {
				this.getUsers();
			}
		});
	}

	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if (col.colSortable.SortType === SortTypeEnum.Descending) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.pagination.resetPagination();
		this.getUsers();
	}

	public departmentOnChange() {
		const selectedDepId = this.form.controls.department.value;
		if (selectedDepId) {
			// Change the roles to show by department selected
			const foundedDepartment = this.departments.find(currDepartment => currDepartment._id === selectedDepId);
			this.rolesToShow = this.roles.filter(role => foundedDepartment.roles.some(roleId => role._id === roleId));

			// Check if selected role is part of the department, if not then reset the value of the role
			if (this.form.controls.role.value) {
				const foundedSelectedRole = this.rolesToShow.some(role => role._id === this.form.controls.role.value);
				if (!foundedSelectedRole) {
					this.form.controls.role.setValue('');
				}
			}

		} else {
			this.rolesToShow = this.roles;
		}

		this.afterFilterChange();
	}

	public afterFilterChange() {
		this.pagination.resetPagination();
		this.getUsers();
	}

	private getUsers() {
		const { role, department, searchText } = this.form.controls;
		this.userService.getAllUsers(this.pagination, this.sortableCol, searchText.value, role.value, department.value).subscribe((resp: PaginationResponseModel<UserModel>) => {
			this.pagination.amountPages = resp.totalPages;
			this.users = resp.items;
		});
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.FIRST_NAME, 'firstName', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.LAST_NAME, 'lastName', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.LastName, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.EMAIL, 'email', ColSize.LONG, undefined, undefined, new SortableColModel(SortableOptionsEnum.Email, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.DEPARTMENT, 'department.name', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.Department, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.USERS.POSITION, 'role.name', ColSize.MEDIUM, undefined, undefined, new SortableColModel(SortableOptionsEnum.Role, false)));
	}

	private getTableFilters() {
		this.roleService.all().subscribe((roles: RoleModel[]) => {
			this.roles = roles;
			this.rolesToShow = this.roles;
		});

		this.departmentService.all().subscribe((departments: DepartmentModel[]) => {
			this.departments = departments;
		});
	}

	private createForm() {
		this.form = this.fb.group({
			role: new FormControl(''),
			department: new FormControl(''),
			searchText: new FormControl(''),
		});
	}


}
