import { Injectable } from '@angular/core';
import {HttpHandlerService} from './http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceService extends HttpHandlerService {

	private url: string = 'finance/';

	public getTraverlerFinanceFile(personalDetailsId:string,tripId:string) {
		return this.get(this.url, {personalDetailsId,tripId});
	}

	public updateFinanceData(financeData) {
		return this.put(this.url, financeData);
	}

	public updateFinanceNeedPaymentData(financeData,financeId:string) {
		return this.put(this.url+ `checkbox?financeId=${financeId}`,financeData);
	}

	public updateFinancePayment(financeData,tripCurrency:number,financeId:string) {
		return this.put(this.url + `payment?currency=${tripCurrency}&financeId=${financeId}`,financeData);
	}

	public getTripIncomes(tripId:string) {
		return this.get(this.url + 'incomes',{tripId});
	}

}

