import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { Subscription } from 'rxjs';
import { ClientModel } from '../../../models/client/client.model';
import { ClientService } from '../../../services/client.service';
import { CustomValidatorsService } from '../../../services/custom-validators.service';

@Component({
	selector: 'app-email-autocomplete',
	templateUrl: './email-autocomplete.component.html',
	styleUrls: ['./email-autocomplete.component.scss']
})
export class EmailAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {


	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() control: FormControl;
	@Input() level:number
	@Output() clientSelectedAction = new EventEmitter();
	@Output() clientDeSelectedAction = new EventEmitter();

	@ViewChild(MatAutocompleteTrigger,{static:true}) trigger: MatAutocompleteTrigger;

	public filteredOptions: ClientModel[] = [];
	public form: FormGroup;
	private subscription: Subscription;

	constructor(private fb: FormBuilder,
				private customValidatorsService: CustomValidatorsService,
				private clientService: ClientService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		// this.form = this.fb.group({
		// 	email: new FormControl('', {
		// 		validators: Validators.required,
		// 		asyncValidators: this.customValidatorsService.asyncValidateEmail()
		// 	}),
		// });

		this.form = this.fb.group({
			email: this.control,
		});

		this.filteredOptions = [];

		this.form.controls.email.valueChanges.subscribe(inputValue => {
			this.setFilteredUsers(inputValue);
		});
	}

	handler(event: MatAutocompleteSelectedEvent): void {
		this.control.setValue(event.option.value.personalDetails.email);
		this.form.controls.email.setValue(event.option.value.personalDetails.email);
		this.clientSelectedAction.emit(event.option.value);
	}

	public setFilteredUsers(inputRegex) {
		this.clientService.getClientByEmail(inputRegex).subscribe((clients: ClientModel[]) => {
			this.filteredOptions = clients;
		});
	}

	private _subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger.panelClosingActions
			.subscribe(e => {
				if (!e || !e.source) {
					this.control.setValue(this.form.controls.email.value);
					this.clientDeSelectedAction.emit();
				}
			},
				err => this._subscribeToClosingActions(),
				() => this._subscribeToClosingActions());
	}
}
