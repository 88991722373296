import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-remove-client-member-dialog',
  templateUrl: './remove-client-member-dialog.component.html',
  styleUrls: ['./remove-client-member-dialog.component.scss']
})
export class RemoveClientMemberDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RemoveClientMemberDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  selectedRelative:any

  ngOnInit() {
	  this.selectedRelative = this.data
  }

	public closeDialog(state?) {
		this.dialogRef.close(state);
	}





}
