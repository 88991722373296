import { UserModel } from './user.model';
import { ClientModel } from './client.model';
import { LeadStatusEnum } from '../types/leadStatus.enum';

export class NoteModel {
	_id?: string;
	description: string;
	lead: string;
	client: string;
	user: UserModel;
	date: string;
	leadStatus: LeadStatusEnum;
}
