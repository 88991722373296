import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadModel } from '../../../../models/client/lead.model';
import { ClientModel } from '../../../../models/client/client.model';

@Component({
	selector: 'app-lead-move-to-sale-success',
	templateUrl: './lead-move-to-sale-success.component.html',
	styleUrls: [ './lead-move-to-sale-success.component.scss' ]
})
export class LeadMoveToSaleSuccessComponent implements OnInit {
	@Input() lead: LeadModel;
	@Output() close = new EventEmitter();
	public client: ClientModel;

	constructor() {
	}

	ngOnInit() {
		this.client = this.lead.client as ClientModel;
	}

	public closeClicked() {
		this.close.emit();
	}
}
