import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TripsService } from '../../services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-trip-new-page',
	templateUrl: './trip-new-page.component.html',
	styleUrls: ['./trip-new-page.component.scss']
})
export class TripNewPageComponent implements OnInit {

	public trip: TripModel = new TripModel();
	public showForm: boolean = false;

	constructor(private activatedRoute: ActivatedRoute, private tripsService: TripsService) { }

	ngOnInit() {
		const tripId = this.activatedRoute.snapshot.paramMap.get('id');
		if (tripId) {
			this.tripsService.getTripFormById(tripId).subscribe(trip => {
				this.trip = trip;
				this.showForm = true;
			});
		} else {
			this.showForm = true;
		}
	}

}
