import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IFileUpload } from 'src/app/utils/fileUpload/IFileUpload';
import S3FileUpload from 'src/app/utils/fileUpload/S3FileUpload';
import { MatDialog } from '@angular/material';
import { FileNewComponent } from '../../file-new/file-new.component';
import { AppDocument } from '../../../models/client/appDocument';
import * as moment from 'moment';
import { ClientModel } from '../../../models/client/client.model';
import { ClientService } from '../../../services/client.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-client-files',
	templateUrl: './client-files.component.html',
	styleUrls: [ './client-files.component.scss' ]
})
export class ClientFilesComponent implements OnInit, OnDestroy {
	amazonFileUpload: IFileUpload;
	@Input() client: ClientModel;
	private subscriptions: Subscription[] = [];
	public isLoading:boolean = false;

	constructor(private dialog: MatDialog, private clientService: ClientService) {
		this.amazonFileUpload = new S3FileUpload('eu-west-1', '7eab6e63-7f95-4b67-9546-f0e39c6931af', 'geo-dev');

	}

	ngOnInit() {
		this.getClientDocuments();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public fileInputOnChange(event) {
		this.isLoading = true
		if ( event.target.files.length > 0 ) {
			this.uploadFile(event.target.files[0]);
		}
	}

	private getClientDocuments() {
		const subscription = this.clientService.getDocuments(this.client._id).subscribe((documents: AppDocument[]) => {
			this.client.documents = documents;
		});
		this.subscriptions.push(subscription);
	}

	private async uploadFile(file: File) {
		await this.amazonFileUpload.UploadFileAsync(file, (res) => {
			if ( !res.success ) {
				this.isLoading = false;
				return console.error('%c an error has occurred trying to upload the image', 'background-color: salmon;');
			}
			let fileName = file.name;
			const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
			fileName = fileName.replace('.' + fileType, '');
			const document = new AppDocument();
			document.url = res.url;
			document.type = fileType;
			document.name = fileName;
			document.createdAt = moment().toISOString();
			this.isLoading = false;
			const dialogRef = this.dialog.open(FileNewComponent, {
				width: '500px',
				height: '450px',
				panelClass: 'custom-dialog-container',
				backdropClass: 'custom-backdropClass',
				position: { top: '170px' },
				disableClose: true,
				data: { document, clientId: this.client._id }
			});

			const subscription = dialogRef.afterClosed().subscribe((addedDoc: boolean) => {
				if ( addedDoc ) {
					this.getClientDocuments();
				}
			});
			this.subscriptions.push(subscription);
		});

	}

	public deleteFile(file: AppDocument) {
		const subscription = this.clientService.deleteDocument(this.client._id, file._id).subscribe(success => {
			this.getClientDocuments();
		});
		this.subscriptions.push(subscription);
	}
}
