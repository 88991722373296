import { Injectable } from '@angular/core';
import { TripModel } from '../models/trip/trip.model';
import { HttpHandlerService } from './http-handler.service';
import { PaginationModel } from '../models/client/pagination.model';
import { TripClassEnum } from '../models/types/tripClass.enum';
import { SortableColModel } from '../models/client/sortableCol';
import { TripQueryModel } from '../models/request/tripQuery.model';

@Injectable({
	providedIn: 'root'
})
export class TripsService extends HttpHandlerService {
	private url: string = 'trips/';

	public createNewTrip(newTrip: TripModel) {
		const path = '';
		return this.post(this.url + path, newTrip);
	}

	public getAllTrips(tripQuery: TripQueryModel) {
		return this.get(this.url, tripQuery.toQueryObject());
	}

	public getClosedTrips(status) {
		return this.get(this.url, { status });
	}

	public getTripByID(tripId: string) {
		const path = 'byId';
		return this.get(this.url + path, { tripId });
	}

	public getTripFormById(tripId: string) {
		const path = 'formById';
		return this.get(this.url + path, { tripId });
	}

	public getDocuments(tripId: string) {
		const path = 'documents';
		return this.get(this.url + path, { tripId });
	}

	public deleteDocument(tripId: string, fileId: string) {
		const path = 'documents';
		return this.delete(this.url + path, { tripId, fileId });
	}

	public updateTrip(trip: TripModel) {
		const path = '';
		return this.put(this.url + path, trip);
	}

	public setAsCompleted(tripId: string) {
		const path = 'setComplete';
		return this.put(this.url + path, { tripId });
	}

	public setAsClosed(tripId: string) {
		const path = 'setClose';
		return this.put(this.url + path, { tripId });
	}

	public getTripByDestination(destinationId: string) {
		const path = 'byDestination';
		return this.get(this.url + path, { destinationId });
	}

	public deleteTrip(id: string) {
		const path = '';
		return this.delete(this.url + path, { id });
	}

	public getTripHotels(tripId: string) {
		const path = 'hotels';
		return this.get(this.url + path, { tripId });
	}

	public registerTraveler(tripId: string, lead) {
		const path = 'addLead';
		return this.put(this.url + path, { tripId, lead });

	}


	public getLeadTrip(leadId: string) {
		const path = 'lead';
		return this.get(this.url + path, { leadId });
	}

	public removeClientMemberFromTrip(memberId, tripId) {
		const path = 'removeMember';
		return this.put(this.url + path, { memberId, tripId });
	}

	public getTripIitinerary(tripId: string, completed: boolean) {
		const path = 'itinerary';
		return this.getBlob(this.url + path, { tripId, completed })
	}

	public getPDFData(tripId: string) {
		const path = 'pdf';
		return this.get(this.url + path, { tripId });
	}


	public getUpdatedDayDescription(pdfId: string) {
		const path = 'pdf/day';
		return this.get(this.url + path, { pdfId });
	}

	public getPDFForm(pdfId: any) {
		const path = 'pdf/file';
		return this.getPdf(this.url + path, { pdfId });
	}

}
