import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VoucherModel } from 'src/app/models/trip/voucher.model';
import * as moment from 'moment'
import { CurrencyOptions, CurrencyOptionsHedlines, VoucherTypesHedlines } from 'src/app/models/trip/finance.enum';
import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/models/client/user.model';

@Component({
  selector: 'app-provider-table-row',
  templateUrl: './provider-table-row.component.html',
  styleUrls: ['./provider-table-row.component.scss']
})
export class ProviderTableRowComponent implements OnInit {

  constructor(public userService:UserService) { }

  @Input() withActionButton:boolean = true;
  @Input() withCheckBox:boolean = true;
  @Input() withPayDate:boolean = true;
  @Input() withJonny:boolean = false;
  @Input() voucher:any;
  @Input() resolved:boolean = false;
  @Input() currencyObj
  @Output() askForApproval:any = new EventEmitter()
  @Output() afterApproval:any = new EventEmitter()
  @Output() managerApproval:any = new EventEmitter()
  public moment = moment
  public CurrencyOptions = CurrencyOptions
  public CurrencyOptionsHedlines = CurrencyOptionsHedlines
  public VoucherTypesHedlines = VoucherTypesHedlines
  checkBoxArray = []
  paymentActive:boolean = false;
  isOpen:boolean;
  checkedItems:any[]
  jonnyApproval:boolean;
  adminPermission:boolean = false;
  user:UserModel

  ngOnInit() {
	  console.log(this.voucher)
	  this.checkBoxArray = new Array(this.voucher.items.length)
	  this.checkJonnyApproval()
	  this.getConnectedUser()
  }

  getConnectedUser(){
	this.userService.getCurrentUserProfile().subscribe( res => {
		this.user = res.user
		if(this.user._id.toString() === "5d24aade7537454da0a8769a"){
			this.adminPermission = true;
		}
	})
}

  checkJonnyApproval(){
	  let approvedArray = this.voucher.items.filter( item =>{
		  return item.approved === true;
	  })
	  if(approvedArray.length === this.voucher.items.length){
		  this.jonnyApproval = true;
	  }
  }

  checkBoxChange(){
	let trueArray = []
	this.checkBoxArray.filter( (item,index) =>{
		if(item){
			trueArray.push(this.voucher.items[index])
		}
	})
	if(trueArray.length > 0){
		this.paymentActive = true
	} else {
		this.paymentActive = false;
	}
	this.checkedItems = trueArray;
  }

  managerVoucherApprove(event){
	  event.stopPropagation()
		  this.voucher.items.map( voucher => {
			if(this.jonnyApproval){
				 voucher.approved = false;
			} else {
				 voucher.approved = true;
			}
		})
	  this.managerApproval.emit(this.voucher.items)
  }

  askForApprovalVouchers(){
	this.askForApproval.emit(this.checkedItems)
  }

  payAfterApprove(){
	this.afterApproval.emit(this.voucher.items)
  }

  calculateTotal(){
	let total = 0
		this.voucher.items.forEach(item => {
			total += this.calculateToDollars(item.price,item.currency)
		});
		this.voucher['total'] = total;
	}

	calculateToDollars(price,currency){
		switch(currency){
			case 1:
			return price * 1
			break;

			case 2:
			return price * this.currencyObj.EUR
			break;

			case 3:
			return price * this.currencyObj.ILS
			break;

			case 4:
			return price * this.currencyObj.GBP
			break;
		}
	}

}

