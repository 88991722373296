import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
	form: FormGroup;
	date: any;

	@Output() selectAction = new EventEmitter();
	@Input() type:number;
	@Input() control:FormControl;
	@Input() title:string
	@Input() selectStyle:boolean

	constructor(public filterService: FilterService, fb: FormBuilder) {
	 }


  ngOnInit() {
	  if(this.filterService.currentDates.start && this.type === 1){
		  this.control.setValue(this.filterService.currentDates.start)
	  }
	  if(this.filterService.currentDates.end && this.type === 2) {
		this.control.setValue(this.filterService.currentDates.end)
	  }
	  if(this.filterService.currentDates.leadStart && this.type === 3){
		this.control.setValue(this.filterService.currentDates.leadStart)
	}
	if(this.filterService.currentDates.leadEnd && this.type === 4) {
	  this.control.setValue(this.filterService.currentDates.leadEnd)
	}
  }

  dateChange(){
	  switch(this.type){
		  case 1:
		  this.filterService.currentDates.start = this.control.value
		  break;

		  case 2:
		  this.filterService.currentDates.end = this.control.value
		  break;

		  case 3:
		  this.filterService.currentDates.leadStart = this.control.value
		  break;

		  case 4:
		  this.filterService.currentDates.leadEnd = this.control.value
		  break;
	  }
	this.selectAction.emit('dateChanged')
  }

}
