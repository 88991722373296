import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { times } from '../../../../utils/times';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../../shared/texts';
import { LeadModel } from '../../../../models/client/lead.model';
import * as moment from 'moment';
import { LeadsService } from '../../../../services/leads.service';

@Component({
	selector: 'app-lead-no-answer',
	templateUrl: './lead-no-answer.component.html',
	styleUrls: [ './lead-no-answer.component.scss' ]
})
export class LeadNoAnswerComponent implements OnInit {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public readonly times = times;
	public minDate = moment().add(-1, 'days');
	@Input() lead: LeadModel;
	@Output() saveCompleted = new EventEmitter();
	@Output() close = new EventEmitter();
	public form: FormGroup;
	public waitingResponse: boolean = false;

	constructor(private fb: FormBuilder, private leadService: LeadsService) {
		this.createForm();
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
	}

	public closeClicked() {
		this.close.emit();
	}

	public save() {
		this.waitingResponse = true;
		const { date, time } = this.form.controls;
		const timeArray = time.value.split(':');
		const realDateMoment = moment(date.value);
		const dueDate = moment.utc().year(realDateMoment.year()).month(realDateMoment.month())
			.date(realDateMoment.date()).startOf('day').hour(Number(timeArray[0])).minutes(timeArray[1]).toISOString(true);
		this.leadService.noAnswer(this.lead._id, dueDate).subscribe(() => {
			this.saveCompleted.emit(dueDate);
			this.waitingResponse = false;
		}, err => {
			this.waitingResponse = false;
		});
	}

	private createForm() {
		this.form = this.fb.group({
			date: new FormControl('', Validators.required),
			time: new FormControl('', Validators.required)
		});
	}
}
