import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from '../../services/hotel.service';
import { ActivityService } from '../../services/activity.service';
import { TableColModel } from '../../models/client/tableCols.model';
import { BreadcrumbModel } from 'src/app/models/client/breadcrumb.model';
import { MatDialog } from '@angular/material';
import { NewHotelComponent } from 'src/app/components/storage/new-hotel/new-hotel.component';
import { NewActivityComponent } from 'src/app/components/storage/new-activity/new-activity.component';
import { ActivityModel } from 'src/app/models/trip/activity.model';
import { HotelModel } from 'src/app/models/trip/hotel.model';

enum TableEnum {
	Hotels = 1,
	Activities,
}

@Component({
	selector: 'app-storage-city-table-page',
	templateUrl: './storage-city-table-page.component.html',
	styleUrls: [ './storage-city-table-page.component.scss' ]
})
export class StorageCityTablePageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 311;
	public cols: TableColModel[] = [];
	public hotelsList: HotelModel[] = [];
	public activitiesList: ActivityModel[] = [];
	public continent: BreadcrumbModel = new BreadcrumbModel();
	public destination: BreadcrumbModel = new BreadcrumbModel();
	public city: BreadcrumbModel = new BreadcrumbModel();
	public tableEnum = TableEnum;
	public showingPage: TableEnum = TableEnum.Hotels;
	public cityId: string = '';

	constructor(private activatedRoute: ActivatedRoute,
				private dialog: MatDialog,
				private activityService: ActivityService,
				private router: Router,
				private hotelService: HotelService) {
	}

	ngOnInit() {
		this.cityId = this.activatedRoute.snapshot.paramMap.get('id');
		this.getHotels();
		this.getActivities();
	}

	public editClicked() {
		alert();
	}

	public rowClicked(row: any) {
	}

	public changeShowingPage(toPage: TableEnum) {
		this.showingPage = toPage;
	}

	public addHotelClicked() {
		const openedDialog = this.dialog.open(NewHotelComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { hotel: undefined, city: this.city._id, destination: this.destination._id }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.updateTable();
			}
		});
	}

	public addActivityClicked() {
		const openedDialog = this.dialog.open(NewActivityComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { activity: undefined, city: this.city._id, destination: this.destination._id }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if ( success ) {
				this.updateTable();
			}
		});
	}

	public updateTable() {
		switch ( this.showingPage ) {
			case TableEnum.Hotels:
				this.getHotels();
				break;
			case TableEnum.Activities:
				this.getActivities();
				break;
			default:
				break;
		}
	}

	private getHotels() {
		this.hotelService.getHotelsByCity(this.cityId).subscribe(resp => {
			this.hotelsList = resp.hotels;
			this.continent = resp.department;
			this.destination = resp.destination;
			this.city = resp.city;
		}, err => {
			console.log('error', err);
			// this.router.navigateByUrl('/app/storage');
		});
	}

	private getActivities() {
		this.activityService.getByCity(this.cityId).subscribe(resp => {
			this.activitiesList = resp;
		}, err => {
			console.log('error', err);
			// this.router.navigateByUrl('/app/storage');
		});
	}

}
