import { ClientInfoModel } from './../../../../models/client/client.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LeadModel } from '../../../../models/client/lead.model';
import { Router } from '@angular/router';
import { DataService } from '../../../../services/data.service'
import { TripModel } from 'src/app/models/trip/trip.model';

@Component({
	selector: 'app-lead-cancel-popup',
	templateUrl: './lead-cancel-popup.component.html',
	styleUrls: [ './lead-cancel-popup.component.scss' ]
})
export class LeadCancelPopupComponent implements OnInit {
	public isSuccess: boolean = false;
	public lead: LeadModel;
	public client: boolean;
	public personalDetails: ClientInfoModel;
	public trip:TripModel;
	constructor(private dialogRef: MatDialogRef<LeadCancelPopupComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private router: Router,private dataService:DataService) {
		this.lead = data.lead;
		this.client = data.client;
		this.personalDetails = data.personalDetails;
		this.trip = data.trip;
	}

	ngOnInit(): void {
	}

	close(success?: boolean) {
		this.dialogRef.close();

		if ( success ) {
			for ( const subscription of this.dataService.leadSubscription) {
				subscription.unsubscribe();
			}
			if(this.trip){
				this.router.navigateByUrl(`app/trips/details/${this.trip._id}`);
			} else {
				this.router.navigateByUrl('app/leads');
			}
		}
	}

	afterSave() {
		this.dialogRef.updateSize('600px', '250px');
		this.isSuccess = true;
	}
}
