import { DestinationModel } from './destination.model';
import { ClientModel } from './client.model';
import { DepartmentModel } from './department.mode';
import { UserModel } from './user.model';
import { LeadStatusEnum } from '../types/leadStatus.enum';
import { TripTypeModel } from './tripType.model';
import { LeadSourceModel } from './leadSource.model';
import { NotesAndRequestsModel } from '../trip/notesAndRequests.model';

export class LeadModel {
	_id: string;
	webpage: string;
	campaigm: string;
	tripId?: string;
	tripType: TripTypeModel | string = '';
	leadSource: LeadSourceModel | string = '';
	destination: DestinationModel | string = '';
	departureDate: Date | string;
	client: ClientModel | string = new ClientModel();
	lead_preferences: NotesAndRequestsModel
	department: DepartmentModel | string = '';
	supervisor: UserModel | string = '';
	status: LeadStatusEnum;
	createdAt: Date = new Date();
	travelers: number;
	note: string = ''
	clientTempInfo: ClientModel | undefined;
}
