import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class AuthService {
	constructor() {
	}

	logout() {
		localStorage.removeItem('token');
	}

	getToken() {
		return localStorage.getItem('token');
	}

	setToken(token: string) {
		localStorage.setItem('token', token);
	}
}
