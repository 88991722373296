import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-pricing-import-warning-popup',
	templateUrl: './pricing-import-warning-popup.component.html',
	styleUrls: ['./pricing-import-warning-popup.component.scss']
})
export class PricingImportWarningPopupComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<any>,
	) { }

	ngOnInit() {
	}

	closeWarning(answer) {
		this.dialogRef.close(answer);
	}

}
