import { map } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DayService } from '../../../services/day.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import * as moment from 'moment';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { Subscription } from 'rxjs';
import { ConfirmationPopupTypes } from '../../../models/types/confirmationPopup.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { transition, trigger, style, animate } from '@angular/animations';

@Component({
	selector: 'app-trip-schedule',
	templateUrl: './trip-schedule.component.html',
	styleUrls: ['./trip-schedule.component.scss'],
	animations: [trigger(
		'fadeInAndOut', 
		[
		  transition(
			':enter', 
			[
			  style({ opacity: 0 }),
			  animate('0.3s ease-out', 
					  style({ opacity: 1 }))
			]
		  ),
		  transition(
			':leave', 
			[
			  style({ opacity: 1 }),
			  animate('0.3s ease-in', 
					  style({ opacity: 0 }))
			]
		  )
		]
	  )
	]
})
export class TripScheduleComponent implements OnInit, OnDestroy {

	@Input() active
	@Input() trip: TripModel = new TripModel();
	@Input() edit: boolean = false;
	@Output() update: EventEmitter<void> = new EventEmitter();
	public hoveredOn: boolean[];
	public activeDay: number = 0;
	private subscriptions: Subscription[] = [];
	onMouseHover(value: boolean, index: number) {
		this.hoveredOn[index] = value;
	}

	onAddDayClick(index: number) {
		//console.log('new add clicked');
		this.dayService.CreateDayInMiddle(this.trip._id, index).subscribe(day => {

			this.update.emit()
			this.activeDay = this.trip.days.length - 1;
		});

	}
	getDate(index: number) {
		let newDay = moment(this.trip.departureDate);
		newDay = newDay.add(index, 'days');
		let formattedNewDay = newDay.format('DD.MM.YY');
		return newDay.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
	}
	constructor(private dayService: DayService, private dialog: MatDialog) { }

	ngOnInit() {
		this.hoveredOn = Array(this.trip.days.length).fill(false);
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.trip.days, event.previousIndex, event.currentIndex);
	}



	public setActiveDay(day: number) {
		this.activeDay = day;
	}

	public addDay() {
		const tripStartdate = moment.utc(this.trip.departureDate);
		const numOfDays = this.trip.days.length;
		const dayDate = tripStartdate.add(numOfDays, 'days').utc().toISOString();
		this.dayService.CreateDay(this.trip._id, dayDate).subscribe(day => {
			this.trip.days.push(day);
			this.activeDay = this.trip.days.length - 1;
		});
	}

	public deleteDayClicked(dayId: string, i: number) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveDay,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.deleteDay(dayId);
				if (i > 0) {
					this.activeDay = i - 1
				}
			}
		});
	}

	public deleteDay(dayId: string) {
		const subscription = this.dayService.deleteDay(dayId).subscribe(success => {
			this.update.emit();

		});

		this.subscriptions.push(subscription);
	}

}
