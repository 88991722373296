import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TaskModel } from 'src/app/models/client/task.model';
import { FORM_PLACEHOLDERS } from '../../shared/texts';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
	selector: 'app-task-new-duedate-popup',
	templateUrl: './task-new-duedate-popup.component.html',
	styleUrls: ['./task-new-duedate-popup.component.scss']
})
export class TaskNewDuedatePopupComponent implements OnInit {

	@Output() confirm = new EventEmitter();
	public readonly FORM_PLACEHOLDERS = FORM_PLACEHOLDERS;
	public dueDate: Date;
	public form: FormGroup;

	constructor(private dialogRef: MatDialogRef<TaskNewDuedatePopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TaskModel,
		private fb: FormBuilder,
		private taskService: TasksService) { }

	ngOnInit() {
		this.dueDate = new Date(this.data.dueDate);
		this.createForm();
	}

	confirmClicked() {
		if (this.validateForm()) {
			const date = moment(this.form.controls.date.value).format('YYYY-MM-DD');
			const time = this.form.controls.time.value;
			this.data.dueDate = moment.utc(`${date} ${time}`).toISOString();
			this.taskService.updateTask(this.data).subscribe(resp => {
				this.dialogRef.close(true);
			});
		}
	}

	cancelClicked() {
		this.dialogRef.close();
	}

	get controls() {
		return this.form.controls;
	}

	private createForm() {
		this.form = this.fb.group({
			date: new FormControl(moment.utc(this.data.dueDate).format('YYYY-MM-DD'), Validators.required),
			time: new FormControl(moment.utc(this.data.dueDate).format('HH:mm'), Validators.required)
		});
	}

	private validateForm(): boolean {
		if (this.form.controls.time.value) {
			return true;
		}
		return false;
	}

}
