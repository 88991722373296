import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TaskViewEnum } from 'src/app/models/types/taskView.enum';
import { TasksService } from 'src/app/services/tasks.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { TaskModel } from 'src/app/models/client/task.model';
import { TaskStatusEnum } from 'src/app/models/types/taskStatus.enum';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from '../../models/types/confirmationPopup.enum';

@Component({
	selector: 'app-done-tasks',
	templateUrl: './done-tasks.component.html',
	styleUrls: [ './done-tasks.component.scss' ]
})
export class DoneTasksComponent implements OnInit, OnDestroy {

	doneTasksList = [];
	public taskViewEnum = TaskViewEnum;
	private subscriptions: Subscription[] = [];
	private leadId: string;

	constructor(private dialogRef: MatDialogRef<DoneTasksComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private tasksService: TasksService,
				private dataService: DataService, private dialog: MatDialog) {
		if ( data ) {
			this.leadId = data;
		}
	}

	ngOnInit() {
		this.getTasks();
	}

	ngOnDestroy() {
		for ( const subscription of this.subscriptions ) {
			subscription.unsubscribe();
		}
	}

	public close() {
		this.dialogRef.close();
	}

	public deleteTask(task: TaskModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveTask,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if ( confirm ) {
				const subscription = this.tasksService.deleteTask(task._id).subscribe(resp => {
					this.dataService.getUserTasks();
					this.getTasks();
				});
				this.subscriptions.push(subscription);
			}
		});
	}

	public restoreTask(task: TaskModel) {
		task.status = TaskStatusEnum.Open;
		const subscription = this.tasksService.updateTask(task).subscribe(success => {
			this.dataService.getUserTasks();
			this.getTasks();
		});

		this.subscriptions.push(subscription);
	}

	private getTasks() {
		const subscription = this.tasksService.getTasksByStatus(TaskStatusEnum.Completed).subscribe(tasks => {
			if ( this.leadId ) {
				this.doneTasksList = tasks.filter(task => task.lead === this.leadId);
			} else {
				this.doneTasksList = tasks;

			}
		});
		this.subscriptions.push(subscription);
	}
}
