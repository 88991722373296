import { Component, OnInit, OnDestroy } from '@angular/core';
import { TaskViewEnum } from '../../models/types/taskView.enum';
import { TasksService } from '../../services/tasks.service';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { LeadModel } from 'src/app/models/client/lead.model';

enum OpenedComponent {
	Calendar = 1,
	Tasks,
	New_Task
}

@Component({
	selector: 'app-notification-managment',
	templateUrl: './notification-managment.component.html',
	styleUrls: ['./notification-managment.component.scss']
})
export class NotificationManagmentComponent implements OnInit, OnDestroy {

	public openedComponentEnum = OpenedComponent;
	public taskViewEnum = TaskViewEnum;
	public isOpen: boolean = false;
	public selectedComponent = OpenedComponent.Calendar;
	public newTaskSubscription: Subscription;
	public lead: LeadModel;


	constructor(private tasksService: TasksService, private dataService: DataService) {
	}

	ngOnInit() {
		this.newTaskSubscription = this.dataService.globalNewTask.subscribe(lead => {
			if (lead) {
				this.lead = lead;
				this.isOpen = true;
				this.selectedComponent = this.openedComponentEnum.New_Task;
			}
		});

	}



	public iconClick(selected: OpenedComponent) {
		this.lead = undefined;
		if (this.isOpen && selected === this.selectedComponent) {
			this.isOpen = false;
		} else {
			this.selectedComponent = selected;
			this.isOpen = true;
		}
	}

	public clickOutside(e: any) {
		if (this.isOpen && (e.pageX > 420 || e.pageY < 56)) {
			this.lead = undefined;
			this.close();
			e.stopPropagation();
		}
	}

	public close() {
		this.isOpen = false;
	}

	public openNewTask() {
		this.lead = undefined;
		this.selectedComponent = this.openedComponentEnum.New_Task;
	}

	public openTasks() {
		this.lead = undefined;
		this.selectedComponent = this.openedComponentEnum.Tasks;
	}

	ngOnDestroy() {
		this.newTaskSubscription.unsubscribe();
	}
}
