import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FORM_MESSAGES } from '../../../shared/texts';
import { PricingService } from 'src/app/services/pricing.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TripModel } from 'src/app/models/trip/trip.model';
import { TripClassEnum } from 'src/app/models/types/tripClass.enum';

@Component({
  selector: 'app-pricing-general-details',
  templateUrl: './pricing-general-details.component.html',
  styleUrls: ['./pricing-general-details.component.scss']
})
export class PricingGeneralDetailsComponent implements OnInit {

  constructor(public fb:FormBuilder,public pricingService:PricingService,public route:ActivatedRoute) {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.tripId = this.route.snapshot.paramMap.get('id');
	  })
  }

  public readonly INVALID_FORM = FORM_MESSAGES;
  form:FormGroup

  @Output() emitStep = new EventEmitter()
  @Output() getTripWithPricing = new EventEmitter()
  @Input() tripData
  @Input() currentCurrency:string;
  tripId:string
  startDate:string;
  endDate:string
  generalDetailsData:any;
  TripClassEnum = TripClassEnum
  showImportPricing:boolean = false;
  selectedTripPricing:any
  pricingsData:any
  showDarkLayer:boolean = false;

  ngOnInit() {
  }



	public getTripClassName(): string {
		switch (this.tripData.tripClass) {
			case TripClassEnum.FIX: return 'טיול חוברת';
			case TripClassEnum.GROUP: return 'קבוצה סגורה';
			case TripClassEnum.FIT: return 'FIT';
			case TripClassEnum.INCENTIVE: return 'Incentive';
			default: return 'N/A';
		}
	}

	showImport(){
		if(this.showImportPricing){
			this.showDarkLayer = true;
			this.pricingService.getAllPricings().subscribe( res => {
				this.pricingsData = res
			})
		} else {
			this.showDarkLayer = false;
		}
	}



	pricingChanged(value){
		this.getTripWithPricing.emit(value)
	}


	checkInputData(){
		if(this.tripData){
			// if(this.tripData.completed && this.tripData.tripClass === 1){
			// 	this.createFormDisabled()
			// } else {
				this.createForm()
			// }
			this.generalDetailsData = this.tripData.pricing.generalDetails
			this.form.setValue(this.generalDetailsData)
			this.endDate = moment(this.tripData.returnDate).format('DD.MM.YY')
			this.startDate = moment(this.tripData.departureDate).format('DD.MM.YY')
		}
	}

  private createFormDisabled() {
	this.form = this.fb.group({
		minimalTravelsNumber: new FormControl({value:'', disabled: true },Validators.required),
		numberOfTravelersJumps: new FormControl({value:'', disabled: true }, Validators.required),
		includeFreeTravelers: new FormControl({value:false, disabled: true }, Validators.required),
		currency: new FormControl({value:'', disabled: true }, Validators.required),
		priceRanks: new FormControl({value:null, disabled: true }, [Validators.required,Validators.max(5), Validators.min(0)]),
		pricesCatagory: new FormControl({value:[], disabled: true }),
	});
}

public get controls() {
	if(this.form){
		return this.form.controls;
	}
	}

private createForm() {
	this.form = this.fb.group({
		minimalTravelsNumber: new FormControl('', Validators.required),
		numberOfTravelersJumps: new FormControl('', Validators.required),
		includeFreeTravelers: new FormControl(false, Validators.required),
		currency: new FormControl('', Validators.required),
		priceRanks: new FormControl(null, [Validators.required,Validators.max(5), Validators.min(0)]),
		pricesCatagory: new FormControl([]),
	});
}

	updateGeneralDetails(stepNumber){
		let data = {
			pricingId:this.tripData.pricing._id,
			tripId:this.tripData._id,
			dataToUpdate: this.form.value,
			fieldToUpdate:'generalDetails'
		}
		this.pricingService.updatePricingGeneralDetails(data).subscribe( res => {
			this.emitStep.emit(stepNumber)
		})
	}



}
