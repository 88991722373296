import { Router, ActivatedRoute } from '@angular/router';
import { ClientModel } from 'src/app/models/client/client.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-three-success-message',
  templateUrl: './three-success-message.component.html',
  styleUrls: ['./three-success-message.component.scss']
})
export class ThreeSuccessMessageComponent implements OnInit {
  public imageUrl: string;
  public successMessageTop: string;
  public successMessageBottom: string;
  public additionalDetailsMessage: string;
  public buttonText: string;
  public anchorText: string;
  @Input() client: ClientModel;
  @Output() relativesEmitter = new EventEmitter();
  public link: string;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.imageUrl = '../../../../../../../assets/images/success-green-arrow.svg';
    this.successMessageTop = 'העברה להרשמה בוצעה בהצלחה !';
    // this.successMessageBottom = 'נשלח טופס הרשמה במייל';
    this.additionalDetailsMessage = '*להשלמת פרטים נוספים על בני המשפחה';
    this.buttonText = 'המשך לתיק הטיול';
    this.anchorText = 'עבור לתיק הלקוח';
  }

  ngOnInit() {
	  
    this.link = `/app/clients/${this.client._id}`
  }

  done(status: boolean) {
    if (!status){
		this.router.navigateByUrl(this.link);
	}
    this.relativesEmitter.emit(status);
  }

}
