import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadModel } from '../../../../models/client/lead.model';
import { LeadsService } from '../../../../services/leads.service';
import { LeadStatusEnum } from '../../../../models/types/leadStatus.enum';
import { ClientModel } from '../../../../models/client/client.model';

@Component({
	selector: 'app-lead-move-to-sale',
	templateUrl: './lead-move-to-sale.component.html',
	styleUrls: [ './lead-move-to-sale.component.scss' ]
})
export class LeadMoveToSaleComponent implements OnInit {
	@Input() lead: LeadModel;
	@Output() saveCompleted = new EventEmitter();
	@Output() close = new EventEmitter();
	public  client: ClientModel;

	constructor(private leadService: LeadsService) {

	}


	ngOnInit() {
		this.client = this.lead.client as ClientModel;
	}

	public closeClicked() {
		this.close.emit();
	}

	public save() {
		this.leadService.sale(this.lead._id).subscribe(() => {
			this.saveCompleted.emit();
		});
	}

}
