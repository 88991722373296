import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadModel } from '../../../../models/client/lead.model';
import { ClientModel } from '../../../../models/client/client.model';
import { DestinationModel } from '../../../../models/client/destination.model';
import { LeadCancelPopupComponent } from '../../popups/lead-cancel-popup/lead-cancel-popup.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LeadSourceModel } from 'src/app/models/client/leadSource.model';

@Component({
	selector: 'app-lead-base-info',
	templateUrl: './lead-base-info.component.html',
	styleUrls: [ './lead-base-info.component.scss' ]
})
export class LeadBaseInfoComponent implements OnInit {

	public _lead: LeadModel;
	@Input() set lead(value: LeadModel){
		if (value) {
			this._lead = Object.assign(new LeadModel(), value);
			this.init();
		}
	}
	@Input() tripStyle:boolean = false
	@Input() withLeadChanges: boolean = false;
	@Input() withLeadClose: boolean = false;
	@Input() withLeadEdit: boolean = false;
	@Output() leadEditAction = new EventEmitter();
	public client: ClientModel;
	public destination: DestinationModel;
	public leadSourceName: string;

	constructor(private dialog: MatDialog, private router: Router,) {
	}

	ngOnInit() {
	}

	private init() {
		this.client = this._lead.client as ClientModel;
		this.destination = this._lead.destination as DestinationModel;
		this._lead.createdAt = new Date(this._lead.createdAt);
		this.leadSourceName = (this._lead.leadSource as LeadSourceModel).name;
	}

	public goWebsite(url){
		var win = window.open(`http://${url}`, "_blank");
  		win.focus();
	}

	public editLeadClicked() {
		this.leadEditAction.emit();
	}

	public closeLeadClicked() {
		this.dialog.open(LeadCancelPopupComponent, {
			width: '600px',
			height: '600px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {lead: this._lead}
		});
	}

	public goToClientPage() {
		this.router.navigateByUrl(`/app/clients/${this.client._id}`);
	}

}
