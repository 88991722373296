import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material';
import { Subscription } from 'rxjs';
import { ClientModel } from '../../../models/client/client.model';
import { ClientService } from '../../../services/client.service';
import formatPhoneNumberUtil from 'src/app/utils/formatPhoneNumber';

@Component({
	selector: 'app-phone-client-autocomplete',
	templateUrl: './phone-client-autocomplete.component.html',
	styleUrls: ['./phone-client-autocomplete.component.scss']
})
export class PhoneClientAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	@Input() control: FormControl;
	@Input() level: number
	// @Input() onPhoneNumberChange: (isValid: boolean)=>void;
	@Output() clientSelectedAction = new EventEmitter();
	@Output() clientDeSelectedAction = new EventEmitter();

	@ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

	public filteredOptions: ClientModel[] = [];
	public form: FormGroup;
	private subscription: Subscription;

	constructor(private fb: FormBuilder, private clientService: ClientService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		// mobile and land line
		// this.form = this.fb.group({
		// 	name: new FormControl('', Validators.compose([ Validators.required, Validators.pattern(/^05\d([-]{0,1})\d{7}$|^0\d([-]{0,1})\d{7}$/) ])),
		// });

		this.form = this.fb.group({
			name: this.control,
		},{ updateOn: 'change' });
		this.filteredOptions = [];

		this.form.controls.name.valueChanges.subscribe(inputValue => {
			this.setFilteredUsers(inputValue);
		});
	}

	isInputEnabled: boolean = true;

	formatOnChange(event){
		let output: string = formatPhoneNumberUtil(event.target.value.toString());
		this.control.setValue(output);
		this.form.controls.name.setValue(output);
		// this.onPhoneNumberChange(this.control.valid);
	}

	handler(event: MatAutocompleteSelectedEvent): void {
		this.control.setValue(event.option.value.personalDetails.phoneNumber);
		this.form.controls.name.setValue(event.option.value.personalDetails.phoneNumber);
		this.clientSelectedAction.emit(event.option.value);
	}

	public setFilteredUsers(inputRegex) {
		this.clientService.getClientByPhone(inputRegex).subscribe((clients: ClientModel[]) => {
			this.filteredOptions = clients;
		});
	}

	private _subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger.panelClosingActions
			.subscribe(e => {
				if (!e || !e.source) {
					this.control.setValue(this.form.controls.name.value);
					this.clientDeSelectedAction.emit();
				}
			},
				err => this._subscribeToClosingActions(),
				() => this._subscribeToClosingActions());
	}
}
