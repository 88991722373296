import { Component, OnInit, OnDestroy } from '@angular/core';
import { DepartmentService } from '../../services/department.service';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { Subscription } from 'rxjs';
import { DestinationService } from 'src/app/services/destination.service';

@Component({
	selector: 'app-storage-department-page',
	templateUrl: './storage-department-page.component.html',
	styleUrls: ['./storage-department-page.component.scss']
})
export class StorageDepartmentPageComponent implements OnInit, OnDestroy {

	public subscription: Subscription;
	public departments: DepartmentModel[] = [];

	constructor(private departmentService: DepartmentService,private destinationService: DestinationService) { }

	ngOnInit() {
		this.getDepartments();
	}

	private getDepartments() {
		this.subscription = this.departmentService.notAdministrative().subscribe(departments => {
			departments.forEach(d => {
				const department = Object.assign(new DepartmentModel(), d);
				this.departments.push(department);
			});
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
