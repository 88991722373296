import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeadModel } from '../../../../models/client/lead.model';
import { LeadMoveToSalePopupComponent } from '../../popups/lead-move-to-sale-popup/lead-move-to-sale-popup.component';
import { LeadCancelPopupComponent } from '../../popups/lead-cancel-popup/lead-cancel-popup.component';
import { LeadMoveToFuturePopupComponent } from '../../popups/lead-move-to-future-popup/lead-move-to-future-popup.component';
import { LeadNoAnswerPopupComponent } from '../../popups/lead-no-answer-popup/lead-no-answer-popup.component';
import { LeadsService } from '../../../../services/leads.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { NoteModel } from 'src/app/models/client/note.model';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { NotesService } from 'src/app/services/notes.service';

@Component({
	selector: 'app-lead-new',
	templateUrl: './lead-new.component.html',
	styleUrls: ['./lead-new.component.scss']

})
export class LeadNewComponent implements OnInit {

	@Input() lead: LeadModel;
	@Output() leadUpdatedAction = new EventEmitter();
	public leadNote: NoteModel;
	public noteText: string = ''
	public isEdit: boolean = false;
	constructor(public dataService: DataService, private noteService: NotesService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
	}

	ngOnInit() {
		this.getNotes()
	}

	saveNote() {
		let note = new NoteModel()
		note.description = this.noteText
		note.lead = this.lead._id;
		note.client = this.lead.client['_id'];
		this.dataService.globalUser.subscribe(user => {
			note.user = user
		})
		note.date = moment().toISOString();
		note.leadStatus = this.lead.status
		if (!this.leadNote) {
			this.noteService.saveNot(note).subscribe(res => {
				this.getNotes()
				this.isEdit = false;
			})
		} else {
			this.noteService.updateNote(this.leadNote._id, note.description).subscribe(res => {
				this.getNotes()
				this.isEdit = false;
			})
		}
	}

	getNotes() {
		this.noteService.getLeadNotes(this.lead._id, this.lead.status).subscribe(note => {
			if (note.length) {
				this.leadNote = note[0]
				this.noteText = this.leadNote.description
			}
		})
	}

	cancelNote() {
		if (this.leadNote && this.leadNote.description) {
			this.noteText = this.leadNote.description
		} else {
			this.noteText = ''
		}
	}

	public moveToSaleClicked() {
		const saleDialog = this.dialog.open(LeadMoveToSalePopupComponent, {
			width: '500px',
			height: '341px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.lead
		});

		saleDialog.afterClosed().subscribe(success => {
			this.leadUpdatedAction.emit();
		});
	}

	public closeLeadClicked() {
		this.dialog.open(LeadCancelPopupComponent, {
			width: '600px',
			height: '600px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { lead: this.lead }
		});
	}

	public moveToFutureClicked() {
		this.dialog.open(LeadMoveToFuturePopupComponent, {
			width: '600px',
			height: '492px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.lead
		});
	}

	public noAnswerClicked() {
		this.dialog.open(LeadNoAnswerPopupComponent, {
			width: '600px',
			height: '492px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.lead
		});
	}
}
