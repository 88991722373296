import { ClientModel } from "src/app/models/client/client.model";
import { RelativeMinimizeModel } from "./../../lead/popups/lead-move-to-register/components/two-add-relatives/two-add-relatives.component";
import { ClientService } from "./../../../services/client.service";
import { ClientInfoModel } from "./../../../models/client/client.model";
import { addRelativeOption } from "./../../../models/types/addRelativeOption.enum";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "app-add-relative-dialog",
	templateUrl: "./add-relative-dialog.component.html",
	styleUrls: ["./add-relative-dialog.component.scss"]
})
export class AddRelativeDialogComponent implements OnInit {
	public continueTxt = "המשך";
	public backTxt = "חזרה";
	public addRelativeOption = addRelativeOption;
	public switchState = addRelativeOption.PersonalDetails;
	public realtiveDetails = new ClientInfoModel();
	public passportFormIsValid = false;
	public personalFormIsValid = false;
  public requestsFormIsValid = false;
  public submitted: boolean = false;
	constructor(
		private dialogRef: MatDialogRef<AddRelativeDialogComponent>,
		private clientService: ClientService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		console.log(this.realtiveDetails);
		this.realtiveDetails.client = this.data.clientId;
	}

	public closeDialog(value?: boolean) {
		// TODO: logics!!!

		this.dialogRef.close(value);
	}

	public onContinueClicked() {
    this.submitted = true;
		if (
			this.switchState == this.addRelativeOption.PersonalDetails &&
			this.personalFormIsValid
		) {
      this.submitted = false;
			this.switchState++;
			return;
		}
		if (
			this.switchState == this.addRelativeOption.PassportDetails &&
			this.passportFormIsValid
		) {
			this.switchState++;
			return;
		}
		if (
			this.switchState == this.addRelativeOption.SpecialRequests &&
			this.requestsFormIsValid
		) {
			this.addContacts();
			this.clientService
				.addClientMember(this.realtiveDetails)
				.subscribe();
			this.closeDialog(true);
		}
  }


	private addContacts(): void {
		const contactName = (this.realtiveDetails.client as ClientModel)
			.personalDetails.contactUser;
		const contactPhone = (this.realtiveDetails.client as ClientModel)
			.personalDetails.contactUserPhone;
		this.realtiveDetails.contactUser = contactName;
		this.realtiveDetails.contactUserPhone = contactPhone;
	}

	public onBackClicked() {
		this.switchState != this.addRelativeOption.PersonalDetails? this.switchState-- : null;
	}
}
