import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-bundle-pop',
  templateUrl: './bundle-pop.component.html',
  styleUrls: ['./bundle-pop.component.scss']
})
export class BundlePopComponent implements OnInit {

  constructor(private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<any>) { }

  public form: FormGroup;
  termsArray:string[] = []
  componentType:string
  note:any;
  componentHeadline:string = '';


  get controls() {
	return this.form.controls;
}
  ngOnInit() {
	this.componentHeadline = this.data.componentHeadline
	this.componentType = this.data.component
	if(this.componentType === 'included'){
		this.form = this.fb.group({
			isInclude: ['', Validators.required],
			headLine: ['', Validators.required]
		});
	} else {
		this.form = this.fb.group({
			headLine: ['', Validators.required]
	})
}
	if(this.data.note){
		this.note = this.data.note
		this.form.patchValue({
			headLine:this.data.note.headLine
		})
		if(this.componentType === 'included'){
			this.form.patchValue({
				isInclude:this.data.note.isInclude
			})
		}
		if(this.note.description.length > 0){
			this.termsArray = JSON.parse(JSON.stringify(this.note.description))
		}
	}
}

saveTermToTrip(){
	let note = {
		headLine:this.form.value.headLine,
		description:this.termsArray
	}
	if(this.componentType === 'included'){
		note['isInclude'] = this.form.value.isInclude
	}
	this.dialogRef.close(note);
}

  addTerm(){
	  let term:string = ''
	  this.termsArray.push(term)
  }

  deleteRow(i){
	  this.termsArray.splice(i,1)
  }

  trackByFn(index: any, item: any) {
	return index;
 }

 closeDialog(){
	 this.dialogRef.close()
 }

}
