import { Injectable } from '@angular/core';
import { SuccessMessageComponent } from '../components/success-message/success-message.component';
import { MatDialog } from '@angular/material';

@Injectable({
	providedIn: 'root'
})
export class SharedActionsService {

	constructor(private dialog: MatDialog) {
	}


	public showActionConfirm(title: string) {
		this.dialog.open(SuccessMessageComponent, {
			width: '500px',
			height: '275px',
			panelClass: 'custom-dialog-container',
			backdropClass: 'custom-backdropClass',
			data: title
		});
	}

}
