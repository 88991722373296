import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { ProviderModel } from '../models/trip/provider.model';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService extends HttpHandlerService {

	private url: string = 'providers/';

	getAllProviders(){
		return this.get(this.url, { });
	}

	public create(provider: ProviderModel) {
		return this.post(this.url, provider);
	}

	public update(provider: ProviderModel) {
		return this.put(this.url, provider);
	}

	public remove(id: string) {
		return this.delete(this.url, {id});
	}
}
