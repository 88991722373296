export const FORM_MESSAGES = {
	REQUIRED_FIELD: 'Field is required',
	INVALID_EMAIL: 'כתובת המייל שגויה',
	INVALID_PHONE: 'טלפון שגוי',
	EMAIL_EXIST: 'כתובת מייל קיימת במערכת',
	PHONE_EXIST: 'מספר טלפון קיים במערכת',
	INVALID_ID: 'מספר תעודת זהות שגוי',
	CONFIRM_PASSWORD: 'הסיסמאות אינן שוות',
	MIN_STARS: 'יש להזין מספר ככבים תקין',
	MAX_STEPS:'יש להזין מספר נמוך מ-5',
	MIN:'יש להזמין מספר הגבוהה מ-0'
};
