import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { TripModel } from 'src/app/models/trip/trip.model';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { SortTypeEnum } from 'src/app/models/types/sortType.enum';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { TripStatusEnum } from 'src/app/models/trip/tripStatus.enum';
import { MatDialog } from '@angular/material';
import { SortableOptionsEnum } from 'src/app/models/types/sortableOptions.enum';
import { TripsService } from 'src/app/services/trips.service';
import { TripClassEnum } from '../../../models/types/tripClass.enum';
import { DepartmentService } from 'src/app/services/department.service';
import { Subscription } from 'rxjs';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { isArray } from 'util';
import { DestinationService } from 'src/app/services/destination.service';
import { FilterService } from 'src/app/services/filter.service';
import { TripQueryModel } from 'src/app/models/request/tripQuery.model';
import { GuideModel } from 'src/app/models/trip/guide.model';


@Component({
	selector: 'app-trip-incentive',
	templateUrl: './trip-incentive.component.html',
	styleUrls: ['./trip-incentive.component.scss']
})
export class TripIncentiveComponent implements OnInit, OnDestroy {
	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel(15);
	public trips: TripModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public tableHeight: number = window.innerHeight - 440;
	@Output() rowClickedAction = new EventEmitter();
	@Input() completed: boolean; // filters between completed trips to trip in build process
	private readonly tripStatus = TripStatusEnum.Fix;
	private subscriptions: Subscription[] = [];
	public destinations: DestinationModel[] = [];
	public departments: DepartmentModel[] = [];
	@Input() guidesList: GuideModel[];

	constructor(public filterService:FilterService,public destinationService:DestinationService,private dialog: MatDialog, private fb: FormBuilder, private tripService: TripsService, private departmentService: DepartmentService) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.TripName, true);

	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createColsTableItem();
		this.createForm();
		this.getDepartments();
		const subscription = this.destinationService.getAllCitiesByDestinationId(this.filterService.currentDepartment && this.filterService.currentDepartment._id).subscribe(resp => {
			this.destinations = resp;
		})
		this.getTrips();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private getDepartments() {
		const subscription = this.departmentService.all().subscribe(departments => {
			this.departments = departments;
		});
		this.subscriptions.push(subscription);
	}

	public clearFilters() {
		this.form.reset();
		this.filterService.clearFilters()
		this.updateDestinations()
		this.afterFilterChange();
	}

	public clearClicked() {
		this.controls['searchText'].setValue('');
		this.afterFilterChange();
	}
	public goToPage(nextPage: number) {
		this.pagination.nextPage = nextPage;
		this.getTrips();
	}

	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if ( col.colSortable.SortType === SortTypeEnum.Descending ) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.pagination.resetPagination();
		this.getTrips();
	}

	private updateDestinations() {
		const subscription = this.destinationService.getAllCitiesByDestinationId(this.filterService.currentDepartment && this.filterService.currentDepartment._id).subscribe(resp => {
			this.destinations = resp;
			let checkDestination
			if(this.filterService.currentDestination){
				if(isArray(this.filterService.currentDestination._id)){
					checkDestination = this.destinations.find(d =>  d._id === this.filterService.currentDestination._id[0])
				} else {
					checkDestination = this.destinations.find(d =>  d._id === this.filterService.currentDestination._id)
				}
			} else {
				checkDestination = false;
			}
			if (!isArray(this.destinations) || !checkDestination) {
				this.form.controls.destination.setValue(undefined);
				this.filterService.currentDestination = undefined;
			}
			this.afterFilterChange();
		});
		this.subscriptions.push(subscription);
	}

	public afterDepartmentChange() {
		this.updateDestinations();
	}

	public afterFilterChange() {
		this.pagination.resetPagination();
		this.getTrips();
	}

	public rowClicked(trip: TripModel) {
		this.rowClickedAction.emit(trip._id);
	}

	private getTrips() {
		const searchText = this.filterService.searchText? this.filterService.searchText : undefined;;
		const destinationId = this.filterService.currentDestination? this.filterService.currentDestination._id : undefined;
		const departmentId = this.filterService.currentDepartment? this.filterService.currentDepartment._id : undefined;
		const guideId = this.filterService.currentGuide? this.filterService.currentGuide._id : undefined;
		const dates = this.filterService.currentDates;
		const tripQuery = new TripQueryModel(this.completed,TripClassEnum.INCENTIVE, destinationId, departmentId,searchText,guideId,undefined,dates,this.pagination,this.sortableCol);
		this.tripService.getAllTrips(tripQuery).subscribe(allTrips => {
			this.trips = [];
			allTrips.forEach(trip => {
				const newTrip: TripModel = Object.assign(new TripModel(), trip);
				newTrip.setFormattedDate();
				this.trips.push(newTrip);
			});
		});
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.NUMBER, 'tripNumber', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.Number, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.NAME, 'name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.TripName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DATES, 'departureDate', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.DepartureDate, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.TOTAL, '', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.INSTRUCTOR, 'guide.name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.Guide, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.OPERATOR, '', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DESTINATION, 'destination.name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.Destination, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DIVISION, 'department.name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.Department, true)));
	}


	private createForm() {
		this.form = this.fb.group({
			destination: new FormControl(''),
			department: new FormControl(''),
			guide: new FormControl(''),
			searchText: new FormControl(''),
			startDate:new FormControl(''),
			endDate:new FormControl('')
		});
	}

	public removeClicked(el: any) {}
	public editClicked(el: any) {}


}
