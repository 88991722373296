import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HotelService } from '../../../services/hotel.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { HotelModel } from 'src/app/models/trip/hotel.model';

@Component({
	selector: 'app-hotel-autocomplete',
	templateUrl: './hotel-autocomplete.component.html',
	styleUrls: ['./hotel-autocomplete.component.scss']
})
export class HotelAutocompleteComponent implements OnInit, OnDestroy {
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	// @Input() city: string = undefined; // city id to filter by

	private _cityId: string = undefined;
	@Input() set city(value: string) {
	this._cityId = value;
		if(this._cityId){
			this.getHotels();
		}
	}

	@Input() control: FormControl;

	public form: FormGroup;
	public hotelsList: HotelModel[] = [];
	public filteredOptions: HotelModel[] = [];
	private subscription: Subscription;

	constructor(private fb: FormBuilder, private hotelService: HotelService) {
	}

	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		if(this.subscription){
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.buildForm();
	}

	private getHotels() {
		this.subscription = this.hotelService.getAll(this._cityId).subscribe(hotelsList => {
			this.hotelsList = hotelsList;

			// sets city name value when in edit mode
			const hotel = hotelsList.find(h => h._id === this.control.value);
			if (hotel) {
				this.form.controls.name.setValue(hotel._id);
			}
		});
	}

	private buildForm() {
		this.form = this.fb.group({
			name: [name, Validators.required]
		});
	}

	public handler(value): void {
		this.control.setValue(value);
		// this.form.controls.name.setValue(value.name);
	}

}
