import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-message-popup',
	templateUrl: './message-popup.component.html',
	styleUrls: ['./message-popup.component.scss']
})
export class MessagePopupComponent implements OnInit {

	constructor(private dialogRef: MatDialogRef<MessagePopupComponent>, @Inject(MAT_DIALOG_DATA) public message: string) {}

	ngOnInit() {
	}

	closeClicked() {
		this.dialogRef.close();
	}

}
