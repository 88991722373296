import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../shared/texts';
import { UserModel } from '../../models/client/user.model';
import { UserService } from '../../services/user.service';
import { CustomValidatorsService } from '../../services/custom-validators.service';
import { RoleModel } from '../../models/client/role.model';
import { DepartmentModel } from '../../models/client/department.mode';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: [ './user.component.scss' ]
})
export class UserComponent implements OnInit, OnDestroy {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public waitingResponse: boolean = false;
	public form: FormGroup;
	public options = [ 'אופרציה', 'אופרציה', 'אופרציה', 'אופרציה' ];
	public roles: RoleModel[];
	public rolesToShow: RoleModel[];
	public departments: DepartmentModel[];
	private departmentChangeSubscription: Subscription;
	public userToUpdate: UserModel;
	public title: string;

	constructor(private dialogRef: MatDialogRef<UserComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private fb: FormBuilder,
				private userService: UserService, private customValidatorsService: CustomValidatorsService) {

		this.roles = data.roles;
		this.rolesToShow = data.roles;
		this.departments = data.departments;
		if ( data.user ) {
			this.userToUpdate = data.user;
		}
		this.title = data.user ? 'עריכת עובד/ת' : 'הוספת עובד/ת חדש';
		this.createForm();

	}

	public get controls() {
		return this.form.controls;
	}

	ngOnInit() {
	}

	public close() {
		this.dialogRef.close();
	}

	public addOrEditUser() {
		const user = this.createUserFromForm();
		if ( this.userToUpdate ) {
			this.updateUser(user);
		} else {
			this.addUser(user);
		}
	}

	ngOnDestroy(): void {
		this.departmentChangeSubscription.unsubscribe();
	}

	private updateUser(user: UserModel) {
		this.waitingResponse = true;

		this.userService.updateUser(user).subscribe(resp => {
			this.manageUserResponse();
		}, err => {
			this.waitingResponse = false;
		});
	}

	private addUser(newUser: UserModel) {
		this.waitingResponse = true;

		this.userService.addUser(newUser).subscribe(resp => {
			this.manageUserResponse();
		}, err => {
			this.waitingResponse = false;
		});
	}

	private manageUserResponse() {
		this.waitingResponse = false;
		this.dialogRef.close(true);
	}

	private createForm() {
		this.form = this.fb.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl('', Validators.compose([ Validators.required, CustomValidators.email ]), this.customValidatorsService.asyncValidateUserEmailExist.bind(this)),
			role: new FormControl('', Validators.required),
			department: new FormControl('', Validators.required),
		});

		this.departmentChangeSubscription = this.form.controls.department.valueChanges.subscribe(newValue => {
			this.onDepartmentChange(newValue);
		});


		if ( this.userToUpdate ) {
			this.manageFormCreationForUpdate();
		}
	}

	private manageFormCreationForUpdate() {
		this.form.reset({
			firstName: this.userToUpdate.firstName,
			lastName: this.userToUpdate.lastName,
			email: this.userToUpdate.email,
			department: (this.userToUpdate.department as DepartmentModel)._id,
			role: (this.userToUpdate.role as RoleModel)._id,
		});
		this.onDepartmentChange((this.userToUpdate.department as DepartmentModel)._id);
	}

	private createUserFromForm(): UserModel {
		const {
			firstName, lastName, email, role, department
		} = this.form.controls;

		const user = Object.assign(new UserModel(), {
			firstName: firstName.value,
			lastName: lastName.value,
			email: email.value,
			role: role.value,
			department: department.value
		});

		if ( this.userToUpdate ) {
			user._id = this.userToUpdate._id;
		}
		return user;
	}

	private onDepartmentChange(departmentId: string) {
		if ( departmentId ) {
			// Change the roles to show by department selected
			const foundedDepartment = this.departments.find(currDepartment => currDepartment._id === departmentId);
			this.rolesToShow = this.roles.filter(role => foundedDepartment.roles.some(roleId => role._id === roleId));

			// Check if selected role is part of the department, if not then reset the value of the role
			if ( this.form.controls.role.value ) {
				const foundedSelectedRole = this.rolesToShow.some(role => role._id === this.form.controls.role.value);
				if ( !foundedSelectedRole ) {
					this.form.controls.role.setValue('');
				}
			}

		} else {
			this.rolesToShow = this.roles;
		}
	}

}
