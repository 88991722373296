import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FinanceTabHeadlines, SupplierPaymentsEnum } from 'src/app/models/trip/finance.enum';
import { ProvidersService } from 'src/app/services/providers.service';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { VoucherModel } from 'src/app/models/trip/voucher.model';
import { VouchersService } from 'src/app/services/vouchers.service';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { VoucherQueryModel } from 'src/app/models/request/voucherQuery.model';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SortableOptionsEnum } from 'src/app/models/types/sortableOptions.enum';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { SortTypeEnum } from 'src/app/models/types/sortType.enum';
import { ProvidersVouchersTabComponent } from 'src/app/components/finance/providers-vouchers-tab/providers-vouchers-tab.component';
import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/models/client/user.model';

@Component({
  selector: 'app-provider-payments-page',
  templateUrl: './provider-payments-page.component.html',
  styleUrls: ['./provider-payments-page.component.scss']
})
export class ProviderPaymentsPageComponent implements OnInit {

  constructor(public userService:UserService,public fb:FormBuilder,public providersService:ProvidersService,public departmentService:DepartmentService,public vouchersService:VouchersService) { }

  public SupplierPaymentsTab = SupplierPaymentsEnum;
  public FINANCE_ENUM_STRINGS = FinanceTabHeadlines;
  public showingPage: SupplierPaymentsEnum = SupplierPaymentsEnum.Vouchers;
  providers:any
  departments:DepartmentModel[];
  vouchers:VoucherModel[]
  pagination:PaginationModel = new PaginationModel(15)
  form:FormGroup
  sort:SortableColModel = new SortableColModel(SortableOptionsEnum.Provider,true)
  currencyObj:any;


  ngOnInit() {
	  this.getAllProvidersTypes()
	  this.getAllDepartments()
	  this.createForm()
	  this.sort.setDescending()
  }

  getAllCurrencies(){
	this.vouchersService.getAllCurrencies().subscribe( res => {
		this.currencyObj = res
	})
}



  getAllProvidersTypes(){
	this.providersService.getAllProviders().subscribe( providers => {
		this.providers = providers
		console.log(this.providers,'providers')
	})
  }

  getAllDepartments(){
	  this.departmentService.all().subscribe( departments => {
		this.departments = departments
	  })
  }

  getAllVouchers(){
	  let voucherQuery = new VoucherQueryModel(this.showingPage,this.pagination,this.form.value,this.sort)
	  this.vouchersService.getAllVouchers(voucherQuery).subscribe( vouchers => {
		  this.vouchers = vouchers
		  console.log(this.vouchers)
	  })
  }

  pageClicked(pageNumber){
	this.pagination.nextPage = pageNumber
	this.getAllVouchers()
  }

  createForm(){
	this.form = this.fb.group({
		providerType:new FormControl(''),
		month:new FormControl(''),
		department:new FormControl(''),
		searchText:new FormControl('')
	})
}

  changeTab(tab){
	this.showingPage = tab
  }

}
