import { CityModel } from '../trip/city.model';
import { HotelModel } from '../trip/hotel.model';
import { HotelMealsEnum } from '../types/hotelMeals.enum';

export class HotelResModel {
	_id?: string;
	notes: string;
	hotel: string | HotelModel = new HotelModel();
	city: string | CityModel = new CityModel();
	meals: HotelMealsEnum;
	roomDescription: string;
	roomOther:string;
}
