export class HeaderCategory {
	name: string;
	links: Link[] = [];
	isOpen: boolean = false;
	id?: string;
}

class Link {
	url: string = '';
	text: string = '';
}
