import { Component, OnInit, Input } from '@angular/core';
import { BankTitles } from 'src/app/models/types/currencies.strings.enum';
import { FORM_PLACEHOLDERS, FORM_MESSAGES } from 'src/app/shared/texts';

@Component({
  selector: 'app-type-cash',
  templateUrl: './type-cash.component.html',
  styleUrls: ['./type-cash.component.scss']
})
export class TypeCashComponent implements OnInit {

	@Input() accountControl
	BankTitles = BankTitles
	public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;

	constructor() { }

	ngOnInit() {
	}

	handler(value){
	  console.log(value,'value')
  }



}
