import { Component, OnInit, Input } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { SortableOptionsEnum } from 'src/app/models/types/sortableOptions.enum';
import { LeadModel } from 'src/app/models/client/lead.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { Router } from '@angular/router';
import { LeadsService } from 'src/app/services/leads.service';
import { TripPaymentComponent } from '../trip-payment/trip-payment.component';
import { MatDialog } from '@angular/material';
import { FinancePopComponent } from '../../finance/finance-pop/finance-pop.component';

@Component({
	selector: 'app-trip-travelers',
	templateUrl: './trip-travelers.component.html',
	styleUrls: ['./trip-travelers.component.scss']
})
export class TripTravelersComponent implements OnInit {

	@Input() trip: TripModel
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public pagination: PaginationModel = new PaginationModel(15);
	public travelers: any = [];
	public leads: LeadModel[]

	// public tableHeight = window.innerHeight - 300;

	constructor(
		public leadService: LeadsService,
		public router: Router,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		this.createColsTableItem();
		this.getTripLead()
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.NAME_HEB, '', ColSize.SMALL, 'textred', undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.NAME_EN, '', ColSize.SMALL, 'nameEng', undefined, new SortableColModel(SortableOptionsEnum.PassportFirst, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.PASSPORT, 'passportNumber', ColSize.SMALL, 'text', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.EMAIL, 'email', ColSize.MEDIUM, 'text', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.NOTES, '', ColSize.SMALL, 'notes', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.ROOMS, 'lead_preferences.room_split', ColSize.SMALL, 'room', undefined, undefined));
		// this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.TOTAL, '', ColSize.SMALL, 'text', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.PAYMENTS, '', ColSize.SMALL, 'payments', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRAVELER.PAY_STATUS, '', ColSize.SMALL, 'payStatus', undefined, undefined));
	}

	getTripLead() {
		this.leadService.getTripActiveClients(this.trip._id).subscribe(leads => {
			console.log(leads);
			this.leads = leads
			this.leads.sort((lead, b)=> {return lead['firstName'].localeCompare(b['firstName'])})
		})
	}

	public editClicked(el: any) { }
	public removeClicked(el: any) { }

	rowClicked(lead) {
		this.leadService.storeActiveClient(lead);
		const clientBool = lead.trips ? 'true' : 'false';
		this.router.navigateByUrl(`/app/trips/lead/${lead.leadId}?isClient=${clientBool}&personalDetails=${lead._id}`);
	}

	openPaymentPopAction(data){
	const openedDialog = this.dialog.open(FinancePopComponent, {
			position: {top: '20' },
			height: '773px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: false,
			data: {traveler:data,trip:this.trip}
		});

		openedDialog.afterClosed().subscribe((data: any) => {

		});
	}


}
