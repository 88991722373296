import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';

@Component({
	selector: 'app-custom-select',
	templateUrl: './custom-select.component.html',
	styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
	public showOptions: boolean = false;
	public selectedOption: string = 'תפקיד';
	public changeSubscriber: Subscription;

	@Input() type: number;
	@Input() control: FormControl;
	@Input() placeHolder: string;
	@Input() options: Array<{}>;
	@Input() nameProperty: string;
	@Output() selectAction = new EventEmitter();

	constructor(private filterService: FilterService, private fb: FormBuilder) {
	}



	ngOnInit() {
		this.setInitFilter()
	}

	public clickOutside(e: Event) {
		this.showOptions = false;
		e.stopPropagation();
	}

	public toggleOptions() {
		this.showOptions = !this.showOptions;
	}

	public selectOption(selectedOption) {
		this.saveFilter()
		this.selectAction.emit(selectedOption);
	}

	saveFilter() {
		switch (this.type) {
			case 1:
				this.filterService.currentDepartment = this.control.value ? this.control.value : undefined
				break;

			case 2:
				this.filterService.currentDestination = this.control.value ? this.control.value : undefined
				break;

			case 3:
				this.filterService.currentSupervisor = this.control.value ? this.control.value : undefined
				break;

			case 4:
				this.filterService.tripType = this.control.value ? this.control.value : undefined
				break;

			case 5:
				this.filterService.currentGuide = this.control.value ? this.control.value : undefined
				break;
		}
		this.selectAction.emit(this.control.value);
	}

	setInitFilter() {
		switch (this.type) {
			case 1:
				this.filterService.currentDepartment ? this.control.patchValue(this.filterService.currentDepartment) : undefined
				break;

			case 2:
				this.filterService.currentDestination ? this.control.patchValue(this.filterService.currentDestination) : undefined
				break;

			case 3:
				this.filterService.currentSupervisor ? this.control.patchValue(this.filterService.currentSupervisor) : undefined
				break;
			case 4:
				this.filterService.tripType ? this.control.patchValue(this.filterService.tripType) : undefined
				break;

			case 5:
				this.filterService.currentGuide ? this.control.patchValue(this.filterService.currentGuide) : undefined
				break;
		}
	}
	public getValue() {
		if (this.control.value) {
			let name
			if (this.type !== 3) {
				name = this.control.value.name;
			} else {
				name = this.control.value.firstName + ' ' + this.control.value.lastName
			}
			if (name.length >= 18 && this.type != 1) {
				name = name.substring(0, 18) + '...';
			}
			return name;
		}
	}
}
