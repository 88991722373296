import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TableColModel } from '../../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { NewActivityComponent } from '../new-activity/new-activity.component';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { ActivityModel } from 'src/app/models/trip/activity.model';
import { ActivityService } from '../../../services/activity.service';

@Component({
	selector: 'app-activities-table',
	templateUrl: './activities-table.component.html',
	styleUrls: ['./activities-table.component.scss']
})
export class ActivitiesTableComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 420;
	public cols: TableColModel[] = [];
	@Input() activities: ActivityModel[] = [];
	@Output() edited = new EventEmitter();
	private subscriptions: Subscription[] = [];

	constructor(private dialog: MatDialog, private activityService: ActivityService) { }

	ngOnInit() {
		this.createColsTableItem();
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.ACTIVITY.NAME, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.ACTIVITY.DESCRIPTION, 'description', ColSize.MEDIUM, undefined, undefined, undefined));
	}

	public editClicked(activity: ActivityModel) {
		const openedDialog = this.dialog.open(NewActivityComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { activity }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.edited.emit();
			}
		});
	}

	public removeClicked(hotel: any) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveActivity,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.removeActivity(hotel._id);
			}
		});
	}

	private removeActivity(activityId: string) {
		const subscription = this.activityService.deleteActivity(activityId).subscribe(success => {
			this.edited.emit();
		});

		this.subscriptions.push(subscription);
	}

}
