import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LeadStatusEnum } from '../../models/types/leadStatus.enum';
import { LEAD_TYPES } from '../../shared/texts';
import { DepartmentService } from '../../services/department.service';
import { DepartmentModel } from '../../models/client/department.mode';
import { DestinationService } from '../../services/destination.service';
import { DestinationModel } from '../../models/client/destination.model';
import { NewLeadComponent } from '../../components/lead/components/new-lead/new-lead.component';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import { TripTypeService } from 'src/app/services/trip-type.service';

@Component({
	selector: 'app-leads-page',
	templateUrl: './leads-page.component.html',
	styleUrls: ['./leads-page.component.scss']
})
export class LeadsPageComponent implements OnInit {

	public showingPage: LeadStatusEnum = LeadStatusEnum.New;
	public leadTypes = LEAD_TYPES;
	public leadStatusEnum = LeadStatusEnum;
	public departments: DepartmentModel[] = [];
	public destinations: DestinationModel[] = [];
	public tableHeight = window.innerHeight - 475;
	public supervisors: [] = []
	public tripTypes: [] = []

	constructor(public tripTypeService: TripTypeService, public filterService: FilterService, public userService: UserService, private filterSerivce: FilterService, private dialog: MatDialog, private router: Router, private departmentService: DepartmentService, private destinationService: DestinationService) {
		this.getUsersMinimize()
	}

	ngOnInit() {
		this.getTripTypes()
		// this.filterService.clearFilters()
		this.getTableFilters();
		this.showingPage = this.filterSerivce.currentLeadTab
	}

	public changeShowingPage(toPage: LeadStatusEnum) {
		this.filterSerivce.clearFilters()
		this.showingPage = toPage;
	}

	private getUsersMinimize() {
		this.userService.getUsersMinimize().subscribe(users => {
			let usersOriginal = users
			this.supervisors = usersOriginal.filter((user, i) => {
				return user['status'] === 1
			})
		});
	}

	private getTripTypes() {
		this.tripTypeService.all().subscribe(res => {
			this.tripTypes = res
		})
	}

	public goToLead(id: string) {
		this.router.navigateByUrl(`app/leads/${id}`);
	}

	public newLeadClicked() {
		const openedDialog = this.dialog.open(NewLeadComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {
				destinations: this.destinations,
				departments: this.departments
			}
		});

		openedDialog.afterClosed().subscribe((addedId: string) => {
			if (addedId) {
				this.goToLead(addedId);
			}
		});
	}

	private getTableFilters() {
		// this.destinationService.all().subscribe((destinations: DestinationModel[]) => {
		// 	console.log(this.destinations,'destinations')
		// 	this.destinations = destinations;
		// });

		this.departmentService.notAdministrative().subscribe((departments: DepartmentModel[]) => {
			// const dep: any = { name: 'מחלקה' }
			// departments.unshift(dep)
			console.log('departments', departments)
			this.departments = departments;
		});
	}


}
