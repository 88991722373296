import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-trip-notebook-note-item',
  templateUrl: './trip-notebook-note-item.component.html',
  styleUrls: ['./trip-notebook-note-item.component.scss']
})
export class TripNotebookNoteItemComponent implements OnInit {

  constructor() { }

  @Input() note
  @Input() bold
  @Output() emitRemove = new EventEmitter()
  @Output() emitEdit = new EventEmitter()

  ngOnInit() {
  }

  removeNote(){
	  this.emitRemove.emit('remove')
  }

  editNote(){
	this.emitEdit.emit('edit')
}

}
