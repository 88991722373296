import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FinanceAddPaymentPopComponent } from '../finance-add-payment-pop/finance-add-payment-pop.component';
import { CurrencyOptionsHedlines } from 'src/app/models/trip/finance.enum';

@Component({
  selector: 'app-finance-specifications-tab',
  templateUrl: './finance-specifications-tab.component.html',
  styleUrls: ['./finance-specifications-tab.component.scss']
})
export class FinanceSpecificationsTabComponent implements OnInit {

  constructor(
	private dialog: MatDialog,
  ) { }

  color = 'accent';

  @Input() travelerFinanceData;
  @Input() tripCurrency:number;
  @Output() updateEmit = new EventEmitter();
  @Output() updateCheckboxEmit = new EventEmitter();
  public CurrencyOptionsHedlines = CurrencyOptionsHedlines


  ngOnInit() {
  }

  addPaymentDialog(){
	const dialog = this.dialog.open(FinanceAddPaymentPopComponent, {
		position: {top: '20' },
		height: '440px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: true,
		data:{}
	});
	dialog.afterClosed().subscribe((data: any) => {
		if(data){
			this.travelerFinanceData.needToPay.push(data)
			this.updateEmit.emit(this.travelerFinanceData)
		}
	});
  }

  checkboxChanged(){
	this.updateCheckboxEmit.emit(this.travelerFinanceData)
  }

}
