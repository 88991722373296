import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-storage-page',
	templateUrl: './storage-page.component.html',
	styleUrls: ['./storage-page.component.scss']
})
export class StoragePageComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
