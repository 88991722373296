import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterService } from 'src/app/services/filter.service';

@Component({
	selector: 'app-custom-filter-input',
	templateUrl: './custom-filter-input.component.html',
	styleUrls: ['./custom-filter-input.component.scss']
})
export class CustomFilterInputComponent implements OnInit {
	@Input() control: FormControl;
	@Input() placeHolder: string;
	@Output() searchAction = new EventEmitter();
	@Output() clearAction = new EventEmitter();
	constructor(private filterService: FilterService) {

	}

	ngOnInit() {
		this.filterService.searchText ? this.control.patchValue(this.filterService.searchText) : undefined
	}



	public searchClicked() {
		this.filterService.searchText = this.control.value
		this.searchAction.emit();
	}

	public clearClicked() {
		this.clearAction.emit();
	}
}
