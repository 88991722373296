import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

	constructor(private router: Router, private authService: AuthService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const jwtToken = this.authService.getToken();

		const cloned = req.clone({
			setHeaders: {
				Authorization: `Bearer ${jwtToken}`,
				'Tom-App-Auth': 'NoJ20bK27$vj5W3Ome41hEy04f*FC1988'
			}
		});
		if (req.url.includes('ipinfo')) {
			return next.handle(req);
		}
		return next.handle(cloned);
	}
}
