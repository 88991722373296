import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appHebrewOnly]'
})
export class HebrewOnlyDirective {

	// Allow hebrew letters
	readonly regex = new RegExp(/^[\u0590-\u05FF ]*$/);
	// Allow key codes for special events. Reflect :
	// Backspace, tab, end, home
	private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];

	constructor(private el: ElementRef) {
	}

	@HostListener('keydown', [ '$event' ])
	onKeyDown(event: KeyboardEvent) {
		// Allow Backspace, tab, end, and home keys
		const key = this.specialKeys.indexOf(event.key);
		if ( key === 4 ) {
			return event.preventDefault();
		}

		if ( key !== -1 ) {
			return;
		}
		const current: string = this.el.nativeElement.value;
		const next: string = current.concat(event.key);
		if ( next && !String(next).match(this.regex) ) {
			event.preventDefault();
		}
	}
}
