import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'utcTimeTask'
})
export class UtcTimeTaskPipe implements PipeTransform {

	transform(dueDate: string, args?: any): any {
		return moment(dueDate).utc().format('HH:mm');
	}

}
