import { CustomValidatorsService } from "./../../../services/custom-validators.service";
import {
	ClientInfoModel,
	ClientModel,
} from "./../../../models/client/client.model";
import * as _ from "lodash";
import {
	Component,
	Input,
	OnInit,
	ChangeDetectorRef,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";
import { FORM_MESSAGES } from "../../../shared/texts/form.messages";
import {
	CLIENT_REGISTRATION,
	FIELDS_LABELS,
	FIELDS_OPTIONS,
	FIELDS_PLACEHOLDERS,
} from "../../../shared/texts/client-registration-info.messages";
import { FlightFoodEnum } from "../../../models/types/flightFood.enum";
import { CLIENT_TITLES } from "../../../models/client/clientTitles";
import { CustomValidators } from "ngx-custom-validators";
import { ClientService } from "../../../services/client.service";
import * as moment from "moment";
import formatPhoneNumberUtil from "src/app/utils/formatPhoneNumber";

@Component({
	selector: "app-client-personal-details",
	templateUrl: "./client-personal-details.component.html",
	styleUrls: ["./client-personal-details.component.scss"],
})
export class ClientPersonalDetailsComponent implements OnInit {
	@Input() withSpecial: boolean = true;
	@Input() client: ClientModel;
	public today = new Date();
	public formPersonal: FormGroup;
	public formPassport: FormGroup;
	public formSpecial: FormGroup;
	public INVALID_FORM = FORM_MESSAGES;
	public FORM_FIELDS = CLIENT_REGISTRATION;
	public FIELDS_PLACEHOLDERS = FIELDS_PLACEHOLDERS;
	public FIELDS_LABELS = FIELDS_LABELS;
	public FIELDS_OPTIONS = FIELDS_OPTIONS;
	public CLIENT_TITLES = CLIENT_TITLES;
	public FLIGHT_FOOD = FlightFoodEnum;
	public editModeOn: boolean = false;
	public backgroundColors: string[]; // = ['white', 'white', 'white', '#f8f8f8']
	public currentlyShownModel: ClientInfoModel;
	public currentIndexShow: number;
	public saveButtonValid: boolean = false;
	public lastFormPersonal: FormGroup;
	public lastFormPassport: FormGroup;
	public lastFormSpecial: FormGroup;

	constructor(
		private fb: FormBuilder,
		private customValidatorsService: CustomValidatorsService,
		private clientService: ClientService
	) {}

	get personalControls() {
		return this.formPersonal.controls;
	}

	get passportControls() {
		return this.formPassport.controls;
	}

	get specialControls() {
		return this.formSpecial.controls;
	}

	public get relatives() {
		return this.client.relatives;
	}
	ngOnInit() {
		console.log(this.client);

		this.currentlyShownModel = this.client.personalDetails;
		this.currentlyShownModel.phoneNumber = formatPhoneNumberUtil(this.currentlyShownModel.phoneNumber);
		this.createForm(this.currentlyShownModel);
		this.backgroundColors = Array(this.relatives.length + 1).fill("white");
		this.backgroundColors[0] = "#f8f8f8";

		// this.subscribeToPhoneNumberValueChange();
	}

	// private subscribeToPhoneNumberValueChange() {
	// 	this.formPersonal.controls["phone"].valueChanges.subscribe(
	// 		(newValue) => {
	// 			this.formPersonal.controls["phone"].setValue(
	// 				formatPhoneNumberUtil(newValue)
	// 			);
	// 		}
	// 	);
	// }

	public changeValue(newValue, inputName) {
		this.saveButtonValid = true;
	}
	public cancelChangeValue() {
		// console.log("first", this.lastFormPersonal.value);
		this.formPassport = this.lastFormPassport;
		this.formPersonal = this.lastFormPersonal;
		this.formSpecial = this.lastFormSpecial;
		this.editModeOnChange();
	}
	public updateForm() {
		const newItem = {
			_id: this.currentlyShownModel._id,
			...this.formPersonal.value,
			...this.formPassport.value,
			...this.formSpecial.value,
		} as ClientInfoModel;

		newItem.phoneNumber = formatPhoneNumberUtil(newItem.phoneNumber);

		this.clientService.updatePersonalDetails(newItem).subscribe((resp) => {
			if (this.currentIndexShow === 0) {
				this.client.personalDetails = resp;
			} else {
				this.relatives[this.currentIndexShow - 1] = resp;
			}
			this.editModeOnChange();
		});
	}
	public editModeOnChange(mode = null) {
		if (mode === "editMode") {
			this.lastFormPersonal = _.cloneDeep(this.formPersonal);
			this.lastFormPassport = _.cloneDeep(this.formPassport);
			this.lastFormSpecial = _.cloneDeep(this.formSpecial);
		}

		this.editModeOn = !this.editModeOn;
		if (this.editModeOn) {
			this.formPassport.enable();
			this.formPersonal.enable();
		} else {
			this.formPassport.disable();
			this.formPersonal.disable();
		}

		if (this.withSpecial) {
			if (this.editModeOn) {
				this.formSpecial.enable();
			} else {
				this.formSpecial.disable();
			}
		}
		this.saveButtonValid = false;
	}
	private createForm(detailsToShow: ClientInfoModel) {
		const {
			airline,
			frequentNumber,
			title,
			food,
			firstName,
			lastName,
			email,
			phoneNumber,
			homePhoneNumber,
			isMale,
			dateOfBirth,
			idNumber,
			homeAddress,
			contactUser,
			contactUserPhone,
			passportFirstName,
			passportLastName,
			passportNumber,
			passportExpiryDate,
			passportReleaseDate,
			client,
		} = this.client.personalDetails;

		this.formPersonal = this.fb.group({
			// title: new FormControl(title, Validators.required),
			firstName: new FormControl(firstName, Validators.required),
			lastName: new FormControl(lastName, Validators.required),
			email: new FormControl(
				email,
				Validators.compose([
					Validators.required,
					CustomValidators.email,
				])
			),
			phoneNumber: new FormControl(formatPhoneNumberUtil(phoneNumber), [
				Validators.required,
				Validators.maxLength(11),
				Validators.minLength(10),
				this.customValidatorsService.validateCellPhoneNumber,
			]),
			isMale: new FormControl(isMale, Validators.required),
			dateOfBirth: new FormControl(
				dateOfBirth,
				Validators.compose([
					Validators.required,
					this.customValidatorsService.issuingDateValidator,
				])
			),
			idNumber: new FormControl(
				idNumber,
				Validators.compose([
					Validators.required,
					this.customValidatorsService.validateIsraelId,
					Validators.minLength(9),
				])
			),
			homeAddress: new FormControl(homeAddress),
			homePhoneNumber: new FormControl(homePhoneNumber),
			contactUser: new FormControl(contactUser),
			contactUserPhone: new FormControl(contactUserPhone),
		});

		this.formPassport = this.fb.group({
			title: new FormControl(title),
			passportFirstName: new FormControl(passportFirstName),
			passportLastName: new FormControl(passportLastName),
			passportNumber: new FormControl(passportNumber),
			passportReleaseDate: new FormControl(passportReleaseDate, [
				this.customValidatorsService.issuingDateValidator,
			]),
			passportExpiryDate: new FormControl(passportExpiryDate, [
				this.customValidatorsService.expirationDateValidator,
			]),
		});

		this.formPassport.disable();
		this.formPersonal.disable();

		if (this.withSpecial) {
			this.formSpecial = this.fb.group({
				frequentNumber: new FormControl(
					frequentNumber
				),
				airline: new FormControl(airline),
				// chairWindow: new FormControl(true, Validators.required),
				food: new FormControl(
					food || this.FLIGHT_FOOD.Regular,
				),
				// couplesBed: new FormControl(true, Validators.required),
				// lonelySingleBedroom: new FormControl(true, Validators.required),
			});

			this.formSpecial.disable();
		}
		this.formPassport.reset(this.client.personalDetails);
		this.formPersonal.reset(this.client.personalDetails);
	}

	public onRelativesIndividualClicked(index: number) {
		this.currentIndexShow = index;
		this.currentlyShownModel =
			index == 0
				? this.client.personalDetails
				: this.client.relatives[index - 1];
		this.formPassport.reset(this.currentlyShownModel);
		this.formPersonal.reset(this.currentlyShownModel);
		this.formSpecial.reset(this.currentlyShownModel);
		this.backgroundColors.fill("white");
		this.backgroundColors[index] = "#f8f8f8";
	}

	public getStyle(i: number) {
		const color = this.backgroundColors[i];
		const weight = color == "white" ? "normal" : "bold";
		return { "font-weight": weight, "background-color": color };
	}
	public getCroppedName(name: string) {
		if (name.length > 6) return name.slice(0, 6) + "...";
		return name;
	}
}
