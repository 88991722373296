import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import * as moment from 'moment';
@Component({
	selector: 'app-lead-no-answer-success',
	templateUrl: './lead-no-answer-success.component.html',
	styleUrls: [ './lead-no-answer-success.component.scss' ]
})
export class LeadNoAnswerSuccessComponent implements OnInit {
	@Input() dueDate: string;
	public time: string;
	public date: string;
	@Output() close = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
		this.date = moment.utc(this.dueDate).format('DD.MM.YY');
		this.time = moment.utc(this.dueDate).format('HH:mm');
	}

	public closeClicked() {
		this.close.emit(true);
	}
}
