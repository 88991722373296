import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { PermissionTypesEnum } from '../models/types/permissionTypesEnum.enum';
import { UserModel } from '../models/client/user.model';
import { RoleModel } from '../models/client/role.model';

@Directive({
	selector: '[appShowByPermissions]'
})
export class ShowByPermissionsDirective implements OnDestroy {
	private userSubscription: Subscription;

	constructor(
		private dataService: DataService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) {
	}

	@Input() set appShowByPermissions(permissionType: PermissionTypesEnum) {
		this.viewContainer.clear();
		this.userSubscription = this.dataService.globalUser.subscribe((user: UserModel) => {
			if ( user._id ) {
				const hasPermission = this.dataService.Permissions.some(per => per.enumValue === permissionType && per.level >= (user.role as RoleModel).level);
				if ( hasPermission ) {
					this.viewContainer.createEmbeddedView(this.templateRef);
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.userSubscription.unsubscribe();
	}
}
