import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-trip-room-empty',
  templateUrl: './trip-room-empty.component.html',
  styleUrls: ['./trip-room-empty.component.scss']
})
export class TripRoomEmptyComponent implements OnInit {
  @Input() type: string = '';
  constructor() { }

  ngOnInit() {
  }

}
