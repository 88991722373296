import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableColModel } from '../../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { NewCityComponent } from '../new-city/new-city.component';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { CityService } from 'src/app/services/city.service';
import { CityModel } from 'src/app/models/trip/city.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-cities-table',
	templateUrl: './cities-table.component.html',
	styleUrls: ['./cities-table.component.scss']
})
export class CitiesTableComponent implements OnInit {


	public tableHeight: number = window.innerHeight - 430;
	public cols: TableColModel[] = [];
	@Input() cities: CityModel[];
	@Output() edited = new EventEmitter();

	constructor(private dialog: MatDialog, private cityService: CityService, private router: Router) { }

	ngOnInit() {
		this.createColsTableItem();
	}

	public rowClicked(city: any) {
		this.router.navigateByUrl(`/app/storage/city/${city._id}`);
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.STATE, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.HOTELS_NUM, 'hotels', ColSize.MEDIUM, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.TOURS_NUM, 'activities', ColSize.MEDIUM, undefined, undefined, undefined));
	}

	public editClicked(city: CityModel) {
		const openedDialog = this.dialog.open(NewCityComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { city }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.edited.emit();
			}
		});
	}

	public removeClicked(city: CityModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGuide,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.removeCity(city._id);
			}
		});
	}

	private removeCity(cityId: string) {
		this.cityService.removeCity(cityId).subscribe(success => {
			this.edited.emit();
		});
	}

}
