import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-room-autocomplete',
  templateUrl: './room-autocomplete.component.html',
  styleUrls: ['./room-autocomplete.component.scss']
})
export class RoomAutocompleteComponent implements OnInit {

  constructor(private fb: FormBuilder) { }

  @Input() room: string;
  @Input() placeHolder: string = 'Room type';
  @Input() control: FormControl;
  @Input() class: string; // options are: 'location' or nothing

  public readonly INVALID_FORM = FORM_MESSAGES;

  roomsType = ['Standart','Superior','Deluxe','Junior Suite','Other']
  filteredOptions:string[]
  form:FormGroup

  ngOnInit() {
	this.buildForm()

  if(this.control.value){
	  this.form.patchValue({
		  roomDescription:this.control.value
	  })
  }
}


  private buildForm() {
	this.form = this.fb.group({
		roomDescription: ['', Validators.required ]
	});
}

public handler(value): void {
	this.control.setValue(value);
	// this.form.controls.roomDescription.setValue(event.option.value);
}

}
