import { ClientSoldTripsComponent } from './components/client/client-sold-trips/client-sold-trips.component';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeAu from '@angular/common/locales/en-AU';

import { CustomFormsModule } from 'ngx-custom-validators';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatMenuModule} from '@angular/material/menu';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import {
	DateAdapter,
	MatAutocompleteModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatFormFieldModule,
	MatInputModule,
	MatNativeDateModule,
	MatRadioModule,
	MatSelectModule,
	MatTabsModule
} from '@angular/material';
import { DateFormat } from './shared/date-format.service';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { BidiModule } from '@angular/cdk/bidi';
import { ClickOutsideModule } from 'ng-click-outside';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptorService } from './shared/jwt-interceptor.service';
import { ErrorInterceptorService } from './shared/error-interceptor.service';
import { CanActivateLoggedUserService } from './shared/can-activate-logged-user.service';
import { CanActivateNotLoggedUserService } from './shared/can-activate-not-logged-user.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LeadsPageComponent } from './pages/leads-page/leads-page.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationManagmentComponent } from './components/notification-managment/notification-managment.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomFilterInputComponent } from './components/custom-filter-input/custom-filter-input.component';
import { TableComponent } from './components/tableComponents/table/table.component';
import { UserComponent } from './components/user/user.component';
import { PropByStringPipe } from './pipes/prop-by-string.pipe';
import { ClientsPageComponent } from './pages/clients-page/clients-page.component';

import { LeadsNewComponent } from './components/lead/components/leads-new/leads-new.component';
import { LeadsSaleComponent } from './components/lead/components/leads-sale/leads-sale.component';
import { LeadsFutureComponent } from './components/lead/components/leads-future/leads-future.component';
import { LeadsActiveComponent } from './components/lead/components/leads-active/leads-active.component';
import { NewLeadComponent } from './components/lead/components/new-lead/new-lead.component';
import { DestinationAutocompleteComponent } from './components/autocompletes/destination-autocomplete/destination-autocomplete.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { LeadPageComponent } from './pages/lead-page/lead-page.component';
import { LeadIconTextComponent } from './components/lead/components/lead-icon-text/lead-icon-text.component';
import { LeadNewCardComponent } from './components/lead/components/lead-new-card/lead-new-card.component';
import { LeadNewComponent } from './components/lead/components/lead-new/lead-new.component';
import { LeadSaleComponent } from './components/lead/components/lead-sale/lead-sale.component';
import { LeadNoAnswerComponent } from './components/lead/components/lead-no-answer/lead-no-answer.component';
import { LeadNoAnswerSuccessComponent } from './components/lead/components/lead-no-answer-success/lead-no-answer-success.component';
import { LeadMoveToSaleComponent } from './components/lead/components/lead-move-to-sale/lead-move-to-sale.component';
import { LeadNoAnswerPopupComponent } from './components/lead/popups/lead-no-answer-popup/lead-no-answer-popup.component';
import { LeadMoveToSalePopupComponent } from './components/lead/popups/lead-move-to-sale-popup/lead-move-to-sale-popup.component';
import { LeadMoveToSaleSuccessComponent } from './components/lead/components/lead-move-to-sale-success/lead-move-to-sale-success.component';
import { LeadMoveToFuturePopupComponent } from './components/lead/popups/lead-move-to-future-popup/lead-move-to-future-popup.component';
import { LeadMoveToFutureComponent } from './components/lead/components/lead-move-to-future/lead-move-to-future.component';
import { LeadCancelPopupComponent } from './components/lead/popups/lead-cancel-popup/lead-cancel-popup.component';
import { LeadCancelComponent } from './components/lead/components/lead-cancel/lead-cancel.component';
import { LeadCancelSuccessComponent } from './components/lead/components/lead-cancel-success/lead-cancel-success.component';
import { LeadMoveToFutureSuccessComponent } from './components/lead/components/lead-move-to-future-success/lead-move-to-future-success.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { LeadBaseInfoComponent } from './components/lead/components/lead-base-info/lead-base-info.component';
import { LeadSaleProcessComponent } from './components/lead/components/lead-sale-process/lead-sale-process.component';
import { ClientLastCallsComponent } from './components/lead/components/client-last-calls/client-last-calls.component';
import { NoteRecordComponent } from './components/lead/components/note-record/note-record.component';
import { TaskViewComponent } from './components/task-view/task-view.component';
import { LeadTasksComponent } from './components/lead/components/lead-tasks/lead-tasks.component';
import { ClientPageComponent } from './pages/client-page/client-page.component';
import { ClientBaseInfoComponent } from './components/client/client-base-info/client-base-info.component';
import { ClientNotesComponent } from './components/client/client-notes/client-notes.component';
import { ClientOldTripComponent } from './components/client/client-old-trip/client-old-trip.component';
import { ClientFutureTripComponent } from './components/client/client-future-trip/client-future-trip.component';
import { PhoneClientAutocompleteComponent } from './components/autocompletes/phone-client-autocomplete/phone-client-autocomplete.component';
import { TimeAutocompleteComponent } from './components/autocompletes/time-autocomplete/time-autocomplete.component';
import { ClientCancelLeadComponent } from './components/client/client-cancel-lead/client-cancel-lead.component';
import { ClientSaleTripsComponent } from './components/client/client-sale-trips/client-sale-trips.component';
import { ClientFilesComponent } from './components/client/client-files/client-files.component';
import { FileRowComponent } from './components/file-row/file-row.component';
import { FileNewComponent } from './components/file-new/file-new.component';
import { LatinOnlyDirective } from './directives/latin-only.directive';
import { HebrewOnlyDirective } from './directives/hebrew-only.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ShowByPermissionsDirective } from './directives/show-by-permissions.directive';
import { ClientPersonalDetailsComponent } from './components/client/client-personal-details/client-personal-details.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { NewTaskComponent } from './components/new-task/new-task.component';
import { DoneTasksComponent } from './components/done-tasks/done-tasks.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ClientAutocompleteComponent } from './components/autocompletes/client-autocomplete/client-autocomplete.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { TaskNewDuedatePopupComponent } from './components/task-new-duedate-popup/task-new-duedate-popup.component';
import { TaskNotificationComponent } from './components/task-notification/task-notification.component';
import { UtcTimeTaskPipe } from './pipes/utc-time-task.pipe';
import { UtcDateTaskPipe } from './pipes/utc-date-task.pipe';
import { TripsPageComponent } from './pages/trips-page/trips-page.component';
import { TripPageComponent } from './pages/trip-page/trip-page.component';
import { TripClosedGroupsComponent } from './components/trip/trip-closed-groups/trip-closed-groups.component';
import { TripFixComponent } from './components/trip/trip-fix/trip-fix.component';
import { TripFitComponent } from './components/trip/trip-fit/trip-fit.component';
import { TripIncentiveComponent } from './components/trip/trip-incentive/trip-incentive.component';
import { TripTravelersComponent } from './components/trip/trip-travelers/trip-travelers.component';
import { TripPricingComponent } from './components/trip/trip-pricing/trip-pricing.component';
import { TripDocumentsComponent } from './components/trip/trip-documents/trip-documents.component';
import { TripScheduleComponent } from './components/trip/trip-schedule/trip-schedule.component';
import { TripScheduleDayComponent } from './components/trip/trip-schedule-day/trip-schedule-day.component';
import { TripScheduleDayItemComponent } from './components/trip/trip-schedule-day-item/trip-schedule-day-item.component';
import { TripNewTransportComponent } from './components/trip/trip-new-transport/trip-new-transport.component';
import { TripNewHotelComponent } from './components/trip/trip-new-hotel/trip-new-hotel.component';
import { TripNewActivityComponent } from './components/trip/trip-new-activity/trip-new-activity.component';
import { TripNewFlightComponent } from './components/trip/trip-new-flight/trip-new-flight.component';
import { TripNewComponent } from './components/trip/trip-new/trip-new.component';
import { TripNewPageComponent } from './pages/trip-new-page/trip-new-page.component';
import { CityAutocompleteComponent } from './components/autocompletes/city-autocomplete/city-autocomplete.component';
import { HebDatePipe } from './pipes/heb-date.pipe';
import { HotelAutocompleteComponent } from './components/autocompletes/hotel-autocomplete/hotel-autocomplete.component';
import { MealPipe } from './pipes/meal.pipe';
import { StorageDepartmentPageComponent } from './pages/storage-department-page/storage-department-page.component';
import { StorageDepartmentTablePageComponent } from './pages/storage-department-table-page/storage-department-table-page.component';
import { StorageDestinationTablePageComponent } from './pages/storage-destination-table-page/storage-destination-table-page.component';
import { StorageCityTablePageComponent } from './pages/storage-city-table-page/storage-city-table-page.component';
import { HotelsTableComponent } from './components/storage/hotels-table/hotels-table.component';
import { ActivitiesTableComponent } from './components/storage/activities-table/activities-table.component';
import { NewHotelComponent } from './components/storage/new-hotel/new-hotel.component';
import { NewCountryComponent } from './components/storage/new-country/new-country.component';
import { NewCityComponent } from './components/storage/new-city/new-city.component';
import { NewActivityComponent } from './components/storage/new-activity/new-activity.component';
import { TripBuildPageComponent } from './pages/trip-build-page/trip-build-page.component';
import { GuidesTableComponent } from './components/storage/guides-table/guides-table.component';
import { DestinationsTableComponent } from './components/storage/destinations-table/destinations-table.component';
import { NewGuideComponent } from './components/storage/new-guide/new-guide.component';
import { PastTripsPageComponent } from './pages/past-trips-page/past-trips-page.component';
import { EmailAutocompleteComponent } from './components/autocompletes/email-autocomplete/email-autocomplete.component';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { SuppliersTableComponent } from './components/storage/suppliers-table/suppliers-table.component';
import { CitiesTableComponent } from './components/storage/cities-table/cities-table.component';
import { SelectGuideComponent } from './components/storage/select-guide/select-guide.component';
import { StoragePageComponent } from './pages/storage-page/storage-page.component';
import { StorageGuidesPageComponent } from './pages/storage-guides-page/storage-guides-page.component';
import { StorageAirlinesPageComponent } from './pages/storage-airlines-page/storage-airlines-page.component';
import { LeadMoveToRegisterComponent } from './components/lead/popups/lead-move-to-register/lead-move-to-register.component';
import { TripNewMealComponent } from './components/trip/trip-new-meal/trip-new-meal.component';
import { RoomAutocompleteComponent } from './components/autocompletes/room-autocomplete/room-autocomplete.component';
import { LeadMoveToActiveComponent } from './components/lead-move-to-active/lead-move-to-active.component';
import { TripLeadPage } from './pages/trip-lead-page/trip-lead-page.component';
import { TripNotesComponent } from './components/trip/trip-notes/trip-notes.component';
import { TripNotesAndRequestsComponent } from './components/trip/trip-notes-and-requests/trip-notes-and-requests.component';
import { TripPaymentComponent } from './components/trip/trip-payment/trip-payment.component';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import { TripManageComponent } from './components/trip/trip-manage/trip-manage.component';
import { TripBalanceComponent } from './components/trip/trip-balance/trip-balance.component'
import {MatExpansionModule} from '@angular/material/expansion';
import { TripNotebookComponent } from './pages/trip-notebook/trip-notebook.component';
import { TripNotebookBundleComponent } from './components/trip/trip-notebook-bundle/trip-notebook-bundle.component';
import { TripNotebookScheduleComponent } from './components/trip/trip-notebook-schedule/trip-notebook-schedule.component';
import { FlightsDestinationsAutocompleteComponent } from './components/autocompletes/flights-destinations-autocomplete/flights-destinations-autocomplete.component';
import { BundlePopComponent } from './components/notebook/bundle-pop/bundle-pop.component';
import { TripNotebookGeneralDetailsComponent } from './components/trip/trip-notebook-general-details/trip-notebook-general-details.component';
import { TripNotebookPricingComponent } from './components/trip/trip-notebook-pricing/trip-notebook-pricing.component';
import { TripNotebookNoteItemComponent } from './components/trip/trip-notebook-note-item/trip-notebook-note-item.component';
import { TripNotebookNoteItemScheduleComponent } from './components/trip/trip-notebook-note-item-schedule/trip-notebook-note-item-schedule.component';
import { TripPricingPageComponent } from './pages/trip-pricing-page/trip-pricing-page.component';
import { PricingStepperComponent } from './components/pricing/pricing-stepper/pricing-stepper.component';
import { PricingGeneralDetailsComponent } from './components/pricing/pricing-general-details/pricing-general-details.component';
import { PricingFlightsComponent } from './components/pricing/pricing-flights/pricing-flights.component';
import { FlightPopupComponent } from './components/pricing/popups/flight-popup/flight-popup.component';
import { PricingGroundServiceComponent } from './components/pricing/pricing-ground-service/pricing-ground-service.component';
import { GroundServicePopupComponent } from './components/pricing/popups/ground-service-popup/ground-service-popup.component';
import { PricingTravelFreeComponent } from './components/pricing/pricing-travel-free/pricing-travel-free.component';
import { PricingGroupExpensesComponent } from './components/pricing/pricing-group-expenses/pricing-group-expenses.component';
import { GroupExpensesPopupComponent } from './components/pricing/popups/group-expenses-popup/group-expenses-popup.component';
import { PricingGuideExpensesComponent } from './components/pricing/pricing-guide-expenses/pricing-guide-expenses.component';
import { PricingSummaryComponent } from './components/pricing/pricing-summary/pricing-summary.component';
import { PricingCashExpensesComponent } from './components/pricing/pricing-cash-expenses/pricing-cash-expenses.component';
import { CashExpensesPopupComponent } from './components/pricing/popups/cash-expenses-popup/cash-expenses-popup.component';
import { GuideExpensesPopupComponent } from './components/pricing/popups/guide-expenses-popup/guide-expenses-popup.component';
import { TripSummaryItemComponent } from './components/pricing/trip-summary-item/trip-summary-item.component';
import { PricingImportWarningPopupComponent } from './components/pricing/popups/pricing-import-warning-popup/pricing-import-warning-popup.component';
import { TripNotebookPricingTermsComponent } from './components/trip/trip-notebook-pricing-terms/trip-notebook-pricing-terms.component';
import { PricingActionsComponenetsComponent } from './components/pricing/pricing-actions-componenets/pricing-actions-componenets.component';
import { LeadSoldComponent } from './components/lead/components/lead-sold/lead-sold.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { TripRoomDialogComponent } from './components/trip/trip-room-dialog/trip-room-dialog.component';
import { TripRoomContainerComponent } from './components/trip/trip-room-container/trip-room-container.component';
import { TripRoomEmptyComponent } from './components/trip/trip-room-empty/trip-room-empty.component';
import { AddRelativeDialogComponent } from './components/client/add-relative-dialog/add-relative-dialog.component';
import { AddRelativePersonalDetailsComponent } from './components/client/add-relative-personal-details/add-relative-personal-details.component';
import { AddRelativePassportDetailsComponent } from './components/client/add-relative-passport-details/add-relative-passport-details.component';
import { AddRelativeSpecialRequestsComponent } from './components/client/add-relative-special-requests/add-relative-special-requests.component';

import { OneSelectTripComponent } from './components/lead/popups/lead-move-to-register/components/one-select-trip/one-select-trip.component';
import { TwoAddRelativesComponent } from './components/lead/popups/lead-move-to-register/components/two-add-relatives/two-add-relatives.component';
import { ThreeSuccessMessageComponent } from './components/lead/popups/lead-move-to-register/components/three-success-message/three-success-message.component';
import { RemoveClientMemberDialogComponent } from './components/client/remove-client-member-dialog/remove-client-member-dialog.component'
import { OverlayContainer } from '@angular/cdk/overlay';
import { FinancePopComponent } from './components/finance/finance-pop/finance-pop.component';
import { FinanceSpecificationsTabComponent } from './components/finance/finance-specifications-tab/finance-specifications-tab.component';
import { FinancePayedTabComponent } from './components/finance/finance-payed-tab/finance-payed-tab.component';
import { FinanceAddPaymentPopComponent } from './components/finance/finance-add-payment-pop/finance-add-payment-pop.component';
import { TypeBankTrnasferComponent } from './components/trip/trip-payment/type-bank-trnasfer/type-bank-trnasfer.component';
import { TypeChequeComponent } from './components/trip/trip-payment/type-cheque/type-cheque.component';
import { TypeCreditCardComponent } from './components/trip/trip-payment/type-credit-card/type-credit-card.component';
import { TypeCashComponent } from './components/trip/trip-payment/type-cash/type-cash.component';
import { ProviderPaymentsPageComponent } from './pages/provider-payments-page/provider-payments-page.component';
import { ProviderTabsFilterComponent } from './components/finance/provider-tabs-filter/provider-tabs-filter.component';
import { ProviderTableRowComponent } from './components/finance/provider-table-row/provider-table-row.component';
import { ProvidersVouchersTabComponent } from './components/finance/providers-vouchers-tab/providers-vouchers-tab.component';
import { ProvidersHeadrowComponent } from './components/finance/providers-headrow/providers-headrow.component';
import { ProvidersVouchersWaitingTabComponent } from './components/finance/providers-vouchers-waiting-tab/providers-vouchers-waiting-tab.component';
import { ProvidersVouchersResolvedTabComponent } from './components/finance/providers-vouchers-resolved-tab/providers-vouchers-resolved-tab.component';
import { AddVoucherPopComponent } from './components/finance/add-voucher-pop/add-voucher-pop.component';
import { DatepickerMonthYearComponent } from './components/datepicker-month-year/datepicker-month-year.component';
import { LeadsFailComponent } from './components/lead/components/leads-fail/leads-fail.component';
import { StorageProvidersPageComponent } from './pages/storage-providers-page/storage-providers-page.component';
import { NewAirlineComponent } from './components/storage/new-airline/new-airline.component';
import { NewProviderComponent } from './components/storage/new-provider/new-provider.component';
import { TripFullPopComponent } from './components/lead/popups/trip-full-pop/trip-full-pop.component';
import { LeadsTableFiltersComponent } from './components/tableComponents/leads-table-filters/leads-table-filters.component'
import { PhoneNumberPipe } from './pipes/phoneNumber.pipe';


registerLocaleData(localeAu);

// scrollbar configuration
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};


@NgModule({
	declarations: [
		AppComponent,
		LoginPageComponent,
		LeadsPageComponent,
		AppLayoutComponent,
		InputComponent,
		ForgetPasswordComponent,
		SuccessMessageComponent,
		UsersPageComponent,
		HeaderComponent,
		NotificationManagmentComponent,
		CustomSelectComponent,
		CustomFilterInputComponent,
		TableComponent,
		UserComponent,
		PropByStringPipe,
		ClientsPageComponent,
		LeadsNewComponent,
		LeadsSaleComponent,
		LeadsFutureComponent,
		LeadsActiveComponent,
		NewLeadComponent,
		DestinationAutocompleteComponent,
		NumberOnlyDirective,
		LeadPageComponent,
		LeadIconTextComponent,
		LeadNewCardComponent,
		LeadNewComponent,
		LeadSaleComponent,
		LeadNoAnswerComponent,
		LeadNoAnswerSuccessComponent,
		LeadMoveToSaleComponent,
		LeadNoAnswerPopupComponent,
		LeadMoveToSalePopupComponent,
		LeadMoveToSaleSuccessComponent,
		LeadMoveToFuturePopupComponent,
		LeadMoveToFutureComponent,
		LeadCancelPopupComponent,
		LeadCancelComponent,
		LeadCancelSuccessComponent,
		LeadMoveToFutureSuccessComponent,
		CalendarComponent,
		LeadBaseInfoComponent,
		LeadSaleProcessComponent,
		ClientLastCallsComponent,
		NoteRecordComponent,
		TaskViewComponent,
		LeadTasksComponent,
		ClientPageComponent,
		ClientBaseInfoComponent,
		ClientNotesComponent,
		ClientOldTripComponent,
		ClientFutureTripComponent,
		PhoneClientAutocompleteComponent,
		TimeAutocompleteComponent,
		ClientCancelLeadComponent,
		ClientSaleTripsComponent,
		ClientSoldTripsComponent,
		ClientFilesComponent,
		FileRowComponent,
		FileNewComponent,
		LatinOnlyDirective,
		HebrewOnlyDirective,
		ClickOutsideDirective,
		ShowByPermissionsDirective,
		ClientPersonalDetailsComponent,
		TasksComponent,
		NewTaskComponent,
		DoneTasksComponent,
		ChangePasswordComponent,
		ClientAutocompleteComponent,
		ConfirmPopupComponent,
		TaskNotificationComponent,
		TaskNewDuedatePopupComponent,
		UtcTimeTaskPipe,
		UtcDateTaskPipe,
		TripsPageComponent,
		TripPageComponent,
		TripClosedGroupsComponent,
		TripFixComponent,
		TripFitComponent,
		TripIncentiveComponent,
		TripTravelersComponent,
		TripPricingComponent,
		TripDocumentsComponent,
		TripScheduleComponent,
		TripScheduleDayComponent,
		TripScheduleDayItemComponent,
		TripNewTransportComponent,
		TripNewHotelComponent,
		TripNewActivityComponent,
		TripNewFlightComponent,
		TripNewComponent,
		TripNewPageComponent,
		CityAutocompleteComponent,
		HebDatePipe,
		PhoneNumberPipe,
		HotelAutocompleteComponent,
		MealPipe,
		StorageDepartmentPageComponent,
		StorageDepartmentTablePageComponent,
		StorageDestinationTablePageComponent,
		StorageCityTablePageComponent,
		HotelsTableComponent,
		ActivitiesTableComponent,
		NewHotelComponent,
		NewCountryComponent,
		NewCityComponent,
		NewActivityComponent,
		TripBuildPageComponent,
		GuidesTableComponent,
		DestinationsTableComponent,
		NewGuideComponent,
		PastTripsPageComponent,
		EmailAutocompleteComponent,
		MessagePopupComponent,
		SuppliersTableComponent,
		CitiesTableComponent,
		SelectGuideComponent,
		StoragePageComponent,
		StorageGuidesPageComponent,
		StorageAirlinesPageComponent,
		LeadMoveToRegisterComponent,
		TripNewMealComponent,
		RoomAutocompleteComponent,
		LeadMoveToActiveComponent,
		TripLeadPage,
		TripNotesComponent,
		TripNotesAndRequestsComponent,
		TripPaymentComponent,
		TripManageComponent,
		TripBalanceComponent,
		TripNotebookComponent,
		TripNotebookBundleComponent,
		TripNotebookScheduleComponent,
		FlightsDestinationsAutocompleteComponent,
		BundlePopComponent,
		TripNotebookGeneralDetailsComponent,
		TripNotebookPricingComponent,
		TripNotebookNoteItemComponent,
		TripNotebookNoteItemScheduleComponent,
		TripPricingPageComponent,
		PricingStepperComponent,
		PricingGeneralDetailsComponent,
		PricingFlightsComponent,
		FlightPopupComponent,
		PricingGroundServiceComponent,
		GroundServicePopupComponent,
		PricingTravelFreeComponent,
		PricingGroupExpensesComponent,
		GroupExpensesPopupComponent,
		PricingGuideExpensesComponent,
		PricingSummaryComponent,
		PricingCashExpensesComponent,
		CashExpensesPopupComponent,
		GuideExpensesPopupComponent,
		TripSummaryItemComponent,
		PricingImportWarningPopupComponent,
		TripNotebookPricingTermsComponent,
		PricingActionsComponenetsComponent,
		LeadSoldComponent,
		DatepickerComponent,
		TripRoomDialogComponent,
		TripRoomContainerComponent,
		TripRoomEmptyComponent,
		AddRelativeDialogComponent,
		AddRelativePersonalDetailsComponent,
		AddRelativePassportDetailsComponent,
		AddRelativeSpecialRequestsComponent,
		OneSelectTripComponent,
		TwoAddRelativesComponent,
		ThreeSuccessMessageComponent,
		RemoveClientMemberDialogComponent,
		FinancePopComponent,
		FinanceSpecificationsTabComponent,
		FinancePayedTabComponent,
		FinanceAddPaymentPopComponent,
		TypeBankTrnasferComponent,
		TypeChequeComponent,
		TypeCreditCardComponent,
		TypeCashComponent,
		ProviderPaymentsPageComponent,
		ProviderTabsFilterComponent,
		ProviderTableRowComponent,
		ProvidersVouchersTabComponent,
		ProvidersHeadrowComponent,
		ProvidersVouchersWaitingTabComponent,
		ProvidersVouchersResolvedTabComponent,
		AddVoucherPopComponent,
		DatepickerMonthYearComponent,
		LeadsFailComponent,
		StorageProvidersPageComponent,
		NewAirlineComponent,
		NewProviderComponent,
		TripFullPopComponent,
		LeadsTableFiltersComponent,
	],
	imports: [
		BidiModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		CustomFormsModule,
		FlexLayoutModule,
		HttpClientModule,
		NgbPaginationModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		MatButtonModule,
		MatDividerModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatMenuModule,
		SatDatepickerModule,
		DragDropModule,
		ClickOutsideModule,
		PerfectScrollbarModule,
		MatSelectModule,
		MatNativeDateModule,
		MatRadioModule,
		MatCheckboxModule,
		MatDatepickerModule,
		SatNativeDateModule,
		MatExpansionModule,
		MatTabsModule,
		SatDatepickerModule, SatNativeDateModule,
		NgxMaskModule.forRoot()
	],
	providers: [
		CanActivateLoggedUserService,
		CanActivateNotLoggedUserService,
		[
			{
				provide: LOCALE_ID, useValue: 'en-AU'
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: JwtInterceptorService,
				multi: true
			},
			{
				provide: DateAdapter,
				useClass: DateFormat
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ErrorInterceptorService,
				multi: true
			},
			{
				provide: PERFECT_SCROLLBAR_CONFIG,
				useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
			}
		]
	],
	bootstrap: [ AppComponent ],
	entryComponents: [
		TripFullPopComponent,
		NewProviderComponent,
		NewAirlineComponent,
		AddVoucherPopComponent,
		FinanceAddPaymentPopComponent,
		FinancePopComponent,
		PricingImportWarningPopupComponent,
		GuideExpensesPopupComponent,
		CashExpensesPopupComponent,
		GroupExpensesPopupComponent,
		GroundServicePopupComponent,
		FlightPopupComponent,
		ForgetPasswordComponent,
		UserComponent,
		RemoveClientMemberDialogComponent,
		NewLeadComponent,
		LeadNoAnswerPopupComponent,
		LeadMoveToSalePopupComponent,
		LeadMoveToFuturePopupComponent,
		LeadCancelPopupComponent,
		FileNewComponent,
		DoneTasksComponent,
		ChangePasswordComponent,
		ConfirmPopupComponent,
		SuccessMessageComponent,
		TaskNewDuedatePopupComponent,
		TripNewTransportComponent,
		TripNewHotelComponent,
		TripNewActivityComponent,
		TripNewFlightComponent,
		TripNewComponent,
		NewHotelComponent,
		NewCountryComponent,
		NewCityComponent,
		NewActivityComponent,
		NewGuideComponent,
		MessagePopupComponent,
		SelectGuideComponent,
		TripNewMealComponent,
		LeadMoveToRegisterComponent,
		LeadMoveToActiveComponent,
		TripPaymentComponent,
		BundlePopComponent,
		AddRelativeDialogComponent,
		TripRoomDialogComponent
	]
})

export class AppModule {
	constructor(private dateAdapter: DateAdapter<Date>,overlayContainer: OverlayContainer) {
		dateAdapter.setLocale('en-in'); // DD/MM/YYYY
		overlayContainer.getContainerElement().classList.add('app-dark-theme');
	}
}
