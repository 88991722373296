import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NotebookService } from 'src/app/services/notebook.service';

@Component({
	selector: 'app-trip-notebook-pricing-terms',
	templateUrl: './trip-notebook-pricing-terms.component.html',
	styleUrls: ['./trip-notebook-pricing-terms.component.scss']
})
export class TripNotebookPricingTermsComponent implements OnInit {

	@Input() trip;
	groundServices: any;
	@Output() getData = new EventEmitter()
	@Input() currentCurrency
	notesArray: boolean[] = []
	currentNote: string;
	totalPriceInGroup: any
	flightPricing: any
	priceIndexes;
	checkBoxArrayRooms = []
	constructor(public notebookService: NotebookService) { }

	ngOnInit() {
		this.totalPriceInGroup = this.trip.pricing.totalPriceInGroup;
		this.groundServices = this.trip.pricing.groundServices;
		this.flightPricing = this.trip.pricing.flightPricing;
		if (this.flightPricing) {
			this.flightPricing.forEach(flight => {
				if ( flight['show'] === false) {
					return;
				} else {
					flight['show'] = true
				}
			})
		}
		this.changeCheckbox('')
		console.log(this.totalPriceInGroup)
	}

	openNoteField(index) {
		this.currentNote = ''
		this.flightPricing.forEach((noteCell, i) => {
			this.notesArray[i] = false;
		})
		if (this.flightPricing[index].notes) {
			this.currentNote = this.flightPricing[index].notes
		}
		this.notesArray[index] = true;
	}

	saveNote(i) {
		this.flightPricing[i]['notes'] = this.currentNote
		this.currentNote = ''
		this.notesArray[i] = false;
		this.changeCheckbox('flight')
	}

	cancelNote(i) {
		this.flightPricing[i]['notes'] = this.flightPricing[i]['notes']
		this.currentNote = ''
		this.notesArray[i] = false;
	}

	changeCheckbox(type) {
		let trip = this.trip
		if (type === 'groundServices') {
			trip.pricing.groundServices = this.groundServices;
			this.notebookService.updateNotebookData(trip._id, 'pricing', trip.pricing).subscribe(res => {
				this.getData.emit('now');
			})
		} else if (type === 'price') {
			trip.pricing.totalPriceInGroup = this.totalPriceInGroup;
			this.notebookService.updateNotebookData(trip._id, 'pricing', trip.pricing).subscribe(res => {
				this.getData.emit('now');
			})
		} else if (type === 'flight'){
			trip.pricing.flightPricing = this.flightPricing;
			this.notebookService.updateNotebookData(trip._id, 'pricing', trip.pricing).subscribe(res => {
				this.getData.emit('now');
			})
		}
		if (type) {

		}
	}

}
