export enum SortableOptionsEnum {
	FirstName = 1,
	LastName,
	Email,
	Phone,
	Role,
	InterestedIn,
	Destination,
	DepartureDate,
	User,
	DueDate,
	Lead,
	Department,
	Number,
	Supervisor,
	PassportFirst,
	Guide,
	TripName,
	Provider
}
