import { Component, OnInit, Input } from '@angular/core';
import { TripScheduleDayItem } from '../../../models/trip/tripScheduleDayItem.enum';
import { MatDialog } from '@angular/material';
import { TripNewActivityComponent } from '../../../components/trip/trip-new-activity/trip-new-activity.component';
import { TripNewHotelComponent } from '../../../components/trip/trip-new-hotel/trip-new-hotel.component';
import { TripNewFlightComponent } from '../../../components/trip/trip-new-flight/trip-new-flight.component';
import { TripNewTransportComponent } from '../../../components/trip/trip-new-transport/trip-new-transport.component';
import { DayModel } from 'src/app/models/trip/day.model';
import { DayService } from 'src/app/services/day.service';
import { IFileUpload } from 'src/app/utils/fileUpload/IFileUpload';
import S3FileUpload from 'src/app/utils/fileUpload/S3FileUpload';
import { AppDocument } from 'src/app/models/client/appDocument';
import * as moment from 'moment';
import { FileNewComponent } from '../../file-new/file-new.component';
import { Subscription } from 'rxjs';
import { TripNewMealComponent } from '../trip-new-meal/trip-new-meal.component';


@Component({
	selector: 'app-trip-schedule-day',
	templateUrl: './trip-schedule-day.component.html',
	styleUrls: ['./trip-schedule-day.component.scss']
})
export class TripScheduleDayComponent implements OnInit {

	@Input() edit: boolean = false;
	@Input() dayId: string;
	@Input() dayNum: number;

	public array: { type: TripScheduleDayItem, data: any, img: string }[] = []; // delete later
	public day: DayModel;
	public menuOpen: boolean = false;
	private subscriptions: Subscription[] = [];
	public countryName: string = '';
	public cityName: string = '';
	public tripScheduleDayItemEnum = TripScheduleDayItem;
	amazonFileUpload: IFileUpload;

	constructor(
		private dialog: MatDialog,
		private dayService: DayService
		) {
		this.amazonFileUpload = new S3FileUpload('eu-west-1', '7eab6e63-7f95-4b67-9546-f0e39c6931af', 'geo-dev');
	}

	ngOnInit() {
		this.getDayDetails();
	}

	private getDayDetails() {
		this.dayService.getDayDetails(this.dayId).subscribe(day => {
			this.day = day;
			this.initArrayOrder();
		});
	}

	private initArrayOrder() {
		if(this.day){
			this.array = [];
			this.day.activities.forEach(activity => { this.array.push({type: TripScheduleDayItem.Activity, data: activity, img: 'trip-arrow'}); });
			this.day.hotels.forEach(hotel => { this.array.push({type: TripScheduleDayItem.Hotel, data: hotel, img: 'trip-bed'}); });
			this.day.transfers.forEach(transfer => { this.array.push({type: TripScheduleDayItem.Transport, data: transfer, img: 'trip-car'}); });
			this.day.flights.forEach(flight => { this.array.push({type: TripScheduleDayItem.Flight, data: flight, img: 'trip-plane'}); });
			this.day.meals.forEach(meal => { this.array.push({type: TripScheduleDayItem.Meal, data: meal, img: 'trip-meal'}); });
			this.reorderAarray();
		}
	}

	public toggleMenu() {
		this.menuOpen = !this.menuOpen;
	}

	public addFlight() {
		this.menuOpen = false;
		this.openDialog(TripNewFlightComponent, TripScheduleDayItem.Flight, 'trip-plane');
	}

	public addActivity() {
		this.menuOpen = false;
		this.openDialog(TripNewActivityComponent, TripScheduleDayItem.Activity, 'trip-arrow');
	}

	public addHotel() {
		this.menuOpen = false;
		this.openDialog(TripNewHotelComponent, TripScheduleDayItem.Hotel, 'trip-bed');

	}

	public addTransport() {
		this.menuOpen = false;
		this.openDialog(TripNewTransportComponent, TripScheduleDayItem.Transport, 'trip-car');
	}

	public addMeal() {
		this.menuOpen = false;
		this.openDialog( TripNewMealComponent , TripScheduleDayItem.Meal, 'trip-meal');
	}


	public openDialog(component: any, type: TripScheduleDayItem, img: string): any {
		const openedDialog = this.dialog.open(component, {
			position: { left: '0', top: '0' },
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {day: this.day, dayNum: this.dayNum}
		});

		openedDialog.afterClosed().subscribe((data: any) => {
			console.log(data,'data')
			if (data) {
				const dataItem = {
					data,
					type,
					img
				};
				this.array.push(dataItem);
				this.reorderAarray();
				this.getDayDetails();
			}
			// location.reload()
		});
	}

	private reorderAarray() {
		const hotelArray = this.array.filter(item => item.type === TripScheduleDayItem.Hotel);
		let newArray = this.array.filter(item => item.type !== TripScheduleDayItem.Hotel);
		newArray.sort(function(a, b) {
			if ( a.data.startTime < b.data.startTime ){
				return -1;
			  }
			  if ( a.data.startTime > b.data.startTime ){
				return 1;
			  }
			  return 0;
		});
		newArray = newArray.concat(hotelArray);
		this.array = newArray;
	}


	public editClicked(item: {data: any, type: TripScheduleDayItem}) {
		switch (item.type) {
			case TripScheduleDayItem.Activity: this.editActivity(item.data); break;
			case TripScheduleDayItem.Hotel: this.editHotel(item.data); break;
			case TripScheduleDayItem.Transport: this.editTransport(item.data); break;
			case TripScheduleDayItem.Flight: this.editFlight(item.data); break;
			case TripScheduleDayItem.Meal: this.editMeal(item.data); break;
			default: return;
		}
	}


	public openEditDialog(component: any, type: TripScheduleDayItem, data: any): any {
		const openedDialog = this.dialog.open(component, {
			position: { left: '0', top: '0' },
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: {day: this.day, dayNum: this.dayNum, data}
		});

		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				// this.reorderAarray();
				this.getDayDetails();
			}
		});
	}

	private editActivity(data: any) {
		this.openEditDialog(TripNewActivityComponent, TripScheduleDayItem.Activity, data);
	}

	private editTransport(data: any) {
		this.openEditDialog(TripNewTransportComponent, TripScheduleDayItem.Transport, data);
	}

	private editHotel(data: any) {
		this.openEditDialog(TripNewHotelComponent, TripScheduleDayItem.Hotel, data);
	}

	private editFlight(data: any) {
		this.openEditDialog(TripNewFlightComponent, TripScheduleDayItem.Flight, data);
	}

	private editMeal(data: any) {
		this.openEditDialog( TripNewMealComponent, TripScheduleDayItem.Meal, data);
	}

	public fileInputOnChange(event) {
		if ( event.target.files.length > 0 ) {
			this.uploadFile(event.target.files[0]);
		}
	}

	private async uploadFile(file: File) {
		await this.amazonFileUpload.UploadFileAsync(file, (res) => {
			if ( !res.success ) {
				return console.error('%c an error has occurred trying to upload the image', 'background-color: salmon;');
			}
			let fileName = file.name;
			const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
			fileName = fileName.replace('.' + fileType, '');
			const document = new AppDocument();
			document.url = res.url;
			document.type = fileType;
			document.name = fileName;
			document.createdAt = moment().toISOString();

			const dialogRef = this.dialog.open(FileNewComponent, {
				width: '500px',
				height: '450px',
				panelClass: 'custom-dialog-container',
				backdropClass: 'custom-backdropClass',
				position: { top: '170px' },
				disableClose: true,
				data: { document, dayId: this.dayId }
			});
		});

	}

}
