export default function formatPhoneNumberUtil(
	phoneNumber: string | number
): string {
	let output = phoneNumber.toString();
	output = output.replace(/\D/g, "");
	if (output.length >= 3) {
		let indexToInsertSymbol: number;
		if (output.length < 10) indexToInsertSymbol = 2;
		if (output.length >= 10) indexToInsertSymbol = 3;
		output = "".concat(
			output.slice(0, indexToInsertSymbol),
			"-",
			output.slice(indexToInsertSymbol)
		);
	}
	return output;
}
