import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LeadNoAnswerPopupComponent } from '../lead-no-answer-popup/lead-no-answer-popup.component';

@Component({
  selector: 'app-trip-full-pop',
  templateUrl: './trip-full-pop.component.html',
  styleUrls: ['./trip-full-pop.component.scss']
})
export class TripFullPopComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<LeadNoAnswerPopupComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
	}

  ngOnInit() {
  }

}
