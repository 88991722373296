import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TripPaymentComponent } from '../../trip/trip-payment/trip-payment.component';
import { MatDialog } from '@angular/material';
import { paymentTypeTitles } from 'src/app/models/types/currencies.strings.enum';
import * as moment from 'moment';
import { CurrencyEnum } from 'src/app/models/pricing/currency.Enum';

@Component({
  selector: 'app-finance-payed-tab',
  templateUrl: './finance-payed-tab.component.html',
  styleUrls: ['./finance-payed-tab.component.scss']
})
export class FinancePayedTabComponent implements OnInit {

  @Input() travelerFinanceData;
  @Input() tripCurrency:CurrencyEnum;
  @Output() updateEmit = new EventEmitter();
  public moment = moment

  constructor(
	private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  addPayDialog(){
	const dialog = this.dialog.open(TripPaymentComponent, {
		position: {top: '20' },
		// height: '440px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: true,
		data:{currency:this.tripCurrency,travelerFinanceData:this.travelerFinanceData}
	});
	dialog.afterClosed().subscribe((data: any) => {
		if(data){
			this.updateEmit.emit(data)
		}
	});
  }


  setTitle(type){
	switch(type){
	  case 1:
	  return paymentTypeTitles.TRANSFER
	  break;

	  case 2:
	  return paymentTypeTitles.CREDIT
	  break;

	  case 3:
	  return paymentTypeTitles.CHEQUE
	  break;

	  case 4:
	  return paymentTypeTitles.CASH
	  break;
	}
	}

	checkCurrency(currency) {
		if(currency === 1) return '$'
		if(currency === 2) return '€'
		if(currency === 3) return '₪'
		if(currency === 4) return '£'
	}

}
