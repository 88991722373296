import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { GroundServicePopupComponent } from '../popups/ground-service-popup/ground-service-popup.component';
import { GroundServiceModel } from 'src/app/models/pricing/groundService.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { PricingService } from 'src/app/services/pricing.service';
import { PricesCatagoryModel } from 'src/app/models/pricing/pricesCatagoryModel';
import { TaskModel } from 'src/app/models/client/task.model';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-pricing-ground-service',
  templateUrl: './pricing-ground-service.component.html',
  styleUrls: ['./pricing-ground-service.component.scss']
})
export class PricingGroundServiceComponent implements OnInit {
	constructor(public dialog: MatDialog, public pricingService:PricingService) {
	  }
	  public cols: TableColModel[] = [];
	  tableHeight:number = 300;
	  groundServiceData:GroundServiceModel[] = []
	  @Output() emitStep = new EventEmitter()
	  @Output() getPricingData = new EventEmitter()
	  @Input() tripData:TripModel
	  @Input() currentCurrency:string;
	  priceRanks:number
	  pricesCatagories:PricesCatagoryModel[] = []

	  ngOnInit() {
		  this.priceRanks = this.tripData.pricing.generalDetails.priceRanks
		  this.pricesCatagories = this.tripData.pricing.generalDetails.pricesCatagory
		  console.log(this.pricesCatagories)
		  this.createColsTableItem();
		  this.groundServiceData = this.tripData.pricing.groundServices.tableData
	  }

	  private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GROUND_SERVICE.SERVICE, 'type', ColSize.MEDIUM, 'groundTypes', undefined, undefined,undefined));
		this.pricesCatagories.forEach((price,i) =>{
			this.cols.push(new TableColModel(`${price}`, ``, ColSize.MEDIUM, `priceCategory`, undefined, undefined,i));
		})
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GROUND_SERVICE.SUPPLIER, 'provider.name', ColSize.MEDIUM, 'priceNotes', undefined, undefined,undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.GROUND_SERVICE.NOTES, 'note', ColSize.MEDIUM, 'priceNotes', undefined, undefined,undefined));
	}

	removeItem(itemToRemove){
		let index = this.groundServiceData.findIndex( item => {
			return item === itemToRemove
		})
		this.groundServiceData.splice(index,1)
		let data = {
			pricingId:this.tripData.pricing._id,
			dataToUpdate: this.groundServiceData,
			tripId:this.tripData._id,
			fieldToUpdate:'groundServices'
		}
		  this.pricingService.updatePricingFlights(data).subscribe( res => {
			  this.getPricingData.emit(this.tripData._id)
		  })
	  }


	addEditGroundServices(groundData?): void {
		if(this.tripData.completed && this.tripData.tripClass === 1) return;
		const dialogRef = this.dialog.open(GroundServicePopupComponent, {
		  position: { left: '0', top: '0' },
		  width: '360px',
		  height: '100vh',
		  data: {formData:groundData && groundData.data,pricesCatagory:this.pricesCatagories},
		  disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				if(groundData && groundData.data){
					this.groundServiceData[groundData.index] = result
				} else {
					this.groundServiceData.push(result)
				}
			let data = {
			  pricingId:this.tripData.pricing._id,
			  dataToUpdate: this.groundServiceData,
			  fieldToUpdate:'groundServices',
			  tripId:this.tripData._id,
		  }
			this.pricingService.updatePricingFlights(data).subscribe( res => {
				this.getPricingData.emit(this.tripData._id)
			})
		  }
		});
	  }

	  goStep(stepNumber){
		if(this.tripData.tripClass === 3) stepNumber = 8;
		this.emitStep.emit(stepNumber)
	}


	public deleteGroundService(itemToRemove) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGround,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if(confirm){
				this.removeItem(itemToRemove)
			}
		});
	}


}
