import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VouchersService } from 'src/app/services/vouchers.service';

@Component({
  selector: 'app-providers-vouchers-tab',
  templateUrl: './providers-vouchers-tab.component.html',
  styleUrls: ['./providers-vouchers-tab.component.scss']
})
export class ProvidersVouchersTabComponent implements OnInit {

  constructor(public vouchersService:VouchersService) { }

	@Input() data
	@Output() getVouchers = new EventEmitter()
	@Output() pageClicked = new EventEmitter()
	vouchers:any;
	count:number;


  ngOnInit() {
	  this.getVouchers.emit('now')
	  console.log(this.vouchers)
  }

  updateVouchers(vouchers,status){
	vouchers.forEach(voucher => {
		voucher.status = status
	});
	this.vouchersService.updateMany(vouchers).subscribe( res => {
		this.getVouchers.emit('now')
	})
  }

  pageOnClick(pageNumber){
	this.getVouchers.emit(pageNumber)
}

}
