import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PricesCatagoryModel } from 'src/app/models/pricing/pricesCatagoryModel';
import { TripModel } from 'src/app/models/trip/trip.model';
import { GuideExpensesPopupComponent } from '../popups/guide-expenses-popup/guide-expenses-popup.component';
import { PricingService } from 'src/app/services/pricing.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
	selector: 'app-pricing-guide-expenses',
	templateUrl: './pricing-guide-expenses.component.html',
	styleUrls: ['./pricing-guide-expenses.component.scss']
})
export class PricingGuideExpensesComponent implements OnInit {

	constructor(public dialog: MatDialog, public pricingService: PricingService) {
	}

	@Output() emitStep = new EventEmitter()
	@Output() getPricingData = new EventEmitter()
	@Input() tripData: TripModel
	@Input() currentCurrency: string
	guideExpensesData: any[] = []
	priceRanks: number
	pricesCatagories: PricesCatagoryModel[] = [];
	tableHeight: number = 500;
	arr = new Array(10)
	sumArray: number[]

	ngOnInit() {
		this.priceRanks = this.tripData.pricing.generalDetails.priceRanks
		this.pricesCatagories = this.tripData.pricing.generalDetails.pricesCatagory
		this.guideExpensesData = this.tripData.pricing.guideExpenses.data
		this.sumArray = new Array(this.pricesCatagories.length)
		this.calculateTotals()
	}

	calculateTotals() {
		this.sumArray.fill(0)
		this.guideExpensesData.forEach((expense, i) => {
			expense.rowDetails.forEach(details => {
				if (details.pricesCatagory.length > 0) {
					details.pricesCatagory.forEach((price, z) => {
						if (price.price) {
							this.sumArray[z] += price.price
						}
					})
				}
			})
		})
	}

	removeItem(rowIndex, guideIndex) {
		this.guideExpensesData[rowIndex].rowDetails.splice(guideIndex, 1)
		let data = {
			pricingId: this.tripData.pricing._id,
			dataToUpdate: this.guideExpensesData,
			tripId: this.tripData._id,
			fieldToUpdate: 'guideExpenses'
		}
		this.pricingService.updatePricingFlights(data).subscribe(res => {
			this.getPricingData.emit(this.tripData._id)
			this.calculateTotals()
		})
	}

	public deleteGuideExpense(itemToRemove, guideIndex) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveGuideExpenses,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.removeItem(itemToRemove, guideIndex)
			}
		});
	}

	goStep(stepNumber) {
		this.emitStep.emit(stepNumber)
	}

	edit() {
		// let data = JSON.parse(JSON.stringify({ formData: guideData, pricesCatagory: this.pricesCatagories, 0 }))
		// result.forEach((guide) => {
		// 	guide.pricesCatagory.forEach((prices) => {
		// 		prices.price = guide.pricesCatagory[0].price;
		// 	});
		// });
		// this.guideExpensesData[0].rowDetails = JSON.parse(JSON.stringify(result));
		// let data = {
		// 	pricingId: this.tripData.pricing._id,
		// 	dataToUpdate: this.guideExpensesData,
		// 	fieldToUpdate: 'guideExpenses',
		// 	tripId: this.tripData._id,
		// }
		// this.pricingService.updatePricingFlights(data).subscribe(res => {
		// 	this.getPricingData.emit(this.tripData._id)
		// 	this.calculateTotals()
		// })
	}

	editExpenses(guideData, title, index): void {
		if (this.tripData.completed && this.tripData.tripClass === 1) return;
		const dialogRef = this.dialog.open(GuideExpensesPopupComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100vh',
			data: JSON.parse(JSON.stringify({ formData: guideData, pricesCatagory: this.pricesCatagories, index })),
			disableClose: true
		});

		dialogRef.afterClosed().subscribe((result: any[]) => {
			if (result) {
				result.forEach((guide) => {
					guide.pricesCatagory.forEach((prices) => {
						
						if(guide.title === 'ערכות'){
							prices.price = guide.priceForDay * this.tripData.days.length * prices.numberOfPeople;
						} else {
							prices.price = guide.priceForDay * this.tripData.days.length;
						}
					});
				});
				this.guideExpensesData[index].rowDetails = JSON.parse(JSON.stringify(result));
				let data = {
					pricingId: this.tripData.pricing._id,
					dataToUpdate: this.guideExpensesData,
					fieldToUpdate: 'guideExpenses',
					tripId: this.tripData._id,
				}
				this.pricingService.updatePricingFlights(data).subscribe(res => {
					this.getPricingData.emit(this.tripData._id)
					this.calculateTotals()
				})
			}
		});
	}


}
