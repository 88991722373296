import { DocumentStatus } from '../types/documentStatus.enum';
import { RoleModel } from './role.model';
import { DepartmentModel } from './department.mode';


export class UserModel {
	_id: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	role: RoleModel | string;
	department: DepartmentModel | string;
	status: DocumentStatus;
}
