export enum ConfirmationPopupTypes {
	RemoveUser = 1,
	RemoveFile,
	RemoveTask,
	RemoveHotel,
	RemoveCity,
	RemoveCountry,
	RemoveActivity,
	SetTripAsCompleted,
	RemoveGuide,
	RemoveDay,
	RemoveNote,
	RemoveTripBuild,
	RemoveGround,
	RemoveCash,
	RemoveGroup,
	RemoveGuideExpenses,
	MoveToSold,
	CloseTrip,
	RemoveItem,
	RemoveVoucher,
	RemoveProvider,
	UpdateDayDescription
}
