export enum LeadStatusEnum {
    New = 1, // פניה חדשה
    Future, // = 2 ליד עתידי
    Sale, // = 3 תהליך מכירה
    Active, // = 4  מטייל פעיל
    Sold, // = 5 ליד נמכר
    Closed, // = 6 ליד סגור
    Rejected // = 7 ליד נכשל
}


export const LEAD_ENUM_STRINGS_AND_COLORS = {
	1: { name: 'פנייה חדשה', color: 'rgba(250, 207, 200, 0.8)' },
	2: { name: 'ליד עתידי', color: 'rgba(250, 223, 195, 0.8)' },
	3: { name: 'תהליך מכירה', color: 'rgba(226, 209, 229, 0.8)' },
	4: { name: 'מטייל פעיל', color: 'rgba(181, 231, 225, 0.8)' },
	5: { name: 'נמכר', color: 'rgba(181, 231, 225, 0.5)' },
	7: { name: 'נכשל', color: 'rgba(255, 116, 93, 0.8)' }
};

