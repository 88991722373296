import { Component, OnInit, Input } from '@angular/core';
import { BundlePopComponent } from '../../notebook/bundle-pop/bundle-pop.component';
import { MatDialog } from '@angular/material';
import { NotebookService } from 'src/app/services/notebook.service';
import { TripsService } from 'src/app/services/trips.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
  selector: 'app-trip-notebook-pricing',
  templateUrl: './trip-notebook-pricing.component.html',
  styleUrls: ['./trip-notebook-pricing.component.scss']
})
export class TripNotebookPricingComponent implements OnInit {

  constructor(public route:ActivatedRoute,private dialog: MatDialog,public tripsService:TripsService,public notebookService:NotebookService) { }

  flightNotes:any[] = []
  priceNotes:any[] = []
  pdfData:any
  pricing:any
  isInternal:boolean;
  totalPrice:string = '';
  tripId:string;
  isEdit:boolean;
  @Input() currentCurrency

  @Input() trip;

  ngOnInit() {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.tripId = this.route.snapshot.paramMap.get('id');
		this.getPDFData()
	});
}

getPDFData(){
  this.tripsService.getPDFData(this.tripId).subscribe( res => {
	  this.pdfData = res
	  this.pricing = res.pricing
	  this.flightNotes = res.pricing.internalFlights
	  this.priceNotes = res.pricing.terms
	  if(this.pricing.totalPrice){
		  this.totalPrice = this.pricing.totalPrice
	  }
  })
}

editTotal(){
	this.isEdit = true;
}

cancelEdit(){
	this.totalPrice = this.pricing['totalPrice']? this.pricing['totalPrice'] : null
	this.isEdit = false;
}

saveTotal(){
	this.pricing['totalPrice'] = this.totalPrice
	this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
		this.getPDFData()
		this.isEdit = false;
	})
}

isInternalChange(){
	this.pricing['includeInternalFlights'] = this.isInternal
	this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
		this.getPDFData()
	})
}

removeTotal(){
	this.totalPrice = null
	this.saveTotal()
}


  openTermsDialog(isFlight){
	  let headline
	if(isFlight){
		headline = 'הוסף טיסה פנימית'
	} else {
		headline = 'הוסף מחיר/תנאי'
	}
	const dialogRef = this.dialog.open(BundlePopComponent, {
		width: '858px',
		height: '775px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: false,
		data:{component:'general',componentHeadline:headline}
	});

	dialogRef.afterClosed().subscribe(result => {
		if(result){
			let note = {
				headLine:result.headLine,
				description:result.description? result.description:[]
			}
			if(isFlight){
				this.flightNotes.push(note)
			} else {
				this.priceNotes.push(note)
			}
			}
			this.pricing.internalFlights = this.flightNotes
			this.pricing.terms = this.priceNotes
			this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
				this.getPDFData()
			})
		})
}

editNote(note,index,isFlight){
	let headline
	if(isFlight){
		headline = 'ערוך טיסה פנימית'
	} else {
		headline = 'ערוך מחיר/תנאי'
	}
	const dialogRef = this.dialog.open(BundlePopComponent, {
		width: '858px',
		height: '775px',
	  panelClass: 'custom-right-dialog-container',
	  backdropClass: 'custom-backdropClass',
	  disableClose: false,
	  data:{component:'general',note,componentHeadline:headline}
	});
	dialogRef.afterClosed().subscribe(result => {
		if(result){
			if(isFlight){
				this.flightNotes[index] = result
			} else {
				this.priceNotes[index] = result
			}
		}
		this.pricing.internalFlights = this.flightNotes
		this.pricing.terms = this.priceNotes
		this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
			this.getPDFData()
		})	});
}

removeNote(index,isFlight){
	const dialogRef = this.dialog.open(ConfirmPopupComponent, {
		width: '500px',
		height: '226px',
		panelClass: 'custom-right-dialog-container',
		backdropClass: 'custom-backdropClass',
		disableClose: false,
		data:ConfirmationPopupTypes.RemoveItem
	  });

	  dialogRef.afterClosed().subscribe(result => {
		  if(result){
			  if(isFlight){
				  this.flightNotes.splice(index,1)
			  } else {
				  this.priceNotes.splice(index,1)
			  }
			  this.pricing.internalFlights = this.flightNotes
					  this.pricing.terms = this.priceNotes
					  this.notebookService.updateNotebookData(this.pdfData._id,'pricing',this.pricing).subscribe(res => {
						  this.getPDFData()
			 })
		  }
		})
}


}
