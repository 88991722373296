import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { PaginationModel } from '../models/client/pagination.model';
import { SortableColModel } from '../models/client/sortableCol';
import { SortTypeEnum } from '../models/types/sortType.enum';
import { VoucherModel } from '../models/trip/voucher.model';
import { VoucherQueryModel } from '../models/request/voucherQuery.model';

@Injectable({
  providedIn: 'root'
})
export class VouchersService extends HttpHandlerService {

	private url: string = 'vouchers/';

	public addVoucher(voucher: VoucherModel) {
		return this.post(this.url, voucher);
	}

	public updateVoucher(voucher: VoucherModel) {
		return this.put(this.url, voucher);
	}

	public updateMany(vouchers: VoucherModel[]) {
		let current = 'many'
		return this.put(this.url + current, vouchers);
	}

	public managerApprove(vouchers: VoucherModel[]) {
		let current = 'approve'
		return this.put(this.url + current, vouchers);
	}

	public getTripVouchers(tripId:string){
		return this.get(this.url + 'tripId', {tripId});
	}

	public getAllVouchers(voucherQuery:VoucherQueryModel){
		return this.get(this.url,voucherQuery.toQueryObject());
	}

	public getAllCurrencies(){
	    let currencyUrl = 'currencies'
		return this.get(this.url + currencyUrl, {})
	}

}

