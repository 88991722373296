import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-providers-headrow',
  templateUrl: './providers-headrow.component.html',
  styleUrls: ['./providers-headrow.component.scss']
})
export class ProvidersHeadrowComponent implements OnInit {

  constructor() { }

  @Input() resolved:boolean = false;
  @Input() withJonny:boolean = false;

  ngOnInit() {
  }

}
