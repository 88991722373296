import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES, HOTEL_FORM_PLACEHOLDERS } from '../../../shared/texts';
import { HotelService } from 'src/app/services/hotel.service';
import { HotelModel } from 'src/app/models/trip/hotel.model';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';

@Component({
	selector: 'app-new-hotel',
	templateUrl: './new-hotel.component.html',
	styleUrls: ['./new-hotel.component.scss']
})
export class NewHotelComponent implements OnInit, OnDestroy {

	@Input() hotel: HotelModel = new HotelModel();
	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = HOTEL_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;

	constructor(private fb: FormBuilder,public customValidatorsService:CustomValidatorsService,
		private hotelService: HotelService,
		@Inject(MAT_DIALOG_DATA) private data: {hotel: HotelModel, city: string, destination: string},
		private dialogRef: MatDialogRef<NewHotelComponent>) {
			this.hotel = this.data.hotel;
		}

	ngOnInit() {
		if (!this.hotel) {
			this.hotel = new HotelModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		this.createForm();
	}

	private createForm() {
		const { name, phone, hotelWeb } = this.hotel;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			phone: new FormControl(phone, Validators.compose([Validators.required,this.customValidatorsService.validateCellPhoneNumber])),
			hotelWeb: new FormControl(hotelWeb, Validators.required)
		});
	}

	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const form = this.form.controls;
		const hotel = new HotelModel();
		hotel.name = form.name.value;
		hotel.destination = this.data.destination;
		hotel.city = this.data.city;
		hotel.phone = form.phone.value;
		hotel.hotelWeb = form.hotelWeb.value;

		if (this.hotel._id) {
			hotel._id = this.hotel._id;
			this.updateHotel(hotel);
		} else {
			this.createHotel(hotel);
		}
	}


	private updateHotel(hotel: HotelModel) {
		const subscription = this.hotelService.updateHotel(hotel).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createHotel(hotel: HotelModel) {
		const subscription = this.hotelService.createHotel(hotel).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

}
