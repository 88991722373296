import { TripRoomDialogComponent } from "./../../components/trip/trip-room-dialog/trip-room-dialog.component";
import { TripRoomContainerComponent } from "./../../components/trip/trip-room-container/trip-room-container.component";
import { Component, OnInit } from "@angular/core";
import {
	SingleTripStatusEnum,
	SINGLE_TRIP_ENUM_STRINGS
} from "src/app/models/trip/tripStatus.enum";
import { ActivatedRoute, Router } from "@angular/router";
import { TripsService } from "../../services/trips.service";
import { TripModel } from "src/app/models/trip/trip.model";
import { DestinationModel } from "src/app/models/client/destination.model";
import { DepartmentModel } from "src/app/models/client/department.mode";
import * as moment from "moment";
import { TripClassEnum } from "../../models/types/tripClass.enum";
import { GuideModel } from "src/app/models/trip/guide.model";
import { ConfirmPopupComponent } from "src/app/components/confirm-popup/confirm-popup.component";
import { ConfirmationPopupTypes } from "src/app/models/types/confirmationPopup.enum";
import { MatDialog } from "@angular/material";
import { LeadsService } from 'src/app/services/leads.service';
import { LeadModel } from 'src/app/models/client/lead.model';
import { VouchersService } from 'src/app/services/vouchers.service';

@Component({
	selector: "app-trip-page",
	templateUrl: "./trip-page.component.html",
	styleUrls: ["./trip-page.component.scss"]
})
export class TripPageComponent implements OnInit {
	public trip: TripModel = new TripModel();

	public tripDetailsArray: { icon: string; name: string; content }[] = [];
	activeTravelers:any = []
	public singleTripStatusEnum = SingleTripStatusEnum;
	public TRIP_ENUM_STRINGS = SINGLE_TRIP_ENUM_STRINGS;
	public showingPage: SingleTripStatusEnum = SingleTripStatusEnum.Schedule;
	completed: boolean;
	leads:LeadModel[]
	currencyObj:any = {}

	constructor(
		private activatedRoute: ActivatedRoute,
		private tripsService: TripsService,
		private router: Router,
		private dialog: MatDialog,
		private leadService: LeadsService,
		private vouchersService:VouchersService
	) {}

	ngOnInit() {
		this.getTripByID();
	}

	private getTripByID() {
		this.tripDetailsArray = [];
		const tripId = this.activatedRoute.snapshot.paramMap.get("id");
		this.getTripActiveTravelers(tripId)
		this.tripsService.getTripByID(tripId).subscribe(
			trip => {
				this.trip = Object.assign(new TripModel(), trip);
				this.setTripHeader();
				this.completed = this.trip.completed;
			},
			err => {
				console.log("error", err);
				this.router.navigateByUrl("/app/trips");
			}
		);
	}
	getTripActiveTravelers(tripId){
		this.leadService.getTripActiveClients(tripId).subscribe(leads => {
			console.log(leads);
			this.leads = leads
			this.activeTravelers = leads.filter( lead =>{
				return lead.status != 7 && lead.status != 0
			})
			this.leads.sort((lead, b)=> {return lead['firstName'].localeCompare(b['firstName'])})
		})
	}

	private setTripHeader() {
		let date: string;
		if (this.trip.completed) {
			date = `${moment
				.utc(this.trip.departureDate)
				.format("DD.MM.YY")} - ${moment
				.utc(this.trip.departureDate)
				.add(this.trip.days.length, "days")
				.format("DD.MM.YY")}`;
		} else {
			date = moment.utc(this.trip.departureDate).format("DD.MM.YY");
		}
		const range = `${this.trip.maxTravelers} / ${this.activeTravelers.length}`; // zero 0 => number of registered travelers
		const tripClass: string = this.trip.getTripClassName();
		this.tripDetailsArray.push({
			icon: "/assets/images/trip-type.svg",
			name: "סוג הטיול",
			content: tripClass
		});
		this.tripDetailsArray.push({
			icon: "/assets/images/trip-destination.svg",
			name: "יעד",
			content: (this.trip.destination as DestinationModel).name
		});
		this.tripDetailsArray.push({
			icon: "/assets/images/trip-division.svg",
			name: "מחלקה",
			content: (this.trip.department as DepartmentModel).name
		});
		this.tripDetailsArray.push({
			icon: "/assets/images/trip-dates.svg",
			name: "תאריכי טיול",
			content: date
		});
		if (
			this.trip.tripClass === TripClassEnum.FIX ||
			this.trip.tripClass === TripClassEnum.GROUP
		) {
			this.tripDetailsArray.push({
				icon: "/assets/images/trip-instructor.svg",
				name: "מדריך",
				content: (this.trip.guide as GuideModel).name
			});
		}
		this.tripDetailsArray.push({
			icon: "/assets/images/trip-num-of-travelers.svg",
			name: "מספר מטיילים",
			content: range
		});
	}

	public changeShowingPage(toPage: SingleTripStatusEnum) {
		this.showingPage = toPage;
	}

	public getItinerary() {
		this.tripsService.getTripIitinerary(this.trip._id, true).subscribe(
			res => {
				let url = window.URL.createObjectURL(res);
				let a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = `${this.trip.name}-itinerary.xlsx`;
				document.body.style.height = "100%";
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			},
			err => {
				console.log(err);
			}
		);
	}

	closeTrip() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: "500px",
			height: "226px",
			panelClass: "custom-right-dialog-container",
			backdropClass: "custom-backdropClass",
			disableClose: true,
			data: ConfirmationPopupTypes.CloseTrip
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.tripsService
					.setAsClosed(this.trip._id)
					.subscribe(res => {});
				this.router.navigateByUrl("/app/trips/completed");
			}
		});
	}

	public removeTrip() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: "500px",
			height: "226px",
			panelClass: "custom-right-dialog-container",
			backdropClass: "custom-backdropClass",
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveTripBuild
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.deleteTrip(this.trip._id);
			}
		});
	}

	private deleteTrip(tripId: string) {
		this.tripsService.deleteTrip(tripId).subscribe(success => {
			this.router.navigateByUrl("app/trips/completed");
		});
	}

	public openRoomingDialog() {
		const rooming = this.dialog.open(TripRoomDialogComponent, {
			width: "602px",
			height: "812px",
			direction: "rtl",
			panelClass: "custom-room-dialog-container",
			backdropClass: "custom-backdropClass",
			disableClose: true,
			data: this.trip._id
		});
		rooming.afterClosed().subscribe(() => location.reload());
	}

	public editTripClicked() {
		this.router.navigateByUrl(`app/trips/edit/${this.trip._id}`);
	}
}
