import { Component, Input, OnInit } from '@angular/core';
import { ClientModel } from '../../../models/client/client.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LeadModel } from '../../../models/client/lead.model';
import { TableColModel } from '../../../models/client/tableCols.model';
import { SortableColModel } from '../../../models/client/sortableCol';
import { LeadStatusEnum } from '../../../models/types/leadStatus.enum';
import { MatDialog } from '@angular/material';
import { LeadsService } from '../../../services/leads.service';
import { Router } from '@angular/router';
import { SortableOptionsEnum } from '../../../models/types/sortableOptions.enum';
import { SortTypeEnum } from '../../../models/types/sortType.enum';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { PaginationResponseModel } from '../../../models/response/paginationResponse.model';
import { ClientService } from '../../../services/client.service';
import { PaginationModel } from '../../../models/client/pagination.model';

@Component({
	selector: 'app-client-sold-trips',
	templateUrl: './client-sold-trips.component.html',
	styleUrls: ['./client-sold-trips.component.scss']
})

export class ClientSoldTripsComponent implements OnInit {
	@Input() client: ClientModel;
	@Input() supervisors: [];
	public pagination: PaginationModel = new PaginationModel();
	public form: FormGroup;
	public leads: LeadModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public tableHeight: number = window.innerHeight - 389;
	private readonly leadStatus = LeadStatusEnum.Sold;

	constructor(private dialog: MatDialog, private fb: FormBuilder, private leadService: LeadsService, private router: Router, private clientService: ClientService) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.DepartureDate, true);
		this.sortableCol.setDescending()
		this.createColsTableItem();
	}

	get controls() {
		return this.form.controls;
	}


	ngOnInit() {
		this.getTrips();
	}


	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if (col.colSortable.SortType === SortTypeEnum.Descending) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.getTrips();
	}

	public rowClicked(lead: LeadModel) {
		this.router.navigateByUrl(`/app/leads/${lead._id}`);
	}

	private getTrips() {
		const status = LeadStatusEnum.Sold
		this.clientService.trips(this.client._id, status).subscribe((resp: LeadModel[]) => {
			this.leads = resp;
		});
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.LOCATION, 'destination.name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.STATUS, 'status', ColSize.MEDIUM, 'leadStatus', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.DUE_DATE, 'departureDate', ColSize.MEDIUM, 'monthDate', undefined, new SortableColModel(SortableOptionsEnum.DueDate, false)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.LEADS.DATE, 'createdAt', ColSize.MEDIUM, 'monthDate', undefined, undefined));
	}


}
