import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../shared/texts';
import { TripDialogDataModel } from 'src/app/models/trip/tripDialogData.model';
import * as moment from 'moment';
import { ActivityResModel } from 'src/app/models/reservations/activityRes.model';
import { ReservationsService } from '../../../services/reservations.service';
import { CityModel } from 'src/app/models/trip/city.model';
import { TripModel } from 'src/app/models/trip/trip.model';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityModel } from 'src/app/models/trip/activity.model';

@Component({
	selector: 'app-trip-new-activity',
	templateUrl: './trip-new-activity.component.html',
	styleUrls: ['./trip-new-activity.component.scss']
})
export class TripNewActivityComponent implements OnInit {

	public form: FormGroup;
	public INVALID_FORM = FORM_MESSAGES;
	public dateTitle: string = '';
	public actionTitle: string = '';
	public tripDestination: string;
	public currentActivities

	constructor(private dialogRef: MatDialogRef<TripNewActivityComponent>, public activityService:ActivityService,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private fb: FormBuilder, private reservationsService: ReservationsService) { }

	ngOnInit() {
		console.log(this.data)
		this.createForm();
		this.setActionTitle();
		this.setDateTitle();
		this.tripDestination = (this.data.day.tripId as TripModel).destination as string;
	}

	private setDateTitle() {
		this.dateTitle = `יום ${this.data.dayNum}, ${moment(this.data.day.date).format('DD.MM.YY')}`;
	}

	private setActionTitle() {
		this.actionTitle = this.data.data ? 'עריכת' : 'הוספת';
	}

	public get controls() {
		return this.form.controls;
	}

	private createForm() {
		const activity: ActivityResModel = Object.assign(new ActivityResModel(), this.data.data);
		this.form = this.fb.group({
			city:  new FormControl((activity.city as CityModel)._id, Validators.required),
			activity:  new FormControl((activity.activity as ActivityModel)._id, Validators.required),
			notes:  new FormControl(activity.notes),
			fullDay: new FormControl(activity.fullDay),
			isPublic: new FormControl(activity.isPublic),
			startTime: new FormControl(activity.startTime),
		});
		this.cityChanged()
	}

	public close() {
		this.dialogRef.close();
	}

	public cityChanged(){
			this.activityService.getByCity(this.form.value.city,this.form.value.fullDay).subscribe(res =>{
				this.currentActivities = res
			})
	}

	public onSubmit() {
		if (this.form.invalid) {
			return;
		}
		const form = this.form.controls;
		const activityRes: ActivityResModel = {
			city: form.city.value,
			activity: form.activity.value,
			notes: form.notes.value,
			fullDay: form.fullDay.value,
			startTime: form.startTime.value,
			isPublic: form.isPublic.value
		};

		if (this.data.data && this.data.data._id) {
			activityRes._id = this.data.data._id;
			this.updateActivityRes(activityRes);
		} else {
			this.createNewActivityRes(activityRes);
		}


	}

	private createNewActivityRes(newActivityRes: ActivityResModel) {
		this.reservationsService.createActivityRes(newActivityRes, this.data.day._id).subscribe(newActivity => {
			this.dialogRef.close(newActivity);
		});
	}

	private updateActivityRes(newActivityRes: ActivityResModel) {
		this.reservationsService.updateActivityRes(newActivityRes).subscribe(updatedActivity => {
			this.dialogRef.close(updatedActivity);
		});
	}

}
