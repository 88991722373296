import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FORM_PLACEHOLDERS, FORM_MESSAGES } from 'src/app/shared/texts';
import { BankTitles } from 'src/app/models/types/currencies.strings.enum';

@Component({
  selector: 'app-type-cheque',
  templateUrl: './type-cheque.component.html',
  styleUrls: ['./type-cheque.component.scss']
})
export class TypeChequeComponent implements OnInit {

  form:FormGroup
  public readonly PLACEHOLDERS = FORM_PLACEHOLDERS;
  public readonly INVALID_FORM = FORM_MESSAGES;
  BankTitles = BankTitles
  @Input() accountControl
  @Input() branchControl
  @Input() chequeControl
  @Input() bankControl

  constructor(
	  public fb:FormBuilder
  ) {
	  this.createForm()
   }

  ngOnInit() {
  }

  public get controls(){
	  return this.form.controls;
  }


	handler(value){
		console.log(value,'value')
	}


  createForm(){
	this.form = this.fb.group({
		accountNumber: new FormControl('', Validators.required),
		bankNumber: new FormControl('', Validators.required),
		chequeNumber: new FormControl('', Validators.required),
	});
	}

}
