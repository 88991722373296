import { Component, OnInit, ViewChild } from '@angular/core';
import { TripsService } from 'src/app/services/trips.service';
import { PricingService } from 'src/app/services/pricing.service';
import { ActivatedRoute } from '@angular/router';
import { TripModel } from 'src/app/models/trip/trip.model';
import { PricingGeneralDetailsComponent } from 'src/app/components/pricing/pricing-general-details/pricing-general-details.component';
import { PricingImportWarningPopupComponent } from 'src/app/components/pricing/popups/pricing-import-warning-popup/pricing-import-warning-popup.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-trip-pricing-page',
  templateUrl: './trip-pricing-page.component.html',
  styleUrls: ['./trip-pricing-page.component.scss']
})
export class TripPricingPageComponent implements OnInit {

  constructor(public dialog: MatDialog,public tripsService:TripsService,public pricingService:PricingService,public route:ActivatedRoute) { }

 currentStep:number = 1;
 routeId:string;
 tripData:TripModel
 currentCurrency:string;

 @ViewChild('general', { static: false }) generalDetails: PricingGeneralDetailsComponent;

  ngOnInit() {
	const leadSubscription = this.route.paramMap.subscribe((params: any) => {
		this.routeId = this.route.snapshot.paramMap.get('id');
		this.getPricingData(this.routeId)
	  })
}

	getPricingData(id){
		this.pricingService.getPricingData(id).subscribe( res => {
			this.tripData = res
			this.checkCurrency()
			if(res.pricing){
				this.tripData['pricing'] = res.pricing
			}
			setTimeout(() => {
				if(this.generalDetails){
					this.generalDetails.checkInputData()
				}
			}, );
		})

	}

	stepEmitted(step){
		
		if(step != 8){
			this.getPricingData(this.tripData._id)
		}
		this.currentStep = step
	}

	checkCurrency() {
		if(this.tripData && this.tripData.pricing && this.tripData.pricing.generalDetails.currency){
			let currency = this.tripData.pricing.generalDetails.currency
			if(currency === 1) this.currentCurrency = '$'
			if(currency === 2) this.currentCurrency = '€'
			if(currency === 3) this.currentCurrency = '₪'
		    if(currency === 4) this.currentCurrency = '£'
		}
	}

	getTripWithPricing(tripId){
		this.openImportWarning(tripId)
	}

	openImportWarning(tripId): void {
		const dialogRef = this.dialog.open(PricingImportWarningPopupComponent, {
		  width: '500px',
		  height: '394px',
		  disableClose:true,
		  panelClass: 'custom-right-dialog-container',
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.getPricingData(tripId)
		  } else {
			  this.generalDetails.selectedTripPricing = null
		  }
		});
	  }



}
