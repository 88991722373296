export const GROUND_SERVICE_TYPES = {
	1: 'שירותי קרקע',
	2: 'ויזה',
	3: 'מלון',
	4: 'ארוחות' ,
	5: 'מופע',
	6: 'כניסות',
	7: 'טיפים',
	8: 'ערכות שמע',
	9: 'אחר'
};

