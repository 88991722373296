import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../../shared/texts';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentModel } from '../../../../models/client/department.mode';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DestinationModel } from '../../../../models/client/destination.model';
import { LeadModel } from '../../../../models/client/lead.model';
import { LeadsService } from '../../../../services/leads.service';
import { UserService } from '../../../../services/user.service';
import { UserModel } from '../../../../models/client/user.model';
import { DataService } from '../../../../services/data.service';
import { Subscription } from 'rxjs';
import { TripTypeModel } from '../../../../models/client/tripType.model';
import { TripTypeService } from '../../../../services/trip-type.service';
import { DepartmentService } from '../../../../services/department.service';
import { DestinationService } from '../../../../services/destination.service';
import { ClientModel } from '../../../../models/client/client.model';
import { PaginationModel } from '../../../../models/client/pagination.model';
import { months, days } from '../../../../utils/times';
import * as moment from 'moment';
import { LeadSourceModel } from '../../../../models/client/leadSource.model';
import * as _ from 'lodash';
import { CustomValidatorsService } from '../../../../services/custom-validators.service';
import { UserProfileModel } from 'src/app/models/response/userProfile.model';

@Component({
	selector: 'app-new-lead',
	templateUrl: './new-lead.component.html',
	styleUrls: ['./new-lead.component.scss']
})
export class NewLeadComponent implements OnInit, OnDestroy {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public waitingResponse: boolean = false;
	public readonly months = months;
	public readonly days = days;
	public readonly years = [];
	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel(15);
	public leads: LeadModel[] = [];
	public userMinimize: UserModel[] = [];
	public destinations: DestinationModel[];
	public departments: DepartmentModel[];
	public tripTypes: TripTypeModel[] = [];
	public loggedInUser: UserModel;
	public leadSources: LeadSourceModel[] = [];
	public destinationByDepartment: { [id: string]: DestinationModel[]; } = {};
	public destinationsToShow: DestinationModel[] = [];
	public user: UserModel;
	public userSubscription: Subscription;
	public departmentChangeSubscription: Subscription;

	public clientSelected: ClientModel = new ClientModel();

	public lead: LeadModel;
	public actionBtnText = 'שמור/שמרי';

	constructor(private dialogRef: MatDialogRef<NewLeadComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private fb: FormBuilder,
		private leadService: LeadsService, private userService: UserService, private dataService: DataService,
		private departmentService: DepartmentService, private destinationService: DestinationService,
		private tripTypesService: TripTypeService, private customValidatorsService: CustomValidatorsService) {
		// this.destinations = this.data.destinations;
		// this.departments = this.data.departments;
		this.lead = this.data.lead;
		this.createForm();
		this.getUsersMinimize();
		this.getTripTypes();
		this.getDepartments();
		this.getDestinations();
		this.createYearsArray();
		this.getLeadSources();
		this.getGlobalUser();
		if (!this.lead) { // new lead
			this.actionBtnText = 'הוסף/הוסיפי';
		} else { // edit lead
			this.newDepartmentChange((this.lead.department as DepartmentModel)._id);
		}
	}

	public get controls() {
		return this.form.controls;
	}

	ngOnInit() {
	}

	public close() {
		this.dialogRef.close();
	}

	public submitLead() {
		this.userSubscription = this.dataService.globalUser.subscribe(user => {
			if (user) {
				this.user = user
				if (this.lead && this.lead._id) { // update lead
					this.updateLead();
				} else {
					this.addLead();
				}
			} else {
				console.log('error in sign')
			}
		});
	}

	private addLead() {
		this.waitingResponse = true;
		const newLead = this.createLeadFromForm();
		console.log('newLead', newLead)
		this.leadService.add(newLead, this.user._id).subscribe(resp => {
			this.waitingResponse = false;
			this.dialogRef.close(resp._id);
		}, err => {
			this.waitingResponse = false;
		});
	}

	private updateLead() {
		this.waitingResponse = true;
		const lead: LeadModel = this.createLeadFromForm();
		lead._id = this.lead._id;
		delete lead.createdAt;

		this.leadService.updateLead(lead, this.user._id).subscribe(resp => {
			this.waitingResponse = false;
			this.dialogRef.close(resp._id);
		}, error => {
			this.waitingResponse = false;
			this.dialogRef.close('error');
		});
	}

	public selectedClient(client: ClientModel) {
		this.clientSelected = client;
		this.manageSelectedClientChange();
	}

	public cancelClientSelection() {
		if (this.clientSelected._id) {
			this.clientSelected = new ClientModel();
			this.manageSelectedClientChange();
		}
	}

	ngOnDestroy(): void {
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
		// this.departmentChangeSubscription.unsubscribe();
	}

	public getLeadSources() {
		this.leadService.getLeadSources().subscribe(resp => {
			this.manipulateLeadSourceForSelect(resp);
		});
	}

	private manageDestinations() {
		this.destinationByDepartment = _.groupBy(this.destinations, (destination: DestinationModel) => destination.department);
		this.destinationsToShow = this.destinations;
	}

	private manageSelectedClientChange() {
		const disabled = this.clientSelected._id !== undefined;

		const { firstName, lastName, email, phone } = this.form.controls;

		firstName.reset({ value: this.clientSelected.personalDetails.firstName, disabled });
		lastName.reset({ value: this.clientSelected.personalDetails.lastName, disabled });
		email.reset({ value: this.clientSelected.personalDetails.email, disabled });
		phone.reset({ value: this.clientSelected.personalDetails.phoneNumber, disabled });
		this.form.patchValue({
			firstName: this.clientSelected.personalDetails.firstName,
			lastName: this.clientSelected.personalDetails.lastName,
			email: this.clientSelected.personalDetails.email
		})
	}

	private createForm() {
		const lead: LeadModel = this.lead ? this.lead : new LeadModel();
		let month: number;
		let year: number;
		let day: number;
		if (lead.departureDate) {
			month = moment(lead.departureDate).utc().startOf('day').month();
			year = moment(lead.departureDate).utc().year();
			day = moment(lead.departureDate).utc().date();
		}
		this.form = this.fb.group({
			firstName: new FormControl((lead.client as ClientModel).personalDetails.firstName, Validators.required),
			lastName: new FormControl((lead.client as ClientModel).personalDetails.lastName, Validators.required),
			email: new FormControl((lead.client as ClientModel).personalDetails.email, {
				validators: Validators.required,
				asyncValidators: this.customValidatorsService.asyncValidateEmail(),
				// updateOn: 'blur'
			}),
			phone: new FormControl((lead.client as ClientModel).personalDetails.phoneNumber, [Validators.required, Validators.maxLength(11), Validators.minLength(10), this.customValidatorsService.validateCellPhoneNumber]), // mobile and land line
			// phone: new FormControl((lead.client as ClientModel).personalDetails.phoneNumber, Validators.compose([Validators.required, Validators.pattern(/^05\d([-]{0,1})\d{7}$|^0\d([-]{0,1})\d{7}$/)])), // mobile and land line
			tripType: new FormControl((lead.tripType as TripTypeModel)._id),
			department: new FormControl((lead.department as DepartmentModel)._id),
			destination: new FormControl((lead.destination as DestinationModel)._id),
			supervisor: new FormControl((lead.supervisor as UserModel)._id, Validators.required),
			leadSource: new FormControl((lead.leadSource as LeadSourceModel)._id),
			month: new FormControl(month),
			day: new FormControl(day),
			receiveMail: new FormControl((lead.client as ClientModel).personalDetails.receiveMail),
			travelers: new FormControl(lead.travelers),
			year: new FormControl(year)
		}, { updateOn: 'change' });


		this.form.controls['phone'].valueChanges.subscribe(() => this.onPhoneNumberChange());
	}

	public manageDepartmentChange(newValue: string) {
		if (newValue) {
			this.destinationsToShow = this.destinationByDepartment[newValue];
			// const destinationExistOnDepartment = this.destinationByDepartment[newValue].some(dest => dest._id === this.form.controls.destination.value);
			// if (!destinationExistOnDepartment) {
			// 	this.form.controls.destination.setValue('');
			// }
		} else {
			this.destinationsToShow = this.destinations;
		}
	}

	public newDepartmentChange(newValue: string) {
		if (newValue) {
			this.destinationService.getAllCitiesByDestinationId(newValue).subscribe(res => {
				this.destinationsToShow = res
			})
		} else {
			this.destinationsToShow = this.destinations;
		}
	}


	private createLeadFromForm(): LeadModel {
		const {
			firstName,
			lastName,
			email,
			phone,
			tripType,
			department,
			destination,
			supervisor,
			leadSource,
			month,
			year,
			day,
			travelers,
			receiveMail
		} = this.form.controls;
		let dueDate: any
		if (year.value && month.value && day.value) {
			dueDate = moment().utc().startOf('day').year(year.value).month(month.value - 1).date(day.value).toISOString(true);
		} else if (year.value && month.value && !day.value) {
			dueDate = moment().utc().startOf('month').startOf('day').year(year.value).month(month.value - 1).toISOString(true);
		} else {
			dueDate = ''
		}

		let phoneNumber = phone.value
		// not really relevent because the input has protection - can be without condition, but the the string must have manipulation.
		// if (phoneNumber.charAt(phoneNumber.length - 7) !== '-') {
		// 	phoneNumber = phoneNumber.slice(0, phoneNumber.length - 7) + '-' + phoneNumber.slice(phoneNumber.length - 7, phoneNumber.length)
		// }

		const tempClient = Object.assign(new ClientModel(), {
			firstName: firstName.value,
			lastName: lastName.value,
			email: email.value,
			phoneNumber: phoneNumber,
			receiveMail: receiveMail.value
		});

		const lead = Object.assign(new LeadModel(), {
			tripType: tripType.value,
			department: department.value,
			destination: destination.value,
			supervisor: supervisor.value,
			leadSource: leadSource.value,
			departureDate: dueDate,
			travelers: travelers.value
		});
		if (this.clientSelected._id) {
			lead.client = this.clientSelected._id;
		} else {
			lead.clientTempInfo = tempClient;
		}

		return lead;
	}

	private getUsersMinimize() {
		this.userService.getUsersMinimize().subscribe(users => {
			let usersOriginal = users
			this.userMinimize = usersOriginal.filter((user: { [x: string]: number; }, i: any) => {
				return user['status'] === 1
			})
		});
	}

	private getTripTypes() {
		this.tripTypesService.all().subscribe(tripTypes => {
			this.tripTypes = tripTypes;
		});
	}

	private getGlobalUser() {
		this.userSubscription = this.dataService.globalUser.subscribe(user => {
			if (user) {
				this.loggedInUser = user;
			}
		});
	}

	private createYearsArray() {
		for (let i = 0; i < 5; i++) {
			const date = moment();
			date.add(i, 'years');
			this.years.push(date.year());
		}
	}

	private manipulateLeadSourceForSelect(leads: any[]) {
		const parentLeads = leads.filter((item: { isSubSource: any; }) => !item.isSubSource);
		const childLeads = leads.filter((item: { isSubSource: any; }) => item.isSubSource);

		const dic: { [id: string]: LeadSourceModel; } = {};
		parentLeads.forEach((lead: LeadSourceModel) => {
			lead.childs = [];
			dic[lead._id] = lead;
		});

		childLeads.forEach((item: LeadSourceModel) => {
			dic[item.parent].childs.push(item);
		});
		this.leadSources = [];
		// tslint:disable-next-line:forin
		for (const key in dic) {
			this.leadSources.push(dic[key]);
		}

	}

	private getDepartments() {
		this.departmentService.notAdministrative().subscribe(departments => {
			this.departments = departments;
			const userDepId = ((this.loggedInUser.department) as DepartmentModel)._id;
			const isSaleDepartment = this.departments.some(dep => dep._id === userDepId);
			if (isSaleDepartment) {
				this.form.controls.department.setValue(userDepId);
			}
		});
	}

	private getDestinations() {
		this.destinationService.all().subscribe(destinations => {
			this.destinations = destinations;
			this.manageDestinations();
		});
	}

	// This revalidates form on phone number input value change.
	onPhoneNumberChange() {
		const { phone } = this.form.controls;
		if (this.form.valid != phone.valid) {
			this.form.updateValueAndValidity();
		}
	}

}
