import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-providers-vouchers-resolved-tab',
  templateUrl: './providers-vouchers-resolved-tab.component.html',
  styleUrls: ['./providers-vouchers-resolved-tab.component.scss']
})
export class ProvidersVouchersResolvedTabComponent implements OnInit {


	@Input() data
	@Output() getVouchers = new EventEmitter()
	vouchers:any;
	count:number;


	ngOnInit() {
		this.getVouchers.emit('now')
	}

	nextPageOnClick(pageNumber){
	  console.log(pageNumber)
	}

}
