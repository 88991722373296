import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FlightService } from 'src/app/services/flight.service';
import { FORM_MESSAGES } from '../../../shared/texts';

@Component({
  selector: 'app-flights-destinations-autocomplete',
  templateUrl: './flights-destinations-autocomplete.component.html',
  styleUrls: ['./flights-destinations-autocomplete.component.scss']
})
export class FlightsDestinationsAutocompleteComponent implements OnInit {
	public readonly INVALID_FORM = FORM_MESSAGES;

	destination = new FormControl('',Validators.required);
	locationsList: any;
	filteredOptions: Observable<string[]>;
	@Input() controlDestination: FormControl;
	@Input() controlCode: FormControl;
	@Input() controlCountry: FormControl;
	@Input() controlCountryHeb: FormControl;
	@Input() controlCityHeb:FormControl;
	form:FormGroup

	constructor(public flightService:FlightService){}

	ngOnInit() {
	 this.getAllAirports()
	 if(this.controlDestination.value && this.controlCode.value){
		this.destination.setValue(this.controlDestination.value + ' ('+this.controlCode.value+')')
	}
	}

	private _filter(value): string[] {
		let filterValue
		if(value.CityNameEn){
			 filterValue = value.CityNameEn.toLowerCase()
		} else {
			 filterValue = value.toLowerCase();
		}
		return this.locationsList.filter(option => option.CityNameEn.toLowerCase().includes(filterValue) || option.airportCode.toLowerCase().includes(filterValue));
	}

	// todo: get all destinations
	getAllAirports(){
		this.flightService.all().subscribe( res => {
			this.locationsList = res
			this.filteredOptions = this.destination.valueChanges
			.pipe(
			  startWith(''),
			  map(value => this._filter(value))
			);
		})
	}

	setAirport(value){
		this.destination.setValue(value.CityNameEn + ' ('+value.airportCode+')')
		this.controlDestination.setValue(value.CityNameEn)
		this.controlCode.setValue(value.airportCode)
		this.controlCountry.setValue(value.CountryNameEn)
		this.controlCountryHeb.setValue(value.CountryNameHe)
		this.controlCityHeb.setValue(value.CityNameHe)
	}
}
