import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { HotelModel } from '../models/trip/hotel.model';

@Injectable({
	providedIn: 'root'
})
export class HotelService extends HttpHandlerService {
	private url: string = 'hotels/';


	public createHotel(hotel: HotelModel) {
		const path = '';
		return this.post(this.url + path, hotel);
	}

	public updateHotel(hotel: HotelModel) {
		const path = '';
		return this.put(this.url + path, hotel);
	}

	public deleteHotel(id: string) {
		const path = '';
		return this.delete(this.url + path, {id});
	}

	public getAll(cityId?: string) {
		return this.get(this.url, {cityId});
	}

	public getGroupedByCity(countryId: string) {
		const path = 'groupByCity';
		return this.get(this.url + path, {countryId});
	}

	public getHotelsByCity(cityId: string) {
		const path = 'byCity';
		return this.get(this.url + path, {cityId});
	}
}
