import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { ACTIVITY_FORM_PLACEHOLDERS, FORM_MESSAGES } from '../../../shared/texts';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityModel } from 'src/app/models/trip/activity.model';

@Component({
	selector: 'app-new-activity',
	templateUrl: './new-activity.component.html',
	styleUrls: [ './new-activity.component.scss' ]
})
export class NewActivityComponent implements OnInit, OnDestroy {

	@Input() activity: ActivityModel = new ActivityModel();
	public form: FormGroup;
	public readonly FORM_PLACEHOLDERS = ACTIVITY_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;
	private subscriptions: Subscription[] = [];

	constructor(private fb: FormBuilder,
				private activityService: ActivityService,
				@Inject(MAT_DIALOG_DATA) private data: { activity: ActivityModel, destination: string, city: string },
				private dialogRef: MatDialogRef<NewActivityComponent>) {
		this.activity = this.data.activity;
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		if ( !this.activity ) {
			this.activity = new ActivityModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		this.createForm();
	}

	ngOnDestroy() {
		for ( const subscription of this.subscriptions ) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}

	public save() {
		const form = this.form.controls;
		const activity = new ActivityModel();
		activity.name = form.name.value;
		activity.destination = this.data.destination;
		activity.brochureDescription = form.brochureDescription.value;
		activity.city = this.data.city;


		if ( this.activity._id ) {
			activity._id = this.activity._id;
			this.updateActivity(activity);
		} else {
			this.createActivity(activity);
		}
	}

	private createForm() {
		const { name, brochureDescription } = this.activity;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			brochureDescription: new FormControl(brochureDescription, Validators.required)
		});
	}

	private updateActivity(activity: ActivityModel) {
		const subscription = this.activityService.updateActivity(activity).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createActivity(activity: ActivityModel) {
		const subscription = this.activityService.createActivity(activity).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

}
