import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LeadModel } from '../../../../models/client/lead.model';

@Component({
	selector: 'app-lead-move-to-sale-popup',
	templateUrl: './lead-move-to-sale-popup.component.html',
	styleUrls: [ './lead-move-to-sale-popup.component.scss' ]
})
export class LeadMoveToSalePopupComponent implements OnInit {
	public isSuccess: boolean = false;
	private lead: LeadModel;


	constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<LeadMoveToSalePopupComponent>, @Inject(MAT_DIALOG_DATA) private data: LeadModel) {
		this.lead = data;
	}

	ngOnInit(): void {
	}

	close() {
		this.dialogRef.close(true);
	}

	afterSave() {
		this.dialogRef.updateSize('500px', '250px');
		this.isSuccess = true;
	}
}
