import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserModel } from '../models/client/user.model';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { UserProfileModel } from '../models/response/userProfile.model';
import { PermissionModel } from '../models/client/permission.model';
import { TaskModel } from '../models/client/task.model';
import { LeadModel } from '../models/client/lead.model';
import { TasksService } from './tasks.service';
import { PaginationModel } from '../models/client/pagination.model';
import { TaskQueryModel } from '../models/request/taskQuery.model';
import { TaskStatusEnum } from '../models/types/taskStatus.enum';

@Injectable({
	providedIn: 'root'
})
export class DataService {

	private user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(new UserModel());
	public globalUser = this.user.asObservable();


	private newTask: BehaviorSubject<LeadModel> = new BehaviorSubject<LeadModel>(undefined);
	public globalNewTask = this.newTask.asObservable();

	private tasks: BehaviorSubject<TaskModel[]> = new BehaviorSubject<TaskModel[]>([]);
	public globalTasks = this.tasks.asObservable();

	private permissions: PermissionModel[];
	leadSubscription: Subscription[] = [];

	public roleLevel:number;
	public currentId:string;

	// private user: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>( new UserModel() );
	// public globalUser = this.user.asObservable();

	constructor(private authService: AuthService, private userService: UserService, private router: Router, private taskService: TasksService) {
		const token = this.authService.getToken();
		if (token) {
			this.userService.getCurrentUserProfile().subscribe((data: UserProfileModel) => {
				const userModel = Object.assign(new UserModel(), data.user);
				this.permissions = data.permissions;
				this.roleLevel = data.user.role['level']
				this.currentId = data.user._id
				this.updateUser(userModel);
				console.log(this.roleLevel)
			});
		}
	}

	public get Permissions(): PermissionModel[] {
		return this.permissions;
	}


	public updateUser(user: UserModel) {
		this.user.next(user);
	}

	public updateNewTask(lead: LeadModel) {
		this.newTask.next(lead);
	}

	public updateTasks(tasks: TaskModel[]) {
		this.tasks.next(tasks);
	}

	public login(loginResponse: UserProfileModel) {
		this.authService.setToken(loginResponse.token);
		this.permissions = loginResponse.permissions;
		this.updateUser(loginResponse.user);
	}

	public logOut() {
		this.updateUser(new UserModel());
		this.updateTasks([]);
		this.permissions = [];
		this.authService.logout();
		this.router.navigateByUrl('/login');
	}

	public getUserTasks() {
		this.userService.getCurrentUserProfile().subscribe((data: UserProfileModel) => {
			const userModel = Object.assign(new UserModel(), data.user);
			const taskQuery: TaskQueryModel = new TaskQueryModel(undefined, undefined, undefined, TaskStatusEnum.Open,userModel._id,userModel.role['level']);
			this.taskService.getTasks(taskQuery).subscribe(tasks => {
				this.updateTasks(tasks);
			});
		});
	}
}
