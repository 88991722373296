import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FORM_MESSAGES } from 'src/app/shared/texts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-finance-add-payment-pop',
	templateUrl: './finance-add-payment-pop.component.html',
	styleUrls: ['./finance-add-payment-pop.component.scss']
})
export class FinanceAddPaymentPopComponent implements OnInit {

	constructor(
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.createForm()
	}

	public readonly INVALID_FORM = FORM_MESSAGES;
	form: FormGroup

	ngOnInit() {

	}

	public get controls() {
		return this.form.controls;
	}

	private createForm() {
		this.form = this.fb.group({
			title: new FormControl('', Validators.required),
			price: new FormControl(null, Validators.required),
			checked:new FormControl(false)
		});
	}

	closeDialog(value?) {
		this.dialogRef.close(value);
	}

}
