import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TaskModel } from 'src/app/models/client/task.model';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { TaskNewDuedatePopupComponent } from 'src/app/components/task-new-duedate-popup/task-new-duedate-popup.component';
import { Router } from '@angular/router';
import { LeadModel } from 'src/app/models/client/lead.model';

@Component({
	selector: 'app-task-notification',
	templateUrl: './task-notification.component.html',
	styleUrls: ['./task-notification.component.scss']
})
export class TaskNotificationComponent implements OnInit, OnDestroy {

	@ViewChild('tsakElement', { static: false }) tsakElement: HTMLDivElement;
	@Input() task: TaskModel;
	@Output() delay: EventEmitter<{}> = new EventEmitter();
	@Output() dismiss: EventEmitter<void> = new EventEmitter();
	@Output() newDate: EventEmitter<void> = new EventEmitter();
	private delaySubscription: Subscription = new Subscription();
	public moment = moment;
	public fadeOut: boolean = false;

	constructor(private dialog: MatDialog, private router: Router) { }

	ngOnInit() {
	}

	ngOnDestroy() {
		this.delaySubscription.unsubscribe();
	}

	public goToLead() {
		this.close();
		this.router.navigateByUrl(`/app/leads/${ (this.task.lead as LeadModel)._id }`);
	}

	public delayTask(delayTime: number) {
		this.fadeOut = true;
		setTimeout(() => {
			this.delay.emit({taskId: this.task._id, delayTime});
		}, 1 * 1000);
	}

	public close() {
		this.fadeOut = true;
		setTimeout(() => {
			this.dismiss.emit();
		}, 1 * 1000);
	}

	public setNewTaskDate() {
		const openedDialog = this.dialog.open(TaskNewDuedatePopupComponent, {
			panelClass: 'custom-dialog-container',
			backdropClass: 'custom-backdropClass',
			data: this.task
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if ( confirm ) {
				this.newDate.emit();
			}
		});
	}


}
