import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Form, ValidatorFn } from '@angular/forms';
import { UserService } from './user.service';
import { ClientService } from './client.service';
import { catchError, map } from 'rxjs/operators';
import { AccessService } from './access.service';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root'
})
export class CustomValidatorsService {
	constructor(private userService: UserService, private clientService: ClientService, private accessService: AccessService) {
	}

	public expirationDateValidator(control: FormControl) {
		if (control.value) {
			const time = moment(control.value);
			const now = moment(new Date());
			return time.isAfter(now) ? null : { 'invalidDate': true };
		} else {
			return null;
		}
	}

	public issuingDateValidator(control: FormControl) {
		if (control.value) {
			const time = moment(control.value);
			const now = moment(new Date());
			return time.isBefore(now) ? null : { 'invalidDate': true };
		} else {
			return null;
		}
	}
	public asyncValidateUserEmailExist(emailControl: FormControl) {
		const email = emailControl.value;
		if ((this as any).userToUpdate && (this as any).userToUpdate.email === email) {
			return new Promise(null);
		}
		return this.userService.checkIfEmailNotExist(email).pipe(map(resp => {
			return (!resp || resp.exist) ? { emailExist: true } : null;
		}));
	}

	public asyncValidatePhone(phoneControl: FormControl) {
		const phoneNumber = phoneControl.value;
		if ((this as any).client && (this as any).client.personalDetails && (this as any).client.personalDetails.phoneNumber === phoneNumber) {
			return new Promise(null);
		} else {
			return this.clientService.checkIfPhoneNotExist(phoneNumber).pipe(map(resp => {
				return (resp && resp.exist) ? { phoneExist: true } : null;
			}));
		}
	}

	public validateCellPhoneNumber(control: FormControl): ValidatorFn {
		return () => {
			let output: null | {};
			if (control.value) {
			output = control.value.length == 10 ? null : { 'invalidPhone': true };
			return output;			
		} else {
			return null;
		}}
	}
	public validateIsraelId(control: FormControl) {
		let invalid = false;

		let id = String(control.value).trim();
		if (id.length > 9 || id.length < 5 || isNaN((+id || NaN))) {
			invalid = true;
		}
		if (!invalid) {
			id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
			const calculated = Array.from(id, Number)
				.reduce((counter, digit, i) => {
					const step = digit * ((i % 2) + 1);
					return counter + (step > 9 ? step - 9 : step);
				}) % 10 === 0;
			invalid = !calculated;
		}

		if (invalid) {
			return { invalidId: true };
		} else {
			return null;
		}
	}

	public confirmPasswordValidator(password: string, confirmPassword: string) {

		return (group: FormGroup) => {
			const passwordControl = group.controls[password];
			const confirmPasswordControl = group.controls[confirmPassword];

			if (!passwordControl.value || !confirmPasswordControl.value) {
				return null;
			}
			if (passwordControl.value !== confirmPasswordControl.value) {
				return confirmPasswordControl.setErrors({ mismatch: true });
			} else {
				return null;
			}
		};
	}


	public validateTravelersRange(control: FormControl) {
		const range = control.value.trim().split('-');
		if (isNaN(Number(range[0])) || isNaN(Number(range[1]))) {
			return { invalidRange: true };
		}

		return null;
	}


	asyncValidateEmail(): AsyncValidatorFn {
		return (control: AbstractControl) => {
			const email = control.value;
			return this.accessService.isEmail(email).pipe(map(resp => {
				return (resp && resp.invalid) ? { invalidEmail: true } : null;
			}));
		};
	}
}
