import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HotelService } from '../../../services/hotel.service';
import { TableColModel } from '../../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../../shared/texts';
import { ColSize } from '../../../models/types/colSize.enum';
import { HotelModel } from 'src/app/models/trip/hotel.model';
import { NewHotelComponent } from '../new-hotel/new-hotel.component';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
	selector: 'app-hotels-table',
	templateUrl: './hotels-table.component.html',
	styleUrls: ['./hotels-table.component.scss']
})
export class HotelsTableComponent implements OnInit, OnDestroy {

	public tableHeight: number = window.innerHeight - 420;
	public cols: TableColModel[] = [];
	@Input() hotels: HotelModel[] = [];
	@Output() edited = new EventEmitter();
	private subscriptions: Subscription[] = [];

	constructor(private dialog: MatDialog, private hotelService: HotelService) { }

	ngOnInit() {
		this.createColsTableItem();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.NAME, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.PHONE, 'phone', ColSize.MEDIUM, 'phone', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.HOTEL.WEBSITE, 'hotelWeb', ColSize.SMALL, 'link', undefined, undefined));
	}

	public editClicked(hotel: HotelModel) {
		const openedDialog = this.dialog.open(NewHotelComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { hotel, city: hotel.city, destination: hotel.destination }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.edited.emit();
			}
		});
	}

	public removeClicked(hotel: any) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveHotel,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.removeHotel(hotel._id);
			}
		});
	}

	private removeHotel(hotelId: string) {
		const subscription = this.hotelService.deleteHotel(hotelId).subscribe(success => {
			this.edited.emit();
		});

		this.subscriptions.push(subscription);
	}


}
