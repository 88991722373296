import { NotesAndRequestsModel } from './../../models/trip/notesAndRequests.model';
import { ClientService } from 'src/app/services/client.service';
import { ClientInfoModel, ClientModel } from './../../models/client/client.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadsService } from 'src/app/services/leads.service';
import { TripsService } from 'src/app/services/trips.service';
import { LeadModel } from 'src/app/models/client/lead.model';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { TripClientOptionEnum } from 'src/app/models/types/tripClientOption.enum';
import { TRIP_CLIENT_OPTION } from '../../shared/texts/TripClientOptions';
import { DataService } from 'src/app/services/data.service';
import { NotesService } from 'src/app/services/notes.service';
import { TaskModel } from 'src/app/models/client/task.model';
import * as moment from 'moment';
import { TaskStatusEnum } from 'src/app/models/types/taskStatus.enum';
import { TasksService } from 'src/app/services/tasks.service';
import { TripNotesAndRequestsComponent } from 'src/app/components/trip/trip-notes-and-requests/trip-notes-and-requests.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TripPaymentComponent } from 'src/app/components/trip/trip-payment/trip-payment.component';
import { TripModel } from 'src/app/models/trip/trip.model';
import { TripNotesComponent } from 'src/app/components/trip/trip-notes/trip-notes.component';
import { LeadCancelPopupComponent } from 'src/app/components/lead/popups/lead-cancel-popup/lead-cancel-popup.component';

@Component({
	selector: 'app-trip-lead-page',
	templateUrl: './trip-lead-page.component.html',
	styleUrls: ['./trip-lead-page.component.scss']
})
export class TripLeadPage implements OnInit {


	public waitingToLead: boolean = true;
	public lead: LeadModel = new LeadModel();
	public trip: TripModel = new TripModel()
	public tripDate: string;
	public client: ClientModel = new ClientModel();
	public destination: DestinationModel = new DestinationModel();
	public withLeadChanges: boolean = false;
	public withLeadClose: boolean = false;
	public withLeadEdit: boolean = false;
	public leadName: string = '';
	public subscriptions: Subscription[] = [];

	public allTasks: TaskModel[] = [];
	public tasksList: TaskModel[] = [];
	public nextTask: TaskModel;

	@ViewChild(TripNotesAndRequestsComponent, { static: false }) notesAndRequest: TripNotesAndRequestsComponent;
	@ViewChild(TripNotesComponent, { static: false }) tripNotes: TripNotesComponent;

	public TripClientOptionEnum = TripClientOptionEnum;
	public showingComponent: TripClientOptionEnum = TripClientOptionEnum.Summary;
	public TripClientOptions = TRIP_CLIENT_OPTION;
	public isClient: string;
	public personalDetail: ClientInfoModel;
	public isCancelled = false;
	constructor(
		private leadService: LeadsService,
		private router: Router,
		private route: ActivatedRoute,
		private dataService: DataService,
		private noteService: NotesService,
		public tasksService: TasksService,
		public dialog: MatDialog,
		public tripsService: TripsService,
		public clientService: ClientService
	) { }

	ngOnInit() {
		const leadSubscription = this.route.paramMap.subscribe((params: any) => {
			const id = this.route.snapshot.paramMap.get('id');
			this.route.queryParams.forEach(parameter => {
				this.isClient = parameter.isClient;
				this.clientService.getActiveClient(parameter.personalDetails).subscribe(res => {
					this.personalDetail = res
					this.getLeadData(id);
				});
			});
		});

		this.subscriptions.push(leadSubscription)

		const tasksSubscription = this.dataService.globalTasks.subscribe((tasks: TaskModel[]) => {
			this.allTasks = tasks;
			this.filterTasks();
		});
		this.subscriptions.push(tasksSubscription);
		this.getTasks();

		this.dataService.leadSubscription.push(tasksSubscription);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		})
	}

	public closeTripLead() {

		let lead = this.lead
		lead.status = 2
		if (typeof this.isClient === 'undefined') { //undefined
			console.log('undefined');
			this.leadService.updateLead(lead, lead._id).subscribe((lead: LeadModel) => {
				console.log(lead)
			});
		}
		if (this.isClient) {
			console.log('true');
		}
		else {
			console.log('false');
		}
	}

	public closeLeadClicked() {

		this.dialog.open(LeadCancelPopupComponent, {
			width: '600px',
			height: '600px',
			position: { top: '10%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { lead: this.lead, client: this.isClient, personalDetails: this.personalDetail,trip:this.trip }
		});
	}

	private getLeadData(id) {
		this.leadService.getLeadProfile(id).subscribe((lead: LeadModel) => {
			this.waitingToLead = false;
			this.lead = lead;
			this.client = this.lead.client as ClientModel;
			this.destination = this.lead.destination as DestinationModel;
			this.lead.createdAt = new Date(this.lead.createdAt);
			this.leadName = `${(lead.client as ClientModel).personalDetails.firstName} ${(lead.client as ClientModel).personalDetails.lastName}`;
			if (this.notesAndRequest) {
				this.notesAndRequest.primaryDetails = this.lead.lead_preferences
				this.notesAndRequest.createForm()
				this.notesAndRequest.isEdit = false;
			}
			this.getLeadTrip()
			if (this.tripNotes) {
				setTimeout(() => {
					this.tripNotes.getNotes()
				}, 0);
			}
			this.clientService.getProfile((this.lead.client as ClientModel)._id).subscribe((item: ClientModel) => this.lead.client = this.client = item);
			this.determineLeadStatus();
		}, (err) => {
			console.log(err);
			this.router.navigateByUrl('/app/clients');
		});

	}

	private getLeadTrip() {
		this.tripsService.getTripByID(this.lead.tripId).subscribe(res => {
			this.trip = res
			this.tripDate = moment(res.departureDate).format("DD.MM.YYYY")
		})
	}

	public changeComponent(showComponent: TripClientOptionEnum) {
		this.showingComponent = showComponent;
	}

	private getTasks() {
		this.dataService.getUserTasks();
	}

	private filterTasks() {
		// tslint:disable-next-line:max-line-length
		this.tasksList = this.allTasks.filter((task: TaskModel) => ((task.status === TaskStatusEnum.Open || task.status === TaskStatusEnum.Completed) && (task.lead as LeadModel) && (task.lead as LeadModel)._id === this.lead._id));
		const futureTasks = this.tasksList.filter(task => {
			const now = moment();
			const nowUtc = moment.utc().hours(now.hour()).minute(now.minute());
			const taskDue = moment.utc(task.dueDate);
			return taskDue.isSameOrAfter(nowUtc);
		});
		if (futureTasks.length === 0) {
			this.nextTask = undefined;
		} else {
			this.nextTask = futureTasks[0];
		}
		this.tasksList.reverse();
	}

	public setTaskAsDone(task: TaskModel) {
		task.status = TaskStatusEnum.Completed;
		const subscription = this.tasksService.updateTask(task).subscribe(resp => {
			this.getTasks();
		});

		this.subscriptions.push(subscription);
	}

	public moveToTrip() {
		this.router.navigateByUrl(`/app/trips/details/${this.trip._id}`)
	}

	openPaymentDialog(): void {
		const dialogRef = this.dialog.open(TripPaymentComponent, {
			data: '',
			height: '775px',
			width: '580px',
			disableClose: true,
			panelClass: 'payment-dialog-container'
		});

		dialogRef.afterClosed().subscribe(result => {
		});
	}
	determineLeadStatus() {
		if (typeof this.isClient == 'undefined' || this.isClient == 'false') {
			this.isCancelled = this.lead.status === 7;
		}
		else {
			console.log(this.lead);
			this.personalDetail['trips'].filter(item => item['tripId'] == this.lead.tripId).forEach(element => {
				if (element['status'] === 7)
					this.isCancelled = true;
			});;
		}
	}

}
