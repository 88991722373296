import { Injectable } from '@angular/core';
import { TaskModel } from '../models/client/task.model';
import { HttpHandlerService } from './http-handler.service';
import { TaskQueryModel } from 'src/app/models/request/taskQuery.model';
import { TaskStatusEnum } from '../models/types/taskStatus.enum';
import * as moment from 'moment';


@Injectable({
	providedIn: 'root'
})
export class TasksService extends HttpHandlerService {
	private url: string = 'tasks/';


	public addNewTask(task: TaskModel) {
		return this.post(this.url, task);
	}

	public getTasks(taskQuery: TaskQueryModel) {
		return this.get(this.url, taskQuery.toQueryObject());
	}

	public getTasksByStatus(status: TaskStatusEnum) {
		const path = 'getByStatus';
		return this.get(this.url + path, { status });
	}


	public getTasksByDueDate(date: string, userId, level: number) {
		const path = 'getByDueDate';
		return this.get(this.url + path, { dayDate: date, userId, level });
	}

	public updateTask(task: TaskModel) {
		return this.put(this.url, task);
	}

	public deleteTask(taskId: string) {
		return this.delete(this.url, { taskId });
	}

	public delayTask(taskId: string, delayTime?: number) { // default is 15 minutes
		const path = 'delay';
		return this.put(this.url + path, { taskId, delayTime });
	}
}
