import { Component, OnInit } from '@angular/core';
import { SingleTripStatusEnum, SINGLE_TRIP_ENUM_STRINGS } from 'src/app/models/trip/tripStatus.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { TripsService } from '../../services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import * as moment from 'moment';
import { ConfirmPopupComponent } from '../../components/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material';
import { TripClassEnum } from '../../models/types/tripClass.enum';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { GuideModel } from 'src/app/models/trip/guide.model';
import * as buffer from 'buffer';

@Component({
	selector: 'app-trip-build-page',
	templateUrl: './trip-build-page.component.html',
	styleUrls: ['./trip-build-page.component.scss']
})
export class TripBuildPageComponent implements OnInit {

	public trip: TripModel = new TripModel();

	public tripDetailsArray: { icon: string, name: string, content }[] = [];
	public singleTripStatusEnum = SingleTripStatusEnum;
	public TRIP_ENUM_STRINGS = SINGLE_TRIP_ENUM_STRINGS;
	public showingPage: SingleTripStatusEnum = SingleTripStatusEnum.Schedule;

	constructor(private activatedRoute: ActivatedRoute,
		private tripsService: TripsService,
		private dialog: MatDialog,
		private router: Router) { }

	ngOnInit() {
		this.getTripByID();
	}

	public getTripByID() {
		const tripId = this.activatedRoute.snapshot.paramMap.get('id');
		this.tripsService.getTripByID(tripId).subscribe(trip => {
			this.trip = Object.assign(new TripModel(), trip);
			this.setTripHeader();
		}, err => {
			console.log('error', err);
			this.router.navigateByUrl('/app/trips');
		});
	}

	public getItinerary() {
		this.tripsService.getTripIitinerary(this.trip._id, false).subscribe(res => {
			let url = window.URL.createObjectURL(res);
			let a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.download = `${this.trip.name}-itinerary.xlsx`;
			document.body.style.height = '100%'
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		}, err => {
			console.log(err);
		})
	}

	private setTripHeader() {
		this.tripDetailsArray = [];
		const departureDate = moment.utc(this.trip.departureDate).format('DD.MM.YY');
		const range = `${this.trip.maxTravelers}`;
		const tripClass: string = this.trip.getTripClassName();
		this.tripDetailsArray.push({ icon: '/assets/images/trip-type.svg', name: 'סוג הטיול', content: tripClass });
		this.tripDetailsArray.push({ icon: '/assets/images/trip-destination.svg', name: 'יעד', content: (this.trip.destination as DestinationModel).name });
		this.tripDetailsArray.push({ icon: '/assets/images/trip-division.svg', name: 'מחלקה', content: (this.trip.department as DepartmentModel).name });
		this.tripDetailsArray.push({ icon: '/assets/images/trip-dates.svg', name: 'תאריכי טיול', content: departureDate });
		if (this.trip.tripClass === TripClassEnum.FIX || this.trip.tripClass === TripClassEnum.GROUP) {
			this.tripDetailsArray.push({ icon: '/assets/images/trip-instructor.svg', name: 'מדריך', content: (this.trip.guide as GuideModel).name });
		}
		this.tripDetailsArray.push({ icon: '/assets/images/trip-num-of-travelers.svg', name: 'מספר מטיילים', content: range });
	}

	public changeShowingPage(toPage: SingleTripStatusEnum) {
		this.showingPage = toPage;
	}

	public editTripClicked() {
		this.router.navigateByUrl(`app/trips/edit/${this.trip._id}`);
	}

	public makeTripActive() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.SetTripAsCompleted,
		});


		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.setTripAsCompleted(this.trip._id);
			}
		});
	}

	private setTripAsCompleted(tripId: string) {
		this.tripsService.setAsCompleted(tripId).subscribe(success => {
			this.router.navigateByUrl(`app/trips/details/${tripId}`);
		});
	}

	public deleteTripClicked() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveTripBuild,
		});


		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.deleteTrip(this.trip._id);
			}
		});
	}

	private deleteTrip(tripId: string) {
		this.tripsService.deleteTrip(tripId).subscribe(success => {
			this.router.navigateByUrl('app/trips/inprogress');
		})
	}

}
