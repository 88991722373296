import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../shared/texts';
import { ColSize } from '../../models/types/colSize.enum';
import { SortableColModel } from 'src/app/models/client/sortableCol';
import { SortableOptionsEnum } from 'src/app/models/types/sortableOptions.enum';
import { TripClassEnum } from 'src/app/models/types/tripClass.enum';
import { TripsService } from 'src/app/services/trips.service';
import { TripModel } from 'src/app/models/trip/trip.model';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { PaginationModel } from 'src/app/models/client/pagination.model';
import { TripStatusEnum } from 'src/app/models/trip/tripStatus.enum';
import { Subscription } from 'rxjs';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { MatDialog } from '@angular/material';
import { DepartmentService } from 'src/app/services/department.service';
import { SortTypeEnum } from 'src/app/models/types/sortType.enum';
import { isArray } from 'util';
import { DestinationService } from 'src/app/services/destination.service';
import { FilterService } from 'src/app/services/filter.service';
import { TripQueryModel } from 'src/app/models/request/tripQuery.model';

@Component({
	selector: 'app-past-trips-page',
	templateUrl: './past-trips-page.component.html',
	styleUrls: ['./past-trips-page.component.scss']
})
export class PastTripsPageComponent implements OnInit {

	public form: FormGroup;
	public pagination: PaginationModel = new PaginationModel(15);
	public trips: TripModel[] = [];
	public cols: TableColModel[] = [];
	public sortableCol: SortableColModel;
	public tableHeight: number = window.innerHeight - 440;
	@Output() rowClickedAction = new EventEmitter();
	@Input() completed: boolean; // filters between completed trips to trip in build process
	private readonly tripStatus = TripStatusEnum.Fix;
	private subscriptions: Subscription[] = [];
	public destinations: DestinationModel[] = [];
	public departments: DepartmentModel[] = [];

	constructor(private dialog: MatDialog, private fb: FormBuilder, private tripService: TripsService, private departmentService: DepartmentService, public destinationService: DestinationService, public filterService: FilterService) {
		this.sortableCol = new SortableColModel(SortableOptionsEnum.TripName, true);
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.createColsTableItem();
		this.getDepartments();
		this.updateDestinations(undefined)
		this.createForm();
		this.getTrips();
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	private getDepartments() {
		const subscription = this.departmentService.all().subscribe(departments => {
			this.departments = departments;
		});
		this.subscriptions.push(subscription);
	}

	public clearFilters() {
		this.form.reset();
		this.filterService.clearFilters()
		this.updateDestinations(undefined)
		this.afterFilterChange();
	}


	public goToPage(nextPage: number) {
		this.pagination.nextPage = nextPage;
		this.getTrips();
	}

	public sortClicked(col: TableColModel) {
		this.sortableCol = new SortableColModel(col.colSortable.value, true);
		if (col.colSortable.SortType === SortTypeEnum.Descending) {
			this.sortableCol.setDescending();
		} else {
			this.sortableCol.setAscending();
		}
		this.pagination.resetPagination();
		this.getTrips();
	}

	private updateDestinations(departmentId: string) {
		const subscription = this.destinationService.getAllCitiesByDestinationId(departmentId).subscribe(resp => {
			this.destinations = resp;
			if (!isArray(this.destinations) || !this.destinations.find(d => d._id === this.filterService.currentDepartment)) {
				this.form.controls.destination.setValue(undefined);
			}
			this.afterFilterChange();
		});
		this.subscriptions.push(subscription);
	}

	public afterDepartmentChange() {
		this.updateDestinations(this.filterService.currentDepartment);
	}

	public afterFilterChange() {
		this.pagination.resetPagination();
		this.getTrips();
	}
	public clearClicked() {
		this.controls['searchText'].setValue('');
		this.afterFilterChange();
	}
	public rowClicked(trip: TripModel) {
		this.rowClickedAction.emit(trip._id);
	}

	private getTrips() {
		const searchText = this.form.value.searchText;
		const destinationId = this.filterService.currentDestination;
		const departmentId = this.filterService.currentDepartment;
		const tripQuery = new TripQueryModel(undefined,undefined,destinationId, departmentId, searchText,undefined, 2);
		this.tripService.getAllTrips(tripQuery).subscribe(allTrips => {

			this.trips = [];
			allTrips.forEach(trip => {
				const newTrip: TripModel = Object.assign(new TripModel(), trip);
				newTrip.setFormattedDate();
				this.trips.push(newTrip);
			});
		});
	}


	private createForm() {
		this.form = this.fb.group({
			destination: new FormControl(''),
			department: new FormControl(''),
			searchText: new FormControl(''),
		});
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.NUMBER, 'tripNumber', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.NAME, 'name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DATES, 'departureDate', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.DueDate, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.TOTAL, '', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.INSTRUCTOR, 'guide.name', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.OPERATOR, '', undefined, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DESTINATION, 'destination.name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.TRIPS.DIVISION, 'department.name', undefined, undefined, undefined, new SortableColModel(SortableOptionsEnum.FirstName, true)));
	}
}
