export const TABLE_COLS_NAMES = {
	USERS: {
		CLIENT_NUMBER: "מס' תיק",
		FIRST_NAME: 'שם פרטי',
		LAST_NAME: 'שם משפחה',
		EMAIL: 'דוא״ל',
		POSITION: 'תפקיד',
		STATUS: 'סטטוס',
		DEPARTMENT: 'מחלקה',
		ACTIONS: 'פעולות',
		PHONE: 'טלפון',
	},
	LEADS: {
		FIRST_NAME: 'שם פרטי',
		LAST_NAME: 'שם משפחה',
		EMAIL: 'דוא״ל',
		PHONE: 'טלפון',
		INTERESTED: 'מתעניין ב',
		SOURCE: 'מקור',
		STATUS: 'סטטוס',
		LOCATION: 'יעד',
		DUE_DATE: 'מועד יציאה',
		DATE: 'בתאריך',
		TRIP_DATE: 'תאריך טיול',
		NEXT_ASSIGMENT: 'המשך טיפול',
		SUPERVISOR: 'גורם מטפל',
		TRIP_NAME: 'שם הטיול',
		PAID: 'סך הכל שולם',
		TO_PAY: 'סך הכל לתשלום',
		REJECTED_REASON: 'סיבת ביטול',
		DATE_ENTERED: 'תאריך כניסה'
	},
	CLIENT: {
		TRIP_NAME: 'שם פרטי',
		LAST_NAME: 'שם משפחה',
		EMAIL: 'דוא״ל',
		PHONE: 'טלפון',
		INTERESTED: 'מתעניין ב',
		SOURCE: 'מקור',
		STATUS: 'סטטוס',
		LOCATION: 'יעד',
		DUE_DATE: 'מועד יציאה',
		DATE: 'בתאריך',
		TRIP_DATE: 'תאריך טיול',
		NEXT_ASSIGMENT: 'המשך טיפול',
		SUPERVISOR: 'מטפל'
	},
	TRIPS: {
		NUMBER: "מס' טיול",
		NAME: 'שם הטיול',
		DATES: 'תאריכי הטיול',
		TOTAL: 'עלות',
		INSTRUCTOR: 'מדריך',
		OPERATOR: 'אופרטור',
		DESTINATION: 'יעד',
		DIVISION: 'מחלקה',
		STATUS: 'סטטוס',
	},
	TRAVELER: {
		NAME_HEB: 'שם (עברית)',
		NAME_LAST:'שם משפחה',
		NAME_EN: 'שם (אנגלית)',
		PASSPORT: 'מספר דרכון',
		PHONE: 'טלפון',
		EMAIL: 'דוא״ל',
		TOTAL: 'סכום לתשלום',
		NOTES: 'הערות',
		ROOMS:'חדרים',
		PARTNER:'שותף לחדר',
		PAYMENTS:'גביות',
		PAY_STATUS:'סטטוס תשלום'
	},
	COUNTRY: {
		STATE: 'יעד',
		CITY: 'עיר',
		HOTELS_NUM: 'מלונות',
		TOURS_NUM: 'סיורים'
	},
	HOTEL: {
		NAME: 'שם המלון',
		PHONE: 'טלפון',
		WEBSITE: 'אתר המלון',
		DAY: 'יום',
		COUNTRY: 'ארץ',
		CITY: 'עיר',
		CHECKIN: 'צ׳ק אין',
		CHECKOUT: 'צ׳ק אוט',
		ROOM_TYPE: 'סוג החדר',
	},
	ACTIVITY: {
		NAME: 'שם הסיור',
		DESCRIPTION: 'תיאור',
		DURATION: 'משך זמן'
	},
	GUIDE: {
		NAME: 'שם המדריך',
		PHONE: 'טלפון',
		EMAIL: 'דוא״ל'
	},
	FLIGHTS: {
		DAY: 'יום',
		FLIGHT_NUMBER: 'מספר טיסה',
		AIR_COMPANY: 'חברת תעופה',
		DEPARTURE: 'המראה',
		LANDING: 'נחיתה',
	},
	PROVIDERS: {
		NAME: 'שם הספק',
		TYPE: 'סוג',
	},
	PRICE_FLIGHTS:{
		ROUTE:'מסלול טיסה',
		AIRLINE:'חברת תעופה',
		PRICE:'עלות',
		TAXES:'מסי נמל',
		NOTES:'פירוט והערות'
	},
	GROUND_SERVICE:{
		SERVICE:'סוג השירות',
		SUPPLIER:'ספק',
		NOTES:'פירוט והערות',
		CASH_AGENT:'מזומן \ לסוכן'
	},
	GROUND_EXPENSES:{
		GROUP_EX:'הוצאות קבוצתיות',
		NOTES:'פירוט והערות',
	},
	CASH_EXPENSES:{
		EXPENSE:'הוצאה',
		SUM:'סכום',
		DAYSUM:'סכום ליום'
	},
	SUMMARY:{
		flightPricing:'טיסות',
		groundServices:'שירותי קרקע',
		guideExpenses:'הדרכה',
		freeTravelersDetails:'חופשיים',
		groupExpenses:'הוצאות קבוצתיות',
		agentFee:'עמלת סוכן/ איש מכירות',
		PriceWithoutPortTaxes:'מחיר ללא מיסי נמל',
		tripNetTotalCost:'סה״כ עלות נטו',
		tripPriceForCostumer:'מחיר כולל',
		portTaxes:'מיסי נמל',
		tripGrossTotalCost:'סה״כ עלות ברוטו',
		recommendedTripGrossTotalCost:'מחיר מומלץ',
		profitPerGroup:'רווח פר קבוצה',
		profitPlusPrecent:'רווח + אחוז רווח',
		vat:'מע״מ',
		creditCardFee: 'עמלת אשראי',
		cashExpenses: 'הוצאות במזומן'
	}
};
