import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-actions-componenets',
  templateUrl: './pricing-actions-componenets.component.html',
  styleUrls: ['./pricing-actions-componenets.component.scss']
})
export class PricingActionsComponenetsComponent implements OnInit {

  isEdit:boolean = false

  constructor() { }

  ngOnInit() {
  }

  emitIsEdit(){
	  this.isEdit = true;
  }

}
