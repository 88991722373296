import {Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker-month-year',
  templateUrl: './datepicker-month-year.component.html',
  styleUrls: ['./datepicker-month-year.component.scss'],
})
export class DatepickerMonthYearComponent implements OnInit {

  constructor() { }

  public moment = moment

  @Input() control:FormControl
  @Output() dateEmitted = new EventEmitter()

  ngOnInit() {
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
	let ctrlValue = this.control.value;
    ctrlValue = normalizedYear['_d'];
	this.control.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: moment.Moment, picker: MatDatepicker<moment.Moment>) {
	let ctrlValue = this.control.value;
    ctrlValue = normalizedMonth;
	this.control.setValue(ctrlValue);
	picker.close();
	this.dateEmitted.emit('yey')
  }

}
