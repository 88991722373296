import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NoteModel } from '../../../../models/client/note.model';
import { NotesService } from '../../../../services/notes.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { ConfirmPopupComponent } from '../../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from '../../../../models/types/confirmationPopup.enum';

@Component({
	selector: 'app-note-record',
	templateUrl: './note-record.component.html',
	styleUrls: [ './note-record.component.scss' ]
})
export class NoteRecordComponent implements OnInit, OnDestroy {
	@Input() note: NoteModel;
	@ViewChild('note', { static: true }) noteElement: ElementRef<HTMLTextAreaElement>
	@Output() update = new EventEmitter();
	public date: string;
	public time: string;
	public name: string = '';
	private subscriptions: Subscription[] = [];
	public textInput: FormControl = new FormControl('', Validators.required);

	constructor(private noteService: NotesService, private dialog: MatDialog) {
	}

	ngOnInit() {
		this.date = moment.utc(this.note.date).format('DD.MM.YY');
		this.time = moment.utc(this.note.date).format('HH:mm');
		this.name = `${this.note.user.firstName} ${this.note.user.lastName}`;
		this.textInput.setValue(this.note.description);
		const subscription = this.textInput.valueChanges.subscribe(val => {
			this.resizeTextarea();
		});
		this.subscriptions.push(subscription);
		setTimeout(() => {
			this.textInput.disable();
		}, 1);
	}

	ngOnDestroy() {
		for ( const subscription of this.subscriptions ) {
			subscription.unsubscribe();
		}
	}

	public toggleEdit() {
		if (this.textInput.enabled) {
			this.textInput.disable();
		} else {
			this.textInput.enable();
		}
	}

	public deleteClicked() {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveNote,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.deleteNote();
			}
		});
	}

	private deleteNote() {
		const subscription = this.noteService.deleteNote(this.note._id).subscribe(deleted => {
			this.update.emit();
		});
		this.subscriptions.push(subscription);
	}

	public saveChanges() {
		const subscription = this.noteService.updateNote(this.note._id, this.textInput.value).subscribe(success => {
			this.textInput.disable();
		});
		this.subscriptions.push(subscription);
	}

	public resizeTextarea() {
		this.noteElement.nativeElement.style.height = '50px';
		this.noteElement.nativeElement.style.height = `${this.noteElement.nativeElement.scrollHeight}px`;
	}

}
