export const CLIENT_REGISTRATION  = {
	PERSONAL_INFO: 'פרטים אישיים',
	PASSPORT_INFO: 'פרטי דרכון',
	SPECIAL_REQ: 'בקשות מיוחדות'
};

export const FIELDS_PLACEHOLDERS  = {
	TITLE: 'תואר',
	FIRST_NAME: 'שם פרטי',
	LAST_NAME: 'שם משפחה',
	DATE_OF_BIRTH: 'תאריך לידה',
	ID: 'תעודת זהות',
	RELATION: 'קירבה',
	HOME_ADDRESS: 'כתובת בית',
	EMAIL_ADDRESS: 'כתובת דוא״ל',
	LANDLINE_PHONE: 'טלפון בית',
	MOBILE_PHONE: 'טלפון נייד',
	CONTACT_PERSON: 'איש קשר',
	CONTACT_PERSON_PHONE: 'טלפון של איש קשר',
	GENDER: 'מין',
	FIRST_NAME_E: 'שם פרטי (לועזי)',
	LAST_NAME_E: 'שם משפחה (לועזי)',
	PASSPORT_NUM: 'מספר דרכון ישראלי',
	DATE_OF_ISSUE: 'תאריך הוצאה',
	DATE_OF_EXPIRATION: 'תאריך פקיעת התוקף',
	FREQUENT_TRAVELER_NUM: `מס׳ נוסע מתמיד (בחברה הרלוונטית לטיול הנ״ל)`,
	AIRLINE: `חברת התעופה`,
};


export const FIELDS_LABELS  = {
	GENDER: 'מין',
	PREFERRED_SEAT: 'מקום הושבה מועדף',
	SPECIAL_MEAL: 'ארוחות מיוחדות בטיסות',
	EMAIL_UPDATES: 'מעוניין לקבל עדכונים בדוא״ל',
	FOR_COUPLE: 'לזוגות',
	FOR_INDIVIDUAL: 'למטיילים יחידים'
};

export const FIELDS_OPTIONS  = {
	MALE: 'זכר',
	FEMALE: 'נקבה',
	INTERESTED: 'מעוניין',
	NOT_INTERESTED: 'לא מעוניין',
	WINDOW: 'חלון',
	AISLE: 'מעבר',
	REGULAR: 'רגיל',
	KOSHER: 'כשר',
	VEGGIE: 'צמחוני',
	OTHER: 'אחר',
	TWIN_BED: 'מיטה זוגית',
	TWO_BEDS: 'שתי מיטות',
	SINGLE_ROOM: 'חדר יחיד בתוספת תשלום',
	WITH_ROOMMATE: 'מעוניין בשותף לחדר'
};
