export const CurrenciesTitles = [
	'דולר',
	'אירו',
	'שקל',
	'פאונד'
]

export const BankTitles = [
	'לאומי',
	'פועלים',
]

export const paymentTypeTitles = {
	CREDIT: 'אשראי',
	CASH: 'מזומן',
	TRANSFER: 'העברה בנקאית',
	CHEQUE:'צ׳ק'
}

