import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GuideService } from 'src/app/services/guide.service';
import { DestinationService } from 'src/app/services/destination.service';
import { GuideModel } from 'src/app/models/trip/guide.model';

@Component({
	selector: 'app-select-guide',
	templateUrl: './select-guide.component.html',
	styleUrls: ['./select-guide.component.scss']
})
export class SelectGuideComponent implements OnInit {

	public guidesList: GuideModel[] = [];
	public guideId: string;

	constructor(private dialogRef: MatDialogRef<SelectGuideComponent>,
				private destinationService: DestinationService,
				@Inject(MAT_DIALOG_DATA) private destinationId: string,
				private guideService: GuideService) { }

	ngOnInit() {
		this.getGuides();
	}

	private getGuides() {
		this.guideService.getAll().subscribe(guides => {

			this.guidesList = guides;
		});

	}

	public onGuideSelect(guidId: string) {
		this.guideId = guidId;
	}

	public addGuide() {
		this.destinationService.addGuide(this.destinationId, this.guideId).subscribe(resp => {
			this.close(true);
		});
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}

}
