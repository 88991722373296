import { RoomTypeEnum } from './../types/roomType.enum';
import { TripModel } from './../trip/trip.model';
import { AppDocument } from './appDocument';
import { ClientTitle, CLIENT_TITLES } from './clientTitles';
import { FlightFoodEnum } from '../types/flightFood.enum';

export class ClientInfoModel {
	_id: string;
	client: string | ClientModel;

	title: ClientTitle = CLIENT_TITLES[0];
	firstName: string = '';
	lastName: string = '';
	email: string = '';
	phoneNumber: string = '';

	isMale: boolean = true;
	dateOfBirth: Date;
	idNumber: string;
	homeAddress: string;
	homePhoneNumber: string;
	contactUser: string;
	contactUserPhone: string;
	receiveMail: boolean;
	// Passport details
	passportFirstName: string;
	passportLastName: string;
	passportNumber: string;
	passportReleaseDate: Date;
	passportExpiryDate: Date;
	trips: any[];
	frequentNumber:string = '';
	airline:string = '';
	food:FlightFoodEnum = 1;
}
export class RoomModel {
	_id: string;
	sleepers: ClientInfoModel[] = [];
	roomType: RoomTypeEnum = null;
	tripId: string = '';
}
export class ClientModel {
	_id: string;
	personalDetails: ClientInfoModel;
	relatedRegistrationForms: Array<string> | Array<ClientInfoModel>;
	documents: AppDocument[];
	relatives: ClientInfoModel[];
	constructor() {
		this.personalDetails = new ClientInfoModel();
		this.relatedRegistrationForms = [];
		this.documents = [];
	}
}
