export enum PermissionTypesEnum {
	USER_CREATION = 1,
	USER_EDIT,
	USER_REMOVAL,
	OPERATION_CREATION,
	OPERATION_EDIT,
	ACTIVE_TRIP_CLOSE,
	DASHBOARD,
	REPORTS,
	REPORTS_FINANCE
}
