import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TripModel } from 'src/app/models/trip/trip.model';
import { TABLE_COLS_NAMES } from 'src/app/shared/texts';
import { ColSize } from 'src/app/models/types/colSize.enum';
import { TableColModel } from 'src/app/models/client/tableCols.model';
import { MatDialog } from '@angular/material';
import { PricingService } from 'src/app/services/pricing.service';
import { CashExpensesPopupComponent } from '../popups/cash-expenses-popup/cash-expenses-popup.component';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';

@Component({
  selector: 'app-pricing-cash-expenses',
  templateUrl: './pricing-cash-expenses.component.html',
  styleUrls: ['./pricing-cash-expenses.component.scss']
})
export class PricingCashExpensesComponent implements OnInit {

	constructor(public dialog: MatDialog, public pricingService:PricingService) {
		this.createColsTableItem();
	  }
	  public cols: TableColModel[] = [];
	  tableHeight:number = 300;
	  cashExpensesData:any[] = []
	  currentEditIndex:number;
	  @Output() emitStep = new EventEmitter()
	  @Output() getPricingData = new EventEmitter()
	  @Input() tripData:TripModel
	  @Input() currentCurrency:string;

	  ngOnInit() {
		  this.cashExpensesData = this.tripData.pricing.cashExpenses.tableData
	  }

	  private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.CASH_EXPENSES.EXPENSE, 'title', ColSize.MEDIUM, 'priceNotes', undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.CASH_EXPENSES.SUM, 'price', ColSize.MEDIUM, 'price', undefined, undefined));
	}

	onPrint(){
		window.print()
	}

	addCashExpense(cashExpense?): void {
		if(this.tripData.completed && this.tripData.tripClass === 1) return;
		const dialogRef = this.dialog.open(CashExpensesPopupComponent, {
		  position: { left: '0', top: '0' },
		  width: '360px',
		  height: '100vh',
		  data: {cashExpense},
		  disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  if(result){
			  if(cashExpense && cashExpense.data){
				  this.cashExpensesData[cashExpense.index] = result
			  } else {
				  this.cashExpensesData.push(result)
			  }
			  let data = {
				pricingId:this.tripData.pricing._id,
				dataToUpdate: this.cashExpensesData,
				fieldToUpdate:'cashExpenses',
				tripId:this.tripData._id
			}
			  this.pricingService.updatePricingFlights(data).subscribe( res => {
				  this.getPricingData.emit(this.tripData._id)
			  })
		  }
		});
	  }

	  removeItem(itemToRemove){
		let index = this.cashExpensesData.findIndex( item => {
			return item === itemToRemove
		})
		this.cashExpensesData.splice(index,1)
		let data = {
			pricingId:this.tripData.pricing._id,
			dataToUpdate: this.cashExpensesData,
			fieldToUpdate:'cashExpenses'
		}
		  this.pricingService.updatePricingFlights(data).subscribe( res => {
			  this.getPricingData.emit(this.tripData._id)
		  })
	  }

	  goStep(stepNumber){
		if(!this.tripData.pricing.generalDetails.includeFreeTravelers  && stepNumber === 5) stepNumber = 6
		this.emitStep.emit(stepNumber)
	}

	public deleteCashExpenses(itemToRemove) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveCash,
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if(confirm){
				this.removeItem(itemToRemove)
			}
		});
	}

}
