import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES, PROVIDER_FORM_PLACEHOLDERS } from 'src/app/shared/texts';
import { GuideService } from 'src/app/services/guide.service';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { NewGuideComponent } from '../new-guide/new-guide.component';
import { ProvidersService } from 'src/app/services/providers.service';
import { ProviderModel } from 'src/app/models/trip/provider.model';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { DepartmentService } from 'src/app/services/department.service';
import { CurrencyOptionsHedlines } from 'src/app/models/trip/finance.enum';
import { CurrenciesTitles } from 'src/app/models/types/currencies.strings.enum';

@Component({
  selector: 'app-new-provider',
  templateUrl: './new-provider.component.html',
  styleUrls: ['./new-provider.component.scss']
})
export class NewProviderComponent implements OnInit {


	// public guide: GuideModel = new GuideModel();
	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = PROVIDER_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;
	public editMode: boolean = true;
	departmentsList:DepartmentModel[] = [];
	destinationsList:DestinationModel[] = [];
	currencies = CurrenciesTitles;
	public CurrencyOptionsHedlines = CurrencyOptionsHedlines

	constructor(private fb: FormBuilder,
		private providersService: ProvidersService,
		private departmentService:DepartmentService,

		private customValidatorsService: CustomValidatorsService,
		@Inject(MAT_DIALOG_DATA) private provider: any,
		private dialogRef: MatDialogRef<NewProviderComponent>) {
		if (!provider) {
			provider = new ProviderModel();
		}
	}

	ngOnInit() {
		this.getDepartments()
		if (!this.provider) {
			this.provider = new ProviderModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		} else {
			this.onDepartmentSelect(this.provider.department as string);
		}
		this.createForm();
	}

	private getDepartments() {
		const subscription = this.departmentService.notAdministrative().subscribe(departmentsList => {
			this.departmentsList = departmentsList;
		});
		this.subscriptions.push(subscription);
	}

	public onDepartmentSelect(departmentId: string) {
		this.departmentService.getDepartmentDestinations(departmentId).subscribe(department => {
			this.destinationsList = department.destinations;
		});
	}



	private createForm() {
		const { name,accountNumber, destination, department, phone, phoneEmergency, email, address, beneficiary, iban, swiftRouting, currency, bankAddress, notes} = this.provider;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			accountNumber:new FormControl(accountNumber),
			destination:new FormControl(destination),
			department:new FormControl(department),
			phone:new FormControl(phone),
			phoneEmergency:new FormControl(phoneEmergency),
			email: new FormControl(email, {
				asyncValidators: this.customValidatorsService.asyncValidateEmail(),
			}),
			address:new FormControl(address),
			beneficiary:new FormControl(beneficiary),
			iban:new FormControl(iban),
			swiftRouting:new FormControl(swiftRouting),
			currency:new FormControl(currency),
			bankAddress:new FormControl(bankAddress),
			notes:new FormControl(notes),
	})
}


	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const provider = this.form.value;
		if (this.provider._id) {
			provider._id = this.provider._id;
			this.updateProvider(provider);
		} else {
			this.createProvider(provider);
		}
	}


	private updateProvider(provider: any) {
		const subscription = this.providersService.update(provider).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createProvider(provider: any) {
		const subscription = this.providersService.create(provider).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}


}
