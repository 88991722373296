import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../../../shared/texts';
import { months, times } from '../../../../utils/times';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeadModel } from '../../../../models/client/lead.model';
import * as moment from 'moment';
import { LeadsService } from '../../../../services/leads.service';
import { TasksService } from '../../../../services/tasks.service';
import { DataService } from '../../../../services/data.service';
import { ClientModel } from '../../../../models/client/client.model';
import { TaskModel } from 'src/app/models/client/task.model';
import { UserModel } from 'src/app/models/client/user.model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-lead-move-to-future',
	templateUrl: './lead-move-to-future.component.html',
	styleUrls: ['./lead-move-to-future.component.scss']
})
export class LeadMoveToFutureComponent implements OnInit {
	public PLACEHOLDERS = FORM_PLACEHOLDERS;
	public INVALID_FORM = FORM_MESSAGES;
	public readonly months = months;
	public readonly times = times;
	public minDate = moment().add(-1, 'days');
	public readonly years = [];
	@Input() lead: LeadModel;
	@Output() saveCompleted = new EventEmitter();
	@Output() close = new EventEmitter();
	@Output() back: EventEmitter<void> = new EventEmitter();
	public form: FormGroup;
	public waitingResponse: boolean = false;
	public client: ClientModel;

	private subscriptions: Subscription[] = [];

	constructor(private fb: FormBuilder,
		private dataService: DataService,
		private tasksService: TasksService,
		private leadService: LeadsService) {
		this.createForm();
		// this.createYearsArray();
	}

	get controls() {
		return this.form.controls;
	}

	public backClicked() {
		this.back.emit();
	}

	ngOnInit() {
		this.client = this.lead.client as ClientModel;
	}

	public closeClicked() {
		this.close.emit();
	}

	public save() {
		const client = this.lead.client as ClientModel;
		const supervisor = this.lead.supervisor as UserModel;
		console.log('this.lead.client', this.lead.client)
		console.log('this.lead', this.lead)
		this.waitingResponse = true;
		const { date, time } = this.form.controls;
		const userName = `${client.personalDetails.firstName} ${client.personalDetails.lastName}`
		const timeArray = time.value.split(':');
		const realDateMoment = moment(date.value);
		const dueDate = moment.utc().year(realDateMoment.year()).month(realDateMoment.month())
			.date(realDateMoment.date()).startOf('day').hour(Number(timeArray[0])).minutes(timeArray[1]).toISOString(true);
		this.leadService.future(this.lead._id, dueDate, supervisor._id, userName).subscribe(() => {
			this.saveCompleted.emit(dueDate);
			this.waitingResponse = false;
		}, err => {
			this.waitingResponse = false;
		});
		// const newTask: TaskModel = {
		// 	dueDate,
		// 	title: userName,
		// 	lead: this.lead._id,
		// 	user: supervisor._id
		// };
		// this.waitingResponse = true;
		// const subscription: Subscription = this.tasksService.addNewTask(newTask).subscribe(success => {
		// 	this.waitingResponse = false;
		// 	this.dataService.getUserTasks();
		// 	this.backClicked();
		// }, (err) => {
		// 	this.waitingResponse = false;
		// });
		// this.subscriptions.push(subscription);
		// const dueDate = moment().utc().startOf('month').endOf('day').year(year.value).month(month.value - 1).toDate();
		// this.leadService.future(this.lead._id).subscribe(() => {
		// 	// this.addFutureLeadToTasks();
		// 	this.saveCompleted.emit('');
		// });
	}

	private createForm() {
		this.form = this.fb.group({
			date: new FormControl('', Validators.required),
			time: new FormControl('', Validators.required)
		});
		// this.form = this.fb.group({
		// 	month: new FormControl('', Validators.required),
		// 	year: new FormControl('', Validators.required)
		// });
	}

	private createYearsArray() {
		for (let i = 0; i < 20; i++) {
			const date = moment();
			date.add(i, 'years');
			this.years.push(date.year());
		}

		this.form.controls.year.setValue(this.years[0]);
	}

	private addFutureLeadToTasks() {
		const { month, year } = this.form.controls;
		const dueDate = moment().utc().startOf('month').startOf('day').year(year.value).month(month.value - 1).hour(10).minute(0).toISOString(true);
		const task: TaskModel = new TaskModel();
		task.lead = this.lead._id;
		task.title = 'תזכורת: מכירה עתידית';
		task.dueDate = dueDate;
		this.tasksService.addNewTask(task).subscribe(success => {
			this.dataService.getUserTasks();
			this.saveCompleted.emit(dueDate);
		});
	}
}
