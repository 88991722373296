import { NotesAndRequestsModel } from "./../../../models/trip/notesAndRequests.model";
import {
	ClientInfoModel,
	ClientModel
} from "./../../../models/client/client.model";
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import {
	FormGroup,
	Validators,
	FormBuilder,
	FormControl
} from "@angular/forms";
import { LeadPreferencesService } from "../../../services/lead-preferences.service";
import { LeadModel } from "src/app/models/client/lead.model";
import { TripModel } from "src/app/models/trip/trip.model";
import { RoomTypeEnum } from "src/app/models/types/roomType.enum";
import { FlightSitEnum, FlightSits } from "src/app/models/types/flightSit.enum";
import { LeadsService } from "src/app/services/leads.service";
import { ClientService } from "src/app/services/client.service";

@Component({
	selector: "app-trip-notes-and-requests",
	templateUrl: "./trip-notes-and-requests.component.html",
	styleUrls: ["./trip-notes-and-requests.component.scss"]
})
export class TripNotesAndRequestsComponent implements OnInit {
	@Input() lead: LeadModel;
	@Input() trip: TripModel;
	@Input() clientInfo: ClientInfoModel;
	@Output() emitGetUpdatedLead = new EventEmitter();
	public form: FormGroup;
	public isEdit: boolean = false;
	public primaryDetails: NotesAndRequestsModel;
	public details: NotesAndRequestsModel[] = [];
	public RoomTypeEnum = RoomTypeEnum;
	public FlightSitEnum = FlightSitEnum;
	public FlightSits = FlightSits;
	public backgroundColors: string[]; // = ['white', 'white', 'white', '#f8f8f8']
	public currentlyShownModel: ClientInfoModel;
	public currentIndex: number = 0;

	constructor(
		private fb: FormBuilder,
		public leadService: LeadsService,
		public clientService: ClientService
	) {}

	get relatives() {
		return (this.lead.client as ClientModel).relatives;
	}

	get client() {
		return this.lead.client as ClientModel;
	}
	get controls() {
		return this.form.controls;
	}

	private getDetailsArray() {
		//this.details.forEach(cell => { cell = new NotesAndRequestsModel() });
		this.details.push(this.primaryDetails);
		if (this.lead.lead_preferences) {
			this.details[0] = this.lead.lead_preferences;
		}
		this.relatives.forEach(relative => {
			relative.trips.forEach(async trip => {
				if (trip.lead_preferences) {
					this.details.push(trip.lead_preferences);
				}
			});
		});
	}

	ngOnInit() {
		this.getDetailsArray();
		this.currentlyShownModel = this.client.personalDetails;
		this.backgroundColors = Array(this.relatives.length + 1).fill("white");
		this.backgroundColors[0] = "#f8f8f8";

		this.createForm();
	}

	createForm() {
		this.form = this.fb.group({
			room_split: new FormControl(null, Validators.required),
			room_notes: new FormControl(""),
			flight_sit: new FormControl(null, Validators.required),
			flight_notes: new FormControl("")
		});
		if (this.details && this.details[this.currentIndex]) {
			this.form.patchValue({
				room_split: this.details[this.currentIndex].room_split,
				room_partner: this.details[this.currentIndex].room_partner,
				room_notes: this.details[this.currentIndex].room_notes,
				flight_sit: this.details[this.currentIndex].flight_sit,
				flight_notes: this.details[this.currentIndex].flight_notes
			});
		}
	}

	startEdit() {
		this.isEdit = true;
		this.createForm();
	}

	save() {
		const leadId = this.lead._id;
		let lead_preferences = this.form.value;
		if (this.details[this.currentIndex] && this.currentIndex === 0) {
			this.leadService
				.updateLeadPrefs(lead_preferences, this.lead._id)
				.subscribe(res => {
					this.details[this.currentIndex] = lead_preferences;
					this.emitGetUpdatedLead.emit("");
					this.isEdit = false;
				});
		} else if (this.details[this.currentIndex] && this.currentIndex > 0) {
			this.clientService
				.updateRelativePrefs(
					this.relatives[this.currentIndex - 1]._id,
					this.trip._id,
					lead_preferences
				)
				.subscribe(res => {
					this.details[this.currentIndex] = lead_preferences;
					this.emitGetUpdatedLead.emit("");
					this.isEdit = false;
				});
		}
	}

	cancel(prefs: NotesAndRequestsModel) {
		this.isEdit = false;
	}

	async getUpdatedLead(prefs: NotesAndRequestsModel) {
		this.details[this.currentIndex] = prefs;
		this.emitGetUpdatedLead.emit("");
	}

	public onRelativesIndividualClicked(index: number) {
		this.currentIndex = index;
		this.currentlyShownModel =
			index == 0
				? this.client.personalDetails
				: this.client.relatives[index - 1];
		// this.formPassport.reset(this.currentlyShownModel);
		if (this.details[this.currentIndex] != null) {
			this.form.reset(this.details[this.currentIndex]);
		} else {
			this.createForm();
		}
		this.backgroundColors.fill("white");
		this.backgroundColors[index] = "#f8f8f8";
	}

	public getStyle(i: number) {
		const color = this.backgroundColors[i];
		const weight = color == "white" ? "normal" : "bold";
		return { "font-weight": weight, "background-color": color };
	}
	public getCroppedName(name: string) {
		if (name.length > 6) return name.slice(0, 6) + "...";
		return name;
	}
}
