import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelService } from '../../services/hotel.service';
import { CityService } from '../../services/city.service';
import { DestinationService } from '../../services/destination.service';
import { TableColModel } from '../../models/client/tableCols.model';
import { TABLE_COLS_NAMES } from '../../shared/texts';
import { ColSize } from '../../models/types/colSize.enum';
import { MatDialog } from '@angular/material';
import { NewCityComponent } from 'src/app/components/storage/new-city/new-city.component';
import { CityModel } from 'src/app/models/trip/city.model';
import { ConfirmPopupComponent } from 'src/app/components/confirm-popup/confirm-popup.component';
import { ConfirmationPopupTypes } from 'src/app/models/types/confirmationPopup.enum';
import { GuideModel } from 'src/app/models/trip/guide.model';
import { GuideService } from 'src/app/services/guide.service';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { DestinationModel } from 'src/app/models/client/destination.model';
import { SelectGuideComponent } from 'src/app/components/storage/select-guide/select-guide.component';

enum TableEnum {
	Cities = 1,
	Guides,
	Suppliers
}


@Component({
	selector: 'app-storage-destination-table-page',
	templateUrl: './storage-destination-table-page.component.html',
	styleUrls: ['./storage-destination-table-page.component.scss']
})
export class StorageDestinationTablePageComponent implements OnInit {

	public tableHeight: number = window.innerHeight - 311;
	public cols: TableColModel[] = [];
	public citiesList: CityModel[] = [];
	public guidesList: GuideModel[] = [];
	public destination: DestinationModel = new DestinationModel();
	public department: DepartmentModel = new DepartmentModel();
	public showingPage: TableEnum = TableEnum.Cities;
	public tableEnum = TableEnum;
	private destinationId: string = '';

	constructor(private activatedRoute: ActivatedRoute,
		private router: Router,
		private guideService: GuideService,
		private destinationService: DestinationService,
		private dialog: MatDialog,
		private hotelService: HotelService,
		private cityService: CityService) {
	}

	ngOnInit() {
		this.destinationId = this.activatedRoute.snapshot.paramMap.get('id');
		this.createColsTableItem();
		this.getCities();
		// this.getGuides();
	}

	public removeClicked(city: CityModel) {
		const openedDialog = this.dialog.open(ConfirmPopupComponent, {
			width: '500px',
			height: '226px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: ConfirmationPopupTypes.RemoveCity,
		});


		openedDialog.afterClosed().subscribe((success: boolean) => {
			if (success) {
				this.removeCity(city._id);
			}
		});
	}

	private createColsTableItem() {
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.STATE, 'name', ColSize.LONG, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.HOTELS_NUM, 'hotels', ColSize.MEDIUM, undefined, undefined, undefined));
		this.cols.push(new TableColModel(TABLE_COLS_NAMES.COUNTRY.TOURS_NUM, 'activities', ColSize.MEDIUM, undefined, undefined, undefined));
	}

	private getCities() {
		this.cityService.getCitiesByDestination(this.destinationId).subscribe(resp => {
			this.destination = resp;
			this.department = resp.department;
			this.citiesList = resp.cities;
		}, err => {
			console.log('error', err);
			// this.router.navigateByUrl('/app/storage');
		});
	}

	private getGuides() {
		this.destinationService.getGuides(this.destinationId).subscribe(guides => {
			this.guidesList = guides;
		});
	}

	private getSuppliers() {
		// this.guideService.getAll(this.destination._id).subscribe(guides => {
		// 	this.guidesList = guides;
		// });
	}

	public editClicked(city: CityModel) {
		this.openCityForm(city);
	}

	public rowClicked(city: any) {
		this.router.navigateByUrl(`/app/storage/city/${city._id}`);
	}

	public addCityClicked() {
		this.openCityForm();
	}

	private openCityForm(city?) {
		const openedDialog = this.dialog.open(NewCityComponent, {
			position: { left: '0', top: '0' },
			width: '360px',
			height: '100%',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: { city, destination: this.destination._id }
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.getCities();
			}
		});
	}

	private removeCity(cityId: string) {
		this.cityService.removeCity(cityId).subscribe(success => {
			this.getCities();
		});
	}

	public changeShowingPage(toPage: TableEnum) {
		this.showingPage = toPage;
	}

	public addGuideClicked() {
		const openedDialog = this.dialog.open(SelectGuideComponent, {
			width: '360px',
			height: '400px',
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
			data: this.destinationId
		});

		openedDialog.afterClosed().subscribe((success: string) => {
			if (success) {
				this.getGuides();
			}
		});
	}

	public addSupplierClicked() {

	}

	public updateTable() {
		switch (this.showingPage) {
			case TableEnum.Cities:
				this.getCities();
				break;
			case TableEnum.Guides:
				this.getGuides();
				break;
			case TableEnum.Suppliers:
				this.getSuppliers();
				break;
			default:
				break;
		}
	}

}
