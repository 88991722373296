import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { UserModel } from '../../models/client/user.model';
import { TaskModel } from 'src/app/models/client/task.model';
import * as moment from 'moment';
import { TaskStatusEnum } from 'src/app/models/types/taskStatus.enum';
import { MatDialog } from '@angular/material';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { TasksService } from 'src/app/services/tasks.service';
import { TaskNewDuedatePopupComponent } from '../task-new-duedate-popup/task-new-duedate-popup.component';
import { HeaderCategory } from 'src/app/models/header/headerCategory';
import { RoleModel } from 'src/app/models/client/role.model';
import { FilterService } from 'src/app/services/filter.service';


class TaskNotification extends TaskModel {
	closed: boolean = false;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	public activeLink: string;
	public user: UserModel = new UserModel();
	public routerSubscription: Subscription;
	public userSubscription: Subscription;
	public tasksSubscription: Subscription;

	public alertOpen: boolean = false;
	public menuOpen: boolean = false;

	public alerts: TaskNotification[] = [];
	public moment = moment;
	public headerCategories: HeaderCategory[] = [];
	private timer: any;

	constructor(public filterService: FilterService, private router: Router, private dataService: DataService, private dialog: MatDialog, private taskService: TasksService) {
	}

	ngOnInit() {
		this.setHeaderLinks();
		this.setStartingLink();
		this.manageActiveLinkOnRouteChange();
		this.manageUser();
		this.manageTasks();
	}

	ngOnDestroy(): void {
		this.routerSubscription.unsubscribe();
		this.tasksSubscription.unsubscribe();
		this.userSubscription.unsubscribe();
	}

	public logoutClicked() {
		this.dataService.logOut();
	}


	public changePasswordClick() {
		this.dialog.open(ChangePasswordComponent, {
			width: '600px',
			height: '500px',
			position: { top: '15%' },
			panelClass: 'custom-right-dialog-container',
			backdropClass: 'custom-backdropClass',
			disableClose: true,
		});
	}

	public closeAlertBox(e: Event) {
		if (this.alertOpen) {
			this.alertOpen = false;
			e.stopPropagation();
		}
	}

	public closeUserMenu(e: Event) {
		if (this.menuOpen) {
			this.menuOpen = false;
			e.stopPropagation();
		}
	}

	public toggleAlert() {
		if (this.alerts.length > 0) {
			setTimeout(() => {
				this.alertOpen = this.alertOpen ? false : true;
			}, 0);
		}
	}

	public delayTask(delayInfo: any) {
		this.taskService.delayTask(delayInfo.taskId, delayInfo.delayTime).subscribe(resp => {
			this.manageTasks();
		});
	}

	public setNewTaskDate(task: TaskModel) {
		const openedDialog = this.dialog.open(TaskNewDuedatePopupComponent, {
			panelClass: 'custom-dialog-container',
			backdropClass: 'custom-backdropClass',
			data: task
		});

		openedDialog.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.manageTasks();
			}
		});
	}

	public goToLead(leadId: string) {
		this.router.navigateByUrl(`/app/leads/${leadId}`);
	}

	public toggleCategory(category: HeaderCategory) {
		if (category.name === 'מנהלים' && this.user.role['level'] > 2) return;
		const state = !category.isOpen;
		for (const cat of this.headerCategories) {
			cat.isOpen = false;
		}
		category.isOpen = state;
	}
	public deleteTask(taskId: string, i: number) {
		this.taskService.deleteTask(taskId).subscribe(resp => this.alerts.splice(i, 1))
	}

	public goToBasePage() {
		let url;
		switch ((this.user.role as RoleModel).name) {
			case 'מנכל': url = '/app/users'; break;
			case 'מנהל/ת מכירות': url = '/app/leads'; break;
			case 'מנהל/ת שיווק': url = '/app/leads'; break;
			case 'מנהל/ת אופרציה': url = '/app/trips/inprogress'; break;
			case 'מנהל/ת הנהלת חשבונות': url = '/app/trips/inprogress'; break;
			case 'מכירות': url = '/app/leads'; break;
			case 'שיווק': url = '/app/leads'; break;
			case 'מנהל/ת מוצר': url = '/app/trips/inprogress'; break;
			case 'מנהלת משרד': url = '/app/trips/inprogress'; break;
			case 'הנהלת חשבונות': url = '/app/trips/inprogress'; break;
			default: url = '/app/users';
		}

		this.router.navigateByUrl(url);
	}

	public clickOutside(categoryId: string) {
		this.headerCategories.find(hd => hd.id === categoryId).isOpen = false;
	}

	private manageActiveLinkOnRouteChange() {
		this.routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const urlArray = event.url.split('/');
				const activeLink = urlArray[2];
				if (this.activeLink !== activeLink) {
					this.activeLink = activeLink;
				}
			}
		});
	}

	private setStartingLink() {
		const url = this.router.url;
		const urlArray = url.split('/');
		this.activeLink = urlArray[2];
	}

	private manageUser() {
		this.userSubscription = this.dataService.globalUser.subscribe(user => {
			if (user) {
				this.user = user;
			}
		});
	}

	public manageTasks() {
		clearTimeout(this.timer);
		this.dataService.getUserTasks();
		this.tasksSubscription = this.dataService.globalTasks.subscribe(tasks => {
			this.manageTasksChange(tasks);
		});
	}

	private manageTasksChange(tasks: TaskModel[]) {
		this.getUpcomingTasks(tasks);
		this.timer = setTimeout(() => {
			this.dataService.getUserTasks();
		}, 10 * 60 * 1000);

	}

	private getUpcomingTasks(tasks: TaskModel[]) {
		const nextTaskTimeFrame = moment().add(15, 'minutes');
		const retro = moment(1970);


		tasks = tasks.filter((task: TaskModel) => {
			const momentUtcTask = moment.utc(task.dueDate);
			const toCheck = moment().date(momentUtcTask.date()).hour(momentUtcTask.hour()).minute(momentUtcTask.minute());
			return toCheck.isBetween(retro, nextTaskTimeFrame) && task.status === TaskStatusEnum.Open && task['user'] === this.user._id;
		});


		// Remove all the alerts that are not part of tasks;
		this.alerts = this.alerts.filter(item => tasks.some(task => task._id === item._id));

		tasks.forEach(task => {
			const taskInAlert = this.alerts.some(alert => alert._id === task._id);
			if (!taskInAlert) {

				this.alerts.push({ ...task, closed: false } as TaskNotification);
			}
		});

		this.alerts.sort((a: TaskModel, b: TaskModel) => {
			return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
		});

	}

	private setHeaderLinks() {
		const customers: HeaderCategory = new HeaderCategory();
		customers.name = 'מכירות';
		customers.id = 'menuCustomers';
		customers.links.push({ text: 'תיקי לקוח', url: '/app/clients' });
		customers.links.push({ text: 'לידים', url: '/app/leads' });

		const operations: HeaderCategory = new HeaderCategory();
		operations.name = 'אופרציה';
		operations.id = 'menuOperations';
		operations.links.push({ text: 'מאגרים', url: '/app/storage' });
		operations.links.push({ text: 'בניית טיול חדש', url: '/app/trips/new' });
		operations.links.push({ text: 'טיולים בבניה', url: '/app/trips/inprogress' });
		operations.links.push({ text: 'טיולי עבר', url: '/app/trips/past' });

		const trips: HeaderCategory = new HeaderCategory();
		trips.name = 'טיולים';
		trips.id = 'menuTrips';
		trips.links.push({ text: 'טיולים פעילים', url: '/app/trips' });


		const managers: HeaderCategory = new HeaderCategory();
		managers.name = 'מנהלים';
		managers.id = 'menuManagers';
		managers.links.push({ text: 'דוחות', url: '' });
		managers.links.push({ text: 'דשבורד', url: '' });
		managers.links.push({ text: 'ניהול משתמשים', url: '/app/users' });
		managers.links.push({ text: 'תשלום לספקים', url: '/app/finance' });

		this.headerCategories.push(customers);
		this.headerCategories.push(operations);
		this.headerCategories.push(trips);
		this.headerCategories.push(managers);
	}
}
