import { PricesCatagoryModel } from './pricesCatagoryModel';

export class GuideExpensesModel {
	guide?: string;
	title?: string;
	pricesCatagory:PricesCatagoryModel[]
	notes: number;
	priceForDay;


	constructor(pricesCatagoryData : PricesCatagoryModel[]){
		this.pricesCatagory = pricesCatagoryData;
	}
}
