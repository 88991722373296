import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
// Allow decimal numbers and negative values
	private regex: RegExp = new RegExp(/^[0-9]*$/g);
	// Allow key codes for special events. Reflect :
	// Backspace, tab, end, home
	private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-','1','2','3','4','5','6','7','8','9','0' ];

	constructor(private el: ElementRef) {
	}

	@HostListener('keydown', [ '$event' ])
	onKeyDown(event: KeyboardEvent) {
		// Allow Backspace, tab, end, and home keys
		const key = this.specialKeys.indexOf(event.key);
		if ( key === 4 ) {
			return event.preventDefault();
		}

		if ( key !== -1 ) {
			return;
		}
		const current: string = this.el.nativeElement.value;
		const next: string = current.concat(event.key);
		if ( next && !String(next).match(this.regex) ) {
			event.preventDefault();
		}
	}
}
