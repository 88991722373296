import { TaskStatusEnum } from '../types/taskStatus.enum';
import { LeadModel } from './lead.model';

export class TaskModel {
	title: string;
	user?: string;
	lead: string | LeadModel;
	dueDate: string;
	status?: TaskStatusEnum;
	_id?: string;
}
