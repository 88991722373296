import { Component, OnInit, Output, Input,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { months } from '../../../utils/times';
import { DepartmentModel } from 'src/app/models/client/department.mode';
import { VoucherTypes } from 'src/app/models/trip/finance.enum';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material';

@Component({
  selector: 'app-provider-tabs-filter',
  templateUrl: './provider-tabs-filter.component.html',
  styleUrls: ['./provider-tabs-filter.component.scss']
})
export class ProviderTabsFilterComponent implements OnInit {

  constructor(private fb:FormBuilder) {
  }
  @Input() form:FormGroup
  @Input() providers;
  @Input() departments : DepartmentModel[];
  @Output() filterChanged = new EventEmitter();
  public readonly months = months;
  public VoucherTypes = VoucherTypes



  ngOnInit() {
  }

  selectOption(){
	this.filterChanged.emit(this.form.value)
  }

	searchClicked(){
		this.filterChanged.emit(this.form.value)
	}

	clearClicked(){
		this.form.patchValue({searchText:''})
		this.filterChanged.emit(this.form.value)
	}

	get controls() {
		return this.form.controls;
	}

}
