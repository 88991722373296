import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { FORM_MESSAGES, CITY_FORM_PLACEHOLDERS } from '../../../shared/texts';
import { CityService } from 'src/app/services/city.service';
import { CityModel } from 'src/app/models/trip/city.model';

@Component({
	selector: 'app-new-city',
	templateUrl: './new-city.component.html',
	styleUrls: ['./new-city.component.scss']
})
export class NewCityComponent implements OnInit, OnDestroy {
	@Input() city: CityModel = new CityModel();
	public form: FormGroup;
	private subscriptions: Subscription[] = [];
	public readonly FORM_PLACEHOLDERS = CITY_FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public actionTitle: string = 'עריכת';
	public actionBtn: string = 'שמור';
	public waitingResponse: boolean = false;

	constructor(private fb: FormBuilder,
		private cityService: CityService,
		@Inject(MAT_DIALOG_DATA) private data: { city: CityModel, destination: string },
		private dialogRef: MatDialogRef<NewCityComponent>) {
		this.city = this.data.city;
	}

	ngOnInit() {
		if (!this.city) {
			this.city = new CityModel();
			this.actionTitle = 'הוספת';
			this.actionBtn = 'הוסף';
		}
		this.createForm();
	}

	private createForm() {
		const { name,nameInHeb } = this.city;
		this.form = this.fb.group({
			name: new FormControl(name, Validators.required),
			nameInHeb: new FormControl(nameInHeb, Validators.required)
		});
	}

	get controls() {
		return this.form.controls;
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public close(state?: boolean) {
		this.dialogRef.close(state);
	}


	public save() {
		const form = this.form.controls;
		const city = new CityModel();
		city.name = form.name.value;
		city.nameInHeb = form.nameInHeb.value;
		city.destination = this.data.destination;


		if (this.city._id) {
			city._id = this.city._id;
			this.updateCity(city);
		} else {
			this.createCity(city);
		}
	}


	private updateCity(city: CityModel) {
		const subscription = this.cityService.updateCity(city).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

	private createCity(city: CityModel) {
		const subscription = this.cityService.createCity(city).subscribe(success => {
			this.close(true);
		});

		this.subscriptions.push(subscription);
	}

}
