import {Injectable} from '@angular/core';
import {HttpHandlerService} from './http-handler.service';

@Injectable({
	providedIn: 'root'
})
export class AccessService extends HttpHandlerService {

	private url: string = 'auth/';


	public login(email: string, password: string) {
		return this.post(this.url, {email, password});
	}

	public forgot(email: string) {
		const url = this.url + 'forgot';
		return this.post(url, {email});
	}

	public isEmail(email: string) {
		const url = this.url + 'isEmail';
		return this.post(url, {email});
	}
}

