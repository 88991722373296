export interface ClientTitle {
    name: string,
    value: number
}

export const CLIENT_TITLES: ClientTitle[] = [
    {name: 'Mr', value: 1},
    {name: 'Mrs', value: 2},
    {name: 'Miss', value: 3},
    {name: 'Chd', value: 4},
    {name: 'Inf', value: 5},
    {name: 'Guide', value: 6},
    {name: 'Staff', value: 7}
];
