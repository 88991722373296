import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

@Injectable({
	providedIn: 'root'
})

export class FlightService extends HttpHandlerService {
	private url: string = 'flights/';


	public all() {
		const path = 'airports';
		return this.get(this.url + path, {});
	}
}
