import { DayModel } from './day.model';
import { DestinationModel } from '../client/destination.model';
import { DepartmentModel } from '../client/department.mode';
import { TripClassEnum } from '../types/tripClass.enum';
import * as moment from 'moment';
import { HotelMealsEnum } from '../types/hotelMeals.enum';
import { GuideModel } from './guide.model';
import { UserModel } from '../client/user.model';
import { LeadModel } from '../client/lead.model';

export class TripModel {
	_id?: string;
	name: string = '';
	tripClass: TripClassEnum;
	destination: string | DestinationModel;
	secondaryDestinations: DestinationModel[];
	department: string | DepartmentModel;
	departureDate: Date | string = '';
	city:string;
	leads:LeadModel[];
	guide: string | GuideModel = '';
	minTravelers?: number = 0; // has changed - remove later if not in use
	maxTravelers: number = 0;
	days?: DayModel[] = [];
	meal: HotelMealsEnum;
	pricing?:any;
	completed?: boolean;
	supervisor: UserModel | string;
	tcFoc: boolean = false;


	constructor() {
		this.departureDate = moment(this.departureDate).format('DD.MM.YY');
	}

	public getTripClassName?(): string {
		switch (this.tripClass) {
			case TripClassEnum.FIX: return 'טיול חוברת';
			case TripClassEnum.GROUP: return 'קבוצה סגורה';
			case TripClassEnum.FIT: return 'FIT';
			case TripClassEnum.INCENTIVE: return 'Incentive';
			default: return 'N/A';
		}
	}

	public setFormattedDate?() {
		this.departureDate = moment(this.departureDate).format('DD.MM.YY');
	}
}
