import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ClientModel } from 'src/app/models/client/client.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FORM_MESSAGES } from 'src/app/shared/texts';

@Component({
	selector: 'app-two-add-relatives',
	templateUrl: './two-add-relatives.component.html',
	styleUrls: ['./two-add-relatives.component.scss']
})
export class TwoAddRelativesComponent implements OnInit {

	@Input() client: ClientModel
	@Output() close = new EventEmitter();
	@Output() relativesEmit = new EventEmitter();
	isAdding: boolean = false;
	form: FormGroup;
	relativeModelArray:boolean[]
	relativeInputsArray:any[] = []
	public INVALID_FORM = FORM_MESSAGES;

	constructor(public fb: FormBuilder) {
	}

	ngOnInit() {
		this.relativeModelArray = new Array(this.client.relatives.length || 0)
		//this.relativeModelArray = new Array(3)
	}


	openRelativeField(){
		let relative = new RelativeMinimizeModel()
		this.relativeInputsArray.push(relative)
	}

	removeFromArray(index){
		this.relativeInputsArray.splice(index,1)
	}

	public get controls() {
		return this.form.controls;
	}

	cancel() {
		this.close.emit(false)
	}

	nextStep(){
		let formStatus:boolean = true;
		this.relativeInputsArray.forEach( relative =>{
			if(!relative.firstName || !relative.lastName){
				formStatus = false
			}
		})
		if(!formStatus) return;
		
		let data = {
			existRelatives:this.checkCheckboxesAndCreateArray(),
			newRelatives:this.relativeInputsArray
		}
		this.relativesEmit.emit(data)
	}

	checkCheckboxesAndCreateArray(){
	
		let booleanArray = this.relativeModelArray
		let updatedArray = []
		this.client.relatives.forEach( (member,i) =>{
			if(booleanArray[i]){
				updatedArray.push(member)
			}
		})
		return updatedArray;
	}


}

export class RelativeMinimizeModel {
	firstName: string;
	lastName: string;
}
