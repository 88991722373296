import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeadsService } from '../../services/leads.service';
import { TasksService } from '../../services/tasks.service';
import { TaskModel } from '../../models/client/task.model';
import { LeadModel } from '../../models/client/lead.model';
import { ClientModel } from '../../models/client/client.model';
import { PaginationModel } from '../../models/client/pagination.model';
import * as moment from 'moment';
import { LeadQueryModel } from 'src/app/models/request/leadQuery.model';
import { Subscription } from 'rxjs';
import { times } from 'src/app/utils/times';
import { FORM_MESSAGES, FORM_PLACEHOLDERS } from '../../shared/texts';
import { DataService } from 'src/app/services/data.service';
import { FilterService } from 'src/app/services/filter.service';
import { UserModel } from 'src/app/models/client/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-new-task',
	templateUrl: './new-task.component.html',
	styleUrls: ['./new-task.component.scss']
})
export class NewTaskComponent implements OnInit, OnDestroy {



	@Output() close: EventEmitter<void> = new EventEmitter();
	@Output() back: EventEmitter<void> = new EventEmitter();
	@Input() lead: LeadModel;
	public form: FormGroup;
	public timeArr = times;
	public readonly FORM_PLACEHOLDERS = FORM_PLACEHOLDERS;
	public readonly INVALID_FORM = FORM_MESSAGES;
	public leadList: LeadModel[] = [];
	public waitingResponse: boolean = false;
	private subscriptions: Subscription[] = [];
	public client: ClientModel;
	public user: UserModel
	public users: UserModel[]

	constructor(private fb: FormBuilder,
		public filterService: FilterService,
		private tasksService: TasksService,
		private dataService: DataService,
		private userService: UserService,
		private leadsService: LeadsService) {
		this.createForm();
	}

	get controls() {
		return this.form.controls;
	}

	ngOnInit() {
		this.manageUser()
		this.getSystemUsers()
		if (this.lead) {
			this.leadList.push(this.lead);
			this.form.controls.lead.setValue(this.lead._id);
			this.client = (this.lead.client as ClientModel);
			this.form.controls.lead.enable();
		}
	}

	private manageUser() {
		this.dataService.globalUser.subscribe(user => {
			if (user) {
				this.user = user;
				this.form.controls.user.setValue(this.user._id);
			}
		});
	}

	public getSystemUsers() {
		this.userService.getAllUsersWithoutPagination().subscribe(res => {
			this.users = res
		})
	}

	ngOnDestroy() {
		for (const subscription of this.subscriptions) {
			subscription.unsubscribe();
		}
	}

	public closeClicked() {
		this.close.emit();
	}

	public backClicked() {
		this.back.emit();
	}

	public save(dating = null) {
		const form = this.form.controls;
		const { date, time } = this.form.controls;
		const timeArray = time.value.split(':');
		const realDateMoment = moment(date.value);
		let dueDate: any
		if (dating) dueDate = dating
		else dueDate = moment.utc().year(realDateMoment.year()).month(realDateMoment.month())
			.date(realDateMoment.date()).startOf('day').hour(Number(timeArray[0])).minutes(timeArray[1]).toISOString(true);

		const newTask: TaskModel = {
			dueDate,
			title: form.mission.value,
			lead: this.form.controls.lead.value,
			user: this.form.controls.user.value
		};
		this.waitingResponse = true;
		const subscription: Subscription = this.tasksService.addNewTask(newTask).subscribe(success => {
			this.waitingResponse = false;
			this.dataService.getUserTasks();
			this.backClicked();
		}, (err) => {
			this.waitingResponse = false;
		});

		this.subscriptions.push(subscription);
	}

	public onClientSelect(client: ClientModel) {
		const pagination: PaginationModel = new PaginationModel(1000);
		const options: LeadQueryModel = new LeadQueryModel(this.filterService.tripType, this.filterService.currentDates, this.filterService.currentSupervisor, undefined, undefined, client._id, undefined, undefined, undefined, pagination, undefined, false);
		const subscription: Subscription = this.leadsService.getAll(options).subscribe(leads => {
			this.leadList = leads.items;
		});
		this.subscriptions.push(subscription);
		this.form.controls.lead.enable();
	}

	public onLeadSelect(lead: LeadModel) {
		this.lead = lead;
	}

	private createForm() {
		this.form = this.fb.group({
			mission: new FormControl('', Validators.required),
			date: new FormControl('', Validators.required),
			time: new FormControl('', Validators.required),
			phone: new FormControl(''),
			lead: new FormControl({ value: '', disable: true }),
			user: new FormControl(''),
		});
	}

}
