import { ClientInfoModel } from './../../../models/client/client.model';
import { FlightFoodEnum } from './../../../models/types/flightFood.enum';
import { ClientService } from './../../../services/client.service';
import { CustomValidatorsService } from './../../../services/custom-validators.service';
import { FIELDS_PLACEHOLDERS, FIELDS_LABELS, FIELDS_OPTIONS } from './../../../shared/texts/client-registration-info.messages';
import { FORM_MESSAGES } from './../../../shared/texts/form.messages';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-relative-special-requests',
  templateUrl: './add-relative-special-requests.component.html',
  styleUrls: ['./add-relative-special-requests.component.scss']
})
export class AddRelativeSpecialRequestsComponent implements OnInit {
  @Input() relativePersonalDetails: ClientInfoModel;
  @Output() validDetailsInserted = new EventEmitter<ClientInfoModel>();
  @Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formSpecial: FormGroup;
  public INVALID_FORM = FORM_MESSAGES;
  public FIELDS_PLACEHOLDERS = FIELDS_PLACEHOLDERS;
  public FLIGHT_FOOD = FlightFoodEnum;
	public FIELDS_LABELS = FIELDS_LABELS;
	public FIELDS_OPTIONS = FIELDS_OPTIONS;
  constructor(private fb: FormBuilder, private customValidatorsService: CustomValidatorsService, private clientService: ClientService) { }

  get specialControls() {
		return this.formSpecial.controls;
	}
  ngOnInit() {

		this.createForm();
		this.formSpecial.patchValue({
			food: this.FLIGHT_FOOD.Regular
		})
  }

  private createForm() {
    this.formSpecial = this.fb.group({
	  frequentNumber: new FormControl(this.relativePersonalDetails.frequentNumber),//, Validators.required),
	  airline: new FormControl(this.relativePersonalDetails.airline),
    //   chairWindow: new FormControl(true),//, Validators.required),
      food: new FormControl(this.relativePersonalDetails.food),//, Validators.required),
    //   couplesBed: new FormControl(true),//, Validators.required),
    //   lonelySingleBedroom: new FormControl(true),//, Validators.required),
	});
	Object.keys(this.formSpecial.controls).forEach(key => {

		this.formSpecial.controls[key].valueChanges.subscribe(value => this.relativePersonalDetails[key] = value);
	  });

    this.formSpecial.statusChanges.subscribe(status => {
      if(status === 'VALID'){
        this.validDetailsInserted.emit(this.relativePersonalDetails);
        this.validated.emit(true);
      }
    });
  }

  public shortTravelerNum() {
    return this.FIELDS_PLACEHOLDERS.FREQUENT_TRAVELER_NUM.split('(')[0];
  }
}
