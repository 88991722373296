export class ProviderModel {
	_id?: string;
	name: string;
	accountNumber:number;
	destination:string;
	department:string;
	phone:string;
	phoneEmergency:string;
	email:string;
	address:string;
	beneficiary:string;
	iban:string;
	swiftRouting:string;
	currency:number;
	bankAddress:string;
	notes:string;
}
