import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { CityModel } from '../models/trip/city.model';

@Injectable({
	providedIn: 'root'
})
export class CityService extends HttpHandlerService {
	private url: string = 'cities/';

	public getAll(destinationId?: string | string[]) {
		return this.get(this.url, { destinationId: destinationId });
	}

	public getCitiesByDestination(destinationId: string) {
		const path = 'groupByDestination';
		return this.get(this.url + path, { destinationId });
	}

	public getCityById(citysId: string[]) {
		const path = 'id';
		return this.get(this.url + path, { citysId });
	}

	public updateCity(city: CityModel) {
		return this.put(this.url, city);
	}

	public createCity(city: CityModel) {
		return this.post(this.url, city);
	}

	public removeCity(id: string) {
		return this.delete(this.url, { id });
	}
}
