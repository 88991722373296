export enum TripClassEnum {
	FIX = 1,
	GROUP,
	FIT,
	INCENTIVE
}

export const tripTypes = [
'טיול חוברת','קבוצה סגורה','FIT','Incentive'
]
