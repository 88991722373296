import { Pipe, PipeTransform } from '@angular/core';
import formatPhoneNumberUtil from '../utils/formatPhoneNumber';

@Pipe({
	name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

	transform(phoneNumber: string | number, args?: any): any {
		if(!phoneNumber) return 'אין מספר';
        let strToReturn: string = phoneNumber.toString();
		return formatPhoneNumberUtil(strToReturn);
	}

}
