import { ClientInfoModel } from './../models/client/client.model';
import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { LeadModel } from '../models/client/lead.model';
import { LeadQueryModel } from '../models/request/leadQuery.model';
import { LeadCancelEnum } from '../models/types/leadCancel.enum';
import { NoteModel } from '../models/client/note.model';

@Injectable({
	providedIn: 'root'
})
export class LeadsService extends HttpHandlerService {
	private url: string = 'leads/';
	private client: ClientInfoModel;

	public getAll(query: LeadQueryModel) {
		return this.get(this.url, query.toQueryObject());
	}

	public getLeadsReport(query: LeadQueryModel) {
		const path = 'report';
		return this.getBlob(this.url + path, query.toQueryObject())
	}

	public getSoldLeads(clientId: string, status: number) {
		const path = 'byClientId';
		return this.get(this.url + path, { clientId, status });

	}

	public add(lead: LeadModel, userId: string) {
		const path = `newLead?id=${userId}`;
		return this.post(this.url + path, lead);
	}

	public updateLeadPrefs(leadPrefs: any, leadId: string) {
		const path = `updatePrefs?leadId=${leadId}`;
		return this.put(this.url + path, leadPrefs);
	}

	public getLeadProfile(id: string) {
		const path = 'profile';
		return this.get(this.url + path, { id });
	}

	public close(id: string, rejectedReason: LeadCancelEnum, rejectedReasonOther: string, tripId: string) {
		const path = 'close';
		const body: { id: string, rejectedReason: LeadCancelEnum, rejectedReasonOther?: string, tripId: string } = {
			id,
			rejectedReason,
			tripId
		};
		if (rejectedReasonOther) {
			body.rejectedReasonOther = rejectedReasonOther;
		}

		return this.put(this.url + path, body);
	}

	public future(id: string, dueDate: string, user: string, title: string) {
		const path = 'future';

		const body: { id: string, dueDate: string, user: string, title: string } = {
			id,
			dueDate,
			user,
			title
		};

		return this.put(this.url + path, body);
	}

	public noAnswer(id: string, dueDate: string) {
		const path = 'notAnswered';

		const body: { id: string, dueDate: string } = {
			id,
			dueDate
		};

		return this.put(this.url + path, body);
	}

	public sale(id) {
		const path = 'sale';
		return this.put(this.url + path, { id });
	}

	public active(id) {
		const path = 'active';
		return this.put(this.url + path, { id });
	}

	public sold(id) {
		const path = 'sold';
		return this.put(this.url + path, { id });
	}

	public getNotes(id) {
		const path = 'notes';
		return this.get(this.url + path, { leadId: id });
	}

	public saveNot(note: NoteModel) {
		const path = 'notes';
		return this.post(this.url + path, { note });
	}

	public getLeadSources() {
		const path = 'leadSources';
		return this.get(this.url + path, {});
	}

	public updateLead(lead: LeadModel, leadId: string) {
		const path = `?id=${leadId}`;
		return this.put(this.url + path, lead);
	}

	public registerTraveler(tripId: string, lead) {
		const path = 'addToTrip';
		return this.put(this.url + path, { tripId, lead });

	}

	public registerLeadWithMembers(data) {
		const path = 'leadWithMembers';
		return this.post(this.url + path, data);
	}

	public getTripLeads(tripId: string) {
		const path = 'byTripId';
		return this.get(this.url + path, { tripId });

	}

	public getTripActiveClients(tripId: string) {
		const path = 'activeTrip';
		return this.get(this.url + path, { tripId });

	}

	public storeActiveClient(client: ClientInfoModel) {
		this.client = client;
	}






}
