export const LEAD_TYPES = {
	NEW: 'פניות חדשות',
	SALES: 'תהליך מכירה',
	FUTURE: 'לידים עתידיים',
	ACTIVE: 'מטיילים פעילים',
	SOLD: 'לידים שנמכרו',
	CANCELLED: 'לידים שבוטלו',
	FAIL: 'לידים שנכשלו'
};

export const LEAD_SALE_TYPES = {
	CALLS: 'סיכומי שיחות',
	TASKS: 'משימות',
};

export const LEAD_TYPES_Enum = {
	1: 'פניות חדשות',
	2: 'לידים עתידיים',
	3: 'תהליך מכירה',
	4: 'מטיילים פעילים',
	5: 'לידים שנמכרו',
	6: 'לידים שנמכרו',
	7: 'לידים שנכשלו'
};

