import { LeadStatusEnum } from './../models/types/leadStatus.enum';
import { ClientInfoModel } from './../models/client/client.model';
import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { PaginationModel } from '../models/client/pagination.model';
import { SortableColModel } from '../models/client/sortableCol';
import { SortTypeEnum } from '../models/types/sortType.enum';
import { AppDocument } from '../models/client/appDocument';
import { TripActiveStatusEnum } from '../models/trip/tripStatus.enum';

@Injectable({
	providedIn: 'root'
})
export class ClientService extends HttpHandlerService {
	private url: string = 'clients/';


	public getAllClients(pagination: PaginationModel, sortableCol: SortableColModel, searchText?: string) {
		const query: any = pagination.toObject();
		query.sort = sortableCol.value;
		query.descending = sortableCol.SortType === SortTypeEnum.Descending;
		if (searchText) {
			query.searchText = searchText;
		}

		return this.get(this.url, query);
	}

	public getProfile(id: string) {
		const path = 'profile';
		return this.get(this.url + path, { id });
	}

	public getClientByPhone(phone) {
		const path = 'phone';

		return this.get(this.url + path, { phone });
	}


	public getClientByEmail(email: string) {
		const path = 'email';

		return this.get(this.url + path, { email });
	}

	public trips(id: string, status: LeadStatusEnum, tripStatus: TripActiveStatusEnum = undefined) {
		const path = 'trips';

		return this.get(this.url + path, { id, status, tripStatus });
	}

	public addDocument(clientId: string, doc: AppDocument) {
		const path = 'document';

		return this.post(this.url + path, { clientId, docURL: doc.url, docName: doc.name, docType: doc.type });
	}

	public getDocuments(id: string) {
		const path = 'document';

		return this.get(this.url + path, { id });
	}

	public deleteDocument(clientId: string, docId: string) {
		const path = 'document';

		return this.delete(this.url + path, { clientId, docId });
	}

	public updatePersonalDetails(personalDetails: ClientInfoModel) {
		const path = 'personalDetails';

		return this.put(this.url + path, personalDetails);
	}

	public updateRelativePrefs(pdId: string, tripId: string, tripPrefs) {
		const path = `clientPrefs?tripId=${tripId}&pdId=${pdId}`;

		return this.put(this.url + path, tripPrefs);
	}

	public deleteClient(clientId: string) {
		return this.delete(this.url, { id: clientId });
	}

	public deleteClientMember(personalDetailsId: string) {
		const path = 'personalDetails';
		return this.delete(this.url + path, { id: personalDetailsId });
	}

	public checkIfPhoneNotExist(phone: string) {
		const path = 'checkPhone';

		return this.get(this.url + path, { phone });
	}

	public addClientMember(info: ClientInfoModel) {
		const path = 'clientMember';

		return this.post(this.url + path, { info });
	}

	public getActiveClient(clientInfoId: string) {

		const path = 'personalDetails'
		return this.get(this.url + path, { personalDetails: clientInfoId });
	}

	public closeFamilyMember(_id: string, trip: string, reason: LeadStatusEnum, other?: string) {
		const path = 'closeMember';
		const request = { id: _id, tripId: trip, rejectedReason: reason }
		if (reason == 1)
			request['otherReason'] = other;
		return this.put(this.url + path, request);
	}

}
