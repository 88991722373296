import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { HotelResModel } from '../models/reservations/hotelRes.model';
import { TransportResModel } from '../models/reservations/transportRes.model';
import { ActivityResModel } from '../models/reservations/activityRes.model';
import { FlightResModel } from '../models/reservations/flightRes.model';
import { MealResModels } from '../models/reservations/mealRes.model';

@Injectable({
	providedIn: 'root'
})
export class ReservationsService extends HttpHandlerService {
	private url: string = 'reservations/';

	public createHotelRes(hotelRes: HotelResModel, dayId: string) {
		const path = 'hotelRes';
		return this.post(this.url + path, {hotelRes, dayId});
	}

	public updateHotelRes(hotelRes: HotelResModel) {
		const path = 'hotelRes';
		return this.put(this.url + path, {hotelRes});
	}

	public deleteHotelRes(hotelResId: string) {
		const path = 'hotelRes';
		return this.delete(this.url + path, {hotelResId});
	}

	public createTransportRes(transferRes: TransportResModel, dayId: string) {
		const path = 'transferRes';
		return this.post(this.url + path, {transferRes, dayId});
	}

	public updateTransportRes(transferRes: TransportResModel) {
		const path = 'transferRes';
		return this.put(this.url + path, {transferRes});
	}

	public createMealRes(mealRes: MealResModels, dayId) {
		const path = 'mealRes';
		return this.post(this.url + path, {mealRes, dayId});
	}

	public updateMealRes(mealRes: MealResModels) {
		const path = 'mealRes';
		return this.put(this.url + path, {mealRes});
	}

	public deleteTransportRes(transferResId: string) {
		const path = 'transferRes';
		return this.delete(this.url + path, {transferResId});
	}

	public createActivityRes(activityRes: ActivityResModel, dayId: string) {
		const path = 'activityRes';
		return this.post(this.url + path, {activityRes, dayId});
	}

	public updateActivityRes(activityRes: ActivityResModel) {
		const path = 'activityRes';
		return this.put(this.url + path, {activityRes});
	}

	public deleteActivityRes(activityResId: string) {
		const path = 'activityRes';
		return this.delete(this.url + path, {activityResId});
	}


	public createFlightRes(flightRes: FlightResModel, dayId: string) {
		const path = 'flightRes';
		return this.post(this.url + path, {flightRes, dayId});
	}

	public updateFlightRes(flightRes: FlightResModel) {
		const path = 'flightRes';
		return this.put(this.url + path, {flightRes});
	}

	public deleteFlightRes(flightResId: string) {
		const path = 'flightRes';
		return this.delete(this.url + path, {flightResId});
	}

	public deleteMealRes(mealResId: string) {
		const path = 'mealRes';
		return this.delete(this.url + path, {mealResId});
	}

}
